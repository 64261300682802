import React, { useState, useMemo, useEffect } from 'react';
import { socialMediaTypes, ExpandHorizontalSVG, CompressHorizontalSVG, ExpandVerticalSVG, CompressVerticalSVG, TrashSVG, MoveSVG, ArrowLeftSVG, ArrowRightSVG } from "../../../components/Icons";
import useEventData from '../../../hooks/data/useEventData';

export default function Index({ _id, url, type, size, height, onDelete, onWidgetChange, index, isFirst, isLast, onMoveLeft, onMoveRight }) {
    const [ widget, setWidget] = useState({_id, url, type, size, height});
    const [ isVideo ] = useState(type === 'youtube' || type === 'vimeo' || type === 'twitch' || type === 'custom');
    const media = socialMediaTypes.find((s) => s.type === type);

    const mediaClass = useMemo(() => {
			switch(media.type) {
				case 'facebook':
					return 'text-social-facebook';
				case 'twitter':
					return 'text-social-twitter';
				case 'pinterest':
					return 'text-social-pinterest';
				case 'youtube':
					return 'text-social-youtube';
				case 'tiktok':
					return 'text-social-tiktok';
				case 'twitch':
					return 'text-social-twitch';
				case 'vimeo':
					return 'text-social-vimeo';
				case 'custom':
					return 'text-social-custom';
			}
		}, [media]);

    function shouldDelete(e) {
			e.preventDefault();
			let answer = prompt("Type 'DELETE' to confirm.");
			if (answer === 'DELETE') {
				onDelete();
			}
		}

		function moveLeft(e) {
			e.preventDefault();
			onMoveLeft(index);
		}

		function moveRight(e) {
			e.preventDefault();
			onMoveRight(index);
		}

		function changeWidgetSize(change, property) {
			let sizes = ['1/4', '1/2', '3/4', 'full'];
			let initialSize = widget[property] || (property === 'height' && '1/4') || 'full';
			let sizeIndex = sizes.indexOf(initialSize);
			let newWidget = {...widget};
			newWidget[property] = change === 'expand' ? sizes[sizeIndex+1] : sizes[sizeIndex-1];

			setWidget(newWidget);
			onWidgetChange(newWidget);
		}

    return (
		<div className='w-full flex flex-col h-full w-full vendor-card-shadow overflow-hidden rounded relative'>
			<div className={`widgetContainer flex flex-col flex-grow justify-center items-center relative z-0 w-full h-full absolute top-0 left-0 bg-white`}>
				<media.logo classes={`h-20 w-20 fill-current ${mediaClass}`}/>
				<p aria-label={widget.url} className="bg-gray-100 p-2 font-mono rounded text-sm truncate mt-10" style={{maxWidth: '80%'}}>{widget.url}</p>
			</div>

			<section className="w-full flex flex-row flex-grow justify-end items-center p-2 z-40 absolute">
				<div className="flex flex-grow w-full justify-between">
					<div className="flex">
						<div className="hidden lg:flex bg-white shadow rounded cursor-pointer p-2">
							{/*<div><MoveSVG classes="w-6 h-6 mr-2 fill-current opacity-75 text-hub-blue cursor-move" /></div>*/}
							{widget.size !== '1/4' && <div onClick={() => changeWidgetSize('compress', 'size')} ><CompressHorizontalSVG classes="w-6 h-6 fill-current opacity-75 text-hub-blue" /></div> }
							{((isVideo && widget.size !== 'full') || widget.size === '1/4') && <div onClick={() => changeWidgetSize('expand', 'size')} ><ExpandHorizontalSVG classes={`w-6 h-6 fill-current opacity-75 text-hub-blue ${widget.size !== '1/4' && 'ml-2'}`} /></div> }
						</div>
						<div className="flex bg-white shadow rounded cursor-pointer p-2 ml-2">
							{widget.height && widget.height !== '1/4' && <div onClick={() => changeWidgetSize('compress', 'height')}><CompressHorizontalSVG classes="transform rotate-90 w-6 h-6 fill-current opacity-75 text-hub-blue" /></div>}
							{widget.height !== 'full' && <div onClick={() => changeWidgetSize('expand', 'height')}><ExpandVerticalSVG classes="w-6 h-6 fill-current opacity-75 text-hub-blue" /></div>}
						</div>
					</div>
					<div className="bg-white shadow rounded cursor-pointer p-2" onClick={shouldDelete} ><TrashSVG classes="w-6 h-6 fill-current opacity-75 text-red-700" /></div>
				</div>
			</section>

			<section className="w-full flex flex-row flex-grow justify-center items-center p-2 z-40 absolute bottom-0">
				<div className="flex flex-grow justify-center">
					<a href="#" className={`${ isFirst ? 'bg-gray-100 cursor-not-allowed' : 'bg-white hover:bg-gray-50'} shadow rounded cursor-pointer p-2 mr-3`} onClick={ moveLeft } ><ArrowLeftSVG classes="w-6 h-6 fill-current opacity-75" /></a>
					<a href="#" className={`${ isLast ? 'bg-gray-100 cursor-not-allowed' : 'bg-white hover:bg-gray-50'} shadow rounded cursor-pointer p-2`} onClick={ moveRight } ><ArrowRightSVG classes="w-6 h-6 fill-current opacity-75" /></a>
				</div>
			</section>

		</div>
    );
}
import React, { useMemo } from 'react';
import VillageLink from '../VillageLink';
import { useTrackers } from '../../utils/analytics';
import { Link } from 'react-router-dom';
import useEventData from "../../hooks/data/useEventData";
import useToggle from "../../hooks/helper/useToggle";
import EventHubImg from '../../assets/powered_by_eh_trans.png';
import { FacebookSqSVG, TwitterSqSVG, InstagramSqSVG, LinkSVG } from '../Icons';
import useWhiteLabelSlugs from "../../hooks/app/useWhitelabelSlugs";

export default function NavBar({ hideHeaderLinks }) {
	const trackers = useTrackers();
	const { eventSlug, boothSlug } = useWhiteLabelSlugs();
	const [ eventProfile ] = useEventData();
	const [ openMenu, setOpenMenu ] = useToggle();
	const { advanceStyles: { brandText, brandColor, linkText }} = eventProfile;
	const inEventPage = useMemo(() => !boothSlug, [boothSlug])

	const headerLinks = eventProfile.headerLinks;
	const hasHeaderLinks = headerLinks && headerLinks.length;

	return (
		<>
			<header id="header-bar" className="main-village-header flex flex-fix justify-between shadow-lg flex-col sm:flex-row relative z-30" style={{ backgroundColor: brandColor, color: brandText }}>
				{inEventPage && <>
					<div id="skip-to-search">
						<a href="#search-exhibitors" className="skip-content-button">skip to search exhibitors</a>
					</div>
					<div id="skip-to-event-description">
						<a href="#event-description" className="skip-content-button">skip to event description</a>
					</div>
					<div id="skip-to-booths">
						<a href="#booths" className="skip-content-button">skip to booths</a>
					</div>
				</>}
				{!inEventPage && <>
					<div id="skip-to-booth-description">
						<a href="#booth-description" className="skip-content-button">skip to booth description</a>
					</div>
					<div id="skip-to-display-items">
						<a href="#display-items" className="skip-content-button">skip to display items</a>
					</div>
				</>}

				<section className="flex flex-col sm:flex-row flex-grow py-3 sm:py-2">
					<div className="flex items-center justify-between sm:justify-center">
						<div className="flex items-center">
							<Link to={`/${ !window.white_label_slug ? eventSlug : '' }`} className="mx-2 sm:mx-0 sm:ml-8 lg:ml-10 xl:ml-32 flex">
								{ eventProfile.headerLogo ? <img src={eventProfile.headerLogo} alt={`${eventProfile.name} Home`} className="w-auto h-16" /> : <span className="h-16 block">&nbsp;</span>	 }
							</Link>

							<section className="flex-grow flex-col mx-2 w-3/5 sm:w-auto sm:mx-0 sm:ml-4">
								{ eventProfile.showNameInHeader && <Link to={`/${ !window.white_label_slug ? eventSlug : '' }`}>
									<h1 className={`event-name text-lg sm:text-xl align-middle flex items-center text-left leading-none mb-1`} style={{ color: brandText }}>{eventProfile.name}</h1>
								</Link>}
							</section>
						</div>
						{ !hideHeaderLinks && ((headerLinks && headerLinks.length > 0) || eventProfile.showHomepageLinkInHeader || eventProfile.showSchedulePageLinkInHeader || eventProfile.showSpeakerPageLinkInHeader) && (
							<button className={`focus:outline-none focus:border-none mx-1 p-2 inline sm:hidden hamburger hamburger--squeeze bg-opacity-25 ${openMenu && 'is-active'}`} type="button" onClick={() => setOpenMenu(!openMenu)}>
								<span className="hamburger-box">
									<span className="hamburger-inner" style={{backgroundColor: brandText}} />
								</span>
							</button>
						) }
					</div>

					{ !hideHeaderLinks && ((headerLinks && headerLinks.length > 0) || eventProfile.showHomepageLinkInHeader || eventProfile.showSchedulePageLinkInHeader || eventProfile.showSpeakerPageLinkInHeader) && (
						<nav className={`main-village-header-links mt-4 mx-4 ${openMenu ? 'flex flex-col' : 'hidden'}`}>
							{ eventProfile.showHomepageLinkInHeader && <Link style={{ color: brandText }} to={`${ !window.white_label_slug ? `/${eventProfile.urlSlug}` : '/' }`}>{ eventProfile.homepageLinkLabel }</Link>}
							{ eventProfile.showSchedulePageLinkInHeader && <Link style={{ color: brandText }} to={!window.white_label_slug ? `/${eventProfile.urlSlug}/schedule` : '/schedule'}>{ eventProfile.schedulePageLinkLabel || 'Schedule' }</Link>}
							{ eventProfile.showSpeakerPageLinkInHeader && <Link style={{ color: brandText }} to={!window.white_label_slug ? `/${eventProfile.urlSlug}/speakers` : '/speakers'}>{ eventProfile.speakerPageLinkLabel || 'Speakers' }</Link>}
							{ hasHeaderLinks ? headerLinks.map((headerLink, index) => <VillageLink key={index} style={{ color: brandText }} eventLabel={headerLink.label} to={ headerLink.url } trackerNames={trackers}> {headerLink.label} </VillageLink>) : null}
						</nav>
					) }

					<section className="event-header-social-links hidden sm:flex flex-grow justify-end flex-col sm:flex-row">
						<nav className={`flex align-middle items-center mt-4 sm:mt-0 pl-3 sm:pl-6 justify-center`} style={{ color: brandText }}>
							{ eventProfile.websiteUrl && <VillageLink eventLabel="Event Website" className="uppercase mr-4" to={ eventProfile.websiteUrl } trackerNames={trackers}>
								<LinkSVG classes="h-5 w-5 fill-current" title={`Visit ${eventProfile.name}'s official website`}/>
							</VillageLink>}
							{ eventProfile.instagramUrl && <VillageLink eventLabel="Event Instagram" className="uppercase mr-4" to={ eventProfile.instagramUrl } trackerNames={trackers}>
								<InstagramSqSVG classes="h-6 w-6 fill-current" title={`Follow ${eventProfile.name} on Instagram`}/>
							</VillageLink>}
							{ eventProfile.facebookUrl && <VillageLink eventLabel="Event Facebook" className="uppercase mr-4" to={ eventProfile.facebookUrl } trackerNames={trackers}>
								<FacebookSqSVG classes="h-6 w-6 fill-current" title={`Follow ${eventProfile.name} on Facebook`}/>
							</VillageLink>}
							{ eventProfile.twitterUrl && <VillageLink eventLabel="Event Twitter" className="uppercase mr-4" to={ eventProfile.twitterUrl } trackerNames={trackers}>
								<TwitterSqSVG classes="h-6 w-6 fill-current" title={`Follow ${eventProfile.name} on Twitter`}/>
							</VillageLink>}
						</nav>
					</section>
				</section>
				<aside className="px-6 hidden sm:flex items-center relative md:ml-4">
					{ eventProfile.sponsorLogo ? <img src={ eventProfile.sponsorLogo } className="h-16" alt="Official Event Sponsor"/> :
						<a href="https://eventhub.net/" target="_blank"><img src={ EventHubImg } className="h-16" alt="Learn More about Event Hub"/></a> }
				</aside>
			</header>
			{ !hideHeaderLinks && ((headerLinks && headerLinks.length > 0) || eventProfile.showHomepageLinkInHeader || eventProfile.showSchedulePageLinkInHeader || eventProfile.showSpeakerPageLinkInHeader) && (
				<section className="hidden sm:flex flex-fix justify-around items-center px-6 py-3 pb-2 bg-white border border-gray-100">
					<div className="container lg:flex mx-auto lg:justify-around lg:items-center">
						<nav className={`main-village-header-links flex-col hidden sm:flex sm:flex-row`}>
							{ eventProfile.showHomepageLinkInHeader && <Link style={{ color: linkText, borderColor: linkText }} to={`${ !window.white_label_slug ? `/${eventProfile.urlSlug}` : '/' }`} className="mr-10 pb-0 hover:underline">{ eventProfile.homepageLinkLabel }</Link>}
							{ eventProfile.showSchedulePageLinkInHeader && <Link style={{ color: linkText, borderColor: linkText }} to={!window.white_label_slug ? `/${eventProfile.urlSlug}/schedule` : '/schedule'} className="mr-10 pb-0 hover:underline">{ eventProfile.schedulePageLinkLabel || 'Schedule' }</Link>}
							{ eventProfile.showSpeakerPageLinkInHeader && <Link style={{ color: linkText, borderColor: linkText }} to={!window.white_label_slug ? `/${eventProfile.urlSlug}/speakers` : '/speakers'} className="mr-10 pb-0 hover:underline">{ eventProfile.speakerPageLinkLabel || 'Speakers' }</Link>}
							{ hasHeaderLinks ? headerLinks.map((headerLink, index) => <VillageLink key={index} style={{ color: linkText, borderColor: linkText }} eventLabel={headerLink.label} className="mr-10 pb-0 hover:underline" to={ headerLink.url } trackerNames={trackers}>{headerLink.label}</VillageLink>) : null}
						</nav>
					</div>
				</section>
			) }
		</>
	);
}

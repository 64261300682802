const MAX_VOLUME = 95;

let animationRequest;

export function volumeAnalyzer(onVolumeChange) {
  navigator.mediaDevices.getUserMedia({
    audio: true
  })
  .then(function(stream) {
    let audioContext = new (window.AudioContext || window.webkitAudioContext)();
    let analyser = audioContext.createAnalyser();
    let microphone = audioContext.createMediaStreamSource(stream);
    let javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(javascriptNode);
    javascriptNode.connect(audioContext.destination);

    function getAverageVolume() {
      let array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      let values = array.reduce((a,b) => a + b);

      let average = (values / array.length);


      onVolumeChange(average < MAX_VOLUME ? average : MAX_VOLUME);

      animationRequest = requestAnimationFrame(getAverageVolume);

    }

    getAverageVolume();
  })
  .catch(function(err) {
    return { message: err };
  });
}

export function stopVolumeAnalyzer() {
  cancelAnimationFrame(animationRequest);
}
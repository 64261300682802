import {useEffect} from "react";
import axios from "axios";
import useConfig from "./useConfig";
import useLocalStorage from "../helper/useLocalStorage";
import useWhiteLabelSlugs from "./useWhitelabelSlugs";

export function simpleMinification(str) {
	return str.trim().replace ( /\s*(;|,|{|})\s*/g, '$1' );
}

export function loadStylesOnDocument(id, styles) {
	let stylesheet = document.getElementById( id );

	if (!stylesheet) {
		stylesheet = document.createElement( "style" );
		stylesheet.id = id;
	}

	stylesheet.innerText = styles;
	document.head.appendChild( stylesheet );
}

export function removeLoadedStylesOnDocument(id) {
	let stylesheet = document.getElementById( id );

	if (stylesheet) {
		stylesheet.remove();
	}
}

export default function useStyles() {
	const { eventSlug } = useWhiteLabelSlugs();
	const { env, apiHost } = useConfig();
	const EVENT_CSS_STYLE_ID = `custom-css-${eventSlug}`;
	const EVENT_CSS_LOCAL_STORAGE_KEY = `${eventSlug}::custom_css`;
	const EVENT_CSS_STYLE_FILENAME = `shop/${ env }/styles/${ EVENT_CSS_STYLE_ID }.css`;
	const EVENT_CSS_STYLE_ABS_PATH = `https://eventlocker.s3.amazonaws.com/${ EVENT_CSS_STYLE_FILENAME }`;
	const [ storedEventCSS, setStoredEventCSS, hasCheckedEventCSSStorage ] = useLocalStorage(EVENT_CSS_LOCAL_STORAGE_KEY);

	function loadCustomStyles() {
		removeLoadedStylesOnDocument();
		axios.get(EVENT_CSS_STYLE_ABS_PATH).then((styles) => {
			console.log('found custom event styles at ', EVENT_CSS_STYLE_ABS_PATH);
			setStoredEventCSS(styles.data);
			loadStylesOnDocument(EVENT_CSS_STYLE_ID, styles.data);
		}).catch(function (err) {
			console.log(err);
			console.log('No custom event styles found at ' + EVENT_CSS_STYLE_ABS_PATH);
		});
	}

	// load stored styles...
	useEffect(() => {
		if (hasCheckedEventCSSStorage && storedEventCSS) {
			loadStylesOnDocument(EVENT_CSS_STYLE_ID, storedEventCSS);
		}
	}, [hasCheckedEventCSSStorage]);

	function saveCustomStylesheet(css) {
		axios.get(`${ apiHost }/sign_s3?file_name=${ EVENT_CSS_STYLE_FILENAME }&file_type=text/css`)
			.then(function (result) {
				let signedRequest = result.data.signed_request.replace('s3.amazonaws.com', 's3-accelerate.amazonaws.com');
				return axios.put(signedRequest, css, {
					headers: {
						'Content-Type': 'text/css',
						'x-amz-acl': 'public-read'
					}
				});
			})
			.then(() => {
				console.log(EVENT_CSS_STYLE_ABS_PATH);
			})
			.catch(function (err) {
				console.log('failed upload');
				console.log(err);
			});
	}

	return {
		saveCustomStylesheet,
		loadCustomStyles,
		stylesheetId: EVENT_CSS_STYLE_ID,
		stylesheetFilename: EVENT_CSS_STYLE_FILENAME,
		stylesheetAbsPath: EVENT_CSS_STYLE_ABS_PATH
	}
}
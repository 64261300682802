import React, { useState } from "react";

export default function Index(props) {
  const [ isDismissed, setDismissed ] = useState(false);

  return (
    <div className={`${isDismissed && 'hidden'} w-full py-1 ${ props.isError ? " bg-red-500" : ' bg-black' } ${props.canDismiss && 'flex'}`}>
      <p className="text-center text-xs w-full text-white">{props.header}</p>
      <p className="text-center" >{props.detail}</p>
      {props.canDismiss && <p className="text-center text-xs w-10 text-white cursor-pointer" onClick={() => setDismissed(true)}>X</p>}
    </div>
  );
}
import React, { useState } from 'react';

export default function useToggle(defaultValue = false) {
	const [ state, setState ] = useState(defaultValue);

	function toggle(value) {
		setState(value !== undefined ? value : !state);
	}

	return Object.assign([ state, toggle ], { state, toggle });
}
import React, { useMemo } from 'react';

import useMode from "../../hooks/app/useMode";
import useConfig from "../../hooks/app/useConfig";
import useCurrentUser from "../../hooks/app/useCurrentUser";
import usePermissions from "../../hooks/app/usePermissions";

const greetings = ['Howdy', 'Hi there', 'Good day', 'Hey there', 'Hi', 'Hello','Hola','Hey','Cheers',"What's up", "G'day mate", "What's new", 'Hiya', 'Yo', 'Greetings'];
const greeting = greetings[Math.floor(Math.random() * greetings.length)];
const IS_BETA = window.location.host.indexOf('beta') > -1;

export default function AdminBar() {
	const { apiHost, appVersion } = useConfig();
	let mode = useMode();
	let [ currentUser, logoutCurrentUser ] = useCurrentUser();
	let { isLoggedIn, isExhibitor, isAdmin }  = usePermissions();
	let version = useMemo(()=> (Object.values(document.getElementsByTagName('script')).filter(script => { return script.src.indexOf('/app.') > 0}).map(script => script.src.split('.'))[0][ IS_BETA ? 3 : 2] || 'local'), [ document.getElementsByTagName('script') ]);

	if (currentUser && !currentUser.token && !isLoggedIn) {
		return null;
	}

	function backToEventHub(e) {
		e.preventDefault();
		let eventScopes = (currentUser.scopes || []).filter(s => s.type === 'event');
		window.location.href = isExhibitor ? `${ process.env.API_HOST }/marketer#history` : `${ process.env.API_HOST }/producer#reports?listing=${(eventScopes[0] || {}).id}`;
	}

	function logout(e) {
		e.preventDefault();
		logoutCurrentUser();
	}

	function gotoView(view) {
		return (e) => {
			e.preventDefault();
			window.location.href = window.location.href.split('?')[0] + `?${ view }`
		}
	}

	return (<div className={`admin-bar flex flex-fix flex-col sm:flex-row justify-between w-full py-2 px-4 leading-none items-center text-center ${ isAdmin ? 'bg-hub-green font-medium': 'bg-gray-900'} text-white text-xs z-40`}>
		<div className="flex flex-col sm:flex-row items-center text-center sm:text-auto">
			<label className="rounded px-2 py-1 bg-white text-black uppercase mr-2">{ apiHost.indexOf('beta') > -1 ? 'beta | ' : isAdmin ? 'prod | ' : '' }App Ver: {appVersion} {apiHost.indexOf('beta') > -1 ? `-(${version})` : ''}</label>
			<p className="">{ greeting }, { currentUser.name }&nbsp;&nbsp;|&nbsp;&nbsp;Change Your View:</p>
			<a href="#" onClick={gotoView('public')} className={`hidden sm:inline ml-1 mr-2 ${mode === 'public' ? 'underline' : 'hover:underline'}`}>Attendee View</a>
			<a href="#" onClick={gotoView('preview')} className={`hidden sm:inline mr-2 ${mode === 'preview' ? 'underline' : 'hover:underline'}`}>Preview Mode</a>
			<a href="#" onClick={gotoView('edit')} className={`hidden sm:inline ${mode === 'edit' ? 'underline' : 'hover:underline'}`}>Edit Mode</a>
		</div>
		<div className="mt-2 sm:mt-0">
			<a href="#" onClick={gotoView('public')} className={`inline sm:hidden mr-4 ${mode === 'public' ? 'underline' : 'hover:underline'}`}>Attendee View</a>
			<a href="#" onClick={gotoView('preview')} className={`inline sm:hidden mr-4 ${mode === 'preview' ? 'underline' : 'hover:underline'}`}>Preview Mode</a>
			<a href="#" onClick={gotoView('edit')} className={`inline sm:hidden mr-4 clearfix ${mode === 'edit' ? 'underline' : 'hover:underline'}`}>Edit Mode</a>
			{ isAdmin && <a href="/admin" className="hover:underline mr-4 mt-2 sm:mt-0">Manage Events</a> }
			{ !currentUser.manualToken && <a href="#" className="hover:underline mr-4 mt-2 sm:mt-0" onClick={ backToEventHub }>Back to EventHub</a> }
			{ !currentUser.manualToken && <a href="#" className="hover:underline mt-2 sm:mt-0" onClick={ logout }>Logout</a> }
		</div>
	</div>);
}

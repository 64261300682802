import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import {TwitterPicker} from "react-color";
import useToggle from "../../hooks/helper/useToggle";
import { TrackSchema as validationSchema } from "../../schemas";
import axios from "axios";
import useCurrentUser from "../../hooks/app/useCurrentUser";
import useEventData from "../../hooks/data/useEventData";
import useConfig from "../../hooks/app/useConfig";
import useNotifications from "../../hooks/app/useNotifications";

export default function TracksModal({ defaultValues, onSave, onDelete, onCloseModal }) {
	const [ currentUser ] = useCurrentUser();
	const [ eventProfile ] = useEventData();
	const { apiHost } = useConfig();
	const [ showColorPicker, togglePicker ] = useToggle();
	const { append: appendNotification } = useNotifications();
	const [ color, setColor ] = useState(defaultValues && defaultValues.color ? defaultValues.color : '#cccccc');
	const [ serverError, setServerError ] = useState('');
	const { register, handleSubmit, setValue, errors, formState: { dirty, isSubmitting } } = useForm({ defaultValues, validationSchema });

	useEffect(() => {
		register({ name: 'color', type: 'custom' }, { required: true });
	},[]);

	function handleColorChange({ hex }) {
		setColor(hex);
		setValue('color', hex);
	}

	function submitData(values) {
		return axios({
			url: `${ apiHost }/village/events/${ eventProfile.id }${defaultValues._id ? `/tracks/${defaultValues._id}`: '/tracks'}`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			},
			data: { ...values, event: eventProfile.id }
		}).then(({ data: track}) => {
			appendNotification('success', `Success! You created the "${values.name}" track.`)
			onSave(track);
		}).catch(e => {
			setServerError(`Failed Saving: ${e.message}`);
		});
	}

	function shouldModalClose() {
		if (!dirty || dirty && confirm("It seems you've enter some info, do you want to close this?")) {
			onCloseModal();
		}
	}

	function shouldDelete(e) {
		e.preventDefault();
		let answer = prompt("Type 'DELETE' to confirm.");
		if (answer === 'DELETE') {
			axios({
				url: `${ apiHost }/village/events/${ eventProfile.id }/tracks/${defaultValues._id}`,
				method: 'DELETE',
				withCredentials: true,
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'Authorization': `Bearer ${ currentUser.token }`
				}
			}).then(() => {
				appendNotification('info', `You deleted the "${defaultValues.name}" track.`)
				onDelete();
			}).catch(e => {
				setServerError(`Error Deleting: ${e.message}`);
			});
		}
	}

	return (<div className="fixed z-50 bottom-0 overflow-auto inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">

		<div className="fixed inset-0 transition-opacity">
			<div className="absolute inset-0 bg-gray-500 opacity-75" />
		</div>

		<div className="bg-white rounded-md shadow-xl transform transition-all sm:w-time-modal" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<form onSubmit={handleSubmit(submitData)} className="mb-0">

				<div className="bg-gray-200 rounded-t-md px-4 py-3 sm:px-6">
					<h3 className="text-lg uppercase leading-6 font-normal text-gray-900">{ defaultValues._id ? 'Edit' : 'Add'} a Track</h3>
				</div>

				<div className="flex flex-col sm:flex-row ">
					<div className="px-6 py-3 flex-grow">

						{ serverError && <div className="rounded py-2 px-3 bg-red-50 text-gray-700 text-sm w-full my-2 mb-3"><strong className="font-medium">Error:</strong>{ serverError }</div> }

						<div className="flex flex-col">
							<div className="flex justify-between">
								<label htmlFor="name" className="block text-xs font-light uppercase leading-normal text-gray-700">Name</label>
							</div>
							<input name="name" id="name" ref={register} className="mt-1 base-input" placeholder="Your Track Name" />
							{ errors.name && <p className="mt-1 text-sm text-red-700">{ errors.name.message }</p> }
						</div>

						<div className="flex mt-2">
							<div className="w-1/2 sm:w-1/3 relative">
								<div className="flex justify-between">
									<label htmlFor="color" className="block text-xs font-light uppercase leading-normal text-gray-700">Color</label>
									{ showColorPicker && <span className='text-xs leading-2 text-gray-500 uppercase tracking-normal inline mr-3 cursor-pointer' onClick={() => togglePicker(false)}>close</span>}
								</div>
								<div className="text-xs leading-5 text-gray-500 uppercase tracking-normal mt-1">
									<div className="rounded-sm h-6 w-full border-gray-800 border-solid border relative cursor-pointer inline-block"
											 style={{backgroundColor: color}} onClick={() => togglePicker(!showColorPicker)} />
									<div className="absolute left-0 mt-2 px-2">
										{ showColorPicker && <TwitterPicker triangle="hide" color={color} onChange={handleColorChange} />}
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>

				<div className="px-4 py-3 flex flex-col-reverse sm:flex-row justify-between items-center border-gray-300 border-t">
					{ defaultValues._id ? <a href="#" className="text-red-700 mx-auto sm:mx-0 mt-2 sm:mt-0" onClick={shouldDelete}>Delete Track</a> : <div />}
					<div className="flex items-center">
						<span className="text-gray-400 mr-2 mt-2 sm:mt-0 cursor-pointer" onClick={shouldModalClose}>Cancel</span>
						<button type="submit" disabled={isSubmitting} className="float-right btn-hub-green sm:w-auto sm:ml-2 mt-2 sm:mt-0 mr-0 sm:mr-0 flex items-center">
							{ !isSubmitting && 'Save Track' }
							{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
						</button>
					</div>
				</div>
			</form>
		</div>

	</div>);
}
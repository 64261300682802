import {ColorWrap, EditableInput, Hue, Swatch} from "react-color/lib/components/common";
import React, {useCallback, useRef} from "react";
import color from "react-color/lib/helpers/color";
import useBlur from "../../../../hooks/helper/useBlur";
import useToggle from "../../../../hooks/helper/useToggle";


let styles = {
	card: {
		width: '276px',
		background: '#fff',
		border: '0 solid rgba(0,0,0,0.25)',
		boxShadow: '0 1px 4px rgba(0,0,0,0.25)',
		borderRadius: '4px',
		position: 'relative',
	},
	body: {
		padding: '15px 9px 9px 15px',
	},
	hash: {
		background: '#F0F0F0',
		height: '30px',
		width: '30px',
		borderRadius: '4px 0 0 4px',
		float: 'left',
		color: '#98A1A4',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	input: {
		width: '100px',
		fontSize: '14px',
		color: '#666',
		border: '0px',
		outline: 'none',
		height: '28px',
		boxShadow: 'inset 0 0 0 1px #F0F0F0',
		boxSizing: 'content-box',
		borderRadius: '0 4px 4px 0',
		float: 'left',
		paddingLeft: '8px',
	},
	hue: {
		height: '15px',
		borderRadius: '3px',
		overflow: 'hidden',
		position: 'relative',
		marginBottom: '8px',
	},
	Hue: {
		radius: '2px',
	},
	picker: {
		width: '15px',
		height: '15px',
		borderRadius: '8px',
		transform: 'translate(-8px, -1px)',
		backgroundColor: 'rgb(248, 248, 248)',
		boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.37)',
	},
	swatch: {
		width: '30px',
		height: '30px',
		float: 'left',
		borderRadius: '4px',
		margin: '0 6px 6px 0',
	},
	clear: {
		clear: 'both',
	}
}

const colorPointer = () => <div style={ styles.picker } />
const colors = ['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF'];
const AdvanceColorPicker = ColorWrap(function ({ onChange, onSwatchHover, hex, hsl, closePicker }) {
	const pickerRef = useRef(null);
	useBlur(pickerRef, closePicker);

	const handleChange = (hexcode, e) => {
		color.isValidHex(hexcode) && onChange({
			hex: hexcode,
			source: 'hex',
		}, e);
	};

	return <div style={ styles.card } ref={ pickerRef }>
		<div style={ styles.body }>

			<div style={ styles.hue }>
				<Hue
					style={ styles.Hue }
					hsl={ hsl }
					pointer={ colorPointer }
					onChange={ onChange }
				/>
			</div>

			{ colors.map((c, i) => {
				return (
					<Swatch
						key={ i }
						color={ c }
						hex={ c }
						style={ styles.swatch }
						onClick={ handleChange }
						onHover={ onSwatchHover }
						focusStyle={{
							boxShadow: `0 0 4px ${ c }`,
						}}
					/>
				)
			}) }
			<div style={ styles.hash }>#</div>
			<EditableInput
				label={null}
				style={{ input: styles.input }}
				value={ hex.replace('#', '') }
				onChange={ handleChange }
			/>
			<div style={ styles.clear } />
		</div>
	</div>
});

export default function ColorPicker({ label, defaultColor = '#eeeeee', handleColorChange }) {
	const color = useRef(defaultColor);
	const [ showColorPicker, toggleColorPicker ] = useToggle();

	const pickerColorChange = useCallback(function ({ hex }) {
		color.current = hex;
		handleColorChange(hex);
	}, [ label, handleColorChange ]);

	return (
		<div className="mt-2">
			<div className="flex items-center">
				<div className="text-sm leading-5 text-gray-500 uppercase tracking-normal flex items-center relative">
					<div className="rounded-full h-4 w-4 border-black border-solid border relative cursor-pointer inline-block"
							 style={{ backgroundColor: color.current }}
							 onClick={() => toggleColorPicker(!showColorPicker)} />
					<div className="absolute top-0 left-0 mt-6 z-10">
						{ showColorPicker && <AdvanceColorPicker color={ color.current } onChange={ pickerColorChange } closePicker={ () => toggleColorPicker(false) } />}
					</div>
				</div>
				<label htmlFor="color" className="block text-base font-light uppercase leading-none text-gray-700 ml-3">{ label }</label>
			</div>
		</div>
	)
}
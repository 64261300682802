import React, { useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/css/css';


export default function CodeEditor({ value, handleChange }) {

	return (<>
		<CodeMirror
			value={value}
			options={{
				mode: 'css',
				smartIndent: false,
			}}
			onBeforeChange={(editor, data, value) => {
				handleChange(value);
			}}
		/>
	</>);
}
import React, { useEffect, useReducer, useMemo, useRef, useState } from "react";
import { ParticipantTile, LargeTile, AudioTile } from "../Tile";
import { ArrowUpSVG, ArrowDownSVG, ExpandSVG } from "../../../../components/Icons";
import CallMessage from "../CallMessage";
import Countdown from "../Countdown";
import {
  INITIAL_STATE,
  CLICK_ALLOW_TIMEOUT,
  PARTICIPANTS_CHANGE,
  CAM_OR_MIC_ERROR,
  FATAL_ERROR,
  callReducer,
  isLocal,
  isScreenShare,
  getMessage
} from "./callState";
import { logDailyEvent } from "../../logUtils";
import { differenceInMilliseconds } from 'date-fns';

// hooks
import { CALL_STATUS } from "../../../../context/sessionCallObject";
import useCallObject from "../../../../hooks/data/useSessionCallObject";

import useDeviceDetect from "../../../../hooks/helper/useDeviceDetect";

// constants
const RECORDING_START = 'recording:start';
const RECORDING_STOP  = 'recording:stop';
const SCREEN_SHARER_CAMERA_POSITIONS = {
  TOP_RIGHT: {top: '0.5rem', right: '1rem', bottom: 'auto', left: 'auto' },
  TOP_LEFT: {top: '0.5rem', right: 'auto', bottom: 'auto', left: '1rem' },
  BOTTOM_RIGHT: {top: 'auto', right: '1rem', bottom: '0.5rem', left: 'auto' },
  BOTTOM_LEFT: {top: 'auto', right: 'auto', bottom: '0.5rem', left: '1rem' },
}

export default function Call({ isMinified, settings, hasHostAccess, endTime }) {
  const detectDevice = useDeviceDetect();
  const [ isRecording, setRecording ] = useState(false);
  const recorderId = useRef("");
  const [ callObject, _, setBoothState ] = useCallObject();
  const [ callState, dispatch ] = useReducer(callReducer, INITIAL_STATE);
  const [ activeParticipant, setActiveParticipant ] = useState('');
  const message = getMessage(callState, settings.videoChat);
  const [ showMuting, setShowMuting ] = useState(false);
  const [ showStoppingVideos, setShowStoppingVideos ] = useState(false);
  const [ collapseSharers, toggleCollapseSharers ] = useState(false);
  const [ sharerCameraDimensions, setSharerCameraDimensions ] = useState({});
  const [ screenSharerCameraPosition, setScreenSharerCameraPosition ] = useState(SCREEN_SHARER_CAMERA_POSITIONS.TOP_RIGHT);
  const resizeable = useRef(null);

  // handle the use of 'app-message' events for non-chat purposes
  useEffect(() => {
    if (!callObject) return;

    function checkIfRecorderLeft(event) {
      if (!event || !event.participant || !event.participant.user_id) {
        return;
      }

      if (recorderId.current === event.participant.user_id) {
        setRecording(false);
        callObject.off('participant-left', checkIfRecorderLeft);
      }
    }

    function handleAppMessage(event) {
      if (!event || !event.data || !event.data.type) return;

      switch(event.data.type) {
        case RECORDING_START:
          setRecording(true);
          recorderId.current = event.data.recorderId;
          callObject.off('participant-left', checkIfRecorderLeft); // remove if it already exists
          callObject.on('participant-left', checkIfRecorderLeft);
          break;
        case RECORDING_STOP:
          setRecording(false);
          recorderId.current = "";
          callObject.off('participant-left', checkIfRecorderLeft);
          break;
      }
    }

    callObject.on('app-message', handleAppMessage);

    return function cleanup() {
      callObject.off('app-message', handleAppMessage);
      callObject.off('participant-left', checkIfRecorderLeft);
    }
  }, [callObject]);

  useEffect(() => {
    if (!callObject) return;

    const events = [
      "participant-joined",
      "participant-updated",
      "participant-left"
    ];

    function handleNewParticipantsState(event) {
      event && logDailyEvent(event);
      dispatch({
        type: PARTICIPANTS_CHANGE,
        participants: callObject.participants()
      });
    }

    // Use initial state
    handleNewParticipantsState();

    // Listen for changes in state
    for (const event of events) {
      callObject.on(event, handleNewParticipantsState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewParticipantsState);
      }
    };
  }, [callObject]);

  function handleNewParticipantDuringRecording(event) {
    if (!event || !event.participant || !event.participant.user_id) {
      return;
    }

    const joinedAt = event.participant.joined_at; // Date()
    const participantAge = differenceInMilliseconds(new Date(), joinedAt); // local tz doesn't matter here since we just care about the difference
    if (participantAge < 60 * 1000) {
      // reason for this check:
      // we need to use participant-updated to send messages to newly joined participants
      // because participant-joined *may* fire before the participant actually joins (so they wouldn't get the following message)
      // but participant-updated also fires during muting/unmuting, etc. so we want to avoid needlessly sending this message
      // ==> only check for the first minute of user's life
      callObject.sendAppMessage({ type: RECORDING_START, recorderId: callObject.participants().local.user_id }, event.participant.user_id);
    }
  }

  useEffect(() => {
    function handlePostMessage(e) {

      if (e && e.data && e.data.action && (e.data.action === 'local-recording-start')) {
        setRecording(true);
        callObject.sendAppMessage({ type: RECORDING_START, recorderId: callObject.participants().local.user_id }, "*");
        callObject.off('participant-updated', handleNewParticipantDuringRecording);
        callObject.on('participant-updated', handleNewParticipantDuringRecording);
      } else if (e && e.data && e.data.action && (e.data.action === 'local-recording-stop')) {
        setRecording(false);
        callObject.sendAppMessage({ type: RECORDING_STOP }, "*");
        callObject.off('participant-updated', handleNewParticipantDuringRecording);
      }
    }
    window.addEventListener("message", handlePostMessage, false);
    return () => {
      window.removeEventListener("message", handlePostMessage);
      if (isRecording) callObject.off('participant-updated', handleNewParticipantDuringRecording);
    }
  }, []);

  useEffect(() => {
    if (!callObject) return;

    const events = [
      'local-recording-start',
      'local-recording-stop',
      "recording-started",
      "recording-stopped",
      "recording-upload-completed",
      "recording-error"
    ];

    function handleRecordState(event) {
      console.log('record event', event);
      if (event && (event.action === 'recording-started' || event.action === 'local-recording-start')) {
        setRecording(true);
        callObject.sendAppMessage({ type: RECORDING_START, recorderId: callObject.participants().local.user_id }, "*");
        callObject.off('participant-updated', handleNewParticipantDuringRecording);
        callObject.on('participant-updated', handleNewParticipantDuringRecording);
      } else {
        setRecording(false);
        callObject.sendAppMessage({ type: RECORDING_STOP }, "*");
        callObject.off('participant-updated', handleNewParticipantDuringRecording);
      }
    }

    // Use initial state
    handleRecordState();

    // Listen for changes in state
    for (const event of events) {
      callObject.on(event, handleRecordState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleRecordState);
      }
      if (isRecording) callObject.off('participant-updated', handleNewParticipantDuringRecording);
    };
  }, [callObject]);

  useEffect(() => {
    if (!callObject) return;

    function handleNewParticipantsState(event) {
      event && logDailyEvent(event);
      console.log('active-speaker-change', event);
      setActiveParticipant(event.activeSpeaker.peerId);
    }

    // Use initial state
    callObject.on('active-speaker-change', handleNewParticipantsState);

    // Stop listening for changes in state
    return function cleanup() {
      callObject.off('active-speaker-change', handleNewParticipantsState);
    };
  }, [callObject]);

  useEffect(() => {
    if (!callObject) return;

    function handleCameraErrorEvent(event) {
      logDailyEvent(event);
      dispatch({
        type: CAM_OR_MIC_ERROR,
        message:
          (event && event.errorMsg && event.errorMsg.errorMsg) || "Unknown"
      });
    }

    // We're making an assumption here: there is no camera error when callObject
    // is first assigned.
    callObject.on("camera-error", handleCameraErrorEvent);

    return function cleanup() {
      callObject.off("camera-error", handleCameraErrorEvent);
    };
  }, [callObject]);

  useEffect(() => {
    if (!callObject) return;

    function handleErrorEvent(e) {
      console.log(e);
      logDailyEvent(e);

      if (e.action === 'error' && e.errorMsg === 'Meeting has ended') {
        setBoothState(CALL_STATUS.booted);
      }

      if (e.action === 'error' && e.errorMsg === 'This meeting is now locked') {
        setBoothState(CALL_STATUS.roomTerminated);
      }

      if (e.action === 'error' && e.errorMsg === 'Meeting is full') {
        setBoothState(CALL_STATUS.roomFull);
      }

      dispatch({
        type: FATAL_ERROR,
        message: (e && e.errorMsg) || "Unknown"
      });
    }

    // We're making an assumption here: there is no error when callObject is
    // first assigned.

    callObject.on("error", handleErrorEvent);

    return function cleanup() {
      callObject.off("error", handleErrorEvent);
    };
  }, [callObject]);

  useEffect(() => {
    const t = setTimeout(() => {
      dispatch({ type: CLICK_ALLOW_TIMEOUT });
    }, 2500);

    return function cleanup() {
      clearTimeout(t);
    };
  }, []);

  function getTiles() {
    let participantTileOrder = [];
    let participantTiles = [];
    let audioOnlyTiles = [];
    let screenShares = [];
    let screenSharerCameraTiles = [];
    let localTile = null;
    // only active speaker or screen share should be large.
    // never local
    // .filter(([id, callItem]) => isMinified && callItem.participant && callItem.participant.owner || isMinified && id === 'local' || !isMinified)
    Object.entries(callState.callItems).forEach(([id, callItem]) => {
      // determine active speaker...
      // const isLarge = isScreenShare(id) || (!isLocal(id) && !containsScreenShare(callState.callItems));
      if (isScreenShare(id)) {
        screenShares.push((<ParticipantTile key={id}
                                            hasHostAccess={hasHostAccess}
                                          videoTrack={callItem.videoTrack}
                                          audioTrack={callItem.audioTrack}
                                          participant={callItem.participant}
                                          isActiveSpeaker={id === activeParticipant}
                                          isLocalPerson={false}
                                          isScreenShare={true}
                                          isLoading={callItem.isLoading} />));
      } else if (isLocal(id) && !isMinified && hasHostAccess) {
        localTile = <LargeTile key={id}
                               hasHostAccess={hasHostAccess}
                               videoTrack={callItem.videoTrack}
                               audioTrack={callItem.audioTrack}
                               participant={callItem.participant}
                               isLocalPerson={isLocal(id)}
                               isLoading={callItem.isLoading} />;
      } else if (isMinified && callItem.participant && !callItem.participant.owner) {
        audioOnlyTiles.push(<AudioTile key={id}
                                       hasHostAccess={hasHostAccess}
                                       audioTrack={callItem.audioTrack}
                                       isLocalPerson={isLocal(id)} />)
      } else if (callItem.participant.video || callItem.participant.audio) {
        participantTileOrder.push(id);
        participantTiles.push((<ParticipantTile key={id}
                                                hasHostAccess={hasHostAccess}
                                          videoTrack={callItem.videoTrack}
                                          audioTrack={callItem.audioTrack}
                                          soundOnly={ false }
                                          participant={callItem.participant}
                                          isActiveSpeaker={id === activeParticipant}
                                          isLocalPerson={isLocal(id)}
                                          isScreenShare={false}
                                          isLoading={callItem.isLoading} />));
      }

      if (callItem.participant && callItem.participant.screen && callItem.participant.video) {
        screenSharerCameraTiles.push((<LargeTile key={id}
                                          hasHostAccess={hasHostAccess}
                                          videoTrack={callItem.videoTrack}
                                          audioTrack={callItem.audioTrack}
                                          participant={callItem.participant}
                                          isActiveSpeaker={id === activeParticipant}
                                          isLocalPerson={isLocal(id)}
                                          isScreenShare={false}
                                          isLoading={callItem.isLoading} />))
      } 
    });
    return [ participantTiles, audioOnlyTiles, screenShares, localTile, participantTileOrder, screenSharerCameraTiles ];
  }

  const [ participantTiles, screenShareTiles, audioOnlyTiles, localTile, screenSharerCameraTiles ] = useMemo(() => {
    const [ participantTiles, audioOnlyTiles, screenShares, localTile, participantTileOrder, screenSharerCameraTiles ] = getTiles();
    const maxDisplayedParticipants = isMinified ? 6 : 9;

    // move the active participant to the front, if it has the potential to get cut off.
    if (activeParticipant && participantTiles.length > maxDisplayedParticipants) {
      const participantTileIndex = participantTileOrder.findIndex( id => id === activeParticipant);
      // is the participant not first, or not in array.
      if (participantTileIndex > 0) {
        const participantTile = participantTiles[participantTileIndex];
        participantTiles.splice(participantTileIndex, 1);
        participantTiles.unshift(participantTile);
      }
    }

    let screenSharerTiles = participantTiles && participantTiles.filter(p => p.props.participant.screen && p.props.participant.video);
    if (localTile && localTile.props.participant.screen && localTile.props.participant.video) {
      screenSharerTiles.push(localTile);
    }

    return [ participantTiles.slice(0, maxDisplayedParticipants), screenShares, audioOnlyTiles, localTile, screenSharerCameraTiles ];
  }, [ callState, activeParticipant ]);

  const networkStats = callObject._network;
  const gridClasses = useMemo(() => {
    if (detectDevice.isMobile() && detectDevice.isPortrait()) {
      return 'grid-cols-1';
    }
    switch (participantTiles.length) {
      case 2:
        return 'grid-cols-2 grid-rows-1';
      case 3:
      case 4:
        return 'grid-cols-2 grid-rows-2';
      case 5:
      case 6:
        return 'grid-cols-3 grid-rows-2';
      case 7:
      case 8:
      case 9:
        return 'grid-cols-3 grid-rows-3';
      default:
        return 'grid-cols-1 grid-rows-1';
    }
  }, [ participantTiles ]);

  function getParticipantIds() {
    return Object.entries(callObject.participants()).filter(([ key, value ]) => key !== 'local' && !value.owner);
  }

  function handleMuteAll(e) {
    e.preventDefault();
    const request = {};
    getParticipantIds().map(([ key ]) => {
      request[key] = {
        setAudio: false
      };
    });
    setShowMuting(true);
    setTimeout(() => setShowMuting(false), 1000);
    callObject.updateParticipants(request);
  }

  function handleStopAllVideo(e) {
    e.preventDefault();
    const request = {};
    getParticipantIds().map(([ key ]) => {
      request[key] = {
        setVideo: false
      };
    });
    setShowStoppingVideos(true);
    setTimeout(() => setShowStoppingVideos(false), 1000);
    callObject.updateParticipants(request);
  }

  function handleBootAll(e) {
    e.preventDefault();
    if (confirm('This will kick out all participants from the booth. Do you want to continue?')) {
      const request = {};
      getParticipantIds().map(([ key ]) => {
        request[key] = {
          eject: true
        };
      });

      callObject.updateParticipants(request);
    }
  }

  function handleBoothTermination(e) {
    e.preventDefault();
    if (prompt('This will delete the booth and kick all participants out of the booth. Enter "confirm" to terminate the booth, otherwise click cancel.') === 'confirm') {
      setBoothState(CALL_STATUS.terminateRoom);
    }
  }

  function handleStartRecording(e) {
    e.preventDefault();
    callObject.startRecording();
  }

  function handleStopRecording(e) {
    e.preventDefault();
    callObject.stopRecording();
  }

  const threshold = useMemo(() => {
    return networkStats.quality > 90 ? 'great' : networkStats.quality > 75 ? 'good' : networkStats.quality > 50 ? 'ok' : networkStats.quality > 40 ? 'poor' : 'bad';
  }, [networkStats.quality])

  const minimumWidth = 215;
  const maxmimumWidth = 445;

  function startDrag(e) {
    e.preventDefault();
    setSharerCameraDimensions({
      width: resizeable.current.getBoundingClientRect().width,
      originalX: resizeable.current.getBoundingClientRect().left,
      originalMouseX: e.pageX,
    });
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResize);
  }

  function resize(e) {
    const width = sharerCameraDimensions.width - (e.pageX - sharerCameraDimensions.originalMouseX);
    if (minimumWidth < width && width < maxmimumWidth) {
      setSharerCameraDimensions(s => ({...s, width: width + 'px'}));
    }
  }

  function stopResize() {
    window.removeEventListener('mousemove', resize);
  }

  return (
  <>
    { message && (<CallMessage header={message.header} detail={message.detail} isError={message.isError} canDismiss={message.canDismiss}/>) }
    <Countdown endTime={endTime} />
    { isRecording && <label className="px-6 py-1 text-xs font-medium text-red-500 uppercase flex items-center"><span className="inline-block h-3 w-3 rounded-full bg-red-500 mr-2" />Recording</label> }
    <div className={`flex flex-col flex-grow-0 ${ !isMinified && 'h-11/12' }`}>
      <div className="flex h-full flex-col sm:flex-row">
        <div className="flex flex-col w-full">
          { screenShareTiles && screenShareTiles.length > 0 && <div className={`w-full h-full grid grid-flow-row grid-cols-1 gap-4 py-2 px-4 justify-center bg-gray-400 relative`}>
            {screenSharerCameraTiles && screenSharerCameraTiles.length > 0 && !detectDevice.isMobile() && 
              <div ref={resizeable} className='screenSharerCamera h-auto absolute top-2 right-4 z-50' style={{width: sharerCameraDimensions.width || `256px`, ...screenSharerCameraPosition}}>
                <div className="w-full flex items-center justify-between h-5 p-1 bg-black rounded text-white">
                    <span className="cursor-pointer" onClick={() => toggleCollapseSharers(c => !c)}>
                      { collapseSharers && screenSharerCameraPosition.top === '0.5rem' && <ArrowDownSVG classes='h-4 w-4 fill-current text-white' title='Show Sharer Cameras'/> }
                      { collapseSharers && screenSharerCameraPosition.bottom === '0.5rem' && <ArrowUpSVG classes='h-4 w-4 fill-current text-white' title='Show Sharer Cameras'/> }
                      { !collapseSharers && screenSharerCameraPosition.top === '0.5rem' && <ArrowUpSVG classes='h-4 w-4 fill-current text-white' title='Hide Sharer Cameras'/> }
                      { !collapseSharers && screenSharerCameraPosition.bottom === '0.5rem' && <ArrowDownSVG classes='h-4 w-4 fill-current text-white' title='Hide Sharer Cameras'/> }
                    </span>
                    <div className="grid grid-cols-2 grid-rows-2 h-4 w-4 gap-px">
                      <span className={`bg-white ${ screenSharerCameraPosition === SCREEN_SHARER_CAMERA_POSITIONS.TOP_LEFT && 'bg-gray-400'} hover:bg-gray-500 rounded-tl-sm cursor-pointer`} title="Move to top left" onClick={() => setScreenSharerCameraPosition(SCREEN_SHARER_CAMERA_POSITIONS.TOP_LEFT)}></span>
                      <span className={`bg-white ${ screenSharerCameraPosition === SCREEN_SHARER_CAMERA_POSITIONS.TOP_RIGHT && 'bg-gray-400'} hover:bg-gray-500 rounded-tr-sm cursor-pointer`} title="Move to top right" onClick={() => setScreenSharerCameraPosition(SCREEN_SHARER_CAMERA_POSITIONS.TOP_RIGHT)}></span>
                      <span className={`bg-white ${ screenSharerCameraPosition === SCREEN_SHARER_CAMERA_POSITIONS.BOTTOM_LEFT && 'bg-gray-400'} hover:bg-gray-500 rounded-bl-sm cursor-pointer`} title="Move to bottom left" onClick={() => setScreenSharerCameraPosition(SCREEN_SHARER_CAMERA_POSITIONS.BOTTOM_LEFT)}></span>
                      <span className={`bg-white ${ screenSharerCameraPosition === SCREEN_SHARER_CAMERA_POSITIONS.BOTTOM_RIGHT && 'bg-gray-400'} hover:bg-gray-500 rounded-br-sm cursor-pointer`} title="Move to bottom right" onClick={() => setScreenSharerCameraPosition(SCREEN_SHARER_CAMERA_POSITIONS.BOTTOM_RIGHT)}></span>
                    </div>
                </div>
                { !collapseSharers && <>
                  { screenSharerCameraTiles }
                </>}
                {!collapseSharers && <div className="rounded bg-black h-5 w-5 p-1 absolute bottom-0 left-0 text-white cursor-resize" onMouseDown={startDrag}><ExpandSVG classes="h-full w-full fill-current" /></div>}
              </div>
            }
            { screenShareTiles }
            { detectDevice.isMobile() && screenSharerCameraTiles }
          </div>}
          
          <div className={`w-full h-full grid grid-flow-row ${ gridClasses } gap-4 py-2 px-4 justify-center bg-gray-400 ${ screenShareTiles && screenShareTiles.length > 0 && 'hidden'}`}>
            {/*<div className="flex flex-grow w-auto rounded-lg items-center overflow-x-auto justify-center my-8">{ !message && largeTiles }</div>*/}
            {/*<div className="py-3 w-full flex justify-center bg-gray-400 overflow-x-auto h-1/3">{ smallTiles }</div>*/}
            { participantTiles }
            { !participantTiles.length && <section className="participantTile transparent w-20" /> }
            {/*<div className="participantTile" />*/}
          </div>
          { audioOnlyTiles }
        </div>
        { localTile && <div className="w-full sm:w-1/3 h-1/2 sm:h-auto flex overflow-y-scroll md:overflow-y-hidden flex-col bg-gray-200 pb-64 sm:pb-0 ">
          <div className="p-4">
            { localTile && !localTile.props.participant.screen && localTile}
          </div>
          <div className="p-4 flex-shrink-0 flex-grow flex flex-col">
            <div className="mb-4">
              <div className="text-sm uppercase font-medium text-black">Booth Stats</div>

              <div className="flex justify-between">
                <p className="text-sm uppercase font-light w-full sm:w-2/3"># of participants</p>
                <p className="text-sm uppercase font-light w-full sm:w-1/3 text-right">{ callState.numberOfParticipants }</p>
              </div>

              <div className="flex justify-between">
                <p className="text-sm uppercase font-light w-full sm:w-1/2">Network Quality</p>
                <p className="text-sm uppercase font-light whitespace-no-wrap w-full sm:w-1/2 text-right"><span>{ networkStats.quality }%&nbsp;</span><label className={`py-1 px-3 rounded inline text-xs ${ threshold === 'great' ? 'bg-green-500 text-white' : threshold === 'good' || threshold === 'Ok' ? 'bg-yellow-500 text-black' : 'bg-red-500 text-white' }`}>{ threshold }</label></p>
              </div>
            </div>

            <div className="text-sm uppercase font-medium text-black">Booth Controls</div>
            <div className="w-full flex flex-shrink-0 justify-between">
              <button onClick={ handleMuteAll } className={`${showMuting ? 'bg-gray-300' : 'bg-white hover:bg-gray-100'} rounded-md text-gray-500 px-4 py-1 mt-2 block text-center w-1/2 mr-2`}>{ showMuting ? 'Muting...' : 'Mute All'}</button>
              <button onClick={ handleStopAllVideo } className={`${showStoppingVideos ? 'bg-gray-300' : 'bg-white hover:bg-gray-100'} rounded-md text-gray-500 px-2 py-1 mt-2 block text-center w-1/2 ml-2`}>{ showStoppingVideos ? 'Stopping..' : 'Stop Videos' }</button>
            </div>
            <div className="w-full flex flex-shrink-0 flex-wrap justify-between">
              {/*{ settings.videoChat.enableRecording && !isRecording && <button onClick={ handleStartRecording } className="bg-white hover:bg-gray-100 rounded-md text-gray-500 px-2 sm:px-4 py-1 mt-2 block text-center w-1/2 sm:w-full mr-2 sm:mr-auto">Record Booth Session</button> }*/}
              {/*{ settings.videoChat.enableRecording && isRecording && <button onClick={ handleStopRecording } className="bg-white hover:bg-gray-100 rounded-md text-gray-500 px-2 sm:px-4 py-1 mt-2 block text-center w-1/2 sm:w-full mr-2 sm:mr-auto">Stop Recording Session</button> }*/}
              <button onClick={ handleBootAll } className="bg-white hover:bg-gray-100 rounded-md text-gray-500 px-2 sm:px-4 py-1 mt-2 block text-center w-1/2 sm:w-full ml-2 sm:ml-auto">Boot All Participants</button>
            </div>
            <button onClick={ handleBoothTermination } className="flex-shrink-0 bg-white border-solid border border-red-500 rounded-md text-red-700 hover:text-white hover:bg-red-500 px-4 py-1 mt-2 block text-center w-full">Terminate Booth</button>
          </div>
        </div> }
      </div>
    </div>
  </>);
}

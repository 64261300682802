import React, { useEffect, useState } from "react";

export default function AskForParticipantName({ onNameSave, title = "Hello! What's Your Name?", placeholderTitle = 'Jane Doe', error, defaultValue }) {
	
	const [ name, setName ] = useState(defaultValue);
	const [ saving, setSaving ] = useState(false);
	const [ displayError, setDisplayError ] = useState('');

	useEffect(() => {
		if (title) {
			setSaving(false);
			setName(defaultValue);
		}
	}, [title])

	function handleNameSave(e) {
		e.preventDefault();

		if (!name) {
			setDisplayError(error);
			return;
		}

		setDisplayError('');
		setSaving(true);
		onNameSave && onNameSave(name);
	}

	return (<section className="flex flex-col items-end justify-end relative">
		<div className="bg-gray-900 sm:h-virtual-booth block w-full">
			<div className="bg-white rounded p-6 mx-1 sm:mx-auto mt-24 w-auto sm:w-2/4 mx-4 sm:mx-0">
				<form onSubmit={ handleNameSave }>
					<h1 className="text-2xl text-center">{title}</h1>
					<div className="flex flex-col sm:flex-row items-center justify-center mt-2">
						<input aria-label="name" type="text" value={ name } onChange={(e) => setName(e.target.value)} className="p-2 px-6 w-full sm:w-auto inline-block text-center text-lg sm:text-xl text-gray-900 border border-gray-500 rounded-lg" placeholder={placeholderTitle} />
						<button type='submit' className="w-full sm:w-auto mt-3 sm:mt-0 bg-blue-500 text-white px-4 py-3 rounded ml-0 sm:ml-4">Enter Booth</button>
						{ saving && <button disabled className="w-full sm:w-auto mt-3 sm:mt-0 bg-blue-500 text-white px-4 py-3 rounded ml-0 sm:ml-4 cursor-not-allowed">Saving...</button> }
					</div>
					{displayError && <p className='text-sm text-red-600 mt-2 ml-32'>{displayError}</p>}
				</form>
			</div>
		</div>
	</section>);
}
import React, { useState, createContext } from 'react';

const INITIAL_STATE = [];

const EventsContext = createContext([{}, () => {}]);

function EventsProvider(props) {
	const [state, setState] = useState(INITIAL_STATE);

	return (
		<EventsContext.Provider value={[state, setState]}>
			{props.children}
		</EventsContext.Provider>
	);
}

export { EventsContext, EventsProvider, INITIAL_STATE };
import React, { useState, useMemo, useCallback, useEffect } from 'react';

const noop = () => {};

const createProcess = (options) => (dataTransfer, event) => {
	if (dataTransfer.files && dataTransfer.files.length) {
		(options.onFiles || noop)(Array.from(dataTransfer.files), event);
		return;
	}
};

export default function useDrop (options = {}, args = []) {
	const { onFiles, onText, onUri } = options;
	const [over, setOverRaw] = useState(false);
	const setOver = useCallback(setOverRaw, []);
	const process = useMemo(() => createProcess(options), [onFiles, onText, onUri]);

	useEffect(() => {
		const onDragOver = event => {
			event.preventDefault();
			setOver(true);
		};

		const onDragEnter = event => {
			event.preventDefault();
			setOver(true);
		};

		const onDragLeave = () => {
			setOver(false);
		};

		const onDragExit = () => {
			setOver(false);
		};

		const onDrop = event => {
			event.preventDefault();
			setOver(false);
			process(event.dataTransfer, event);
		};

		document.addEventListener('dragover', onDragOver);
		document.addEventListener('dragenter', onDragEnter);
		document.addEventListener('dragleave', onDragLeave);
		document.addEventListener('dragexit', onDragExit);
		document.addEventListener('drop', onDrop);

		return () => {
			document.removeEventListener('dragover', onDragOver);
			document.removeEventListener('dragenter', onDragEnter);
			document.removeEventListener('dragleave', onDragLeave);
			document.removeEventListener('dragexit', onDragExit);
			document.removeEventListener('drop', onDrop);
		};
	}, [process, ...args]);

	return Object.assign([ over ], { over });
}
import React, { useState } from 'react';
import useToggle from "../../../hooks/helper/useToggle";
import CreateSocialMediaWidget from '../CreateWidget';
import Index from '../Placeholder';

function move(list, startIndex, endIndex) {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
}

export default function SocialMediaWidgetEditor({widgets, onCloseWidgetEditor, handleUpdateWidgets}) {
	const [ serverError, setServerError ] = useState('');
	const [ socialMediaWidgets, setSocialMediaWidgets ] = useState(widgets || []);
	const [ showWidgetCreator, toggleShowWidgetCreator ] = useState(!widgets || widgets.length === 0);
	const [ isSaving, toggleIsSaving ] = useToggle();

	function updateWidgets() {
		toggleIsSaving(true);
		if (handleUpdateWidgets) {
			handleUpdateWidgets(socialMediaWidgets, onCloseWidgetEditor).catch(e => {
				toggleIsSaving(false);
				setServerError(e.message);
			});
		}
	}

	function addWidget(widget) {
		let newWidgets;

		if (!socialMediaWidgets) {
			newWidgets = [widget];
		} else if (socialMediaWidgets.find(oldWidget => oldWidget.url === widget.url)) {
			alert('This widget has already been created.');
			return;
		} else {
			newWidgets = [widget, ...socialMediaWidgets];
		}

		setSocialMediaWidgets(newWidgets);
		toggleShowWidgetCreator(false);
	}

	function deleteWidget(widgetToDelete) {
		setSocialMediaWidgets(socialMediaWidgets.filter(widget => widget.url !== widgetToDelete.url))
	}

	function reorder(list, startIndex, endIndex) {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	}

	function changeWidget(widget) {
		setSocialMediaWidgets(socialMediaWidgets.map(oldWidget => oldWidget.url === widget.url ? widget : oldWidget));
	}

	function handleMoveLeft(index) {
		setSocialMediaWidgets( widgets => move(widgets, index, index - 1))
	}

	function handleMoveRight(index) {
		setSocialMediaWidgets( widgets => move(widgets, index, index + 1))
	}

	return (<>
		<section className="flex flex-col sm:flex-row mb-6 justify-end items-baseline" >
			<div className="w-full flex flex-wrap justify-between">
				<button className="btn-hub-green-solid sm:w-full lg:w-auto sm:ml-0 sm:mt-0 justify-end" onClick={() => toggleShowWidgetCreator(true)}>Add Widget</button>

				<div>
					{ serverError && <span className="text-red-700 mx-2 mt-2 sm:mt-0 underline">{serverError}</span> }
					{ !isSaving && <span className="text-gray-400 mx-2 mt-2 sm:mt-0 cursor-pointer" onClick={onCloseWidgetEditor} >Cancel Changes</span> }
					{ !isSaving && <button className="btn-hub-green sm:w-auto ml-0 sm:ml-2 sm:mt-0 text-sm" onClick={ updateWidgets }>Save Widget Changes</button> }
					{ isSaving && (
						<button disabled={true} className="btn-hub-green sm:w-auto ml-0 sm:ml-2 sm:mt-0 text-sm flex items-center cursor-not-allowed">
							<span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span>
						</button>
					)}
				</div>
			</div>
		</section>

		{ showWidgetCreator && !socialMediaWidgets.length && <section className="w-full flex flex-col sm:flex-row sm:flex-wrap justify-start">
			<CreateSocialMediaWidget onCloseCreateBox={ () => toggleShowWidgetCreator(false)} addWidget={(widget) => addWidget(widget)} />
		</section>}

		<section className="w-full bg-yellow-50 rounded-md pt-6">
			{ socialMediaWidgets && socialMediaWidgets.length > 0 && <section className="w-full flex flex-col sm:flex-row sm:flex-wrap justify-start">
				{ showWidgetCreator && <CreateSocialMediaWidget onCloseCreateBox={ () => toggleShowWidgetCreator(false)} addWidget={(widget) => addWidget(widget)} /> }
				{ socialMediaWidgets.map((widget, index) => <div key={widget.url} className={`w-full sm:w-full ${widget.type === 'tiktok' ? 'lg:w-auto' : widget.size === '1/4' ? 'lg:w-1/4' : widget.size === '1/2' ? 'lg:w-1/2' : widget.size === '3/4' ? 'lg:w-3/4' : 'lg:w-full'} px-5 mb-6`} style={{height: widget.height === '1/2' ? '437.5px' : widget.height === '3/4' ? '525px' : widget.height === 'full' ? '700px' : '350px'}}>
					<Index {...widget} index={ index } isFirst={ index === 0 } isLast={ index === (socialMediaWidgets.length - 1)}
								 onDelete={() => deleteWidget(widget)}
								 onMoveLeft={ handleMoveLeft }
								 onMoveRight={ handleMoveRight }
								 onWidgetChange={changeWidget}/>
				</div>) }
			</section> }
		</section>
	</>);
}
import React, { useEffect, useState } from 'react';
import { differenceInMinutes, } from "date-fns";
import useLocalStorage from "../../hooks/helper/useLocalStorage";

const AGE_VERIFICATION_EXPIRED_TIMEOUT = 24 * 60; // one day in minutes

export default function AgeVerificationModal({ acceptableAge, handleAcceptedAge, handleYoungerAge, pageType }) {
	const [ showAgeRequest, setShowAgeRequest ] = useState(false);
	const [ error, setError ] = useState(null);
	const [ storedTime, setStoredTime, localTimeCheckOccurred ] = useLocalStorage('AGE_VERIFICATION_TIME', false);
	const [ storedAge, setStoredAge, localAgeCheckOccurred ] = useLocalStorage('AGE_VERIFICATION_AT_LEAST', false);

	useEffect(function() {
		if (!localTimeCheckOccurred || !localAgeCheckOccurred) {
			return;
		}

		if (!storedTime || !storedAge) {
			setShowAgeRequest(true);
			return;
		}

		const hasTimeExpired = differenceInMinutes(new Date(), new Date(storedTime)) > AGE_VERIFICATION_EXPIRED_TIMEOUT;
		if (!hasTimeExpired && storedAge >= acceptableAge) {
			handleAcceptedAge();
		} else if (!hasTimeExpired) {
			setShowAgeRequest(true);
		} else {
			setShowAgeRequest(true);
			setStoredAge(0);
			setStoredTime(null);
		}
	}, [ storedTime, storedAge ]);

	function onClickYes(e) {
		if (acceptableAge > storedAge) {
			setStoredAge(acceptableAge);
		}
		setStoredTime(new Date());
		handleAcceptedAge(e);
	}

	function onClickNo(e) {
		const errorText = handleYoungerAge(e);
		if (errorText) {
			setError(errorText);
		}
	}

	return (<div className={`${!showAgeRequest && 'hidden' } fixed z-50 bottom-0 overflow-auto inset-x-0 px-4 pb-6 inset-0 p-0 flex items-center justify-center`}>

		<div className="fixed inset-0 transition-opacity"><div className="absolute inset-0 bg-gray-500 opacity-75" /></div>

		<div className="bg-white rounded-md shadow-xl transform transition-all py-4 px-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

			<h1 className="text-3xl sm:text-4xl text-center text-black font-medium w-auto">This { pageType || "Virtual Booth" } Is Age Restricted</h1>

			<p className="text-center text-gray-800 mt-4 mb-6 text-2xl w-auto">Are you over <strong>{ acceptableAge }</strong> years old?</p>

			<div className="flex justify-center btn-row-responsive">
				<button onClick={ onClickYes } className="text-white h-8 px-3 py-1 text-sm text-center rounded leading-none bg-hub-green w-full mr-2 ml-2 mb-4">Yes</button>
				<button onClick={ onClickNo } className="text-white h-8 px-3 py-1 text-sm text-center rounded bg-red-500 leading-none w-full mr-2 ml-2 mb-4">No</button>
			</div>

			{ error && <p id="age-error" className="text-red-600 break-normal text-center m-auto flex">{ error }</p>}

		</div>

	</div>);
}
import React, { useEffect, Suspense } from 'react';
import Loading from '../components/Loading';
import Layout from '../components/Layout';
import AdminLayout from '../components/AdminLayout';
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";

// Routes
import EventPage from './EventPage';
import AdminEvents from './Admin/Events';

// Lazy-loaded
const VirtualBoothPage = React.lazy(() => import('./BoothPage/Virtual'));
const SpeakerBoothPage = React.lazy(() => import('./BoothPage/Speaker'));
const EventSchedulePage = React.lazy(() => import('./Schedule'));
const SpeakersPage = React.lazy(() => import('./Speakers'));
const SessionPage = React.lazy(() => import('./SessionPage'));
const MainHallSessionPage = React.lazy(() => import('./MainHallSessionPage'));

// providers
import GlobalContext  from "../context";
import AdminContext  from "../context/admin";
import { ExhibitorProvider } from "../context/exhibitor";
import { SessionProvider } from "../context/session";
import Session from './SessionPage';

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
		window.callObject && callObject.destroy();
	}, [pathname]);

	return null;
}

export default function Routes() {
	return (<Router>
			<ScrollToTop />
			<GlobalContext>
				<Switch>
					<Route path="/admin">
						<AdminContext>
							<AdminLayout>
								<AdminEvents />
							</AdminLayout>
						</AdminContext>
					</Route>
					<Route path="/:eventSlug?/:boothSlug?">
						<ExhibitorProvider>
							<Layout>
								<Switch>
									<Route exact path={ window.white_label_slug ? '/schedule' : '/:eventSlug/schedule' }>
										<Suspense fallback={<Loading>Loading Event Schedule</Loading>}>
											<EventSchedulePage />
										</Suspense>
									</Route>
									<Route sensitive exact path={ window.white_label_slug ? '/speakers' : '/:eventSlug/speakers' }>
										<Suspense fallback={<Loading>Loading Speakers</Loading>}>
											<SpeakersPage />
										</Suspense>
									</Route>
									<Route sensitive exact path={ window.white_label_slug ? '/speakers/:speakerId' : '/:eventSlug/speakers/:speakerId' }>
										<Suspense fallback={<Loading>Loading Speaker</Loading>}>
											<SpeakerBoothPage />
										</Suspense>
									</Route>
									<Route sensitive exact path={ window.white_label_slug ? '/session/:sessionId' : '/:eventSlug/session/:sessionId' }>
										<Suspense fallback={<Loading>Loading Session</Loading>}>
											<SessionProvider>
												<SessionPage />
											</SessionProvider>
										</Suspense>
									</Route>
									<Route sensitive exact path={ window.white_label_slug ? '/mainhall/:sessionId' : '/:eventSlug/mainhall/:sessionId' }>
										<Suspense fallback={<Loading>Loading Main Hall Session</Loading>}>
											<MainHallSessionPage />
										</Suspense>
									</Route>
									<Route exact path={ window.white_label_slug ? '/:boothSlug' : '/:eventSlug/:boothSlug'}>
										<Suspense fallback={<Loading>Virtual Booth</Loading>}>
											<VirtualBoothPage />
										</Suspense>
									</Route>
									<Route exact path={ window.white_label_slug ? '/' : '/:eventSlug'}>
										<EventPage />
									</Route>
								</Switch>
							</Layout>
						</ExhibitorProvider>
					</Route>
				</Switch>
			</GlobalContext>
		</Router>);
}
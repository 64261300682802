import React, { memo, useState, createContext, useContext, useEffect, useLayoutEffect } from 'react';
import axios from "axios";
import {PencilSVG, EyeSVG} from "../../../../components/Icons";
import Toggle from "../../../../components/Toggle";
import useConfig from "../../../../hooks/app/useConfig";
import useEventData from "../../../../hooks/data/useEventData";
import useHotkeys from "@reecelucas/react-use-hotkeys";
import usePermissions from "../../../../hooks/app/usePermissions";
import useToggableElm from "../../../../hooks/helper/useToggableElm";
import EventBlastModal from "./eventBlastModal";
import stableSort from 'stable';
import {format as formatTZ, utcToZonedTime} from "date-fns-tz";

const ListsContext = createContext([{}, () => {}]);

/*
* modal for creation - name, url or booth
* list edit will have name, image preview, url or booth, actions: [delete] and [edit]
* */
function ListItem({ _id, title, type, startTime, ctaUrl, booth, imageUrl }) {
	const [ eventProfile ] = useEventData();
	const [ _, __, { editAnnouncement, showPastAnnouncements } ] = useContext(ListsContext);
	const hasPassed = new Date(startTime) < new Date();
	const listId = type.indexOf('popup') > -1 ? 'popup' : 'slidein';

	if (hasPassed && !showPastAnnouncements) {
		return null;
	}

	return (
		<div className={`flex flex-col sm:flex-row justify-between border ${hasPassed ? 'bg-gray-100' : 'border-gray-200'} sm:shadow mb-2 bg-white p-2 rounded`}>
			{ imageUrl ? (
				<section className="hidden sm:flex bg-white border-4 border-gray-100 h-16 w-24 max-w-none justify-center items-center mr-4">
					<img src={imageUrl} alt={`${title} Image`} className={ listId === 'popup' ? 'h-auto w-full' : 'h-full w-auto' } />
				</section>
			) : <span />
			}
			<div className={`flex-grow flex flex-col ${hasPassed ? 'text-gray-500' : ''}`}>
				<div className="flex flex-row">
					<h3 className="text-base font-normal">{ title }</h3>
					<h3 className="ml-4 text-base font-light">{ formatTZ(utcToZonedTime(startTime, eventProfile.timezone), "MM/dd/yyyy, h:mm aa (z)", { timeZone: eventProfile.timezone }) }</h3>
				</div>
				{ ctaUrl && <p aria-label={ctaUrl} className="bg-gray-100 px-2 py-1 rounded text-sm font-light mt-2 max-w-full">Url: <strong className="font-normal font-mono">{ctaUrl}</strong></p> }
				{ booth && <p aria-label={booth} className="bg-gray-100 px-2 py-1 rounded text-sm font-light mt-2 max-w-full">Booth: <strong className="font-normal font-mono">{booth && booth.name ? booth.name : booth}</strong></p> }
			</div>
			<div className="mt-3 sm:mt-0 flex items-center justify-center">
					{!hasPassed ? 
						<span role="tooltip" aria-label="Edit" onClick={ () => editAnnouncement(_id, listId) }>
							<PencilSVG classes={`h-6 w-6 mx-4 cursor-pointer fill-current text-gray-300 hover:text-gray-900`} />
						</span> : 
						<span role="tooltip" aria-label="View" onClick={ () => editAnnouncement(_id, listId) }>
							<EyeSVG classes={`h-6 w-6 mx-4 cursor-pointer fill-current text-gray-300 hover:text-gray-900`} />
						</span>
					}
			</div>
		</div>
	);
}

const List = memo(function List({ name, heading, panelClasses, nameClasses, headingClasses, items }) {
	return <section className={`${ panelClasses } relative w-full block min-h-manage-list`}>
		{ name && <h2 className={`${ nameClasses } text-sm font-base uppercase`}>{ name }</h2> }
		{ heading && <h2 className={`${ headingClasses } text-sm font-base uppercase`}>{ heading }</h2> }
		<div className="relative">
			{ (items || []).map((item, idx) => <ListItem index={idx} key={item._id} { ...item } />) }
		</div>
	</section>;
});

function AnnouncementsLists() {
	const [ state ] = useContext(ListsContext);

	return <>
		<List name="Pop-up" items={ state.popup }
					panelClasses="rounded-md border border-gray-200 pt-5 px-3 mb-20"
					nameClasses="px-2 -mt-3 ml-4 absolute top-0 left-0 bg-white rounded-full border border-gray-200"
					 />
		<List name="Slide-in" items={ state.slidein }
					panelClasses="rounded-md border border-gray-200 pt-5 px-3 mb-20"
					nameClasses="px-2 -mt-3 ml-4 absolute top-0 left-0 bg-white rounded-full border border-gray-200" />
	</>;
}

export default function MangeEventAnnouncements({ onComplete }) {
	const { hasEventEditAccess } = usePermissions();
	if (!hasEventEditAccess ) {
		return null;
	}

	const { apiHost } = useConfig();
	const [ eventProfile ] = useEventData();
	const [ boothOptions, setBoothOptions ] = useState([]);
	const [ announcements, setAnnouncements ] = useState({popup: [], slidein: []});
	const [ selectedAnnouncement, setSelectedAnnouncement ] = useState(null);
	const [ showPastAnnouncements, toggleShowPastAnnouncements ] = useState(false); 
	let [CreateAnnouncementBtn, showAnnouncementModal, toggleAnnouncementModal] = useToggableElm({
		type: ['button'],
		text: ['Create Notification'],
		classNames: ['btn-hub-green-solid sm:w-auto sm:ml-0 sm:mt-0']
	});

	useHotkeys('Escape', onComplete);

	useEffect(() => {
		document.body.classList.add('overflow-y-hidden');
		return () => document.body.classList.remove('overflow-y-hidden');
	}, []);

	useEffect(function () {
		if (!eventProfile.urlSlug) {
			return;
		}
		axios({
			url: `${apiHost}/village/events/${eventProfile.urlSlug}/profiles`,
			method: 'get'
		}).then(({data}) => {
			let booths = data.map(booth => ({ value: booth.id, text: `${booth.name} - #${booth?.order?.substring(booth?.order?.length - 6)}` }));
			setBoothOptions([{ value: '', text: 'None'}].concat(stableSort(booths, (a,b) => a.text > b.text)));
		});
	}, [eventProfile]);

	useEffect(function() {
		if (!eventProfile.id) {
			return;
		}
		axios({
			url: `${ apiHost }/village/events/${eventProfile.id}/announcements`,
			method: 'GET'
		}).then(({ data }) => {
			if (data) {
				setAnnouncements({
					popup: stableSort( data.filter(a => a.type.indexOf('popup') > -1), (a,b) => a.startTime > b.startTime),
					slidein: stableSort( data.filter(a => a.type.indexOf('slidein') > -1), (a,b) => a.startTime > b.startTime)
				});
			}
		}).catch(() => {
			setAnnouncements({});
		})
	}, [eventProfile]);

	function handleSaveAnnouncement(announcement) {
		let listName = announcement.type;
		if (!selectedAnnouncement) {
			setAnnouncements( state => ({
				...state,
				[listName]: stableSort([ ...state[listName], announcement ], (a,b) => a.startTime > b.startTime)
			}));
		} else {
			setAnnouncements( state => {
				let newAnnouncements = {...state};
				newAnnouncements[listName] = newAnnouncements[listName].map(a => {
					if (a._id === announcement._id) {
						return announcement
					} else {
						return a;
					}
				});
				return {
					popup: stableSort(newAnnouncements.popup, (a,b) => a.startTime > b.startTime),
					slidein: stableSort(newAnnouncements.slidein, (a,b) => a.startTime > b.startTime)
				};
			});
		}
	}

	function handleCloseModal() {
		toggleAnnouncementModal(false)
		setSelectedAnnouncement(null);
	}

	function editAnnouncement(announcementId, listId) {
		let announcement = announcements[listId].find(a => a._id === announcementId);
		setSelectedAnnouncement(announcement);
		toggleAnnouncementModal(true);
	}

	function handleDeleteAnnouncement(announcement) {
		let listName = announcement.type;
		setAnnouncements( state => {
			let newAnnouncements = {...state};
			newAnnouncements[listName] = newAnnouncements[listName].filter(a => a._id !== announcement._id);
			return newAnnouncements;
		});
		toggleAnnouncementModal(false);
		setSelectedAnnouncement(null);
	}

	return (
		<section className="fixed top-0 bottom-0 left-0 right-0 z-50 ">
			{ showAnnouncementModal && <EventBlastModal boothOptions={boothOptions} defaultValues={selectedAnnouncement} onSave={ handleSaveAnnouncement } onDelete={handleDeleteAnnouncement} onCloseModal={handleCloseModal} /> }
			<section className="fixed bg-white top-0 bottom-0 left-0 right-0 z-10 flex flex-col">
				<header className="py-4 sm:py-6 px-6 sm:px-8 flex flex-col sm:flex-row justify-between border-b border-gray-200">
					<div className="flex items-center">
						<h1 className="text-lg font-base capitalize">Manage Notifications</h1>
					</div>
					<div className="flex flex-col-reverse sm:flex-row items-center">
						<div className="flex items-center mr-0 sm:mr-12 mt-4 sm:mt-0">
							<label htmlFor="togglePastNotifications" className="text-sm cursor-pointer mr-2" title={`${showPastAnnouncements ? 'Hide' : 'Show'} Past Notifications`} >Show Past Notifications</label>
							<Toggle isFlipped={ showPastAnnouncements } isLoading={false} onToggle={() => toggleShowPastAnnouncements(s => !s)} />
						</div>
						<CreateAnnouncementBtn />
						<span className="text-gray-400 mx-auto sm:mx-0 mt-2 sm:mt-0 cursor-pointer ml-0 sm:ml-4" onClick={ onComplete }>Close</span>
					</div>
				</header>
				<section className="flex-grow flex flex-col h-full overflow-x-hidden overflow-y-auto">
					<div className="container mx-auto py-12">
						<ListsContext.Provider value={[ announcements, setAnnouncements, { editAnnouncement, showPastAnnouncements } ]}>
							<AnnouncementsLists />
						</ListsContext.Provider>
					</div>
				</section>
			</section>
		</section>
	);
}

import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import axios from "axios";
import Papa from "papaparse";
import { useForm } from "react-hook-form";
import useHotkeys from "@reecelucas/react-use-hotkeys";
import { useTable, useSortBy, usePagination } from 'react-table';
import { useCopyToClipboard } from 'react-use';
import * as JsSearch from 'js-search';

import UISelect from "../../../../components/UISelect";

import useDrop from "../../../../hooks/helper/useDrop";
import useConfig from "../../../../hooks/app/useConfig";
import useToggle from "../../../../hooks/helper/useToggle";
import useEventData from "../../../../hooks/data/useEventData";
import useCurrentUser from "../../../../hooks/app/useCurrentUser";
import useNotifications from "../../../../hooks/app/useNotifications";
import useDeviceDetect from "../../../../hooks/helper/useDeviceDetect";

const TABLE_COLUMNS = [
	{
		Header: 'Ticket #',
		accessor: 'ticketNumber',
	},
	{
		Header: 'First Name',
		accessor: 'metadata.firstName',
	},
	{
		Header: 'Last Name',
		accessor: 'metadata.lastName',
	},
	{
		Header: 'Email',
		accessor: 'metadata.email',
	}
]

function CSVApproveModal({ onCompleteModal, onCancelModal, csvProperties, rows }) {
	const { apiHost } = useConfig();
	const [ eventProfile ] = useEventData();
	const [ currentUser ] = useCurrentUser();
	const [ serverError, setServerError ] = useState('');
	const { register, handleSubmit, formState: { isSubmitting } } = useForm({ defaultValues: {
			ticketNumberField: '',
			firstNameField: '',
			lastNameField: '',
			emailField: ''
		}});

	function submitData(values) {
		if (values.ticketNumberField === '') {
			return setServerError('You must to at least map the ticket number field to a column in your CSV.');
		}
		return new Promise((resolve, reject) => {

			let newAttendees = rows.map(ticket => {
				return {
					...ticket,
					ticketNumber: ticket[values['ticketNumberField']],
					firstName: ticket[values['firstNameField']] || '',
					lastName: ticket[values['lastNameField']] || '',
					email: ticket[values['emailField']] || '',
				}
			});

			return axios({
				url: `${ apiHost }/village/events/${ eventProfile.id }/attendees`,
				method: 'POST',
				withCredentials: true,
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'Authorization': `Bearer ${ currentUser.token }`
				},
				data: newAttendees, // we replace on the server so we need to make sure everything is there now.
			}).then(({ data }) => {
				if (!data || data.message) {
					setServerError(data.message);
					return reject();
				}
				onCompleteModal();
			}).catch(e => {
				setServerError(e.message);
				return reject();
			})
		});
	}

	return (<div className="fixed z-50 bottom-0 overflow-auto inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">

		<div className="fixed inset-0 transition-opacity"><div className="absolute inset-0 bg-gray-500 opacity-75" /></div>

		<div className="bg-white rounded-md sm:overflow-hidden shadow-xl transform transition-all" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<form onSubmit={handleSubmit(submitData)}>
				<div className="bg-gray-200 px-4 py-3 sm:px-6">
					<h3 className="text-lg uppercase leading-6 font-normal text-gray-900">Finalize Ticket Upload</h3>
				</div>

				<div className="flex flex-col bg-white inner-shadow p-6 items-center">

					{ serverError && <div className="rounded py-2 px-3 bg-red-50 text-gray-700 text-sm w-full my-2"><strong className="font-medium">Error:</strong>&nbsp;{ serverError }</div> }

					<p className="text-gray-900 mb-3 text-center">We've located <span className="font-bold">{ rows.length }</span> tickets to upload.<br />Now we need to map the columns of data to EventHub Attendee Access properties:</p>

					<div className="flex justify-between items-center w-full mb-3">
						<div className="w-1/2">
							<p className="text-gray-900 text-base">Ticket Number Field</p>
						</div>
						<div className="w-1/2">
							<UISelect options={ ['', ...csvProperties].map(p => ({ value: p, text: p})) } name="ticketNumberField" register={register} classes="w-full" />
						</div>
					</div>

					<div className="flex justify-between items-center w-full mb-3">
						<div className="w-1/2">
							<p className="text-gray-900 text-base">First Name Field</p>
						</div>
						<div className="w-1/2">
							<UISelect options={ ['', ...csvProperties].map(p => ({ value: p, text: p})) } name="firstNameField" register={register} classes="w-full" />
						</div>
					</div>

					<div className="flex justify-between items-center w-full mb-3">
						<div className="w-1/2">
							<p className="text-gray-900 text-base">Last Name Field</p>
						</div>
						<div className="w-1/2">
							<UISelect options={ ['', ...csvProperties].map(p => ({ value: p, text: p})) } name="lastNameField" register={register} classes="w-full" />
						</div>
					</div>

					<div className="flex justify-between items-center w-full mb-3">
						<div className="w-1/2">
							<p className="text-gray-900 text-base">Email Address Field</p>
						</div>
						<div className="w-1/2">
							<UISelect options={ ['', ...csvProperties].map(p => ({ value: p, text: p})) } name="emailField" register={register} classes="w-full" />
						</div>
					</div>

				</div>

				<div className="px-4 py-3 flex flex-col-reverse sm:flex-row justify-between items-center border-gray-300 border-t">
					{ !isSubmitting && <a href="#" className="text-gray-400 mx-auto sm:mx-0 mt-2 sm:mt-0 cursor-pointer" onClick={(e) => { e.preventDefault(); onCancelModal(); }}>Cancel</a>}
					{ isSubmitting && <span /> }

					<button type="submit"
									disabled={ isSubmitting }
									onClick={ handleSubmit }
									className="float-right btn-hub-green sm:w-auto sm:ml-2 mt-2 sm:mt-0 mr-0 sm:mr-0 flex text-sm items-center">
						{ !isSubmitting && 'Upload Tickets' }
						{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
					</button>
				</div>
			</form>
		</div>

	</div>)
}

function AddOneAttendeeModal({ onCompleteModal, onCancelModal, refreshTickets }) {
	const ticketNumberRef = useRef();
	const { apiHost } = useConfig();
	const [ eventProfile ] = useEventData();
	const [ currentUser ] = useCurrentUser();
	const [ serverError, setServerError ] = useState('');
	const [ keepModalOpen, setKeepModalOpen ] = useState(false);

	const defaults = {
		ticketNumber: '',
		firstName: '',
		lastName: '',
		email: ''
	}
	const { register, handleSubmit, reset, formState: { isSubmitting } } = useForm({ defaultValues: defaults});

	function submitData(values) {
		if (values.ticketNumber === '' || values.firstName === '' || values.lastName === '' || values.email === '') {
			return setServerError('All values are required');
		}
		return new Promise((resolve, reject) => {
			return axios({
				url: `${ apiHost }/village/events/${ eventProfile.id }/attendees/single`,
				method: 'POST',
				withCredentials: true,
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'Authorization': `Bearer ${ currentUser.token }`
				},
				data: values,
			}).then(({ data }) => {
				if (!data || data.message) {
					setServerError(data.message);
					return reject();
				}
				if (keepModalOpen) {
					reset(defaults);
					setKeepModalOpen(false);
					refreshTickets();
					setServerError('');
					ticketNumberRef.current.focus();
				} else {
					onCompleteModal();
				}
				return resolve();
			}).catch(e => {
				let msg;
				if (e && e.response && e.response.data && e.response.data.message) {
					msg = e.response.data.message
				} else {
					msg = e.message;
				}
				setServerError(msg);
				return reject();
			})
		});
	}

	return (<div className="fixed z-50 bottom-0 overflow-auto inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">

		<div className="fixed inset-0 transition-opacity"><div className="absolute inset-0 bg-gray-500 opacity-75" /></div>

		<div className="bg-white rounded-md sm:overflow-hidden shadow-xl transform transition-all" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<form onSubmit={handleSubmit(submitData)}>
				<div className="bg-gray-200 px-4 py-3 sm:px-6">
					<h3 className="text-lg uppercase leading-6 font-normal text-gray-900">Create a new ticket</h3>
				</div>

				<div className="flex flex-col bg-white inner-shadow p-6 items-center">

					{ serverError && <div className="rounded py-2 px-3 bg-red-50 text-gray-700 text-sm w-full my-2"><strong className="font-medium">Error:</strong>&nbsp;{ serverError }</div> }

					<p className="text-gray-900 mb-3 text-center">Fill out the following information to create a new EventHub Attendee Access ticket:</p>

					<div className="flex justify-between items-center w-full mb-3">
						<div className="w-1/2">
							<p className="text-gray-900 text-base">Ticket Number</p>
						</div>
						<div className="w-1/2">
							<input type="text" name="ticketNumber" ref={(e) => { register(e); ticketNumberRef.current = e; } } className="mt-1 base-input" placeholder="12345" />
						</div>
					</div>

					<div className="flex justify-between items-center w-full mb-3">
						<div className="w-1/2">
							<p className="text-gray-900 text-base">First Name</p>
						</div>
						<div className="w-1/2">
							<input type="text" name="firstName" ref={register} className="mt-1 base-input" placeholder="Jane" />
						</div>
					</div>

					<div className="flex justify-between items-center w-full mb-3">
						<div className="w-1/2">
							<p className="text-gray-900 text-base">Last Name</p>
						</div>
						<div className="w-1/2">
							<input type="text" name="lastName" ref={register} className="mt-1 base-input" placeholder="Doe" />
						</div>
					</div>

					<div className="flex justify-between items-center w-full mb-3">
						<div className="w-1/2">
							<p className="text-gray-900 text-base">Email Address</p>
						</div>
						<div className="w-1/2">
							<input type="text" name="email" ref={register} className="mt-1 base-input" placeholder="test@example.com" />
						</div>
					</div>

				</div>

				<div className="px-4 py-3 flex flex-col-reverse sm:flex-row justify-between items-center border-gray-300 border-t">
					{ !isSubmitting && <a href="#" className="text-gray-400 mx-auto sm:mx-0 mt-2 sm:mt-0 cursor-pointer" onClick={(e) => { e.preventDefault(); onCancelModal(); }}>Cancel</a>}
					{ isSubmitting && <span /> }

					<div className="float-right flex">
						{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
						{ !isSubmitting && <><button type="submit"
										disabled={ isSubmitting }
										onClick={ (e) => { setKeepModalOpen(true); handleSubmit(e); } }
										className="btn-hub-green sm:w-auto sm:ml-2 mt-2 sm:mt-0 mr-0 sm:mr-0 flex text-sm items-center">
							Save and add another
						</button>
						<button type="submit"
										disabled={ isSubmitting }
										onClick={ handleSubmit }
										className="btn-hub-green sm:w-auto sm:ml-2 mt-2 sm:mt-0 mr-0 sm:mr-0 flex text-sm items-center">
							Save
						</button></> }
					</div>
				</div>
			</form>
		</div>

	</div>)
}

const RevokeActionCell = (props) => {
	const { apiHost } = useConfig();
	const {
		row: { index, original: { _id: attendeeId, isRevoked} },
		updateTableData,
		eventProfile,
		currentUser
	} = props;
	// We need to keep and update the state of the cell normally
	const { append: appendNotification } = useNotifications();
	const [ revokeAccess, setRevoke ] = React.useState(isRevoked);

	React.useEffect(() => {
		setRevoke(isRevoked);
	}, [isRevoked]);

	React.useEffect(() => {
		if (isRevoked === revokeAccess) {
			return;
		}
		updateTableData(index, 'isRevoked', revokeAccess);
		axios({
			url: `${ apiHost }/village/events/${eventProfile.id}/attendees/${ attendeeId }/revoke/${ revokeAccess ? 'true': 'false' }`,
			method: 'GET',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			}
		}).then(({ data }) => {
			if (data && data.message) {
				appendNotification('alert', "Uh oh. We couldn't save your changes. " + data.message );
			} else {
				appendNotification('success', "Success! Your changes have been saved.");
			}
		}).catch(e => {
			appendNotification('alert', "Uh oh. We couldn't save your changes. " + e.message);
		});
	}, [revokeAccess]);

	function revokeAttendee(e) {
		e.preventDefault();
		setRevoke(true);
	}

	function grantAccessAttendee(e) {
		e.preventDefault();
		setRevoke(false);
	}

	return <div className="flex flex-col justify-center">
		{ revokeAccess && <span className="badge badge-failed uppercase w-20 w-max-none mx-auto">Revoked</span> }
		{ !revokeAccess && <span className="badge badge-success uppercase w-20 w-max-none mx-auto">Granted</span> }
		{ revokeAccess && <a href="#" className="mt-2 text-hub-green underline text-sm mx-auto" onClick={ grantAccessAttendee }>Grant Access</a> }
		{ !revokeAccess && <a href="#" className="mt-2 text-hub-green underline text-sm mx-auto" onClick={ revokeAttendee }>Revoke Access</a> }
	</div>
}

const UsageActionCell = (props) => {
	const { apiHost } = useConfig();
	const {
		row: { index, original: { _id: attendeeId, accessUsage} },
		updateTableData,
		eventProfile,
		currentUser
	} = props;
	// We need to keep and update the state of the cell normally
	const { append: appendNotification } = useNotifications();
	const [tixUsage, setTixUsage] = React.useState(accessUsage);

	React.useEffect(() => {
		setTixUsage(accessUsage);
	}, [accessUsage]);

	React.useEffect(() => {
		if (accessUsage === tixUsage) {
			return;
		}
		updateTableData(index, 'accessUsage', tixUsage);
		axios({
			url: `${ apiHost }/village/events/${eventProfile.id}/attendees/${ attendeeId }/reset-usage`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			}
		}).then(({ data }) => {
			if (data && data.message) {
				appendNotification('alert', "Uh oh. We couldn't save your changes. " + data.message );
			} else {
				appendNotification('success', "Success! Your changes have been saved.");
			}
		}).catch(e => {
			appendNotification('alert', "Uh oh. We couldn't save your changes. " + e.message);
		});
	}, [tixUsage]);

	function resetTicketUsage(e) {
		e.preventDefault();
		setTixUsage(0);
	}

	return <div className="flex flex-col justify-center text-center">
		{ tixUsage }&nbsp;of&nbsp;{ eventProfile.attendeeMaxAccessUsage } uses
		{ tixUsage > 0 && <a href="#" className="mt-2 text-hub-green underline text-sm mx-auto" onClick={ resetTicketUsage }>Reset Usage</a> }
	</div>
}

const AccessLinkCell = (props) => {
	const {
		row: { original: { accessToken} },
		eventProfile
	} = props;
	const [ isCopied, setIsCopied ] = useState(false);
	const [ { value, error, noUserInteraction }, copyToClipboard ] = useCopyToClipboard();
	const customUrl = "https://eventhub.shop/" + eventProfile.urlSlug + "?tix=" + accessToken;

	function handleCopy(e) {
		e.preventDefault();

		copyToClipboard(customUrl);

		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 2 * 1000);
	}

	return <div className="flex flex-col justify-center text-center">
		{ !isCopied && <a href={ customUrl } className="mt-2 text-hub-green underline text-sm mx-auto" onClick={ handleCopy }>Copy access link</a> }
		{ isCopied && <a className="mt-2 text-hub-green underline text-sm mx-auto">Copied!</a> }
	</div>
}

function AttendeeTable({ columns, data, skipPageReset, currentUser, eventProfile, updateTableData }) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page, // Instead of using 'rows', we'll use page,
		// which has only the rows for the active page

		// The rest of these things are super handy, too ;)
		canPreviousPage,
		canNextPage,
		pageOptions,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			autoResetPage: !skipPageReset,
			updateTableData,
			eventProfile,
			currentUser
		},
		useSortBy, usePagination,
		hooks => {
			hooks.visibleColumns.push(columns => [
				...columns,
				{
					id: 'usage',
					Header: () => (<div className="text-center">Access Usage</div>),
					Cell: UsageActionCell,
				},
				{
					id: 'revoke_status',
					Header: () => (<div className="text-center">Status</div>),
					Cell: RevokeActionCell,
				},
				{
					id: 'link',
					Header: () => (<div className="text-center">Access Link</div>),
					Cell: AccessLinkCell,
				}
			])
		}
	)
	return (
		<>
			<table {...getTableProps()} className="attendee-table w-full">
				<thead>
				{headerGroups.map(headerGroup => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps(column.getSortByToggleProps())}>
								{column.render('Header')}
								<span>
                    {column.isSorted
											? column.isSortedDesc
												? ' 🔽'
												: ' 🔼'
											: ''}
                  </span>
							</th>
						))}
					</tr>
				))}
				</thead>
				<tbody {...getTableBodyProps()}>
				{page.map(
					(row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map(cell => {
									return (
										<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
									)
								})}
							</tr>
						)}
				)}
				</tbody>
			</table>
			<br />
			<nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
				<div className="hidden sm:block">
					<p className="text-sm leading-5 text-gray-700">
						Showing page&nbsp;
						<span className="font-medium">{ pageIndex + 1 }</span>
						&nbsp;of&nbsp;
						<span className="font-medium">{ pageOptions.length }</span>
						&nbsp;|&nbsp;{ ( data || [] ).length }&nbsp;results
					</p>
				</div>
				<div className="flex-1 flex justify-between sm:justify-end">
					<button onClick={() => previousPage()} disabled={!canPreviousPage}
									className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
						Previous
					</button>
					<button onClick={() => nextPage()} disabled={!canNextPage}
										className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
							Next
					</button>
					<div className={`inline-block relative`}>
						<select
							value={pageSize}
							onChange={e => {
								setPageSize(Number(e.target.value))
							}}
							name={ name }
							className={`block appearance-none ml-3 relative inline-flex items-center pl-4 pr-8 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`}>
							{[10, 20, 30, 40, 50].map(pageSize => (
								<option key={pageSize} value={pageSize}>
									Show {pageSize}
								</option>
							))}
						</select>
						<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
							<svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
								<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
							</svg>
						</div>
					</div>
				</div>
			</nav>
		</>
	)
}

export default function ManageAttendees({ onComplete }) {
	const { apiHost } = useConfig();
	const { isChrome } = useDeviceDetect();
	const { append : addNotification }  = useNotifications();
	const [ eventProfile ] = useEventData();
	const [ currentUser ] = useCurrentUser();
	const uploadInputRef = useRef(null);
	const [ rows, setRows ] = useState([]);
	const [ file, setFile ] = useState(null);
	const [ attendees, setAttendees ] = useState([]); // displayed attendees
	const [ allAttendees, setAllAttendees ] = useState([]); // all attendees, only set when received from server
	const [ searchBoxInput, setSearchBoxInput ] = useState('');
	const [ isProcessingFile, setProcessingFile ] = useToggle();
	const [ showFinalizationModal, toggleShowFinalizationModal ] = useToggle();
	const [ showAddAttendeeModal, toggleAddAttendeeModal ] = useToggle();
	const [ skipPageReset, setSkipPageReset ] = useState(false);
	const getAttendees = useCallback(() => {
		axios({
			url: `${ apiHost }/village/events/${ eventProfile.id }/attendees`,
			method: 'GET',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			}
		}).then((res) => {
			setAllAttendees(res.data || []);
			setAttendees(res.data || []); // avoid losing sync with table when initially loading/adding new attendee
			setSearchBoxInput(""); // for consistency between displayed results and text input
		});
	}, []);
	const bulkImport = useCallback(() => {
		addNotification('waiting', 'Starting Bulk Import From RegFox. Please Wait...');
		axios({
			url: `${ apiHost }/village/events/${ eventProfile.id }/integrations/regfox/import`,
			method: 'GET',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			}
		}).then(() => {
			addNotification('success', 'Bulk Import Completed');
			getAttendees();
		}).catch(err => {
			console.log(err.response)
			if (err.response && err.response.status && err.response.status === 404){
				addNotification('success', 'Bulk RegFox Import: ' + err.response.data.message);
			} else if (err.response){
				addNotification('alert', 'Bulk RegFox Import Failed: ' + err.response && err.response.data ?  err.response.data.message : 'Server Error Occurred' );
			} else {
				addNotification('alert', 'Bulk RegFox Import Failed');
			}
		});
	}, []);
	const onDrop = useCallback(acceptedFiles => {
		if (!acceptedFiles && isProcessingFile) {
			return;
		}
		setFile(acceptedFiles[0]);
	}, []);
	const [ isDragActive ] = useDrop({ onFiles: onDrop });
	const MenuDropdown = useMemo(() => function MenuDropdown({ deleteAll }) {
		const [ showDropdown, setShowDropdown ] = useState(false);
		const dropdownRef = useRef(null);
		const hasRegFox = eventProfile.integrations.filter(i => i.type === 'regfox')[0];

		function blurEffect(event) {
			if (showDropdown && !dropdownRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		}

		useEffect(function() {
			window.addEventListener('click', blurEffect);
			return () => {
				window.removeEventListener('click', blurEffect);
			}
		});

		return (
			<div className="relative inline-block text-left mr-4">
				<div ref={dropdownRef}>
					<button type="button"
							onClick={() => setShowDropdown(!showDropdown)}
							className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
							id="options-menu" aria-haspopup="true" aria-expanded="true">
						Management Actions
						<svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
							<path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"/>
						</svg>
					</button>
				</div>
				{ showDropdown && (
					<div className="origin-top-right absolute right-0 mt-2 w-56 z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">

						<div className="py-1">
							<a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem" onClick={ () => toggleAddAttendeeModal(true) }>Add Single Attendee</a>
							<a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem" onClick={ () => uploadInputRef.current.click() }>Bulk CSV Upload</a>
						</div>
						{ hasRegFox && <div className="py-1">
							<a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem" onClick={ () => bulkImport() }>RegFox Bulk Import</a>
						</div> }
						<div className="py-1">
							<a href={`${ apiHost }/village/events/${ eventProfile.id }/attendees/export/${ currentUser.token }`} target="_blank"
							   className="block px-4 py-2 text-sm text-hub-green hover:bg-gray-100" role="menuitem">Export All Attendees</a>
						</div>
						<div className="py-1">
							<a onClick={ deleteAll } href="#" className="block px-4 py-2 text-sm text-red-700 hover:bg-gray-100 hover:text-red-900" role="menuitem">Delete All Attendees</a>
						</div>
					</div>
				) }
			</div>
		)
	}, [uploadInputRef, eventProfile, currentUser])

	useEffect(( ) => {
		if (!isProcessingFile && file) {
			setProcessingFile(true);

			Papa.parse(file, {
				header: true,
				worker: true,
				step: function(row) {
					console.log("Row:", row.data);
					setRows( state => [...state, row.data]);
				},
				complete: function() {
					console.log("All done!");
					setProcessingFile(false);
					setFile(null);
					toggleShowFinalizationModal(true);
				}
			});
		}
	}, [ file ]);

	useHotkeys('Escape', onComplete);

	useEffect(getAttendees, []);

	useEffect(() => {
		document.body.classList.add('overflow-y-hidden');
		return () => document.body.classList.remove('overflow-y-hidden');
	}, []);

	function deleteAll() {
		if (confirm('Are you sure you want to delete all tickets?')) {
			if (confirm('This is not reversible... Proceed with caution.')) {
				if (prompt("Type 'DELETE ALL' to complete.") === 'DELETE ALL') {
					axios({
						url: `${ apiHost }/village/events/${ eventProfile.id }/attendees`,
						method: 'DELETE',
						withCredentials: true,
						headers: {
							'X-Requested-With': 'XMLHttpRequest',
							'Authorization': `Bearer ${ currentUser.token }`
						}
					}).then((res) => {
						addNotification('success', 'All tickets have been deleted.');
						setAllAttendees([]);
						setAttendees([]);
						setSearchBoxInput(""); // for consistency between displayed results and text input
					});
				}
			}
		}
	}

	function handleCancel(e) {
		e.preventDefault();
		onComplete();
	}

	function onSelectFile(e) {
		if (e.target.files && e.target.files.length > 0) {
			setFile(e.target.files[0]);
		}
	}

	function onCancelFinalizeModal() {
		toggleShowFinalizationModal(false);
		setRows([]);
	}

	function onCompleteFinalizeModal() {
		toggleShowFinalizationModal(false);
		setRows([]);
		getAttendees();
	}

	const updateTableData = (rowIndex, columnId, value) => {
		// We also turn on the flag to not reset the page
		setSkipPageReset(true);
		setAttendees(prevAttendees =>
			prevAttendees.map((row, index) => {
				if (index === rowIndex) {
					return {
						...prevAttendees[rowIndex],
						[columnId]: value,
					}
				}
				return row
			})
		)
	}

	function onCompleteAddOneAttendeeModal() {
		toggleAddAttendeeModal(false);
		getAttendees();
	}

	function onSearchTickets(e) {
		const searchString = e.target.value;
		setSearchBoxInput(searchString || "");
		if (!searchString || searchString.trim() === "") {
			setAttendees(allAttendees);
			return;
		}
		const search = new JsSearch.Search('_id')
		search.addIndex(['metadata', 'firstName'])
		search.addIndex(['metadata', 'lastName'])
		search.addIndex(['metadata', 'email'])
		search.addIndex(['metadata', 'ticketNumber'])
		search.addDocuments(allAttendees)

		setAttendees(search.search(e.target.value))
	}

	return (
		<section className="fixed top-0 bottom-0 left-0 right-0 z-40 bg-white flex flex-col">
			{ showFinalizationModal && <CSVApproveModal onCompleteModal={ onCompleteFinalizeModal } onCancelModal={ onCancelFinalizeModal } csvProperties={ Object.keys(rows[0]) } rows={ rows } /> }
			{ showAddAttendeeModal && <AddOneAttendeeModal onCompleteModal={ onCompleteAddOneAttendeeModal } onCancelModal={ () => toggleAddAttendeeModal(false) } refreshTickets={ getAttendees } /> }
			{ isDragActive && !isChrome() && <p className="fixed top-0 bottom-0 left-0 right-0 z-30 background bg-hub-green opacity-75 font-bold text-white text-2xl flex justify-center items-center">Drop a single CSV file here...</p> }
			<header className="py-4 sm:py-6 px-6 sm:px-8 flex flex-col sm:flex-row justify-between border-b border-gray-200">
				<div className="flex items-center">
					<h1 className="text-lg font-base capitalize">Manage Attendee Tickets</h1>
				</div>
				<div className="flex flex-grow lg:flex-row flex-col">
					<div className="flex-grow flex py-3 px-3 flex-col sm:flex-row items-center">
						<input type="text" placeholder="Search by name, email, or ticket number" className="px-2 py-1 flex-grow rounded border border-hub-green w-full text-gray-800" onChange={ onSearchTickets } value={ searchBoxInput }/>
					</div>
					<div className="flex flex-col-reverse sm:flex-row items-center">
						<input type="file"
							   ref={uploadInputRef}
							   className="hidden"
							   accept="text/csv"
							   onChange={ onSelectFile } />
						{ !isProcessingFile && <MenuDropdown deleteAll={deleteAll} />}
						{ isProcessingFile && <p className="sm:w-auto mx-4 mt-2 sm:mt-0 flex text-sm items-center cursor-not-allowed">
							<span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Uploading CSV...</span>
						</p> }
						<a href="#" className="text-gray-400 mx-auto sm:mx-0 mt-2 sm:mt-0 cursor-pointer" onClick={ handleCancel }>Close</a>
					</div>
				</div>
			</header>
			<section className="flex-grow flex h-full overflow-x-hidden overflow-y-auto">
				<div className="container mx-6 sm:mx-auto">
					<div className="flex flex-col md:flex-row py-12">
						<section className="flex flex-col w-full">
							<AttendeeTable 	columns={TABLE_COLUMNS}
											 data={attendees}
											 eventProfile={ eventProfile }
											 currentUser={ currentUser }
											 skipPageReset={ skipPageReset }
											 updateTableData={ updateTableData } />
						</section>
					</div>
				</div>
			</section>
		</section>
	);
}

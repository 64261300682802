import React from 'react';
import ReactDOM from 'react-dom';
import Rollbar from 'rollbar';
import './styles/app.css';
import 'core-js/stable';

import Routes from './routes';

ReactDOM.render(<Routes />, document.getElementById('root'));

// iframe check
if (top !== window) {
  document.body.classList.add('inside-iframe');

  if (window.location.pathname.split('/').length > 2 || this.white_label_slug && window.location.pathname.split('/')[1].length > 0) {
    document.body.classList.add('inside-vendor-iframe');
  }
}

if (process.env.NODE_ENV === 'production') {
  let rollbar = new Rollbar({
    accessToken: '852bf99ac86c418d9d609d49e9c81721',
    captureUncaught: true,
    captureUnhandledRejections: true,
    hostWhiteList: ['beta.eventhub.shop', "eventhub.shop", "amazonaws.com"],
    payload: {
      environment: window.location.host.indexOf('beta') > -1 ? 'beta' : 'production'
    }
  });
}

// Hot Module Replacement
if (module.hot) {
  module.hot.accept();
}

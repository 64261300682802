import { useMemo } from 'react';
import { WINDOW_NAME_EDIT, WINDOW_NAME_PREVIEW, WINDOW_NAME_PUBLIC } from '../../context/user';

export default function useMode() {
	return useMemo(() => {
		switch (window.name) {
			case WINDOW_NAME_PUBLIC:
				return 'public';
			case WINDOW_NAME_PREVIEW:
				return 'preview';
			case WINDOW_NAME_EDIT:
				return 'edit';
		}
	}, [window.name]);
}
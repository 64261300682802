import React, {useEffect, useState, useLayoutEffect} from 'react';
import { eachWeekOfInterval } from "date-fns";
import { format as formatTZ, utcToZonedTime } from "date-fns-tz";
import unique from 'array-unique';
import stableSort from 'stable';

import useEventData from "../../hooks/data/useEventData";

import SessionCard from "../../features/Session/Card";

const PADDING_STYLES = 'px-3 sm:px-6 md:px-12 lg:px-24 py-3';

function FilterPill({ index, children, handleClick, backgroundColor, textColor, borderColor}) {
  return (
    <button onClick={handleClick}
            key={index}
            className="font-normal focus:outline-none leading-none py-1 px-3 mb-1 text-xs text-center rounded-full self-end featured-pill mr-1 sm:mr-2 cursor-pointer border"
            style={{backgroundColor, color: textColor, borderColor }}>{children}</button>
  )
}

export default function SessionList({ listTitle, tracks, rawSessionsData, onEditTimeslot, disableFiltering }) {
  const [ eventProfile ] = useEventData();
  const {  advanceStyles : theme, advanceStyles: { brandText, brandColor }} = eventProfile;
  const [ sortedAgenda, setSortedAgenda ] = useState({});
  const [ days, setDays ] = useState([]);
  const [ sessions, setSessions ] = useState([]);
  const [ weeks, setWeeks ] = useState([]);
  const [ selectedDays, setSelectedDays ] = useState([]);
  const [ selectedTracks, setSelectedTracks ] = useState([]);
  const [ isFiltered, setIsFiltered ] = useState(false);

  // create view after fetching sessions
  useEffect(() => {
    if (rawSessionsData.length) {
      let sortedSessions = stableSort(rawSessionsData,(s1, s2) => new Date(s1.startTime) - new Date(s2.startTime));
      let weeks = eachWeekOfInterval({start: new Date(sortedSessions[0].startTime), end: new Date(sortedSessions[sortedSessions.length-1].startTime)});
      let days = unique(sortedSessions.map(s => formatTZ(utcToZonedTime(s.startTime, eventProfile.timezone), weeks.length > 1 ? "iiii, MMMM do (z)" : "iiii", { timeZone: eventProfile.timezone })));
      setWeeks(weeks);
      setDays(days);
      setSessions(sortedSessions);
      filterSessions();
    }
  }, [rawSessionsData]);

  // create view after filtering sessions
  useEffect(() => {
    if (sessions.length) {
      let agenda = {}

      sessions.map(session => {
        let day = formatTZ(utcToZonedTime(session.startTime, eventProfile.timezone), weeks.length > 1 ? "iiii, MMMM do (z)" : "iiii", { timeZone: eventProfile.timezone });
        let time = formatTZ(utcToZonedTime(session.startTime, eventProfile.timezone), "h:mm aa (z)", { timeZone: eventProfile.timezone });

        if (!agenda[day]) {
          agenda[day] = {};
          agenda[day][time] = [session];
        } else if (!agenda[day][time]) {
          agenda[day][time] = [session];
        } else {
          agenda[day][time].push(session);
        }
      });
      console.log('sorted agenda', agenda)
      setSortedAgenda(agenda);
    }
  }, [sessions]);

  function filterSessions() {
    if (!tracks.length && !sessions.length) {
      return;
    }

    if (!selectedTracks.length && !selectedDays.length) {
      setSessions(stableSort(rawSessionsData,(s1, s2) => new Date(s1.startTime) - new Date(s2.startTime)));
      setIsFiltered(false);
      return;
    }

    let filteredSessions = selectedTracks.length ? rawSessionsData.filter(s => s.track && selectedTracks.includes(s.track._id)) : rawSessionsData;
    filteredSessions = selectedDays.length ? filteredSessions.filter(s => s.startTime && selectedDays.includes(formatTZ(utcToZonedTime(s.startTime, eventProfile.timezone), weeks.length > 1 ? "iiii, MMMM do (z)" : "iiii", { timeZone: eventProfile.timezone }))) : filteredSessions;

    setSessions(stableSort(filteredSessions, (s1, s2) => new Date(s1.startTime) - new Date(s2.startTime)));
    setIsFiltered(selectedTracks.length || selectedDays.length);
  }

  useLayoutEffect(filterSessions, [selectedDays, selectedTracks]);

  return(
    <section className={PADDING_STYLES}>

      <h1 className="text-5xl font-bold pb-4">{listTitle}</h1>

      { !disableFiltering && <div className="flex flex-wrap justify-between pb-12">
        <div className="flex flex-col w-full lg:w-1/2 pb-4 lg:pb-0">
          <label htmlFor="event-days">Event Days</label>
          <div id="event-days" className="flex flex-wrap pt-2">
            <FilterPill handleClick={() => setSelectedDays([])}
                        backgroundColor={selectedDays.length === 0 ? brandColor : 'white'}
                        borderColor={(selectedDays.length === 0 && brandColor)}
                        textColor={selectedDays.length === 0 ? brandText : '#000000'}>All Days</FilterPill>
            {days.length > 0 && days.map((day, index) => (
                <FilterPill key={index}
                            handleClick={() => setSelectedDays(sd => sd.includes(day) ? sd.filter(d => d !== day) : sd.concat(day))}
                            backgroundColor={selectedDays.includes(day) ? brandColor : 'white'}
                            borderColor={(selectedDays.includes(day) && brandColor)}
                            textColor={selectedDays.includes(day) ? brandText : '#000000'}>{day}</FilterPill>
            ))}
          </div>
        </div>

        <div className="flex flex-col flex-wrap w-full lg:w-1/2">
          <label htmlFor="event-days">Tracks</label>
          <div id="event-days" className="flex flex-wrap pt-2">
            <FilterPill handleClick={() => setSelectedTracks([])}
                        backgroundColor={selectedTracks.length === 0 ? brandColor : 'white'}
                        borderColor={(selectedTracks.length === 0 && brandColor)}
                        textColor={selectedTracks.length === 0 ? brandText : '#000000'}>All Tracks</FilterPill>
            {tracks.length > 0 && tracks.map((track, index) => (
                <FilterPill key={index}
                            handleClick={() => setSelectedTracks(s => s.includes(track._id) ? s.filter(t => t !== track._id) : s.concat(track._id))}
                            backgroundColor={selectedTracks.includes(track._id) ? brandColor : 'white'}
                            borderColor={(selectedDays.includes(track._id) && brandColor)}
                            textColor={selectedTracks.includes(track._id) ? brandText : '#000000'}>{track.name}</FilterPill>
            ))}
          </div>
        </div>
      </div> }

      {isFiltered && !sessions.length && <div>No sessions found</div>}

      {((isFiltered && sessions.length > 0) || !isFiltered) &&  (<>
        {days.length > 0 && days.filter(d => sortedAgenda[d]).map((day, dayIndex) => (
          <div key={dayIndex} className="pb-6 sm:pb-3">
            <h3 className={`sm:px-0 text-2xl font-medium text-left mb-4`}>{day}</h3>
            {Object.keys(sortedAgenda[day]).map((time, timeIndex) => (
              <div key={dayIndex + timeIndex} className="pb-4">
                <h4 className={`sm:px-0 text-lg font-normal text-left mb-2`}>{time}</h4>
                {sortedAgenda && sortedAgenda[day][time].length > 0 && sortedAgenda[day][time].map((session, sessionIndex) => (
                  <SessionCard key={dayIndex + timeIndex + sessionIndex} session={session} handleEdit={onEditTimeslot(session._id)} />
                ))}
              </div>
            ))}
          </div>
        ))}
      </>)}

    </section>
  )
}

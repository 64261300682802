import React from 'react';
import { OutboundLink } from 'react-ga';
import useMode from '../../hooks/app/useMode';

export default function VillageLink({ children, ...props }) {
    const mode = useMode();
    let usedProps = props;

    if (mode !== 'public'){
        usedProps = {
            className: props.className,
            style: props.style,
            href: props.to,
        }
    }

    usedProps.target = props.target || '_blank';

    return mode === 'public' ? <OutboundLink {...usedProps}>{ children }</OutboundLink> : <a {...usedProps} >{ children }</a>
}

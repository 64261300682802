import React, {useState, useEffect, useMemo, useRef} from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import {Link, useHistory} from 'react-router-dom';
import {addMinutes, setSeconds, differenceInMilliseconds, getUnixTime, subMinutes, isSameDay} from "date-fns";
import { format as formatTZ, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

import EditColumn from "../../features/EditColumns/Session";
import SpeakerCard from "../../features/SpeakerBooth/Card";
import {LongThinArrowLeftSVG} from "../../components/Icons";
import useConfig from "../../hooks/app/useConfig";
import useEventData from "../../hooks/data/useEventData";
import useWhiteLabelSlugs from "../../hooks/app/useWhitelabelSlugs";

const PADDING_STYLES = 'px-3 sm:px-6 md:px-12 lg:px-24 py-3 sm:py-6';

import {SessionStage} from "../../features/Stages/Session";
import useToggableElm from "../../hooks/helper/useToggableElm";
import useToggle from "../../hooks/helper/useToggle";
import usePermissions from "../../hooks/app/usePermissions";
import DescriptionArea from "../../features/SpeakerBooth/EditDescriptionArea";
import VillageLink from "../../components/VillageLink";
import VirtualBooth from "../../features/SessionLiveChat";
import useInterval from "../../hooks/helper/useInterval";
import useEventEmitter from "../../hooks/helper/useEventEmitter";
import usePrevious from "../../hooks/helper/usePrevious";
import AddToCalendarButton from "../../features/Session/AddToCalendarButton";
import useAttendee from "../../hooks/data/useAttendee";
import useDeviceDetect from "../../hooks/helper/useDeviceDetect";
import useSessionData from "../../hooks/data/useSessionData";
import useSessionTimes from "../../hooks/data/useSessionTimes";
import useShowTimes from "../../hooks/data/useShowTimes";
import useNotifications from '../../hooks/app/useNotifications';
import ZoomContainer from "../../components/ZoomMeeting";

export default function Session() {
  const history = useHistory();
  const videoPlayer = useRef(null);
  const { apiHost } = useConfig();
  const { emit }  = useEventEmitter();
  const detectDevice = useDeviceDetect();
  const [ eventProfile ] = useEventData();
  const { sessionId } = useWhiteLabelSlugs();
  const [ attendeeProfile ] = useAttendee();
  const { hasEventEditAccess, hasPreviewAccess} = usePermissions();
  const [ sessionLoading, setSessionLoading ] = useToggle(true);
  const [ hasForcedProgramming, setForceProgramming ] = useToggle(false);
  const [ ageFail, setAgeFail ] = useToggle(false);
  const [ session, setSession ] = useSessionData();
  const { sessionIsActive, duration } = useSessionTimes();
  const { isMainStageActive } = useShowTimes();
  const [ programmedContent, setProgrammedContent ] = useState(null);
  const [ state, setState ] = useState({ durations: [], programming: []});
  const { append: appendNotification, notifications } = useNotifications();
  const [ endTimeOfCurrentProgram, setEndTimeOfCurrentProgram ] = useState();
  const notificationLength = 10000;

  let [ EditDescriptionToggleLink, showEditDescription, toggleEditDescription ] = useToggableElm({
    type: ['button'],
    text: ['Edit Description'],
    classNames: ['btn-hub-green-solid sm:w-auto sm:ml-0 sm:mt-0']
  });

  let [ EditSessionToggleLink, editModeEnabled, setEditMode ] = useToggableElm({
    initial_state: !detectDevice.isMobile(),
    type: ['button'],
    text: [hasEventEditAccess ? 'Edit Session Details' : 'View Session Details'],
    classNames: ['btn-hub-green-solid sm:w-auto sm:ml-0 sm:mt-0 mb-4']
  });

  const hasEditAccess = useMemo(() => {
    if (hasEventEditAccess || !attendeeProfile || !session) {
      return hasEventEditAccess;
    }
    return session.speakers.map(s => s.accessTicketNumber).indexOf(attendeeProfile.ticketNumber) > -1;
  }, [ hasEventEditAccess, session, attendeeProfile ]);

  useInterval(()=>{
    if (!state.programming.length && state.durations && !state.durations.length) {
      return;
    }

    let now = new Date();
    let currentTime = state.durations.reduce((prev, curr) => curr <= now ? curr : prev);
    let suggestedProgramming = state.programming[currentTime];

    // check if the suggestedProgramming is less than the current programming content...
    if (hasForcedProgramming) {
      let programmingIds = session.programming.map(p => p._id);
      let currentProgrammingIndex = programmingIds.indexOf(programmedContent._id);
      let suggestedProgrammingIndex = programmingIds.indexOf(suggestedProgramming._id);
      if (suggestedProgrammingIndex < currentProgrammingIndex) {
        return;
      } else {
        setForceProgramming(false);
      }
    }

    if (suggestedProgramming && (!programmedContent || suggestedProgramming && programmedContent && programmedContent._id !== suggestedProgramming._id)) {
      changeProgrammedContent(suggestedProgramming);
    } else if (!suggestedProgramming) {
      setProgrammedContent(suggestedProgramming);
    }
  }, sessionIsActive ? 1000 : null);

  useEffect(() => {
    if (isMainStageActive && sessionIsActive) emit('close:main_stage');
  }, [isMainStageActive, sessionIsActive])

  useEffect(() => {
    if (!programmedContent) return;

    let programmingIds = session.programming.map(p => p._id);
    let currentProgrammingIndex = programmingIds.indexOf(programmedContent._id);

    setEndTimeOfCurrentProgram(state.durations[currentProgrammingIndex+1]);
  }, [programmedContent]);

  useEffect(() => {
    setSessionLoading(true);
    axios({
      url: `${ apiHost }/village/events/${eventProfile.id}/sessions/${sessionId}`,
      method: 'GET'
    }).then(({data}) => {
      setSessionLoading(false);
      if (data) {
        setSession({ ...data, speakers: (data.speakers || []).filter(s => s !== null)});
      }
    }).catch(() => {
      setSessionLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!session || session.programming && !session.programming.length) {
      return;
    }

    let durations = session.programming.map(p => p.duration).reduce((arr, d) => {
      let lastValue = arr[arr.length - 1];
      arr.push(lastValue + d);
      return arr;
    }, [0]).map(d => setSeconds(addMinutes(zonedTimeToUtc(session.startTime, eventProfile.timezone), d), 0));

    let programming = Object.assign({}, ...durations.map((d, idx) => {
      return { [d]: session.programming[idx] }
    }))

    setState({programming, durations});

  }, [ session ]);

  useEffect(() => {
    if (videoPlayer.current) {
      // videoPlayer.current.seekTo()
    }
  }, []);

  //Session starting and ending reminders
  useEffect(() => {
    //hasEditAccess return true for speakers and exhibitors
    if (!session || !hasEditAccess) {
      return;
    }

		let now = new Date();
    let sessionStartTime = new Date(session.startTime);
    
    if (!isSameDay(sessionStartTime, now)) {
      return;
    }

    now = getUnixTime(new Date());
    sessionStartTime = getUnixTime(new Date(session.startTime));
    let timers = [];
    let sessionEndTime = getUnixTime(new Date(session.endTime));

		let secondsUntilSessionStart = differenceInMilliseconds(sessionStartTime, now);
		let secondsUntilFiveMinutesBeforeSessionStart = differenceInMilliseconds(subMinutes(sessionStartTime, 5), now);
		let secondsUntilSessionEnd = differenceInMilliseconds(sessionEndTime, now);
		let secondsUntilFiveMinutesBeforeSessionEnd = differenceInMilliseconds(subMinutes(sessionEndTime, 5), now);

		if (secondsUntilFiveMinutesBeforeSessionStart > 0) {
			timers.push(setTimeout(() => {
              appendNotification('info', 'Session starts in 5 minutes.', notificationLength);
			}, secondsUntilFiveMinutesBeforeSessionStart*1000));
		}

		if (secondsUntilSessionStart > 0) {
			timers.push(setTimeout(() => {
              appendNotification('info', 'Session is starting now.', notificationLength);
			}, secondsUntilSessionStart*1000));
		}

		if (secondsUntilFiveMinutesBeforeSessionEnd > 0) {
			timers.push(setTimeout(() => {
              appendNotification('info', 'Session ends in 5 minutes.', notificationLength);
			}, secondsUntilFiveMinutesBeforeSessionEnd*1000));
		}

		if (secondsUntilSessionEnd > 0) {
			timers.push(setTimeout(() => {
              appendNotification('info', 'Session is ending now.', notificationLength);
			}, secondsUntilSessionEnd*1000));
		}

		return () => timers.map(t => clearTimeout(t));

	}, [session, hasEditAccess]);

  function handleSessionSave(updates) {
    if (detectDevice.isMobile() && editModeEnabled) {
      setEditMode(false);
    }
    if (updates) {
      setSession(s => ({...s, ...updates}));
    }
  }

  function handleDescriptionComplete(changes) {
    toggleEditDescription(false);
    setSession(s => ({...s, ...changes}));
  }

  function changeProgrammedContent(programming) {
    emit('close:main_stage');
    videoPlayer.current = null;
    setAgeFail(false);
    window.callObject && callObject.destroy();
    setProgrammedContent(programming);
  }

  function handleForceProgrammingChoice(programmingId) {
    setForceProgramming(true);
    changeProgrammedContent(session.programming.filter(p => p._id === programmingId).pop())

    if (detectDevice.isMobile() && editModeEnabled) {
      setEditMode(false);
    }
  }

  if (!session && sessionLoading) {
    return (
        <main className="flex flex-grow bg-hub-grey">
          <div className="flex container bg-white mx-auto shadow-lg p-12 mx-3 sm:mx-auto text-center">
            <h1 className="text-4xl font-medium mx-auto">Loading Session...</h1>
          </div>
        </main>
    );
  }

  if (!session) {
    return (
        <main className="flex flex-grow bg-hub-grey">
          <div className="flex container bg-white mx-auto shadow-lg p-12 mx-3 sm:mx-auto text-center">
            <h1 className="text-4xl font-medium mx-auto">Session not found.</h1>
          </div>
        </main>
    );
  }

  if (session && !session.isPublished && !hasEditAccess && !hasPreviewAccess) {
    return (
        <main className="flex flex-grow bg-hub-grey">
          <div className="flex container bg-white mx-auto shadow-lg p-12 mx-3 sm:mx-auto text-center">
            <h1 className="text-4xl font-medium mx-auto">Sorry, This Session Is Not Active.</h1>
          </div>
        </main>
    );
  }

  if (session && session.isTimeSlot) {
    appendNotification('info', 'Timeslots are only viewable from the schedule page.');
    history.push(`${ !window.white_label_slug ? `/${eventProfile.urlSlug}/schedule` : 'schedule' }`);
  }

  return (
    <>
      <Helmet>
        <title>Session Page</title>
        <meta name="keywords" content="many,many,keywords" />
        <meta name="description" content="Some Event Name description" />
      </Helmet>
      <main className="session-page flex flex-col sm:flex-row">
        <section className="pb-12 flex-grow w-full">
          <section className="block container mx-auto bg-white shadow-lg pb-24">

            <div className={`flex ${PADDING_STYLES}`}>
              <Link to={!window.white_label_slug ? `/${eventProfile.urlSlug}/schedule` : '/schedule'} className="flex items-center">
                <LongThinArrowLeftSVG classes="h-8 w-8 mr-2" />
                Back to Schedule
              </Link>
            </div>

            { (sessionIsActive || hasEditAccess) && programmedContent && programmedContent.type === 'embed' && <SessionStage displayChat={programmedContent.showChat} insertedVideo={ programmedContent.url } /> }
            { (sessionIsActive || hasEditAccess) && programmedContent && programmedContent.type === 'conference-link' && <div className="w-full bg-gray-700 inner-shadow px-6 py-24 flex justify-around">
              <VillageLink to={programmedContent.url} eventLabel="launch third-party conference link from session page" className="rounded font-medium bg-white py-3 px-12 text-base mx-auto">Launch &#8220;{programmedContent.name || 'Enter Conference Tools'}&#8221;</VillageLink>
            </div> }
            { !ageFail && (sessionIsActive || hasEditAccess) && programmedContent && programmedContent.type.includes('video-chat') && (
              <VirtualBooth session={session} hasHostAccess={hasEditAccess} settings={programmedContent} handleFailedAgeVerification={ () => setAgeFail(true) } onLeaveBooth={()=> setAgeFail(true)} endTime={endTimeOfCurrentProgram} />
            ) }
            { !ageFail && (sessionIsActive || hasEditAccess) && programmedContent && programmedContent.type.includes('zoom') && (
              <ZoomContainer hasHostAccess={hasEditAccess} config={programmedContent} handleFailedAgeVerification={ () => setAgeFail(true) } onLeaveBooth={()=> setAgeFail(true)} endTime={endTimeOfCurrentProgram} />
            ) }

            <section className={`bg-hub-grey ${PADDING_STYLES} md:py-6`}>
              <div className="flex flex-col">
                <h1 className="font-bold text-4xl sm:text-6xl leading-none">{session.name}</h1>
                <div className="flex flex-wrap my-2">
                  { duration > 0 ? <span className="text-xl mr-6 font-thin">{duration} minutes</span> : null }
                </div>
                <div className="flex flex-wrap mt-2">
                  { session && session.speakers && session.speakers.length > 0 && session.speakers.map(({ name }, idx) => <span key={idx} className="text-2xl mr-6 font-normal">{name}</span>)}
                </div>
                <p className="text-2xl mt-8 font-normal">{session.cardDescription}</p>
              </div>
              <div className="flex justify-start mt-6">
                <AddToCalendarButton {...session} sessionUrl={window.location.href} dropdownClasses="right-auto left-0" />
                <div className="text-lg ml-4">{session.startTime && formatTZ(utcToZonedTime(session.startTime, eventProfile.timezone), "iiii MMMM do, h:mm aa", { timeZone: eventProfile.timezone })}{session.endTime && formatTZ(utcToZonedTime(session.endTime, eventProfile.timezone), " - h:mm aa", { timeZone: eventProfile.timezone })}{formatTZ(utcToZonedTime(session.startTime, eventProfile.timezone), " z", { timeZone: eventProfile.timezone })}</div>
              </div>
            </section>

            { hasEditAccess && (
              <section className="flex flex-col mx-5 mt-12">
                <div className="flex flex-col sm:flex-row justify-end">
                  <EditSessionToggleLink />
                  <EditDescriptionToggleLink />
                </div>
                { showEditDescription && (<DescriptionArea session={session} onComplete={ handleDescriptionComplete } />) }
              </section>
            ) }

            { !showEditDescription && <section className={`${PADDING_STYLES} md:py-10 flex flex-col mb-10`}>
              <div className="ql-snow">
                <div id="session-description" className="ql-container ql-editor" dangerouslySetInnerHTML={{ __html: session.description }} />
              </div>
            </section> }

            {hasEditAccess && (!session.speakers || !session.speakers.length) &&  (
              <div className="text-black text-center text-gray-800 rounded border-2 border-hub-green mx-3 sm:mx-6 md:mx-12 lg:mx-24 my-4 px-3 py-2">
                <strong className="text-hub-green mr-3">Note:</strong>Add Speakers to Sessions in the edit panel.
              </div>
            )}

            {session.speakers && session.speakers.length > 0 && <section className={`px-3 sm:px-6 lg:px-24 py-3 md:pt-12`}>
              <div className="flex justify-between">
                <h3 className="font-bold text-2xl mb-6">{ eventProfile.speakerPageLinkLabel || 'Speakers'}</h3>
              </div>
              <div className="flex flex-wrap">
                { session.speakers.filter(s => hasEditAccess || s.isPublished).map((speaker, idx) => (
                  <SpeakerCard key={idx} {...speaker} containerClass="mr-6 mb-6" />
                ))}
              </div>
            </section> }
          </section>
        </section>
        { hasEditAccess && session && session._id && editModeEnabled && <EditColumn session={session} onSave={handleSessionSave} forceProgramming={handleForceProgrammingChoice} />}
      </main>
    </>
  );
}

import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import * as JsSearch from 'js-search';
import axios from "axios";
import { getExhibitorTags } from "../../utils/exhibitors";
import { isBefore } from "date-fns";
import { format as formatTZ, utcToZonedTime } from "date-fns-tz";
import { gaTrackEvent, gaTrackPageview } from "../../utils/analytics";
import stableSort from 'stable';

// components
import VirtualBoothCard from "../../features/VirtualBooth/Card";
import {HorizontalAdPlacement as AdPlacement } from "../../components/AdPlacement";
import EventEditColumn from "../../features/EditColumns/Event";
import SocialMediaWidget from '../../features/SocialMediaWidget/Widget';
import DescriptionArea from "../../features/EventPage/EditDescriptionArea";
import ExhibitorManageLists from "../../features/EventPage/ManageBoothLayout";
import SocialMediaWidgetEditor from "../../features/SocialMediaWidget/Editor";
import TestColumns from "../../components/TestColumns"
import { ClockSVG, SearchSVG, VideoSVG, PlaceHolderSVG } from '../../components/Icons';
import AgeVerificationModal from "../../features/AgeVerificationModal";

// hooks
import useConfig from "../../hooks/app/useConfig";
import useEventData from "../../hooks/data/useEventData";
import useShowTimes from "../../hooks/data/useShowTimes";
import useDeviceDetect from "../../hooks/helper/useDeviceDetect";
import useCurrentUser from "../../hooks/app/useCurrentUser";
import useBoothTimes from "../../hooks/data/useBoothTimes";
import useDisplayAds from "../../hooks/data/useDisplayAds";
import usePermissions from "../../hooks/app/usePermissions";
import useToggableElm from "../../hooks/helper/useToggableElm";
import { SelectDropdown } from "../../components/UISelect";
import {INITIAL_STATE as INITIAL_DISPLAY_ADS_STATE} from "../../context/displayAds";

const INITIAL_EXHIBITORS = { all: [], original: [], filtered: [], featured: [] };

export default function EventPage() {
	const detectDevice = useDeviceDetect();
	const termRef = useRef(null);
	const [ eventProfile, setEventProfile ] = useEventData();
	const { advanceStyles : theme } = eventProfile;
	const [ currentUser ] = useCurrentUser();
	const [ _, setDisplayAds ] = useDisplayAds();
	const { isBoothTime, nextBoothTime } = useBoothTimes();
	const { isMainStageActive, nextMainStageTime } = useShowTimes();
	const [ sortableTags, setSortableTags ] = useState([]);
	const [ exhibitors, setExhibitors ] = useState(INITIAL_EXHIBITORS);
	const [ rawExhibitorData, setRawExhibitorData ] = useState([]);
	const [ editModeEnabled, setEditMode ] = useState(!detectDevice.isMobile());
	const [ loadingExhibitors, setLoadingExhibitors ] = useState(false);
	const [ displayExhibitorFilter, setExhibitorFilter ] = useState(null);
	const { hasPreviewAccess, hasEventEditAccess, accessibleOrders } = usePermissions();
	const [ hasInitiallyLoadedExhibitors, setInitiallyLoadedExhibitors ] = useState(false);
	const { allowSortingExhibitors, allowDisplayAds, allowSocialWidgets, apiHost } = useConfig();
	const [ search, setSearch ] = useState({ term: '', results: [], database: null, executed: false});
	const [ socialMediaWidgets, setSocialMediaWidgets ] = useState([]);
	let [ EditDescriptionToggleLink, showEditDescription, toggleEditDescription ] = useToggableElm({
		type: ['button'],
		text: ['Edit Description'],
		classNames: ['btn-hub-green-solid sm:w-auto sm:ml-0 sm:mt-0']
	});
	let [ EditExhibitorsToggleLink, showEditExhibitors, toggleEditExhibitors ] = useToggableElm({
		type: ['button'],
		text: ['Manage Booth Layout'],
		classNames: ['btn-hub-green-solid sm:w-auto sm:ml-0 sm:mt-0']
	});
	let [ ManageSocialMediaWidgetsToggleLink, showSocialMediaWidgetEditor, toggleShowSocialMediaWidgetEditor ] = useToggableElm({
		type: ['button'],
		text: ['Manage Widgets'],
		classNames: ['btn-hub-green-solid sm:w-auto sm:ml-0 sm:mt-0']
	});

	const isEventClosed = !eventProfile.isActive && (!hasEventEditAccess && !hasPreviewAccess);

	const hasUpcomingMainStage = useMemo(() => !isMainStageActive && nextMainStageTime.start && isBefore(new Date(), nextMainStageTime.start), [isMainStageActive, nextMainStageTime.start]);
	const hasUpcomingBoothTimes = useMemo(() => !isBoothTime && nextBoothTime.start, [isBoothTime, nextBoothTime.start]);

	useEffect(function() {
		gaTrackPageview(window.location.pathname);
	}, []);

	useEffect(function() {
		if (!eventProfile.id) {
			return;
		}
		axios({
			url: `${ apiHost }/village/events/${eventProfile.id}/ads`,
			method: 'GET'
		}).then(({ data }) => {
			if (data) {
				setDisplayAds(data);
			}
		}).catch(() => {
			setDisplayAds(INITIAL_DISPLAY_ADS_STATE);
		})
	}, [eventProfile.id]);

	useEffect(function() {
		if (!eventProfile.urlSlug || hasInitiallyLoadedExhibitors) {
			return;
		}
		setSocialMediaWidgets(eventProfile.socialMediaWidgets);
		setLoadingExhibitors(true);
		axios({
			url: `${ apiHost }/village/events/${eventProfile.urlSlug}/profiles`,
			method: 'get'
		}).then(({ data }) => {
			setLoadingExhibitors(false);
			setInitiallyLoadedExhibitors(true);
			setRawExhibitorData(data || []);
		}).catch(() => {
			setLoadingExhibitors(false);
		})
	}, [eventProfile]);

	function sortExhibitors(type, exhibitors) {
		const sortOrder = eventProfile.hasArrangements ? (
			type === 'all' ? [ ...eventProfile.arrangements.featuredExhibitors, ...eventProfile.arrangements.pinnedExhibitors, ...eventProfile.arrangements.exhibitors ] :
			type === 'featured' ? [ ...eventProfile.arrangements.featuredExhibitors ] :
			type === 'pinned' ? [ ...eventProfile.arrangements.pinnedExhibitors ] :
			[ ...eventProfile.arrangements.pinnedExhibitors, ...eventProfile.arrangements.exhibitors ]
		) : [];
		return stableSort(exhibitors, (a, b) => {
			return sortOrder.indexOf(a.id) > sortOrder.indexOf(b.id);
		});
	}

	useLayoutEffect(() => {
		if (currentUser.loading && loadingExhibitors || !rawExhibitorData.length) {
			return;
		}

		let hasAccessToOrders = [...accessibleOrders];
		let allExhibitors = [...rawExhibitorData].filter(e => e.isActive && (hasEventEditAccess || hasAccessToOrders.indexOf(e.id) > -1) && !e.isPublished || e.isPublished).map(e => ({...e, tags: getExhibitorTags(e).map(t => t.value), items: (e.displayItems || []).map(d => d.title)}));

		let filteredExhibitors = allExhibitors.filter(e => !e.isFeatured);
		let featuredExhibitors = allExhibitors.filter(e => e.isFeatured && !e.isPinned);
		let pinnedExhibitors = allExhibitors.filter(e => !e.isFeatured && e.isPinned);

		let allDisplayItems = filteredExhibitors.map(e => e.displayItems).flat();
		let displayTags = getExhibitorTags( allDisplayItems ); // save as select option.

		let searchDB = new JsSearch.Search('id');
		searchDB.addIndex('name');
		searchDB.addIndex('description');
		searchDB.addIndex('tags');
		searchDB.addIndex('items');
		searchDB.addDocuments(allExhibitors);

		setSortableTags( displayTags );
		setExhibitors(s => ({ ...s,
			all: sortExhibitors('all', allExhibitors),
			original: filteredExhibitors,
			pinned: sortExhibitors('pinned', pinnedExhibitors || []),
			filtered: sortExhibitors(null, filteredExhibitors),
			featured: sortExhibitors('featured', featuredExhibitors)
		}));
		setSearch({ ...search, database: searchDB });
	}, [ loadingExhibitors, currentUser, rawExhibitorData ]);

	useEffect(function(){
		if (displayExhibitorFilter !== '' && displayExhibitorFilter !== 'Show All' && exhibitors.original && exhibitors.original.length) {
			let filterableExhibitors = [ ...exhibitors.original.filter(e => !e.isPinned) ];
			setExhibitors({
				...exhibitors,
				filtered: exhibitors.pinned.concat(sortExhibitors(null, filterableExhibitors.filter(e => e.tags.indexOf(displayExhibitorFilter) > -1)))
			});
			gaTrackEvent('Event', 'Booths Filtered', displayExhibitorFilter);
		} else if (displayExhibitorFilter === '') {
			setExhibitors({
				...exhibitors,
				filtered: sortExhibitors(null, exhibitors.original )
			});
		}
	}, [displayExhibitorFilter]);

	function handleSearchExhibitors(e) {
		e.preventDefault();
		let term = termRef.current.value;
		if (term !== '') {
			searchExhibitors(term);
		} else {
			setSearch(state => ({ ...state, results: [], executed: false }))
		}
	}

	function handleKeyPress(e) {
		let term = termRef.current.value;
		if (e.which === 13 && term !== '') {
			searchExhibitors(term);
		}

		if (term === '') {
			setSearch(state => ({ ...state, results: [], executed: false }))
		}
	}

	function searchExhibitors(term) {
		if (!search.database) {
			return;
		}
		gaTrackEvent('Event', 'Booths Searched', term);
		setSearch(state => ({ ...state, results: sortExhibitors(null, search.database.search(term)), executed: true }));
	}

	function clearSearchResults(e) {
		e.preventDefault();
		setSearch(state => ({ ...state, results: [], executed: false }));
		termRef.current.value = '';
	}

	function handleEventChange() {
		if (detectDevice.isMobile() && editModeEnabled) {
			setEditMode(false);
		}
	}

	function handleExhibitorManageComplete(allExhibitors) {
		toggleEditExhibitors(false);
		setRawExhibitorData(allExhibitors);
	}

	function handleExhibitorManageCancel() {
		toggleEditExhibitors(false);
	}
	49
	function handleUpdateWidgets(socialMediaWidgets, onCloseWidgetEditor) {
		return new Promise((resolve, reject) => {
			axios({
				url: `${apiHost}/village/events/${eventProfile.urlSlug}`,
				method: 'POST',
				withCredentials: true,
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'Authorization': `Bearer ${ currentUser.token }`
				},
				data: { socialMediaWidgets }, // we replace on the server so we need to make sure everything is there now.
			}).then(({ data }) => {
				if (data.message) {
					return reject(new Error(data.message));
				}
				setEventProfile(state => ({ ...state, socialMediaWidgets: data.socialMediaWidgets}));
				setSocialMediaWidgets(data.socialMediaWidgets);
				toggleShowSocialMediaWidgetEditor(true);
				onCloseWidgetEditor();
				return resolve();
			}).catch(reject);
		});
	}

	useEffect(() => {
		setSocialMediaWidgets(eventProfile.socialMediaWidgets);
	}, [ eventProfile.socialMediaWidgets ]);

	if (!eventProfile) {
		return <div className="shadow mx-auto my-20 p-20">
			<h1 className="text-4xl capitalize">Loading Event Data</h1>
		</div>
	}

	let hasSearchResults = search.results && search.results.length > 0 || search.executed;
	const HEADER_STYLES = { backgroundColor: theme.brandColor };
	const COVER_IMAGE_STYLES = { backgroundColor: theme.secondaryColor };
	const HERO_STYLES = { color: theme.primaryText, backgroundColor: theme.primaryColor };
	const PRIMARY_BUTTON_STYLES = { color: theme.primaryText, backgroundColor: theme.primaryColor };
	const SECONDARY_BUTTON_STYLES = { height: '28px', color: theme.secondaryText, backgroundColor: theme.secondaryColor };
	let heroHeightClasses = eventProfile.coverImage ? '' : 'py-8';

	if (isEventClosed) {
		return <main className="flex flex-grow bg-hub-grey">
			<div className="flex container bg-white mx-auto shadow-lg p-12 mx-3 sm:mx-auto text-center">
				<h1 className="text-4xl font-medium mx-auto">Event is coming soon..</h1>
			</div>
		</main>
	}

	return (<>
		<Helmet>
			<title>{ eventProfile.name }</title>
			<meta name="keywords" content="many,many,keywords" />
			<meta name="description" content="Some Event Name description" />
		</Helmet>
		{ showEditExhibitors && (<ExhibitorManageLists exhibitors={ [ ...exhibitors.all ] } onCancel={ handleExhibitorManageCancel } onComplete={ handleExhibitorManageComplete } />) }

		<main className="event-page flex flex-col sm:flex-row">
			<div className={`w-full ${ hasEventEditAccess && 'sm:w-8/12 xl:w-9/12 2xl:w-10/12' }`}>

				{ eventProfile.coverImage && !isMainStageActive && <section className={`cover-image-area bg-cover bg-center relative ${heroHeightClasses}`} style={COVER_IMAGE_STYLES}>
					<img src={ eventProfile.coverImage } className="w-full block" alt={`${ eventProfile.name } Event Cover Image`} />
				</section> }

				{ (hasUpcomingMainStage || hasUpcomingBoothTimes || detectDevice.isMobile()) && <section className={`cover-image-area bg-cover bg-center flex flex-col${heroHeightClasses}`} style={HERO_STYLES}>
					{ eventProfile.id && hasEventEditAccess && !editModeEnabled && <button onClick={() => setEditMode(true)} className="bg-black font-medium text-white py-2 px-3 text-xs rounded-md m-4 shadow self-center">Edit Event Profile</button> }
					{ (hasUpcomingMainStage || hasUpcomingBoothTimes) && <div className={`container mx-auto flex px-4 py-2 sm:px-16 sm:py-4`}>
						<div className="w-full flex flex-col sm:flex-row sm:justify-between sm:items-center font-normal">
							{ hasUpcomingMainStage && <div className="flex sm:items-center font-normal text-sm sm:text-base"><ClockSVG classes="h-6 w-6 sm:h-5 sm:w-5 lg:h-4 lg:w-4 fill-current inline mr-2 sm:mr-1" />Main Stage Livestream Begins { formatTZ(utcToZonedTime(nextMainStageTime.start, eventProfile.timezone), "MMM do 'at' h:mmaaaa", { timeZone: eventProfile.timezone }) } to { formatTZ(utcToZonedTime(nextMainStageTime.end, eventProfile.timezone), 'h:mmaaaa z', { timeZone: eventProfile.timezone }) }</div> }
							{ hasUpcomingBoothTimes && <div className="flex sm:items-center font-normal text-sm sm:text-base"><ClockSVG classes="h-6 w-6 sm:h-5 sm:w-5 lg:h-4 lg:w-4 fill-current inline mr-2 sm:mr-1" />Virtual Booth Sessions Begin { formatTZ(utcToZonedTime(nextBoothTime.start, eventProfile.timezone), "MMM do 'at' h:mmaaaa", { timeZone: eventProfile.timezone }) } to { formatTZ(utcToZonedTime(nextBoothTime.end, eventProfile.timezone), 'h:mmaaaa z', { timeZone: eventProfile.timezone }) }</div> }
						</div>
					</div>}
				</section> }

				<section id="search-exhibitors" className="main-village-sub-nav flex shadow-lg" style={HEADER_STYLES}>
					<div className="container mx-auto flex py-3 px-3 sm:px-12 flex-col sm:flex-row items-center">

						{ eventProfile.generalInfoUrl && isBoothTime ? <a href={eventProfile.generalInfoUrl} target="_blank" className={`w-full sm:w-auto text-center px-3 py-1 text-sm rounded ml-0 mt-2 sm:mt-0 h-8 leading-none mr-0 sm:mr-3 mb-2 sm:mb-0 flex items-center`} style={SECONDARY_BUTTON_STYLES}><VideoSVG classes="h-4 w-4 fill-current inline" style={{ color: theme.secondaryText }} />&nbsp;&nbsp;&nbsp;Enter General Info Booth</a> :
							hasUpcomingBoothTimes ? <a href="#" onClick={e => {e.preventDefault(); alert(`Next Booth Time: ${ formatTZ(utcToZonedTime(nextBoothTime.start, eventProfile.timezone), "MMM do 'at' h:mmaaaa z", { timeZone: eventProfile.timezone }) }`)}} className={`w-full sm:w-auto text-center px-3 py-1 text-sm rounded ml-0 mt-2 sm:mt-0 h-8 leading-none mr-3 flex cursor-not-allowed items-center`} style={SECONDARY_BUTTON_STYLES}><VideoSVG classes="h-4 w-4 fill-current inline" style={{ color: theme.secondaryText }} />&nbsp;&nbsp;&nbsp;Enter General Info Booth</a> : null }
						<label className="sr-only" htmlFor="Search booths, products, services, etc.">Search booths, products, services, etc.</label>
						<input id="Search booths, products, services, etc." aria-label="Search booths, products, services, etc." type="text" placeholder="Search booths, products, services, etc." className="px-2 flex-grow rounded border-0  w-full sm:w-auto text-gray-800" ref={termRef} onKeyUp={handleKeyPress} />
						<button className={`px-10 py-1 rounded ml-0 sm:ml-6 mt-2 sm:mt-0 w-full sm:w-auto`} style={PRIMARY_BUTTON_STYLES} onClick={handleSearchExhibitors}><SearchSVG classes="h-4 w-4 leading-none fill-current inline" style={{ color: theme.primaryText }} title="Search booths, products, services, etc." />&nbsp;&nbsp;Search</button>
					</div>
				</section>

				<section className="mb-floating-stage pb-12 flex-grow">
					<section className="container mx-auto bg-white shadow-lg pt-8 pb-12 sm:px-5 md:px-10 lg:px-10">

						{/*<TestColumns />*/}
						{ allowDisplayAds && <AdPlacement /> }

						{ !hasSearchResults && hasEventEditAccess && (
							<section className="flex flex-col mx-5">
								<div className="flex flex-col sm:flex-row justify-end">
									<EditDescriptionToggleLink />
								</div>
								{ showEditDescription && (<DescriptionArea onComplete={ () => toggleEditDescription(false) } />) }
							</section>
						) }

						{ !hasSearchResults && !search.executed && eventProfile.description && !showEditDescription && <section className="flex flex-col mx-5 mb-20">
							<div className="ql-snow">
								<div id="event-description" className="ql-container ql-editor" dangerouslySetInnerHTML={{ __html: eventProfile.description }} />
							</div>
						</section> }

						{ !hasSearchResults && allowSocialWidgets && hasEventEditAccess && (
							<section className="flex flex-col mx-5 mb-4">
								<div className="flex flex-col sm:flex-row justify-end">
									<ManageSocialMediaWidgetsToggleLink />
								</div>
								{ showSocialMediaWidgetEditor && <SocialMediaWidgetEditor widgets={socialMediaWidgets} handleUpdateWidgets={handleUpdateWidgets} onCloseWidgetEditor={() => toggleShowSocialMediaWidgetEditor(false)} />}
							</section>
						) }

						{ !hasSearchResults && hasEventEditAccess && allowSocialWidgets && !showSocialMediaWidgetEditor && (!socialMediaWidgets || socialMediaWidgets.length === 0) && (
							<section className="flex flex-col sm:flex-row sm:flex-wrap sm:-mx-5 md:-mx-10 lg:-mx-5 justify-start">
								<div className="sm:w-full lg:w-1/2 w-full px-5 lg:px-10 mb-12">
									<div className="flex flex-col h-vendor-card w-full vendor-card-shadow overflow-hidden rounded-md"><PlaceHolderSVG /></div>
								</div>
							</section>
						) }

						<div id="skip-social-feeds">
							<a href="#booths" className="skip-content-button">Skip Social Feeds</a>
						</div>

						{ !hasSearchResults && !showSocialMediaWidgetEditor && allowSocialWidgets && socialMediaWidgets && socialMediaWidgets.length > 0 && <section className="flex flex-col sm:flex-row sm:flex-wrap sm:-mx-5 md:-mx-10 lg:-mx-5 justify-start">
							{socialMediaWidgets.map((widget) => <SocialMediaWidget key={widget.url} {...widget} />)}
						</section> }

						{ loadingExhibitors && <div className="flex flex-grow">
							<h1 className="text-4xl font-medium mx-auto mb-12">Loading Exhibitors...</h1>
						</div> }

						{ !hasSearchResults && allowSortingExhibitors && hasEventEditAccess && exhibitors.all.length > 0 && (
							<section className="flex flex-col mx-5 mb-4">
								<div className="flex flex-col sm:flex-row justify-end">
									<EditExhibitorsToggleLink />
								</div>
							</section>
						) }

						{ !hasSearchResults && ((exhibitors.featured && exhibitors.featured.length > 0) || (exhibitors.filtered && exhibitors.filtered.length > 0)) && <h2 id="booths" className="text-2xl font-bold w-full text-left mb-6 sm:mx-5 md:mx-10 lg:mx-5">Virtual Booths</h2> }

						{ hasSearchResults && <div className="w-full text-gray-400 text-center mb-5">Found {search.results.length} {search.results.length === 1 ? 'result' : 'results'} matching "{termRef.current.value}"&nbsp;&nbsp;&nbsp;<a
							href="#" className="text-hub-green hover:underline" onClick={clearSearchResults}>clear results</a></div>}

						{ hasSearchResults && <section className="search-results-section flex flex-col sm:flex-row sm:flex-wrap sm:-mx-5 md:-mx-10 xl:-mx-5">
							{ search.results.map((result, index) => <VirtualBoothCard key={index} {...result} hasEditAccess={hasEventEditAccess} />) }
						</section> }

						<div>
							{ !hasSearchResults && exhibitors.featured && exhibitors.featured.length > 0 && <h2 className="text-2xl font-normal w-full text-left mb-6 sm:mx-5 md:mx-10 lg:mx-5">Featured Booths</h2> }

							{ !hasSearchResults && exhibitors.featured && exhibitors.featured.length > 0 && <section className="featured-exhibitors-section flex flex-col sm:flex-row sm:flex-wrap sm:-mx-5 md:-mx-10 xl:-mx-5">
								{ exhibitors.featured.map((featuredExhibitor, index) => <VirtualBoothCard key={index} {...featuredExhibitor} hasEditAccess={hasEventEditAccess} />) }
							</section> }

							{ !hasSearchResults && exhibitors.filtered && exhibitors.filtered.length > 0 && <div className="flex justify-between items-center">
								<h2 className="text-2xl font-normal text-left mb-6 sm:mx-5 md:mx-10 lg:mx-5">All Booths</h2>
								{ sortableTags.length > 0 ? <div className="flex">
									<label htmlFor="boothFilter" className="mb-3 sm:mb-0 mr-0 sm:mr-4">Filter Booths by Type { !displayExhibitorFilter ? '' : `: ${displayExhibitorFilter}` }</label>
									<section className="flex flex-col sm:flex-row sm:flex-wrap mb-6 justify-end">
										<SelectDropdown id="boothFilter" options={ [{ text: 'Show All', value: 'Show All' }, ...sortableTags] }
															onSelection={filterValue => setExhibitorFilter(filterValue !== 'Show All' ? filterValue : '')} />
									</section>
								</div> : <span /> }
							</div> }

							{ !hasSearchResults && exhibitors.filtered && exhibitors.filtered.length > 0 && <section className="exhibitors-section flex flex-col sm:flex-row sm:flex-wrap sm:-mx-5 md:-mx-10 lg:-mx-5">
								{ exhibitors.filtered.map((exhibitor, index) => <VirtualBoothCard key={index} {...exhibitor} hasEditAccess={hasEventEditAccess} />) }
							</section> }
						</div>

						<div id="skip-back-to-top">
							<a href="#header-bar" className="skip-content-button">Skip back to top</a>
						</div>
					</section>
				</section>
			</div>
			{ eventProfile.id && hasEventEditAccess && editModeEnabled && <EventEditColumn onSave={handleEventChange} /> }
		</main>
	</>);
}

import React, { useState, useEffect, useCallback, useRef } from "react";
import useDeviceDetect from "../../hooks/helper/useDeviceDetect";
import Call from "./components/Call";
import Tray from "./components/Tray";
import BoothChat from "./components/BoothChat";
import { logDailyEvent } from "./logUtils";
import LoadingVirtualBooth from "./LoadingVirtualBooth";

// hooks
import useCallObject from "../../hooks/data/useSessionCallObject";
import { CALL_STATUS } from "../../context/sessionCallObject";
import useFullScreen from "../../hooks/helper/useFullScreen";
import useMessages from "../../hooks/data/useSessionChatMessages";
import useEventData from "../../hooks/data/useEventData";

export default function MobileBooth({ settings, hasHostAccess, endTime }) {
  const [ callObject, boothState, setBoothState ] = useCallObject();
  const videoChatWindow = useRef(null);
  const detectDevice = useDeviceDetect();
  const [ isFullScreen, toggleFullScreen ] = useFullScreen(videoChatWindow);
  const [ isChatOpen, setChatOpen ] = useState(false);
  const [ messages ] = useMessages();
  const [ numUnreadMessages, setNumUnreadMessages ] = useState(0);
  const [ eventProfile ] = useEventData();

  const startLeavingCall = useCallback(() => {
    if (!callObject) return;
    setBoothState(CALL_STATUS.leaving);
  }, [ callObject ]);

  useEffect(() => {
    if (!callObject) {
      return;
    }

    const events = ['joined-meeting', 'left-meeting', 'error', 'app-message', '*'];

    function handleNewMeetingState(event) {
      event && logDailyEvent(event);
      switch (callObject.meetingState()) {
        case "joined-meeting":
          setBoothState(CALL_STATUS.joined);
          break;
        case "left-meeting":
          setBoothState(CALL_STATUS.leftMeeting);
          break;
        default:
          break;
      }
    }

    // Use initial state
    handleNewMeetingState();

    // Listen for changes in state
    for (const event of events) {
      callObject.on(event, handleNewMeetingState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewMeetingState);
      }
    };
  }, [callObject]);

  useState(() => {
    console.log('messages', messages)
    if (!isChatOpen && Object.keys(messages).length) {
      setNumUnreadMessages(numUnreadMessages+1);
    }
  }, [messages])

  function viewUnreadMessages() {
    setChatOpen(true);
    setNumUnreadMessages(0);
  }

  const showChat = [ CALL_STATUS.joined ].includes(boothState) && settings.videoChat.enableChat;
  const showCall = [ CALL_STATUS.joined, CALL_STATUS.error ].includes(boothState);
  const enableCallButtons = [ CALL_STATUS.joined, CALL_STATUS.error ].includes(boothState);
  const showNameField = [ CALL_STATUS.nameRequired ].includes(boothState);

  if ([ CALL_STATUS.terminateRoom, CALL_STATUS.roomTerminated ].includes(boothState)) {
    return <div className="flex h-full w-full items-center justify-center text-white font-black text-3xl" style={{background: eventProfile.advanceStyles.brandColor, color: eventProfile.advanceStyles.brandText}}>Session Ended</div>;
  }

  if ([ CALL_STATUS.booted ].includes(boothState)) {
    return <div className="bg-red-500 flex h-full w-full items-center justify-center text-white font-black text-3xl">Booted From Booth</div>;
  }

  if ([ CALL_STATUS.waitingFullRoom ].includes(boothState)) {
    return (<div className="m-auto">
      <div className="flex justify-center h-full w-full items-center">
        <h1 className="text-bold text-3xl mx-auto text-white text-center">Booth is currently full... Please Try again later.</h1>
      </div>
    </div>);
  }

  return (<section className="mobile-booth max-h-full fixed inset-0 overflow-scroll z-50">
    { !isChatOpen && numUnreadMessages > 0 && showCall && <div className="fixed top-2 right-2 z-50">
			<div onClick={viewUnreadMessages} className="cursor-pointer bg-red-400 font-normal text-white p-2 rounded text-xs leading-tight w-auto inline whitespace-normal break-all max-w-full">{numUnreadMessages} new message{numUnreadMessages === 1 ? "" : "s"}</div>
		</div> }
    <div className="bg-gray-300 h-full w-full flex flex-col justify-between" ref={e => { videoChatWindow.current = e; }}>
      { showCall && <div className="h-full flex flex-col justify-between">
        <div className="w-full flex flex-col justify-between bg-gray-300 pb-10">
            <Call settings={settings} practiceSecondsLeft={false} hasHostAccess={hasHostAccess} endTime={endTime} />
        </div>
        <div className={`fixed inset-x-0 bottom-0 z-50 ${isChatOpen && 'h-3/5'}`}>
          <Tray settings={settings} disabled={!enableCallButtons} onClickLeaveCall={startLeavingCall} hasHostAccess={hasHostAccess} isFullScreen={isFullScreen} onClickFullScreen={() => toggleFullScreen()} isChatOpen={isChatOpen} toggleChat={() => setChatOpen(s => !s)} />
          { showChat && isChatOpen && <BoothChat isChatOpen={isChatOpen} />}
          { !showCall && !showNameField && <LoadingVirtualBooth /> }
        </div>
      </div> }
    </div>
  </section>);
}

import React, { useState, useEffect, useLayoutEffect, useMemo, useRef, useCallback } from 'react';
import VillageLink from "../VillageLink";
import useEventData from "../../hooks/data/useEventData";
import useDisplayAds from "../../hooks/data/useDisplayAds";
import useInterval from "../../hooks/helper/useInterval";
import {Link} from "react-router-dom";
import {randomIntFromInterval} from "../../context/displayAds";

export function VerticalAdPlacement({ forceHideAd, onAdVisibilityChange }) {
	const [ eventProfile ] = useEventData();
	const [ allAdPlacements ] = useDisplayAds();
	const [ hasBeenSkipped, setSkip ] = useState(false);
	const [ canSkipAd, setCanSkip ] = useState(false);
	const [ currentAdPosition, setPlacementPosition ] = useState(0);
	const adPlacements = useMemo(() => allAdPlacements['vertical'], [ allAdPlacements ]);
	const adPlacement = useMemo(() => adPlacements && adPlacements.length ? adPlacements[currentAdPosition] : null, [ currentAdPosition, adPlacements ]);
	const moveAdPosition = useCallback(() => {
		let nextPosition = currentAdPosition + 1 > adPlacements.length - 1 ? 0 : currentAdPosition + 1;
		setPlacementPosition( nextPosition);
	}, [ currentAdPosition, adPlacements ]);

	useEffect(() => {
		if (adPlacements && adPlacements.length) {
			setPlacementPosition(randomIntFromInterval(0, (adPlacements.length - 1)))
		}
	}, [adPlacements]);

	useInterval(() => {
		moveAdPosition();
		setSkip(false);
		setCanSkip(false);
	},5 * 60 * 1000);

	useLayoutEffect(() => {
		if (onAdVisibilityChange) {
			let isShowingAd = adPlacement && !hasBeenSkipped;
			onAdVisibilityChange(isShowingAd);
		}
	}, [ adPlacement, hasBeenSkipped ]);

	// show skip after 5 seconds...
	useLayoutEffect(() => {
		let timer = setTimeout(() => setCanSkip(true), 5 * 1000);
		return () => clearTimeout(timer);
	}, [ adPlacement ]);

	// trigger skip after 30 seconds...
	useLayoutEffect(() => {
		let timer = setTimeout(() => setSkip(true), 30 * 1000);
		return () => clearTimeout(timer);
	}, [ adPlacement ]);

	if (forceHideAd || hasBeenSkipped || !adPlacement) {
		return null;
	}

	const Image = () => (<img src={ adPlacement.imageUrl } alt={ adPlacement.label } aria-label={ adPlacement.label } style={{ margin: '0 auto', maxWidth: '100%', maxHeight: '100%' }} />);

	return (
		<div className={`w-full sm:w-1/4 bg-black sm:h-hero flex flex-col justify-around items-center px-3 py-6 sm:m-0`}>
			<section className="flex relative" style={{ maxHeight: '90%' }}>
				{ !forceHideAd && adPlacement?.booth ? (
					<Link to={`${ !window.white_label_slug ? `/${eventProfile.urlSlug}` : '' }/${ adPlacement.booth.urlSlug }`}><Image /></Link>
				) : !forceHideAd && adPlacement?.label ? (
					<VillageLink to={adPlacement.url} eventLabel={`${ adPlacement.label } ad click`}><Image /></VillageLink>
				) : null }
				{ !forceHideAd && adPlacement && canSkipAd &&  (
					<button className="absolute bottom-10 px-3 py-2 leading-none border border-white bg-black text-white text-lg" style={{left: '50%', marginLeft: '-43px'}} onClick={() => setSkip(true) }>Skip Ad</button>
				) }
			</section>
		</div>
	);
}

export function HorizontalAdPlacement() {
	const [ eventProfile ] = useEventData();
	const [ allAdPlacements ] = useDisplayAds();
	const [ currentAdPosition, setPlacementPosition ] = useState(0);
	const adPlacements = useMemo(() => allAdPlacements['horizontal'], [ allAdPlacements ]);
	const adPlacement = useMemo(() => adPlacements && adPlacements.length ? adPlacements[currentAdPosition] : null, [ currentAdPosition, adPlacements ]);
	const moveAdPosition = useCallback(() => {
		let nextPosition = currentAdPosition + 1 > adPlacements.length - 1 ? 0 : currentAdPosition + 1;
		setPlacementPosition( nextPosition);
	}, [ currentAdPosition, adPlacements ]);

	useEffect(() => {
		if (adPlacements && adPlacements.length) {
			setPlacementPosition(randomIntFromInterval(0, (adPlacements.length - 1)))
		}
	}, [adPlacements]);

	useInterval(() => moveAdPosition(),10 * 1000);

	const Image = () => (<img src={ adPlacement.imageUrl } alt={ adPlacement.label } aria-label={ adPlacement.label } style={{ ...(adPlacement.size || {}), maxWidth: '100%' }} />);

	return (
		<div>
			<section className={`flex justify-around items-center mb-3`} style={{ height: '90px'}}>
				{ adPlacement?.booth ? (
					<Link to={`${ !window.white_label_slug ? `/${eventProfile.urlSlug}` : '' }/${ adPlacement.booth.urlSlug }`}><Image /></Link>
				) : adPlacement?.label ? (
					<VillageLink to={adPlacement.url} eventLabel={`${ adPlacement.label } ad click`}><Image /></VillageLink>
				) : null }
			</section>
		</div>
	);
}
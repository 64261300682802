import React, { useState } from 'react';
import { add, format as formatDate, getDaysInMonth, setDate } from 'date-fns';

const DATE_FORMAT = 'M/dd/yyyy';
const MONTH_FORMAT = 'MMM';
const YEAR_FORMAT = 'yyyy';
const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const LeftArrowSVG = ({ classes }) => <svg className={`h-6 w-6 text-gray-500 inline-flex ${classes}`} fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"/></svg>;

const RightArrowSVG = ({ classes }) => <svg className={`h-6 w-6 text-gray-500 inline-flex ${classes}`} fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/></svg>;

let CURRENT_DATE = new Date();

export default function PickerFrame({ initialDate, onSelectDate, classes }) {
	let [ pickerMonth, setPickerMonth ] = useState({ label: formatDate(initialDate, MONTH_FORMAT), date: initialDate});
	let [ pickerYear, setPickerYear ] = useState({ label: formatDate(initialDate, YEAR_FORMAT) });

	function decrementMonth() {
		if (pickerMonth.date <= CURRENT_DATE) {
			return;
		}
		let newMonth = add(pickerMonth.date, { months: -1 });
		setPickerMonth({ label: formatDate(newMonth, MONTH_FORMAT), date: newMonth });
		setPickerYear({ label: formatDate(newMonth, YEAR_FORMAT) });
	}

	function incrementMonth() {
		let newMonth = add(pickerMonth.date, { months: 1 });
		setPickerMonth({ label: formatDate(newMonth, MONTH_FORMAT), date: newMonth });
		setPickerYear({ label: formatDate(newMonth, YEAR_FORMAT) });
	}

	// build the calendar...
	let initialDateStr = formatDate(initialDate, DATE_FORMAT);
	let daysInMonth = getDaysInMonth(pickerMonth.date);
	let dayOfWeek = parseInt(formatDate(setDate(pickerMonth.date, 1), 'i'));
	let blankDays = [];
	for (let i=1; i <= dayOfWeek; i++) {
		blankDays.push(i);
	}

	let datesOfMonth = [];
	for (let i=1; i <= daysInMonth; i++) {
		datesOfMonth.push(setDate(pickerMonth.date, i));
	}

	return (<div className={`bg-white rounded-lg shadow p-4 absolute z-10 top-0 left-0 ${classes}`} style={{width: '17rem'}}>
		<div className="flex justify-between items-center mb-2">
			<div>
				<span className="text-lg font-bold text-gray-800">{ pickerMonth.label }</span>
				<span className="ml-1 text-lg text-gray-600 font-normal">{ pickerYear.label }</span>
			</div>
			<div>
				<button
					type="button"
					className={`transition ease-in-out duration-100 inline-flex hover:bg-gray-200 p-1 rounded-full ${pickerMonth.date >= CURRENT_DATE ?'cursor-pointer':'cursor-not-allowed'}`}
					onClick={ decrementMonth } >
					<LeftArrowSVG />
				</button>
				<button
					type="button"
					className="transition ease-in-out duration-100 inline-flex p-1 rounded-full"
					onClick={ incrementMonth } >
					<RightArrowSVG />
				</button>
			</div>
		</div>

		<div className="flex flex-wrap mb-3 -mx-1">
			{ DAYS.map((day, dayIndex) => <div key={dayIndex} style={{ width: '14.26%' }} className="px-1">
				<div className="text-gray-800 font-light text-center text-xs">{ day }</div>
			</div>)}
		</div>

		<div className="flex flex-wrap -mx-1">
			{ blankDays.map((d, dateIndex) => <div key={dateIndex}
																						 style={{ width: '14.26%' }}
																						 className="text-center border p-1 border-transparent text-sm" />)}
			{ datesOfMonth.map((date, dateIndex) => <div key={dateIndex} style={{ width: '14.26%' }} className="px-1 mb-1">
				<div
					onClick={() => onSelectDate(date)}
					className={`cursor-pointer text-center text-sm leading-none rounded-full leading-loose transition ease-in-out duration-100 ${ initialDateStr === formatDate(date, DATE_FORMAT) ? 'bg-blue-500 text-white' : 'text-gray-700 hover:bg-blue-200' }`}
				>{ formatDate(date, 'd') }</div>
			</div>)}
		</div>
	</div>)
}
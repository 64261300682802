import React from "react";
import useEventData from "../../../../hooks/data/useEventData";
import EventHubImg from "../../../../assets/powered_by_eh_trans.png";

const noop = (e) => e.preventDefault();

function MiniNavBar({ theme, headerLogo, sponsorLogo }) {
	const [ eventProfile ] = useEventData();
	return (
		<>
			<header className="main-village-header flex flex-fix justify-between shadow-lg flex-col sm:flex-row relative z-30" style={{ backgroundColor: theme.brandColor }}>
				<section className="px-4 py-3">
					<section className="flex items-center">
						<a href="#" onClick={ noop } className="flex">
							{ headerLogo ? <img src={headerLogo} alt={`${eventProfile.name} Home`} className="event-logo w-auto h-12 mb-4 sm:mb-0" /> : <span className="h-16 block">&nbsp;</span>	 }
						</a>
						<section className="flex-grow flex-col mx-2 w-3/5 sm:w-auto sm:mx-0 sm:ml-4">
							{ eventProfile.showNameInHeader && <a href="#" onClick={ noop } className="">
								<h1 className={`event-name-preview text-lg sm:text-xl align-middle flex items-center text-left leading-none mb-1`} style={{ color: theme.brandText }}>Event Name</h1>
							</a>}
						</section>
					</section>
				</section>
				<aside className="sponsor-logo py-3 sm:py-0 px-5 flex items-center relative">
					<a href="#" onClick={ noop } target="_blank" className="mx-auto"><img src={sponsorLogo || EventHubImg} className="h-12" alt="Learn More about Event Hub"/></a>
				</aside>
			</header>
			<section className="hidden sm:flex flex-fix justify-around items-center px-6 py-3 pb-2">
				<div className="container lg:flex mx-auto lg:justify-around lg:items-center">
					<nav className="main-village-header-links-preview flex-row flex" style={{ color: theme.linkText }}>
						<a href="#" className="mr-10 pb-0  hover:underline" onClick={ noop }>Link One</a>
						<a href="#" className="mr-10 pb-0  hover:underline" onClick={ noop }>Link Two</a>
						<a href="#" className="mr-10 pb-0  hover:underline" onClick={ noop }>Link Three</a>
					</nav>
				</div>
			</section>
		</>
	);
}

function MiniSubNavBar({ theme }) {
	const HEADER_STYLES = {backgroundColor: theme.brandColor};
	const PRIMARY_BUTTON_STYLES = {color: theme.primaryText, backgroundColor: theme.primaryColor};
	const SECONDARY_BUTTON_STYLES = {color: theme.secondaryText, backgroundColor: theme.secondaryColor};

	return (
		<section className="main-village-sub-nav exhibitor-village-sub-nav flex shadow-lg" style={HEADER_STYLES}>
			<div className="container mx-auto flex flex-row items-center p-3">
				<span className={`w-auto text-center px-3 py-2 text-sm rounded leading-none mr-3 flex items-center justify-around flex-grow`} style={PRIMARY_BUTTON_STYLES}>Primary Color/Text</span>
				<span className={`w-auto text-center px-3 py-2 text-sm rounded leading-none mr-3 flex items-center justify-around flex-grow`} style={SECONDARY_BUTTON_STYLES}>Secondary Color/Text</span>
			</div>
		</section>
	);
}

function MiniCoverArea({ theme, coverImage }) {
	const COVER_IMAGE_STYLES = {backgroundColor: theme.secondaryColor};

	return coverImage ? (<section className="cover-image-area bg-cover bg-center relative" style={COVER_IMAGE_STYLES}>
		<img src={ coverImage } className="w-full block" alt="Event Cover Image" />
	</section>) : null;
}


export default function MiniSitePreview({ items }) {
	return (
		<section className="advanceStylesMiniPreview flex flex-col w-full sm:w-1/2 md:pl-6 lg:pl-12 lg:pr-12 mt-8 sm:mt-0">
			<div className="sticky top-3 right-0 left-0">
				<div className="container bg-white shadow rounded overflow-hidden">
					<MiniNavBar theme={ items } headerLogo={ items.headerLogo } sponsorLogo={ items.sponsorLogo } />
					<MiniCoverArea theme={ items } coverImage={ items.coverImage } />
					<MiniSubNavBar theme={ items } />
					<section className="px-12 py-6 flex">
						<div className="styled-description description-preview">
							<h1>Heading 1</h1>
							<h2>Heading 2</h2>
							<h3>Heading 3</h3>
							<p>This is a paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent lacinia vitae lorem vel dictum. <a
								href="https://eventhub.net" target="_blank">It also has a link!</a></p>
						</div>
					</section>
				</div>
			</div>
		</section>
	)
}
import React from 'react';
import EventHubImg from "../../assets/powered_by_eh_trans.png";
import VillageLink from "../VillageLink";
import { FacebookSqSVG, InstagramSqSVG, TwitterSqSVG, LinkSVG } from "../Icons";
import { useTrackers } from "../../utils/analytics";
import useEventData from "../../hooks/data/useEventData";

export default function Footer() {
	const trackers = useTrackers();
	const [ eventProfile ] = useEventData();

	return (<footer className="bg-hub-grey px-6 py-2 flex justify-between items-center sm:hidden fixed bottom-0 right-0 left-0 z-20 footer-shadow">
		<section className="">
			<nav className={`flex text-gray-700`}>
				<div>
					<a href={`${window.location.origin}/${eventProfile.urlSlug}`} id="skip-to-event-home" className="skip-content-button">skip to event home</a>
				</div>
				{ eventProfile.websiteUrl && <VillageLink eventLabel="Event Website" className="uppercase mr-4" to={ eventProfile.websiteUrl } trackerNames={trackers}>
					<LinkSVG classes="h-5 w-5 fill-current" title={`Visit ${eventProfile.name}'s official website `}/>
				</VillageLink>}
				{ eventProfile.instagramUrl && <VillageLink eventLabel="Event Instagram" className="uppercase mr-4" to={ eventProfile.instagramUrl } trackerNames={trackers}>
					<InstagramSqSVG classes="h-6 w-6 fill-current" title={`Follow ${eventProfile.name} on Instagram`}/>
				</VillageLink>}
				{ eventProfile.facebookUrl && <VillageLink eventLabel="Event Facebook" className="uppercase mr-4" to={ eventProfile.facebookUrl } trackerNames={trackers}>
					<FacebookSqSVG classes="h-6 w-6 fill-current" title={`Follow ${eventProfile.name} on Facebook`}/>
				</VillageLink>}
				{ eventProfile.twitterUrl && <VillageLink eventLabel="Event Twitter" className="uppercase mr-4" to={ eventProfile.twitterUrl } trackerNames={trackers}>
					<TwitterSqSVG classes="h-6 w-6 fill-current" title={`Follow ${eventProfile.name} on Twitter`}/>
				</VillageLink>}
			</nav>
		</section>
		{ eventProfile.sponsorLogo ? <img src={ eventProfile.sponsorLogo } className="h-12" alt="Official Event Sponsor"/> :
			<a href="https://eventhub.net/" target="_blank"><img src={ EventHubImg } className="h-12" alt="Learn More about Event Hub"/></a> }
	</footer>);
}
import * as yup from "yup";
const urlValidationMsg = 'Must be a valid url starting with http://';

const websiteUrl = yup.string().url(urlValidationMsg);
const mainStageUrl = yup.string().url(urlValidationMsg).matches(/(^(https?\:\/\/)?([^.]+\.(wistia\.com|wi\.st)\/(medias|embed)\/.*)|((www\.)?(((youtube|vimeo)\.com|youtu\.?be)|(twitch\.tv)|(wistia\.com|wi\.st))\/.+$)|(https?:\/\/(^.)+\.(wistia\.com|wi\.st)\/.*))/, {message: 'Url must be from YouTube, Twitch, Vimeo, or Wistia', excludeEmptyString: true}).required('Main Stage livestream requires a url.');
const coverVideoUrl = yup.string().url(urlValidationMsg).matches(/(^(https?\:\/\/)?([^.]+\.(wistia\.com|wi\.st)\/(medias|embed)\/.*)|((www\.)?(((youtube|vimeo)\.com|youtu\.?be)|(twitch\.tv)|(wistia\.com|wi\.st))\/.+$)|(https?:\/\/(^.)+\.(wistia\.com|wi\.st)\/.*))/, {message: 'Url must be from YouTube, Twitch, Vimeo, or Wistia', excludeEmptyString: true});
const youtubeUrl = yup.string().url(urlValidationMsg).matches(/^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/, { message: 'Url must be from YouTube', excludeEmptyString: true });
const linkedinUrl = yup.string().url(urlValidationMsg).matches(/^(https?\:\/\/)?(www\.)?(linkedin\.com).+$/, { message: 'Url must be from linkedin.com', excludeEmptyString: true });
const twitterUrl = yup.string().url(urlValidationMsg).matches(/^(https?\:\/\/)?(www\.)?(twitter\.com)\/.+$/, { message: 'Url must be from twitter.com', excludeEmptyString: true });
const facebookUrl = yup.string().url(urlValidationMsg).matches(/^(https?\:\/\/)?(www\.)?(facebook\.com)\/.+$/, { message: 'Url must be from facebook.com', excludeEmptyString: true });
const instagramUrl = yup.string().url(urlValidationMsg).matches(/^(https?\:\/\/)?(www\.)?(instagram\.com)\/.+$/, { message: 'Url must be from instagram.com', excludeEmptyString: true });
const widgetUrl = yup.string().url(urlValidationMsg).matches(/^(https?\:\/\/)?(www\.)?(((youtube|vimeo|twitter|facebook|pinterest)\.com|youtu\.?be)|(twitch\.tv))\/.*/, {message: 'Url must be from supported platform', excludeEmptyString: true});
const linkLabel = yup.string().max(20, 'Must be less than 20 characters.');

const whitespaceTestOptions = {
	name: "whitespace",
	test: (value) => !value.includes(" "),
	message: "Must not contain any spaces.",
};

const passwordRequirements = {
	name: "password security",
	test: (value) => value.length === 0 || (value.match(/\d/) && value.match(/[A-Z]/) && value.match(/[a-z]/)),
	message: "Must contain 1 number, 1 uppercase, 1 lowercase"
}

const passwordLength = {
	name: "password length",
	test: (value) => value.length === 0 || value.length >= 8,
	message: "Must be empty for no password or at least 8 characters"
}

const urlWithNoProtocolOptions = {
	name: "shopifyUrl",
	test: (value) => !value.match(/(http|ftp|:\/\/)/ig),
	message: "Must only contain the domain name (ex. store.example.com).",
};

const shopifyApiTokenTestOptions = {
	name: "shopifyApiToken",
	test: (value) => value === null || value === '' || value.length >= 32,
	message: "The Storefront API Key should be at least 32 characters",
};

export const DisplayItemSchema = yup.object().shape({
	title: yup.string().required(),
	// description: yup.string().max(180, 'Max character length is 180'),
	ctaUrl: yup.string().url(urlValidationMsg)
});

export const ExhibitorContactMessageSchema = yup.object().shape({
	name: yup.string().required(),
	message: yup.string().min(25, 'Maybe write them something a bit longer?').required('You should write them something.'),
	emailAddress: yup.string().email().required()
});

export const MainStageTimeSchema = yup.object().shape({
	name: yup.string().required(),
	url: mainStageUrl,
	cardDescription: yup.string().max(160, 'Must be less than 160 characters.')
});

export const TrackSchema = yup.object().shape({
	name: linkLabel
});

export const SessionSchema = yup.object().shape({
	name: yup.string().required('We need to have a name.').max(60, 'Must be less than 60 characters.'),
	cardDescription: yup.string().max(160, 'Must be less than 160 characters.')
});

export const EventProfileSchema = yup.object().shape({
	name: yup.string().required(),
	urlSlug: yup.string().matches(/^[A-Za-z0-9-]+$/, {message: 'May only contain letters, numbers, and hyphens'}).min(2, 'Must be at least 2 characters').test(whitespaceTestOptions).trim('Must contain no trailing spaces').lowercase().required(),
	homepageLinkLabel: linkLabel,
	schedulePageLinkLabel: linkLabel,
	speakerPageLinkLabel: linkLabel,
	websiteUrl,
	twitterUrl,
	facebookUrl,
	instagramUrl,
	gaTrackingID: yup.string().matches(/^(UA-[0-9]+-[0-9]+)$/, {message: 'Must be valid Google Tracking ID', excludeEmptyString: true}),
});

export const EventProfileAttendeeSchema = yup.object().shape({
	attendeeMaxAccessUsage: yup.number().integer('You can only enter a number').min(0,'Must be 0 or greater.').required('Must be 0 or greater.'),
	attendeePassword: yup.string().test(passwordRequirements).test(passwordLength).trim('Must contain no trailing spaces')
});

export const EventVirtualSchema = yup.object().shape({
	generalInfoUrl: yup.string().url(urlValidationMsg),
	exhibitorInfoUrl: yup.string().url(urlValidationMsg)
});

export const EventDesignSchema = yup.object().shape({
	name: yup.string().required(),
	urlSlug: yup.string().matches(/^[A-Za-z0-9-]+$/, {message: 'May only contain letters, numbers, and hyphens'}).min(4, 'Must be at least 4 characters').test(whitespaceTestOptions).trim('Must contain no trailing spaces').lowercase().required(),
	websiteUrl,
	twitterUrl,
	facebookUrl,
	instagramUrl,
	gaTrackingID: yup.string().matches(/^(UA-[10-9]*-[0-9])$/, {message: 'Must be valid Google Tracking ID', excludeEmptyString: true}),
	generalInfoUrl: yup.string().url(urlValidationMsg),
	exhibitorInfoUrl: yup.string().url(urlValidationMsg),
	attendeePassword: yup.string().test(passwordRequirements).test(passwordLength).trim('Must contain no trailing spaces'),
	mainStageUrl
});

export const ExhibitorProfileSchema = yup.object().shape({
	name: yup.string().required(),
	location: yup.string(),
	urlSlug: yup.string().matches(/^[A-Za-z0-9-]+$/, {message: 'May only contain letters, numbers, and hyphens'}).min(2, 'Must be at least 2 characters').test(whitespaceTestOptions).trim('Must contain no trailing spaces').strict().required(),
	contactEmail: yup.string().email('Please enter a valid email.').required(),
	websiteUrl,
	twitterUrl,
	facebookUrl,
	instagramUrl,
	coverVideoUrl: coverVideoUrl,
	virtualBoothParticipantLimit: yup.number().nullable(true).max(200).transform((v, o) => o === '' ? null : v),
	conferenceUrl: yup.string().url(urlValidationMsg),
	shopifyUrl: yup.string().test(urlWithNoProtocolOptions),
	shopifyStorefrontApiKey: yup.string().test(shopifyApiTokenTestOptions),
	phoneNumber: yup.string().matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, { message: 'Must enter a valid 10 digit phone number', excludeEmptyString: true })
});

export const ProgrammingSlotSchema = yup.object().shape({
	name: yup.string().required(),
	duration: yup.number().required(),
	videoChat: yup.object().shape({
		participantLimit: yup.number().nullable(true).max(200).transform((v, o) => o === '' ? null : v)
	})
});

export const SpeakerProfileSchema = yup.object().shape({
	name: yup.string().required(),
	websiteUrl,
	twitterUrl,
	facebookUrl,
	instagramUrl,
	youtubeUrl,
	linkedinUrl
});

export const SocialMediaWidgetSchema = yup.object().shape({
	url: widgetUrl
});

export const HeaderLinkSchema = yup.object().shape({
	label: linkLabel.required('Header Link requires a label.'),
	url: websiteUrl.required('Header Link requires a url.')
})

export const DisplayAdSchema = yup.object().shape({
	label: linkLabel.required('We need a label for this ad.'),
	imageUrl: yup.string().required(),
	url: websiteUrl
})

export const AnnouncementSchema = yup.object().shape({
	title: yup.string().max(60, 'Must be less than 60 characters.').required('Notification requires a headline.'),
	ctaUrl: websiteUrl
})
import React, { useState, createContext, useEffect, useRef } from 'react';
import {getContrastHex, isLightColor, LightenDarkenColor} from "../utils/styles";
import ReactGA from 'react-ga';
import useConfig from "../hooks/app/useConfig";
import usePrevious from "../hooks/helper/usePrevious";

const INITIAL_STATE = {
	id: null,
	_id: null,
	timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
	showNameInHeader: true,
	showHomepageLinkInHeader: false,
	showSchedulePageLinkInHeader: false,
	showSpeakerPageLinkInHeader: false,
	isActive: true,
	brandColor: '#cccccc',
	attendeePassword: '',
	attendeeMaxAccessUsage: 2,
	onlyAllowTicketHolders: false,
	exhibitors: [],
	upgrades: [],
	headerLinks: [],
	hasArrangements: false,
	arrangements: null,
	allowExhibitorsExternalConference: true,
	sponsorLogo: '',
	enabledAdvanceStyles: false,
	enableAgeVerification: false,
	ageVerification: 18,
	enabledCustomColors: false,
	enabledCustomFonts: false,
	enabledCustomBranding: false,
	enabledCustomCSS: false,
	advanceStyles: {
		spreadsheetData: "/*.sponsor-logo{background: #EEE;}*/\r `\rbody{//background: blue !important;}",
		brandColor: '#cccccc',
		brandText: '#cccccc',
		primaryColor: '#cccccc',
		primaryText: '#cccccc',
		secondaryColor: '#cccccc',
		secondaryText: '#cccccc',
		linkText: '#cccccc',
		brandFont: 'Roboto',
		headingFont: 'Roboto',
		paragraphFont: 'Roboto'
	}
};

const EventContext = createContext([{}, () => {}]);

function EventProvider(props) {
	const { allowAdvanceStyles, allowAdvanceStylesFor } = useConfig();
	const [ initdTracking, setInitTracking ] = useState(false);
	const [ state, setState ] = useState(INITIAL_STATE);
	const previousState = usePrevious(JSON.stringify(state));

	useEffect(() => {
		if (JSON.stringify(state) === previousState) {
			return;
		}
		const hasAllowedAdvanceStyles = allowAdvanceStyles || allowAdvanceStylesFor.indexOf(state.id) > -1;
		const styleSectionsAllowed = {
			enabledAdvanceStyles: hasAllowedAdvanceStyles || (state.upgrades.find(u => ['virtual-village-advance-styles', 'virtual-village-advance-styles-bundle', 'virtual-village-sponsor-logo', 'virtual-village-custom-branding', 'virtual-village-advanced-branding'].indexOf(u) > -1  ) !== undefined),
			enabledCustomColors: hasAllowedAdvanceStyles || (state.upgrades.find(u => ['virtual-village-advance-styles', 'virtual-village-advance-styles-bundle', 'virtual-village-custom-branding', 'virtual-village-advanced-branding'].indexOf(u) > -1  ) !== undefined),
			enabledCustomFonts: hasAllowedAdvanceStyles || (state.upgrades.find(u => ['virtual-village-advance-styles', 'virtual-village-advance-styles-bundle', 'virtual-village-custom-branding', 'virtual-village-advanced-branding'].indexOf(u) > -1  ) !== undefined),
			enabledCustomBranding: hasAllowedAdvanceStyles || (state.upgrades.find(u => ['virtual-village-custom-branding', 'virtual-village-advance-styles-bundle', 'virtual-village-sponsor-logo', 'virtual-village-advanced-branding'].indexOf(u) > -1 ) !== undefined),
			enabledCustomCSS: hasAllowedAdvanceStyles || (state.upgrades.find(u => ['virtual-village-custom-css', 'virtual-village-advance-styles-bundle', 'virtual-village-advanced-branding'].indexOf(u) > -1 ) !== undefined),
		};

		let advanceStyles = state.advanceStyles;
		if (!styleSectionsAllowed.enabledAdvanceStyles) {
			let brandText = getContrastHex(state.brandColor);

			let primaryColor = LightenDarkenColor(state.brandColor, isLightColor(state.brandColor) ? -30 : 40);
			let primaryText = getContrastHex(primaryColor);

			let secondaryColor = LightenDarkenColor(primaryColor, isLightColor(primaryColor) ? -30 : 40);
			let secondaryText = getContrastHex(secondaryColor);
			advanceStyles = {
				brandColor: state.brandColor,
				brandText,
				primaryColor,
				primaryText,
				secondaryColor,
				secondaryText
			};
		}

		setState(previousState => ({
			...previousState,
			...styleSectionsAllowed,
			advanceStyles: { ...previousState.advanceStyles, ...advanceStyles }
		}));
	}, [ state.id, state.brandColor, state.upgrades, state.advanceStyles ]);

	useEffect(() => {
			if (
				state.arrangements && (
					state.arrangements.featuredExhibitors && state.arrangements.featuredExhibitors.length > 0 ||
					state.arrangements.pinnedExhibitors && state.arrangements.pinnedExhibitors.length > 0 ||
					state.arrangements.exhibitors && state.arrangements.exhibitors.length > 0
				)
			) {
				setState(state => ({ ...state, hasArrangements: true}))
			}
	}, [ state.arrangements ]);

	useEffect(function() {
		if (state && state.gaTrackingID && !initdTracking) {
			ReactGA.addTrackers(
				[
				  {
					trackingId: state.gaTrackingID,
					gaOptions: {
					  name: 'producerTracker',
					}
				  },
				],
				{ debug: true, alwaysSendToDefaultTracker: true }
			);
			setInitTracking(true);
		}
	}, [ state.gaTrackingID ]);

	return (
		<EventContext.Provider value={[state, setState]}>
			{props.children}
		</EventContext.Provider>
	);
}

export { EventContext, EventProvider, INITIAL_STATE };

import React, { useState, createContext, useEffect, useMemo } from 'react';
import useLocalStorage from "../hooks/helper/useLocalStorage";

const INITIAL_ANNOUNCEMENTS = {
	title: '',
	type: 'popup',
	description: '',
	ctaLabel: '',
	ctaUrl: '',
	booth: null,
	imageUrl: '',
	dateTime: '',
}

const INITIAL_STATE = { popup: [], slidein: [], all: [] };

const AnnouncementsContext = createContext([{}, (announcements) => {}]);

function AnnouncementsProvider(props) {
	const [ announcements, setState ] = useState(INITIAL_STATE);
	const [ seenAnnouncements, storeAnnouncements ] = useLocalStorage('ANNOUNCEMENTS', null);
	const seenAnnouncementIds = useMemo(()=> !seenAnnouncements ? [] : seenAnnouncements.split('|'), [seenAnnouncements]);

	useEffect(() => {
		setState(state => ({
			...state,
			popup: announcements.all.filter(a => a.type === 'popup' && !seenAnnouncementIds.includes(a._id)),
			slidein: announcements.all.filter(a => a.type === 'slidein' && !seenAnnouncementIds.includes(a._id))
		}));
	}, [announcements.all, seenAnnouncementIds]);

	function setAnnouncements(newAnnouncements) {
		let originalAnnouncementIds = announcements.all.map(a => a._id);
		let announcementsToAdd = newAnnouncements.filter(a => !seenAnnouncementIds.includes(a._id) && !originalAnnouncementIds.includes(a._id));
		setState(state => ({ ...state, all: [...state.all, ...(announcementsToAdd || []) ] }));
	}

	function markAnnouncementAsSeen(id) {
		if (!seenAnnouncementIds.includes(id)) {
			storeAnnouncements([...seenAnnouncementIds, id].join('|'));
		}
	}

	function removeAnnouncement(id, list) {
		markAnnouncementAsSeen(id);
		setState(state => ({
			...state,
			[list]: state[list].filter(a => a._id !== id)
		}));
	}

	return (
		<AnnouncementsContext.Provider value={[announcements, setAnnouncements, removeAnnouncement ]}>
			{props.children}
		</AnnouncementsContext.Provider>
	);
}

export { AnnouncementsContext, AnnouncementsProvider, INITIAL_STATE, INITIAL_ANNOUNCEMENTS };

import React, { useEffect } from 'react';
import Notification from "../Notification";
import useNotifications from '../../hooks/app/useNotifications';
import useCurrentUser from '../../hooks/app/useCurrentUser';
import usePermissions from "../../hooks/app/usePermissions";

export default function AdminLayout({ children }) {
	let { isAdmin }  = usePermissions();
	let { notifications } = useNotifications();
	let [ currentUser ] = useCurrentUser();

	let displayContents = currentUser.token && isAdmin;

	if (!displayContents) {
		return (<div className="m-auto">
			<h1 className="text-bold text-3xl flex items-center m-auto text-gray-500"><span className="spinner h-full w-20 inline-block" />&nbsp;Loading...</h1>
		</div>)
	}

	return (<>
		{ children }
		<div className="fixed w-full sm:w-time-modal sm:top-5 sm:right-5 z-50">
			{ notifications && notifications.map((notification, index) => <Notification key={index} { ...notification } />)}
		</div>
	</>);
}

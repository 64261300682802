import React, {useState, useEffect, useRef} from 'react';
import { format as formatDate } from 'date-fns';
import PickerFrame from '../PickerFrame';

const DATE_FORMAT = 'M/dd/yyyy';

const CalendarSVG = ({ classes }) => <svg className={`h-6 w-6 text-gray-300 ${classes}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
	<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
				d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
</svg>;

export default function DatePicker({ defaultDate, onPickDate }) {
	let [ date, setDate ] = useState({ label: formatDate(defaultDate, DATE_FORMAT), date: defaultDate});
	let [ showDatePicker, setShowDatePicker ] = useState(false);
	const datePickerRef = useRef(null);

	useEffect(function() {
		onPickDate(date.date);
	}, [date.date]);

	function blurEffect(event) {
		if (showDatePicker && !datePickerRef.current.contains(event.target)) {
			setShowDatePicker(false);
		}
	}

	useEffect(function() {
		window.addEventListener('click', blurEffect);
		return () => {
			window.removeEventListener('click', blurEffect);
		}
	});

	function handleDateSelection(newDate) {
		setDate({ label: formatDate(newDate, DATE_FORMAT), date: newDate});
		setShowDatePicker(false);
	}

	return (<div className="relative flex flex-col" ref={datePickerRef}>
		<input name="date"
					 value={date.label}
					 readOnly
					 className="mt-1 time-input"
					 placeholder="Select date"
					 onClick={() => setShowDatePicker(!showDatePicker)} />
		{/*<div className="absolute bottom-0 right-0 px-1 pb-1">*/}
		{/*	<CalendarSVG />*/}
		{/*</div>*/}
		{ showDatePicker && <PickerFrame key='endDatePicker'
																				classes="mt-10 ml-1"
																				initialDate={date.date}
																				onSelectDate={handleDateSelection} /> }
	</div>);
}

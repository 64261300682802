import React from 'react';
import { Link } from 'react-router-dom';
import useDeviceDetect from "../../../hooks/helper/useDeviceDetect";
import useWhiteLabelSlugs from "../../../hooks/app/useWhitelabelSlugs";
import usePermissions from "../../../hooks/app/usePermissions";

export default function SpeakerCard({ name, _id : speakerId, logo, cardDescription, boothPersonTitle, speakerCompany, isPublished, isFeatured, isPinned, isPendingPublish, containerClass }) {
	const {  hasEventEditAccess } = usePermissions();
	const { eventSlug } = useWhiteLabelSlugs();
	let detectDevice = useDeviceDetect();
	return (<div tabIndex='0' className={`speaker-card ${ isPinned && 'pinned' } ${ isFeatured && 'featured' } w-full sm:w-auto ${containerClass}`}>
		<Link tabIndex='0' aria-label={`View ${ name }'s speaker booth`} to={ `/${  !window.white_label_slug ? `${ eventSlug }/speakers/` : 'speakers/' }${ speakerId }` }>
			<div className="flex flex-col h-speaker-card w-speaker-card mx-auto vendor-card-shadow overflow-hidden rounded cursor-pointer transition ease-in delay-300 duration-200 hover:shadow-xl transform hover:-translate-y-1 hover:scale-110">

				<div className={`flex flex-grow bg-cover bg-center relative z-0 w-full h-full`} style={{backgroundImage: `url("${ logo || '' }")`}}>

					<section className="absolute top-0 right-0 left-0 z-10 flex flex-col">
						{ hasEventEditAccess && isPendingPublish && !isPublished && <div className="uppercase leading-none tracking-widest text-sm text-center pt-1 pb-1 bg-yellow-300 bg-opacity-75 text-black font-medium">Pending Publication</div> }
						{ hasEventEditAccess && !isPendingPublish && !isPublished && <div className="uppercase leading-none tracking-widest text-sm text-center pt-1 pb-1 bg-red-500 bg-opacity-75 text-white font-medium">Profile Not Published</div> }
						{ hasEventEditAccess && isPublished && <div className="uppercase leading-none tracking-widest text-sm text-center pt-1 pb-1 bg-black bg-opacity-75 text-white font-medium">Profile published</div> }
					</section>

					<div className="absolute top-0 bottom-0 left-0 right-0 vendor-card-gradient z-0" />

					<section className="flex flex-col flex-grow justify-end items-center w-full px-4 pb-3 z-20">
						<div className="flex flex-grow items-end w-full">
							<section className="text-white flex flex-col">
								{ isFeatured && <div className="featured-pill">
									<label className="uppercase leading-none tracking-widest py-1 px-2 mb-1 bg-hub-green bg-opacity-75 text-xs text-center font-medium text-white rounded-full float-left">featured</label>
								</div> }
								{ isPinned && <div className="pinned-pill">
									<label className="uppercase leading-none tracking-widest py-1 px-2 mb-1 bg-red-500 bg-opacity-75 text-xs text-center font-medium text-white rounded-full float-left">pinned</label>
								</div> }
								<p className="text-base leading-none font-bold pb-2">{ name }</p>
								<p className="text-sm leading-snug overflow-hidden whitespace-no-wrap">{ `${boothPersonTitle || ''}${speakerCompany && boothPersonTitle ? ', ' : ''}${speakerCompany || ''}` }</p>
							</section>
						</div>

						{ cardDescription? <p className={`cardDescription text-xs text-white tracking-wide leading-snug mt-2 ${ detectDevice.isDesktop() && 'show-hide' }`}>{ cardDescription }</p> : <div/> }
						<span className="text-gray-200 text-xs mt-2">{ detectDevice.isMobile() ? 'Tap' : 'Click'} To Enter Profile</span>
					</section>

				</div>
			</div>
		</Link>
	</div>);
}
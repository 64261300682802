import React from 'react';
import { format as formatDate } from 'date-fns';

export default function EventBlastViewer({ onCloseModal, deleteButton, startTime, timezone, eventBlast }) {
  const DeleteButton = deleteButton;

  return (<div className="fixed z-20 bottom-0 overflow-auto inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">
		<div className="fixed inset-0 transition-opacity">
			<div className="absolute inset-0 bg-gray-500 opacity-75" />
		</div>
    
		<div className="bg-white rounded-md shadow-xl transform transition-all sm:max-w-3xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<div className="mb-0">

				<div className="bg-gray-200 px-4 py-3 sm:px-6">
					<h3 className="text-lg uppercase leading-6 font-normal text-gray-900">View Notification</h3>
				</div>

				<div className="flex flex-col sm:flex-row ">
					<div className="px-6 py-3 flex-grow">

						<div className="flex">
							<div className="mt-2 w-full">
								<div className="flex justify-between">
									<label htmlFor="title" className="block text-xs font-light uppercase leading-normal text-gray-700">Headline</label>
								</div>
								<div>{ eventBlast.title }</div>
							</div>
							<div className="mt-2 ml-4 w-1/4">
                <div className="flex justify-between mb-1">
                  <label htmlFor="type" className="block text-xs font-light uppercase leading-normal text-gray-700">Placement</label>
                </div>
                <div>{ eventBlast.type === 'popup' ? 'Pop-up' : 'Slide In' }</div>
              </div>
						</div>

						{eventBlast.description && <div className="flex flex-col sm:flex-row">
							<div className="w-full mt-0 sm:mt-2">
								<div className="flex justify-between">
									<label htmlFor="description" className="block text-xs font-light uppercase leading-normal text-gray-700">Description</label>
								</div>
								<div>{ eventBlast.description }</div>
							</div>
						</div>}

						<div className="mt-4">
              <p className="text-sm font-light leading-normal text-gray-700">Based on event time zone: <span className="text-xs font-italic bg-gray-200 rounded px-2 py-1">{timezone}</span></p>
              <div className="flex flex-col sm:flex-row mt-2">
                <div className="w-1/2">
                  <label htmlFor="date" className="block text-xs font-light leading-normal text-gray-700">Date</label>
                  <div>{ formatDate(startTime, 'MM/dd/yy') }</div>
                </div>
                <div className="flex flex-col ml-auto pt-1 w-1/2">
                  <label htmlFor="startTime" className="block text-xs font-light leading-normal text-gray-700">Scheduled Time</label>
                  <div>{ formatDate(startTime, 'hh:mm aa') }</div>
                </div>
              </div>
						</div>

						<div className="flex justify-between">
							{eventBlast.ctaUrl && <div className="mt-2 w-1/2">
								<div className="flex justify-between">
									<label htmlFor="ctaUrl" className="block text-xs font-light uppercase leading-normal text-gray-700">Website Url</label>
								</div>
                <div>{ eventBlast.ctaUrl }</div>
							</div>}
							{eventBlast.booth && <div className="mt-2 w-1/2">
								<div className="flex justify-between">
									<label htmlFor="booth" className="block text-xs font-light uppercase leading-normal text-gray-700">Booth</label>
								</div>
								<div>{ eventBlast.booth.name }</div>
							</div>}
              { eventBlast.type === 'popup' && eventBlast.ctaLabel && (
                <div className="mt-2 w-1/2">
                  <div className="flex justify-between">
                    <label htmlFor="ctaLabel" className="block text-xs font-light uppercase leading-normal text-gray-700">CTA Button Label</label>
                  </div>
                  <div>{ eventBlast.ctaLabel }</div>
                </div>
              ) }
						</div>

						{ eventBlast.type === 'popup' && eventBlast.imageUrl && (
							<>
								<div className="mt-2">
									<img src={eventBlast.imageUrl} />
								</div>
							</>
						)}

					</div>
				</div>
				<div className="px-4 py-3 flex flex-col-reverse sm:flex-row justify-between items-center border-gray-300 border-t">
					{ <DeleteButton /> }
					<div className="flex flex-col-reverse sm:flex-row items-center">
						<span className="text-gray-400 mx-auto sm:mx-0 mt-2 sm:mt-0 cursor-pointer" onClick={onCloseModal}>Close</span>
					</div>
				</div>
			</div>
		</div>
	</div>);
}
import React from 'react';
import { useForm } from "react-hook-form";


import useEventData from "../../../hooks/data/useEventData";

import { SocialMediaWidgetSchema as validationSchema } from "../../../schemas";

import { socialMediaTypes } from '../../../components/Icons';

export default function CreateSocialMediaWidget({ onCloseCreateBox, addWidget }) {
	const methods = useForm({ validationSchema });
	const { register, handleSubmit, getValues, errors, formState: { dirty, isSubmitting } } = methods;
	let widget = getValues();

	function getWidgetType(url) {
		return socialMediaTypes.find(media => url.includes(media.type)).type;
	}

	function shouldCreateBoxClose() {
		if (!dirty || dirty && confirm("It seems you've enter some info, do you want to close this?")) {
			onCloseCreateBox();
		}
	}

	function submitData(widget) {
		if (widget.url.trim() === "" && widget.iframe.trim() === "") {
			confirm("Cannot add an empty widget. Please enter a URL or iFrame code, or cancel the widget.");
		} else if (widget.url.trim() !== "") {
			console.log('widgetUrl on submit:', widget.url)
			widget = {...widget, type: getWidgetType(widget.url), size: '1/2'};
			addWidget(widget);
		} else {
			widget = {...widget, type: 'custom', size: '1/2'};
			widget.url = widget.iframe;
			console.log('custom iframe widget:', widget.iframe);
			addWidget(widget);
		}
	}

	return <>
		<div className="w-full sm:w-full lg:w-1/2 px-5 mb-6">
			<form onSubmit={handleSubmit(submitData)} className="mb-0">
				<div className="flex flex-col h-vendor-card w-full vendor-card-shadow overflow-hidden rounded-md justify-between items-center bg-white" style={{height: "500px"}}>
					<div className="flex flex-col flex-wrap justify-center px-8 py-3 bg-gray-100 rounded w-full">
						<p className="w-full text-center">Supported Social Media Platforms</p>
						<div className="flex justify-center">
							{socialMediaTypes.filter(widget => widget.type !== 'tiktok' && widget.type !== 'instagram' && widget.type !== 'custom').map((media, index) => <div className="uppercase text-center p-2 rounded-full" key={index}>
								<media.logo classes={`h-8 w-8 fill-current text-social-${media.type}`}/>
							</div>)}
						</div>
					</div>

					<div className="flex p-6 flex-grow flex-col justify-between w-full">
						<div className="w-full px-6">
							<label htmlFor="url" className="block text-s font-light uppercase leading-normal text-gray-700">Enter Url</label>
							<input name="url" ref={register} placeholder="https://twitter.com/eventhuboff" className="mt-1 mb-1 base-input" />
							{ errors.url && <p className="mt-1 text-sm text-red-700">{ errors.url.message }</p> }
							<span className="mt-1 block text-sm font-light uppercase leading-normal text-gray-700">Video widget URLs must point to video</span>
						</div>
						<div className="mx-auto my-4 text-sm uppercase">or</div>
						<div className="w-full px-6">
							<label htmlFor="iframe" className="block text-s font-light uppercase leading-normal text-gray-700">Enter Custom iFrame</label>
							<textarea name="iframe" ref={register} className="mt-1 mb-1 base-input" placeholder="<iframe src='...' />" />
							<span className="mt-1 block text-sm font-light uppercase leading-normal text-gray-700">Custom iFrame Code is not Validated</span>
						</div>
					</div>



					<div className="border-t border-gray-200 w-full flex items-center justify-between py-2 px-6">
						<span className="text-gray-400 mr-2 mt-2 sm:mt-0 cursor-pointer" onClick={shouldCreateBoxClose}>Cancel</span>
						<button type="submit" disabled={isSubmitting} className="btn-hub-green sm:w-auto sm:ml-2 sm:mt-0 mr-0 sm:mr-0 flex items-center">
							{ !isSubmitting && 'Add' }
							{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Adding...</span></> }
						</button>
					</div>
				</div>
			</form>
		</div>
	</>
}

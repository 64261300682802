import React, {forwardRef} from 'react';

const AgeVerificationFormFields = forwardRef(({enable, age, checkBoxName, dropDownName, onSelectAge, checkBoxText, selectClasses }, ref) => {
    const { register, ageSelectRef } = ref;
    return (
        <>
            <div className="flex justify-between mt-3">
                <label htmlFor={checkBoxName} className="block text-xs font-light uppercase leading-normal text-gray-700">{ checkBoxText || "Enable Age Verification" }</label>
                <input type="checkbox" id={checkBoxName} name={checkBoxName} ref={register} />
            </div>

            { enable && <div className="flex flex-col mt-2">
                <select name={dropDownName} className={selectClasses || 'mt-1 base-input'} ref={ageSelectRef} onChange={ onSelectAge } value={age}>
                    <option value="21">21+</option>
                    <option value="18">18+</option>
                    <option value="13">13+</option>
                    <option value="8">8+</option>
                </select>
            </div> }
        </>
    )
});

export default AgeVerificationFormFields;
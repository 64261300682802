import React, {useEffect, useState} from "react";
import axios from "axios";
import TextEditor from "../../../components/TextEditor";
import useConfig from "../../../hooks/app/useConfig";
import useEventData from "../../../hooks/data/useEventData";
import useCurrentUser from "../../../hooks/app/useCurrentUser";

export default function EditEventDescriptionArea({ onComplete }) {
	const { apiHost } = useConfig();
	const [ currentUser ] = useCurrentUser();
	const [ eventProfile, setEventProfile ] = useEventData();
	const [ descriptionForm, setDescriptionForm ] = useState({ show: true, value: eventProfile.description, submitting: false });

	function submitNewDescription(e) {
		e.preventDefault();
		setDescriptionForm({ ...descriptionForm, submitting: true});
		axios({
			url: `${apiHost}/village/events/${eventProfile.urlSlug}`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			},
			data: { description: descriptionForm.value }, // we replace on the server so we need to make sure everything is there now.
		}).then((res) => {
			setEventProfile(state => ({ ...state, description: descriptionForm.value}));
			if (onComplete) {
				onComplete();
			}
		}).catch(e => {
			setDescriptionForm(state => ({ ...state, submitting: false, error: e?.message}));
		});
	}

	function cancelNewDescription(e) {
		e.preventDefault();
		if (onComplete) {
			onComplete();
		}
	}

	return <section className="flex flex-col mb-20">
		<div className="mb-3 flex flex-col sm:flex-row justify-between">
			<strong className='inline uppercase text-sm flex items-center'>Description</strong>
			{ !descriptionForm.submitting && (
				<div className="flex flex-col-reverse sm:flex-row items-center">
					<span className="text-gray-400 mx-auto sm:mx-0 mt-2 sm:mt-0 cursor-pointer" onClick={cancelNewDescription}>Cancel</span>
					<button className="btn-hub-green sm:w-auto ml-0 sm:ml-2 mt-2 sm:mt-0 text-sm"
									onClick={submitNewDescription}>Save Description Changes</button>
				</div>
			)}
			{ descriptionForm.submitting && (
				<button className="btn-hub-green sm:w-auto sm:ml-0 mt-2 sm:mt-0 sm:mr-0 flex text-sm items-center">
					<span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span>
				</button>
			)}
		</div>
		{ descriptionForm.show && (
			<TextEditor value={descriptionForm.value} handleChange={(value) => setDescriptionForm(state => ({ ...state, value }))} />
		) }
		{ /* hasEventEditAccess && !descriptionForm.value && !descriptionForm.show && <div className="w-full h-64">
			<svg className="border-2 border-dashed border-gray-300 rounded bg-transparent h-full w-full text-gray-200"
					 preserveAspectRatio="none" stroke="currentColor" fill="none" viewBox="0 0 200 200">
				<path vectorEffect="non-scaling-stroke" strokeWidth="2" d="M0 0l200 200M0 200L200 0" />
			</svg>
		</div> */ }
	</section>
}
import React from "react";
import { EventsProvider } from "./events";

export default function AdminContext({ children }) {
	return (
		<EventsProvider>
				{children}
		</EventsProvider>
	);
}

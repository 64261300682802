import React from "react";
import { UserProvider } from "./user";
import { EventProvider } from "./event";
import { AttendeeProvider } from "./attendee";
import { NotificationsProvider } from "./notifications";
import { EventsProvider } from "./events";
import { DisplayAdsProvider } from "./displayAds";
import { AnnouncementsProvider } from "./announcements";

export default function GlobalContext({ children }) {
    return (
      <EventsProvider>
        <NotificationsProvider>
            <AnnouncementsProvider>
                <DisplayAdsProvider>
                    <UserProvider>
                        <EventProvider>
                            <AttendeeProvider>
                                { children }
                            </AttendeeProvider>
                        </EventProvider>
                    </UserProvider>
                </DisplayAdsProvider>
            </AnnouncementsProvider>
        </NotificationsProvider>
      </EventsProvider>
    );
}

import React, { useState, createContext, useEffect, useRef } from 'react';
import axios from "axios";
import cuid from 'cuid';
import { add, getUnixTime } from 'date-fns';
import DailyIframe from "@daily-co/daily-js";

import AskForParticipantName from "../features/VirtualChat/AskForParticipantName";

import usePermissions from "../hooks/app/usePermissions";
import useLocalStorage from "../hooks/helper/useLocalStorage";
import useNotifications from '../hooks/app/useNotifications';

const RECORDING_TYPE = "cloud"; // "rtp-tracks", "output-byte-stream"
const PRIVACY_TYPE = "private";
const VIRTUAL_BOOTH_MEETING_TOKEN_LOCAL_STORAGE_KEY = 'VIRTUAL_BOOTH_MEETING_TOKEN';
const VIRTUAL_USER_LOCAL_STORAGE_KEY = 'VIRTUAL_USER_DATA';
const DAILYCO_API_KEY = 'ca8f5a6d5d9cf6e12f139f0524b7218f5789650ad997d5adfd24d434cee6901b';
const INITIAL_STATE = null;

const CallObjectContext = createContext([{}, '', () => {}]);

const CALL_STATUS = {
	idle: 'IDLE',
	creating: 'CREATING',
	created: 'CREATED', // this means the call obj and room are both created
	updating: 'UPDATING',
	retryJoin: 'RETRY:JOIN',
	nameRequired: 'NAME_REQUIRED',
	joining: 'JOINING',
	joined: 'JOINED',
	leaving: 'LEAVING',
	leftMeeting: 'LEFT_MEETING',
	meetingEnded: 'MEETING_ENDED',
	terminateRoom: 'TERMINATE_ROOM',
	roomTerminated: 'ROOM_TERMINATED',
	roomFull: 'ROOM_IS_FULL',
	waitingFullRoom: 'WAIT_ROOM_IS_FULL',
	booted: 'BOOTED_FROM_ROOM',
	error: 'ERROR'
};

function CallObjectProvider({ settings, onLeaveBooth, children }) {
	const { append } = useNotifications();
	const previousState = useRef(null);
	const [ room, setRoomObject ] = useState(null);
	const [ callObject, setCallObject ] = useState(INITIAL_STATE);
	const { hasEventEditAccess, isLoggedIn } = usePermissions();
	const [ status, setStatus ] = useState(CALL_STATUS.idle);
	const [ storedMeetingToken, setStoredMeetingToken ] = useLocalStorage(VIRTUAL_BOOTH_MEETING_TOKEN_LOCAL_STORAGE_KEY, null);
	const [ virtualBoothUserName, setVirtualBoothUserName, hasLoadedName ] = useLocalStorage(VIRTUAL_USER_LOCAL_STORAGE_KEY, null);
	const [ hasSavedName, setHasSavedName ] = useState(false);

	useEffect(function() {
		if (status === CALL_STATUS.roomTerminated && previousState.current === status) return;
		console.log('change obj status: ', status);
		switch (status) {
			case CALL_STATUS.idle:
				findRoom();
				break;
			case CALL_STATUS.created:
			case CALL_STATUS.retryJoin:
				if (hasSavedName) {
					joinRoom();
				} else {
					setStatus(CALL_STATUS.nameRequired);
				}
				break;
			case CALL_STATUS.joined:
				break;
			case CALL_STATUS.roomFull:
				if (settings.hasHostAccess) {
					modifyRoomToMakeBigger();
				} else {
					setStatus(CALL_STATUS.waitingFullRoom);
					setTimeout(() => onLeaveBooth(), 3 * 1000);
				}
				break;
			case CALL_STATUS.leaving:
				leftMeeting();
				break;
			case CALL_STATUS.terminateRoom:
				deleteRoom();
				break;
			case CALL_STATUS.roomTerminated:
				setTimeout(() => onLeaveBooth(), 3 * 1000);
				break;
			case CALL_STATUS.leftMeeting:
				onLeaveBooth();
				break;
			case CALL_STATUS.error:
				append('alert', 'An error occurred during your virtual booth session.');
				break;
			default:
				break;
		}
		previousState.current = status;
		return () => {}
	}, [ status ]);

	useEffect(() => {
		window.callObject = callObject;
	}, [callObject]);

	function createCallObject() {
		// const newCallObject = DailyIframe.createCallObject({subscribeToTracksAutomatically: false});
		const newCallObject = DailyIframe.createCallObject();
		newCallObject.setNetworkTopology({ topology: 'sfu' });
		setCallObject(newCallObject);
		setStatus(CALL_STATUS.created);
	}

	function findRoom() {
		setStatus(CALL_STATUS.creating);
		// ensure room is created.
		axios({
			url: `https://api.daily.co/v1/rooms/session-${ settings._id }`,
			method: 'GET',
			headers: {
				'content-type': 'application/json',
				'authorization': `Bearer ${ DAILYCO_API_KEY }`
			}
		}).then(updateRoom).then((res) => {
			setRoomObject(res.data);
			append('success', 'Connecting To Virtual Booth');
			createCallObject();
		}).catch(createRoom);
	}

	function baseRoomProperties() {
		return {
			properties: {
				exp: getUnixTime(add(new Date(), { hours: 12 })),
				signaling_impl: 'ws',
				autojoin: true, // default
				enable_knocking: false, // default
				eject_at_room_exp: true, // default
				max_participants: !settings.videoChat.participantLimit || settings.videoChat.participantLimit >= 200 ? 200 : (settings.videoChat.participantLimit === 0 ? 1 : settings.videoChat.participantLimit) + 1,
				enable_screenshare: settings.videoChat.ownerScreenShare,
				enable_chat: settings.videoChat.enableChat,
				start_video_off: !settings.videoChat.ownerVideoOn,
				start_audio_off: !settings.videoChat.ownerSoundOn,
				owner_only_broadcast: !settings.videoChat.participantsAllowed,
				enable_recording: settings.videoChat.enableRecording ? RECORDING_TYPE : null
			},
			privacy: PRIVACY_TYPE
		};
	}

	function bumpMaxParticipantsRoomProperties() {
		let props = baseRoomProperties();
		return {
			...props,
			properties: {
				...props.properties,
				max_participants: (!settings.videoChat.participantLimit || settings.videoChat.participantLimit > 200 ? 200 : settings.videoChat.participantLimit === 0 ? 1 : settings.videoChat.participantLimit) + 1,
			}
		};
	}

	function getRoomProperties() {
		let props = baseRoomProperties();
		return {
			...props,
			name: `session-${ settings._id }`
		};
	}

	function createRoom() {
		let data = JSON.stringify(getRoomProperties());

		axios({
			url: 'https://api.daily.co/v1/rooms',
			method: 'POST',
			data,
			headers: {
				'content-type': 'application/json',
				'authorization': `Bearer ${ DAILYCO_API_KEY }`
			}
		}).then((res) => {
			setRoomObject(res.data);
			append('success', 'Virtual Booth Created');
			createCallObject();
		}).catch((err) => {
			console.log('dailyco POST err', err);
			setStatus(CALL_STATUS.error);
			append('alert', err.message);
		});
	}

	function modifyRoomToMakeBigger() {
		let data = JSON.stringify(bumpMaxParticipantsRoomProperties());

		axios({
			url: `https://api.daily.co/v1/rooms/session-${ settings._id }`,
			method: 'POST',
			data,
			headers: {
				'content-type': 'application/json',
				'authorization': `Bearer ${ DAILYCO_API_KEY }`
			}
		}).then((res) => {
			setRoomObject(res.data);
			append('success', 'Virtual Booth Created');
			createCallObject();
		}).catch((err) => {
			setStatus(CALL_STATUS.error);
			append('alert', err.message);
		});
	}

	function updateRoom(res) {
		if (!isLoggedIn) {
			return Promise.resolve(res);
		}
		setStatus(CALL_STATUS.updating);
		let data = JSON.stringify(baseRoomProperties());

		return axios({
			url: `https://api.daily.co/v1/rooms/session-${ settings._id }`,
			method: 'POST',
			data,
			headers: {
				'content-type': 'application/json',
				'authorization': `Bearer ${ DAILYCO_API_KEY }`
			}
		});
	}

	async function joinRoom() {
		setStatus(CALL_STATUS.joining);
		let meetingToken = await getMeetingRoomToken();

		if (!meetingToken) {
			append('alert', 'Failed retrieving a meeting session id.');
			setStatus(CALL_STATUS.error);
			return;
		}

		callObject.setActiveSpeakerMode(true);
		callObject.setPlayNewParticipantSound(true);
		callObject.join({
			url: room.url,
			token: meetingToken
		}).then(() => {
			setStatus(CALL_STATUS.joined);
		});
	}

	function getMeetingRoomToken() {
		return new Promise((resolve) => {
			let properties = hasEventEditAccess ? getOrganizerTokenRequest() : settings.hasHostAccess ? getSpeakerTokenRequest() : getAttendeeTokenRequest();
			axios({
				url: 'https://api.daily.co/v1/meeting-tokens',
				method: 'POST',
				data: { properties },
				headers: {
					'content-type': 'application/json',
					'authorization': `Bearer ${ DAILYCO_API_KEY }`
				}
			}).then((res) => {
				if (!res.data) {
					append('alert', 'Failed retrieving a meeting session.');
					setStatus(CALL_STATUS.error);
					return;
				}
				setStoredMeetingToken(res.data.token);
				resolve(res.data.token);
			});
		});
	}

	function deleteRoom() {
		if (!hasEventEditAccess && !settings.hasHostAccess) {
			append('alert', 'It seems you do not have access to delete Virtual Booths.');
			return;
		}
		axios({
			url: `https://api.daily.co/v1/rooms/session-${ settings._id }`,
			method: 'DELETE',
			headers: {
				'content-type': 'application/json',
				'authorization': `Bearer ${ DAILYCO_API_KEY }`
			}
		}).then((res) => {
			setRoomObject(null);
			setStatus(CALL_STATUS.roomTerminated);
			append('success', 'Virtual Booth has been deleted.');
		}).catch((err) => {
			console.log('dailyco POST err', err);
			setRoomObject(null);
			setStatus(CALL_STATUS.roomTerminated);
			append('success', 'Virtual Booth has been deleted.');
		});
	}

	function leftMeeting() {
		callObject.destroy();
	}

	function getOrganizerTokenRequest() {
		return {
			room_name: `session-${ settings._id }`,
			is_owner: true,
			user_id: 'organizer-' + cuid(),
			user_name: virtualBoothUserName, //currentUser.name,
			enable_screenshare: settings.videoChat.ownerScreenShare,
			start_video_off: !settings.videoChat.ownerVideoOn,
			start_audio_off: !settings.videoChat.ownerSoundOn
		};
	}

	function getSpeakerTokenRequest() {
		return {
			room_name: `session-${ settings._id }`,
			is_owner: true,
			user_id: 'speaker-' + cuid(),
			user_name: virtualBoothUserName, //currentUser.name,
			enable_screenshare: settings.videoChat.ownerScreenShare,
			start_video_off: !settings.videoChat.ownerVideoOn,
			start_audio_off: !settings.videoChat.ownerSoundOn
		};
	}

	function getAttendeeTokenRequest() {
		return {
			room_name: `session-${ settings._id }`,
			is_owner: false,
			user_id: 'attendee-' + cuid(),
			user_name: virtualBoothUserName,
			enable_screenshare: settings.videoChat.participantScreenShare,
			start_video_off: !settings.videoChat.participantsAllowed || !settings.videoChat.participantStartVideo,
			start_audio_off: !settings.videoChat.participantsAllowed || !settings.videoChat.participantStartSound
		};
	}

	function saveParticipantName(name) {
		setStatus(CALL_STATUS.retryJoin);
		setVirtualBoothUserName(name);
		setHasSavedName(true);
	}

	return (
		<CallObjectContext.Provider value={[ callObject, status, setStatus ]}>
			{ !hasSavedName && hasLoadedName ? <AskForParticipantName onNameSave={ saveParticipantName } defaultValue={ virtualBoothUserName } /> : callObject ? children : <div className="bg-gray-900 sm:h-virtual-booth block w-full" /> }
		</CallObjectContext.Provider>
	);
}

export { CallObjectContext, CallObjectProvider, INITIAL_STATE, CALL_STATUS, DAILYCO_API_KEY, VIRTUAL_USER_LOCAL_STORAGE_KEY };
import React from "react";
import Icon, {
  TYPE_MUTE_CAMERA,
  TYPE_MUTE_MIC,
  TYPE_SCREEN,
  TYPE_LEAVE,
  TYPE_ENTER_FULL_SCREEN,
  TYPE_EXIT_FULL_SCREEN,
  TYPE_CHAT,
} from "../Icon/Icon";

/**
 * Props:
 * - type: string
 * - disabled: boolean
 * - highlighted: boolean
 * - onClick: () => ()
 * - newButtonGroup: boolean
 */
export default function TrayButton(props) {
  function getLabel() {
    switch(props.type) {
      case TYPE_MUTE_CAMERA:
        return `${props.highlighted ? 'Start' : 'Stop'} Video`;
      case TYPE_MUTE_MIC:
        return props.highlighted ? 'Unmute' : 'Mute';
      case TYPE_SCREEN:
        return props.highlighted ? 'Stop Share' : 'Share Screen';
      case TYPE_ENTER_FULL_SCREEN:
        return 'Enter Full Screen';
      case TYPE_EXIT_FULL_SCREEN:
        return 'Exit Full Screen';
      case TYPE_CHAT:
        return `${props.highlighted ? 'Close' : 'Open'} Chat`;
      case TYPE_LEAVE:
        return 'Leave Chat';
      default:
        return;
    }
  }

  return (<div className="flex flex-col items-center px-0 sm:px-3 py-1">
    <button
      id={props.type}
      disabled={props.disabled}
      onClick={props.onClick}
      className={"w-6 h-6 border-none bg-transparent mx-2 my-1" + (props.newButtonGroup ? " new-group" : "")}
    >
      <Icon type={props.type} highlighted={props.highlighted} />
    </button>
    <label htmlFor={props.type} className="text-xs text-center font-normal cursor-pointer">{ getLabel() }</label>
  </div>);
}

export { TYPE_MUTE_CAMERA, TYPE_MUTE_MIC, TYPE_SCREEN, TYPE_LEAVE, TYPE_ENTER_FULL_SCREEN, TYPE_EXIT_FULL_SCREEN, TYPE_CHAT };

import React from 'react';

import NavBar from '../NavBar';
import AdminBar from '../AdminBar';
import TixBar from '../TixBar';

import useMode from "../../hooks/app/useMode";
import MainStage from "../../features/Stages/MainHall";
import useEventData from "../../hooks/data/useEventData";
import useShowTimes from "../../hooks/data/useShowTimes";

function InactiveEventBar() {
	return (<div className="w-full py-1 text-center bg-red-500 text-white">
		<span className="uppercase text-sm font-medium leading-none tracking-wide">This event is currently <em>inactive</em> and is only viewable by the <u>event organizer</u> and <u>exhibitors with approved orders</u>.</span>
	</div>);
}

export default function Header() {
	const mode = useMode();
	const [ eventProfile ] = useEventData();
	const { isMainStageActive, mainStageVideo } = useShowTimes();

	return (
		<>
			<AdminBar />
			<TixBar />
			{ !eventProfile.isActive && mode !== 'preview' && <InactiveEventBar /> }
			<NavBar />
			{ isMainStageActive && mainStageVideo.id && <MainStage /> }
		</>
	);
}
import { useContext, useEffect } from 'react';
import { NotificationsContext } from "../../context/notifications";
import cuid from 'cuid';

export default function useNotifications() {
	let [ notifications, setNotifications ] = useContext(NotificationsContext);

	function append(type, message, timeout, callback, callbackLabel) {
		setNotifications(state => ([{ id: cuid(), type, message, timeout, callback, callbackLabel }, ...state]));
	}

	function remove(id) {
		setNotifications(state => (state.filter(notification => notification.id !== id)));
	}

	return { notifications, append, remove };
}
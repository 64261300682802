import React from 'react';
import { useForm } from "react-hook-form";
import { HeaderLinkSchema as validationSchema } from "../../../../schemas";

export default function HeaderLinkModal({ defaultValues, onSave, onDelete, onCloseModal }) {
	const { register, handleSubmit, errors, formState: { dirty, isSubmitting } } = useForm({ defaultValues, validationSchema });

  function submitData(values) {
    onSave({
      id: defaultValues.id,
      ...values
		});
	}

	function shouldModalClose() {
		if (!dirty || dirty && confirm("It seems you've enter some info, do you want to close this?")) {
			onCloseModal();
		}
  }

  function shouldDelete(e) {
		e.preventDefault();
		let answer = prompt("Type 'DELETE' to confirm.");
		if (answer === 'DELETE') {
			onDelete();
		}
	}

	return (<div className="fixed z-50 bottom-0 overflow-auto inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">

		<div className="fixed inset-0 transition-opacity">
			<div className="absolute inset-0 bg-gray-500 opacity-75" />
		</div>

    <div className="bg-white rounded-md shadow-xl transform transition-all sm:w-link-modal" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <form onSubmit={handleSubmit(submitData)} className="mb-0">

        <div className="bg-gray-200 rounded-t-md px-4 py-3 sm:px-6">
					<h3 className="text-lg uppercase leading-6 font-normal text-gray-900">{ defaultValues.id ? 'Edit' : 'Add'} a Header Link</h3>
				</div>

        <div className="flex flex-col sm:flex-row ">
					<div className="px-6 py-3 flex-grow">

          <div className="w-full mt-0 sm:mt-2">
            <div className="flex justify-between">
            <label htmlFor="label" className="block text-xs font-light uppercase leading-normal text-gray-700">Label</label>
            <span className="text-xs leading-5 text-gray-500 uppercase tracking-normal text-red-500">Required</span>
            </div>
            <input name="label" ref={register} className="mt-1 base-input" placeholder="Schedule" />
            { errors.label && <p className="mt-1 text-sm text-red-600">{ errors.label.message }</p> }
          </div>

          <div className="w-full mt-0 sm:mt-2">
            <div className="flex justify-between">
            <label htmlFor="url" className="block text-xs font-light uppercase leading-normal text-gray-700">Url</label>
            <span className="text-xs leading-5 text-gray-500 uppercase tracking-normal text-red-500">Required</span>
            </div>
            <input name="url" ref={register} className="mt-1 base-input" placeholder="http://event-schedule.com" />
            { errors.url && <p className="mt-1 text-sm text-red-600">{ errors.url.message }</p> }
          </div>

					</div>
				</div>

				<div className="px-4 py-3 flex flex-col-reverse sm:flex-row justify-between items-center border-gray-300 border-t">
					{ defaultValues.id ? <a href="#" className="text-red-500 mx-auto sm:mx-0 mt-2 sm:mt-0" onClick={shouldDelete}>Delete Link</a> : <span className="text-gray-400 mr-2 mt-2 sm:mt-0 cursor-pointer" onClick={shouldModalClose}>Cancel</span>}
					<div className="flex items-center">
						{ defaultValues.id ? <span className="text-gray-400 mr-2 mt-2 sm:mt-0 cursor-pointer" onClick={shouldModalClose}>Cancel</span> : <></> }
						<button type="submit" disabled={isSubmitting} className="float-right btn-hub-green sm:w-auto sm:ml-2 mt-2 sm:mt-0 mr-0 sm:mr-0 flex items-center">
							{ !isSubmitting && 'Save Link' }
							{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
						</button>
					</div>
				</div>
      </form>
    </div>
	</div>);
}
import React, {useState, createContext} from 'react';

const SessionContext = createContext([{}, () => {}]);

function SessionProvider(props) {
	const [state, setState ] = useState(null);

	return (
		<SessionContext.Provider value={[state, setState]}>
			{props.children}
		</SessionContext.Provider>
	);
}

export { SessionContext, SessionProvider };
import React, { useEffect, useCallback, useRef } from "react";
import Call from "./components/Call";
import Tray from "./components/Tray";
import BoothChat from "./components/BoothChat";
import { logDailyEvent } from "./logUtils";
import LoadingVirtualBooth from "./LoadingVirtualBooth";

// hooks
import useCallObject from "../../hooks/data/useSessionCallObject";
import { CALL_STATUS } from "../../context/sessionCallObject";
import useFullScreen from "../../hooks/helper/useFullScreen";
import useEventData from "../../hooks/data/useEventData";

export default function Booth({ settings, hasHostAccess, endTime }) {
  const [ callObject, boothState, setBoothState ] = useCallObject();
  const videoChatWindow = useRef(null);
  const [ isFullScreen, toggleFullScreen ] = useFullScreen(videoChatWindow);
  const [ eventProfile ] = useEventData();

  const startLeavingCall = useCallback(() => {
    if (!callObject) return;
    setBoothState(CALL_STATUS.leaving);
  }, [ callObject ]);

  useEffect(() => {
    if (!callObject) {
      return;
    }

    const events = ['joined-meeting', 'left-meeting', 'error', 'app-message', '*'];

    function handleNewMeetingState(event) {
      event && logDailyEvent(event);
      switch (callObject.meetingState()) {
        case "joined-meeting":
          setBoothState(CALL_STATUS.joined);
          break;
        case "left-meeting":
          setBoothState(CALL_STATUS.leftMeeting);
          break;
        default:
          break;
      }
    }

    // Use initial state
    handleNewMeetingState();

    // Listen for changes in state
    for (const event of events) {
      callObject.on(event, handleNewMeetingState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewMeetingState);
      }
    };
  }, [callObject]);

  const showChat = [ CALL_STATUS.joined ].includes(boothState) && settings.videoChat.enableChat;
  const showCall = [ CALL_STATUS.joined, CALL_STATUS.error ].includes(boothState);
  const enableCallButtons = [ CALL_STATUS.joined, CALL_STATUS.error ].includes(boothState);
  const showNameField = [ CALL_STATUS.nameRequired ].includes(boothState);

  if ([ CALL_STATUS.terminateRoom, CALL_STATUS.roomTerminated ].includes(boothState)) {
    return <div className="flex h-full w-full items-center justify-center text-white font-black text-3xl" style={{background: eventProfile.advanceStyles.brandColor, color: eventProfile.advanceStyles.brandText}}>Session Ended</div>;
  }

  if ([ CALL_STATUS.booted ].includes(boothState)) {
    return <div className="bg-red-500 flex h-full w-full items-center justify-center text-white font-black text-3xl">Booted From Booth</div>;
  }

  if ([ CALL_STATUS.waitingFullRoom ].includes(boothState)) {
    return (<div className="m-auto">
      <div className="flex justify-center h-full w-full items-center">
        <h1 className="text-bold text-3xl mx-auto text-white text-center">Booth is currently full... Please Try again later.</h1>
      </div>
    </div>);
  }

  return (
    <div className="bg-gray-300 h-full w-full flex flex-col sm:flex-row" ref={e => { videoChatWindow.current = e; }}>
      { showCall && (<div className="w-full flex flex-col">
          <Call settings={settings} hasHostAccess={hasHostAccess} endTime={!showChat && endTime} />
          <Tray settings={settings} disabled={!enableCallButtons} onClickLeaveCall={startLeavingCall} hasHostAccess={hasHostAccess} isFullScreen={isFullScreen} onClickFullScreen={() => { toggleFullScreen(); }}/>
      </div>) }
      { showChat && <div className="flex flex-col 'h-full sm:w-1/3 md:w-3/12">
        <BoothChat endTime={endTime} /> 
      </div>}
      { !showCall && !showNameField && <LoadingVirtualBooth /> }
    </div>
  );
}

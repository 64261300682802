import React from 'react';

export default function EmbedModal({eventSlug, vendorSlug, onCloseModal}) {
  let siteUrl =  process.env.API_HOST.replace('net', 'shop');
  let iframeCode = `<iframe src="${siteUrl}/${eventSlug}${vendorSlug ? '/' + vendorSlug : ''}?public" allow="camera; microphone" height="500" width="400" frameborder="0"></iframe>`

  return (<div className="fixed z-50 bottom-0 overflow-auto inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">

		<div className="fixed inset-0 transition-opacity">
			<div className="absolute inset-0 bg-gray-500 opacity-75" />
		</div>

		<div className="bg-white relative rounded-md shadow-xl transform transition-all sm:w-time-modal" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div className="bg-gray-200 rounded-t-md px-4 py-3 sm:px-6">
        <h3 className="text-lg uppercase leading-6 font-normal text-gray-900">Get Embed Code</h3>
      </div>

      <div className="flex flex-col sm:flex-row ">
        <div className="px-6 pt-3 pb-6 flex-grow">

          <div className="flex flex-col">
            <div className="w-full mt-0 sm:mt-2">
              <div className="p-4 rounded-md bg-gray-200 font-mono">{iframeCode}</div>
            </div>
          </div>

        </div>
      </div>
      <div className="px-4 py-3 flex flex-row-reverse border-gray-300 border-t">
        <button onClick={onCloseModal} className="float-right btn-hub-green sm:w-auto sm:ml-2 mt-2 sm:mt-0 mr-0 sm:mr-0 flex items-center">
          <span>Done</span>
        </button>
      </div>
		</div>
	</div>);
}
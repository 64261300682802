import React, { memo, useState, createContext, useContext, useEffect, forwardRef } from 'react';
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {ArrowDownSVG, ArrowUpSVG, DoubleDownSVG, DoubleUpSVG, PencilSVG} from "../../../../components/Icons";
import useConfig from "../../../../hooks/app/useConfig";
import useToggle from "../../../../hooks/helper/useToggle";
import useEventData from "../../../../hooks/data/useEventData";
import useHotkeys from "@reecelucas/react-use-hotkeys";
import usePermissions from "../../../../hooks/app/usePermissions";
import useCurrentUser from "../../../../hooks/app/useCurrentUser";
import useNotifications from "../../../../hooks/app/useNotifications";
import useToggableElm from "../../../../hooks/helper/useToggableElm";
import DisplayAdModal from "./displayAdModal";
import Toggle from "../../../../components/Toggle";
import stableSort from 'stable';

let scallingRow = '';

const ListsContext = createContext([{}, () => {}]);

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

/*
* modal for creation - name, url or booth
* list edit will have name, image preview, url or booth, actions: [delete] and [edit]
* */
function ListItem({ index, _id, label, type, url, booth, size, imageUrl, isActive }) {
	const [ state, _, { pushToTop, pushUp, pushToBottom, pushDown, editDisplayAd, setIsActive } ] = useContext(ListsContext);
	const listId = type.indexOf('horizontal') > 0 ? 'horizontal' : 'vertical';

	const canMoveUp = index !== 0;
	const canMoveDown = index !== (state[listId].length - 1);

	return <Draggable draggableId={ _id } index={ index }>
		{({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
			<div ref={ innerRef } { ...draggableProps } { ...dragHandleProps } className={`flex flex-col sm:flex-row justify-between border ${ isDragging ? 'border-2 border-blue-400' : 'border-gray-200'} sm:shadow sm:hover:shadow-md cursor-pointer mb-2 bg-white p-2 rounded ${scallingRow}`}>
				{ imageUrl ? (
					<section className="hidden sm:flex bg-white border-4 border-gray-100 h-16 w-24 max-w-none justify-center items-center mr-4">
						<img src={imageUrl} alt={`${label} Image`} className={ listId === 'horizontal' ? 'h-auto w-full' : 'h-full w-auto' } />
					</section>
				) : <span className="hidden sm:block h-12 w-12 rounded bg-gray-100 mr-4" />
				}
				<div className="flex-grow flex flex-col">
					<div className="flex flex-row">
						<Toggle isFlipped={ isActive } onToggle={ () => setIsActive(listId, index, !isActive) } />
						<h3 className="ml-4 text-base font-normal">{ label }{ size && <span className="ml-3 text-sm text-gray-500">{ size.width }&nbsp;x&nbsp;{ size.height }</span> }</h3>
					</div>
					{ url && <p aria-label={url} className="bg-gray-100 px-2 py-1 rounded text-sm font-light truncate mt-2 max-w-full">Url: <strong className="font-normal font-mono">{url}</strong></p> }
					{ booth && <p aria-label={booth} className="bg-gray-100 px-2 py-1 rounded text-sm font-light truncate mt-2 max-w-full">Booth: <strong className="font-normal font-mono">{booth && booth.name ? booth.name : booth}</strong></p> }
				</div>
				<div className="mt-3 sm:mt-0 flex items-center justify-center">
					<span role="tooltip" aria-label="Move to the Top" onClick={ () => canMoveUp && pushToTop(listId, index) }>
						<DoubleUpSVG disabled={!canMoveUp} classes={`h-8 w-8 mx-4 cursor-pointer fill-current ${ !canMoveUp ? 'text-gray-100 cursor-not-allowed' : 'text-gray-300'} hover:text-gray-900`} />
					</span>
					<span role="tooltip" aria-label="Move One Up" onClick={ () => canMoveUp && pushUp(listId, index) }>
						<ArrowUpSVG disabled={!canMoveUp} classes={`h-8 w-8 mx-4 cursor-pointer fill-current ${ !canMoveUp ? 'text-gray-100 cursor-not-allowed' : 'text-gray-300'} hover:text-gray-900`} />
					</span>
					<span role="tooltip" aria-label="Move One Down" onClick={ () => canMoveDown && pushDown(listId, index) }>
						<ArrowDownSVG disabled={!canMoveDown} classes={`h-8 w-8 mx-4 cursor-pointer fill-current ${ !canMoveDown ? 'text-gray-100 cursor-not-allowed' : 'text-gray-300'} hover:text-gray-900`} />
					</span>
					<span role="tooltip" aria-label="Move to the Bottom" onClick={ () => canMoveDown && pushToBottom(listId, index) }>
						<DoubleDownSVG disabled={!canMoveDown} classes={`h-8 w-8 mx-4 cursor-pointer fill-current ${ !canMoveDown ? 'text-gray-100 cursor-not-allowed' : 'text-gray-300'} hover:text-gray-900`} />
					</span>
					<span role="tooltip" aria-label="Edit Ad" onClick={ () => editDisplayAd(_id, listId) }>
						<PencilSVG classes={`h-6 w-6 mx-4 cursor-pointer fill-current text-gray-300 hover:text-gray-900`} />
					</span>
				</div>
			</div>
		)}
	</Draggable>;
}

const List = memo(forwardRef(function List({ name, heading, panelClasses, nameClasses, headingClasses, allowPinning, allowUnpinning, items, droppableProps, placeholder }, ref) {
	return <section ref={ref} { ...droppableProps } className={`${ panelClasses } relative w-full block min-h-manage-list`}>
		{ name && <h2 className={`${ nameClasses } text-sm font-base uppercase`}>{ name }</h2> }
		{ heading && <h2 className={`${ headingClasses } text-sm font-base uppercase`}>{ heading }</h2> }
		<div className="relative">
			{ (items || []).map((item, idx) => <ListItem allowPinning={allowPinning} allowUnpinning={allowUnpinning} index={idx} key={item._id} { ...item } />) }
			{ placeholder }
		</div>
	</section>;
}));

function DisplayAdLists() {
	const [ state, setState ] = useContext(ListsContext);

	const onDragEnd = ({ source, destination }) => {
		// dropped outside the list
		if (!destination) {
			return;
		}

		// not being moved from original list
		if (source.droppableId === destination.droppableId) {
			setState(state => ({
				...state,
				[destination.droppableId]: reorder(
					state[source.droppableId],
					source.index,
					destination.index
				)
			}));
		}

	};

	return <>
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="vertical">
				{({ innerRef, droppableProps, placeholder }) => (
					<List ref={ innerRef } droppableProps={ droppableProps } placeholder={ placeholder }
								name="Main Stage (Vertical Ads)"
								panelClasses="rounded-md border border-gray-200 pt-5 px-3 mb-20"
								nameClasses="px-2 -mt-3 ml-4 absolute top-0 left-0 bg-white rounded-full border  border-gray-200"
								allowPinning={ true }
								items={ state.vertical } />
				)}
			</Droppable>
			<Droppable droppableId="horizontal">
				{({ innerRef, droppableProps, placeholder }) => (
					<List ref={ innerRef } droppableProps={ droppableProps } placeholder={ placeholder } items={ state.horizontal }
								name="Main Hall (Horizontal Ads)"
								panelClasses="rounded-md border border-gray-200 pt-5 px-3 mb-20"
								nameClasses="px-2 -mt-3 ml-4 absolute top-0 left-0 bg-white rounded-full border border-gray-200" />
				)}
			</Droppable>
		</DragDropContext>
	</>;
}

export default function MangeDisplayAds({ onComplete }) {
	const { hasEventEditAccess } = usePermissions();
	if (!hasEventEditAccess ) {
		return null;
	}

	const { apiHost } = useConfig();
	const [ currentUser ] = useCurrentUser();
	const [ isSaving, toggleIsSaving ] = useToggle();
	const [ eventProfile ] = useEventData();
	const { append: appendNotification } = useNotifications();
	const [ boothOptions, setBoothOptions ] = useState([]);
	const [ serverError, setServerError ] = useState('');
	const [ displayAds, setDisplayAds ] = useState({vertical: [], horizontal: []});
	const [ selectedDisplayAd, setSelectedDisplayAd ] = useState(null);
	let [CreateDisplayAdBtn, showDisplayAdModal, toggleDisplayAdModal] = useToggableElm({
		type: ['button'],
		text: ['Create Display Ad'],
		classNames: ['btn-hub-green-solid sm:w-auto sm:ml-0 sm:mt-0']
	});

	useHotkeys('Escape', onComplete);

	useEffect(() => {
		document.body.classList.add('overflow-y-hidden');
		return () => document.body.classList.remove('overflow-y-hidden');
	}, []);

	useEffect(function () {
		if (!eventProfile.urlSlug) {
			return;
		}
		axios({
			url: `${apiHost}/village/events/${eventProfile.urlSlug}/profiles`,
			method: 'get'
		}).then(({data}) => {
			let booths = data.map(booth => ({ value: booth.id, text: `${booth.name} - #${booth?.order?.substring(booth?.order?.length - 6)}` }));
			setBoothOptions([{ value: '', text: 'None'}].concat(stableSort(booths, (a,b) => a.text > b.text)));
		}).catch((err) => {
			setServerError(err.message);
		})
	}, [eventProfile]);

	useEffect(function() {
		if (!eventProfile.id) {
			return;
		}
		axios({
			url: `${ apiHost }/village/events/${eventProfile.id}/ads`,
			method: 'GET'
		}).then(({ data }) => {
			if (data) {
				setDisplayAds({
					vertical: data.filter(a => a.type.indexOf('vertical') > 0),
					horizontal: data.filter(a => a.type.indexOf('horizontal') > 0)
				});
			}
		}).catch(() => {
			setDisplayAds({});
		})
	}, [eventProfile]);

	function updateDisplayAd(id, values) {
		return Promise.resolve().then(() => axios({
			url: `${apiHost}/village/events/${eventProfile.urlSlug}/ads/${ id }`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			},
			data: { ...values }
		}))
	}

	function submitData() {
		toggleIsSaving(true);

		let changes = [];

		displayAds.horizontal.map((ads, order) => {
			changes.push(updateDisplayAd(ads._id, { isActive: ads.isActive, order }))
		});

		displayAds.vertical.map((ads, order) => {
			changes.push(updateDisplayAd(ads._id, { isActive: ads.isActive, order }))
		});

		Promise.all(changes).then(() => {
			toggleIsSaving(false);
			if (typeof onComplete === 'function') {
				onComplete();
			}
			appendNotification('success', "Success! Your changes have been saved.");
		}).catch(e => {
			setServerError(e?.message || e[0]?.message || 'An Error occurred...');
			toggleIsSaving(false);
			window.scrollTo(0, 0);
			appendNotification('alert', "Uh oh. We couldn't save your changes.");
		});
	}

	function pushToTop(currentList, currentIndex) {
		setDisplayAds(state => ({
			...state,
			[currentList]: reorder(
				state[currentList],
				currentIndex,
				0
			)
		}));
	}

	function pushUp(currentList, currentIndex) {
		setDisplayAds(state => ({
			...state,
			[currentList]: reorder(
				state[currentList],
				currentIndex,
				(currentIndex - 1)
			)
		}));
	}

	function pushDown(currentList, currentIndex) {
		setDisplayAds(state => ({
			...state,
			[currentList]: reorder(
				state[currentList],
				currentIndex,
				(currentIndex + 1)
			)
		}));
	}

	function pushToBottom(currentList, currentIndex) {
		setDisplayAds(state => ({
			...state,
			[currentList]: reorder(
				state[currentList],
				currentIndex,
				(state[currentList].length - 1)
			)
		}));
	}

	function setIsActive(currentList, currentIndex, activeValue) {
		setDisplayAds( state => {
			let newState = [...state[currentList]];
			newState[currentIndex] = Object.assign({}, newState[currentIndex], { isActive: activeValue });
			return {
				...state,
				[currentList]: newState
			};
		});
	}

	function handleSaveDisplayAd(ad) {
		let listName = ad.type.indexOf('horizontal') > 0 ? 'horizontal' : 'vertical';
		if (!selectedDisplayAd) {
			setDisplayAds( state => ({
				...state,
				[listName]: [ ...state[listName], ad ]
			}));
		} else {
			setDisplayAds( state => {
				let newDisplayAds = {...state};
				let adIndex = newDisplayAds[listName].map(a => a._id).indexOf(ad._id);
				newDisplayAds[listName][adIndex] = ad;
				return newDisplayAds;
			});
		}
	}

	function handleCloseDisplayAdModal() {
		toggleDisplayAdModal(false)
		setSelectedDisplayAd(null);
	}

	function editDisplayAd(adId, listId) {
		let ad = displayAds[listId].find(a => a._id === adId);
		setSelectedDisplayAd(ad);
		toggleDisplayAdModal(true);
	}

	function handleDeleteAd(ad) {
		let listName = ad.type.indexOf('horizontal') > 0 ? 'horizontal' : 'vertical';
		setDisplayAds( state => {
			let newDisplayAds = {...state};
			let adIndex = newDisplayAds[listName].map(a => a._id).indexOf(ad._id);
			newDisplayAds[listName].splice(adIndex, 1);
			return newDisplayAds;
		});
		toggleDisplayAdModal(false);
		setSelectedDisplayAd(null);
	}

	return (
		<section className="fixed top-0 bottom-0 left-0 right-0 z-50 ">
			{ showDisplayAdModal && <DisplayAdModal defaultValues={selectedDisplayAd} onSave={ handleSaveDisplayAd } onDelete={handleDeleteAd} onCloseModal={handleCloseDisplayAdModal} boothOptions={ boothOptions } /> }
			<section className="fixed bg-white top-0 bottom-0 left-0 right-0 z-10 flex flex-col">
				<header className="py-4 sm:py-6 px-6 sm:px-8 flex flex-col sm:flex-row justify-between border-b border-gray-200">
					<div className="flex items-center">
						<h1 className="text-lg font-base capitalize">Manage Display Ads</h1>
					</div>
					<div className="flex flex-col-reverse sm:flex-row items-center">
						<CreateDisplayAdBtn />
						{ !isSaving && <button className="btn-hub-green sm:w-auto ml-0 sm:ml-2 mt-2 sm:mt-0 text-sm" onClick={ submitData }>Save Display Ad Changes</button> }
						{ isSaving && (
							<button disabled={true} className="btn-hub-green sm:w-auto sm:ml-0 mt-2 sm:mt-0 sm:mr-0 flex text-sm items-center cursor-not-allowed">
								<span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving changes...</span>
							</button>
						)}
						{ !isSaving && <span className="text-gray-400 mx-auto sm:mx-0 mt-2 sm:mt-0 cursor-pointer ml-0 sm:ml-4" onClick={ onComplete }>Cancel</span> }
					</div>
				</header>
				<section className="flex-grow flex h-full overflow-x-hidden overflow-y-auto">
					<div className="container mx-auto py-12">

						{ serverError && <div className="rounded py-2 px-3 bg-red-50 text-gray-700 text-sm w-full my-2"><strong className="font-medium">Error:</strong>{ serverError }</div> }

						<ListsContext.Provider value={[ displayAds, setDisplayAds, { setIsActive, pushToTop, pushUp, pushToBottom, pushDown, editDisplayAd } ]}>
							<DisplayAdLists />
						</ListsContext.Provider>
					</div>
				</section>
			</section>
		</section>
	);
}

import { useContext, useEffect, useState } from 'react';
import { EventContext } from "../../context/event";
import { isAfter, isBefore, subMinutes, getUnixTime } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export default function useBoothTimes() {
	const [ eventProfile ] = useContext(EventContext);
	let [ isBoothTime, toggleBoothTimeActive ] = useState(false);
	let [ isPracticeTime, togglePracticeTimeActive ] = useState(false);
	let [ nextBoothTime, setNextBoothTime ] = useState({ start: null, end: null, timezone: null });
	let [ minBoothTime, setMinBoothTime ] = useState('');
	let [ maxBoothTime, setMaxBoothTime ] = useState('');

	useEffect(() => {
		if (isBoothTime) {
			// add logic to "end booth time"
			return;
		}
		let timer = setInterval(() => {
			// check to see if it's time....
			if (isAfter(new Date(), nextBoothTime.start) && isBefore(new Date(), nextBoothTime.end)) {
				toggleBoothTimeActive(true);
			}

			togglePracticeTimeActive(!nextBoothTime.start || isBefore(new Date(), subMinutes(nextBoothTime.start, 30)));

		}, 1000);
		return () => clearInterval(timer);
	}, [nextBoothTime]);

	useEffect(() => {
		if (!eventProfile.boothTimes || !eventProfile.boothTimes.length) {
			return;
		}

		let minBoothTime = eventProfile.boothTimes.map(t => zonedTimeToUtc(t.startTime, eventProfile.timezone)).reduce(function (a, b) { return a < b ? a : b; });
		let maxBoothTime = eventProfile.boothTimes.map(t => zonedTimeToUtc(t.endTime, eventProfile.timezone)).reduce(function (a, b) { return a > b ? a : b; });

		setMinBoothTime(minBoothTime);
		setMaxBoothTime(maxBoothTime);

		let nowDate = new Date();
		let now = getUnixTime(nowDate);
		let closetBoothTime = eventProfile.boothTimes.reduce(function(a, b) {
			let aStartTime = getUnixTime(zonedTimeToUtc(a.startTime, eventProfile.timezone));
			let aEndTime = getUnixTime(zonedTimeToUtc(a.endTime, eventProfile.timezone));
			let isAUpcoming = now < aStartTime;
			let isMiddleOfA = now > aStartTime && now < aEndTime;
			return isMiddleOfA || isAUpcoming ? a : b;
		});
		let nextStartTime = zonedTimeToUtc(closetBoothTime.startTime, eventProfile.timezone);
		let nextEndTime = zonedTimeToUtc(closetBoothTime.endTime, eventProfile.timezone);

		// Have we pasted the next time?
		if (isAfter(new Date(), nextEndTime)) {
			return;
		}

		if (isAfter(new Date(), nextStartTime) && isBefore(new Date(), nextEndTime)) {
			toggleBoothTimeActive(true);
			console.log('booth time is during');
		}

		togglePracticeTimeActive(isBefore(new Date(), subMinutes(nextStartTime, 30)));

		setNextBoothTime({
			start: nextStartTime,
			end: nextEndTime,
			timezone: eventProfile.timezone
		});
	}, [eventProfile.boothTimes]);

	return { isBoothTime, isPracticeTime, nextBoothTime, minBoothTime, maxBoothTime };
}
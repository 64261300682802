
/**
 * Gets [isCameraMuted, isMicMuted, isSharingScreen].
 * This function is declared outside Tray() so it's not recreated every render
 * (which would require us to declare it as a useEffect dependency).
 */
export function getStreamStates(callObject) {
	let isCameraMuted,
		isMicMuted,
		isSharingScreen = false;
	if (
		callObject &&
		callObject.participants && callObject.participants() &&
		callObject.participants().local
	) {
		const localParticipant = callObject.participants().local;
		isCameraMuted = !localParticipant.video;
		isMicMuted = !localParticipant.audio;
		isSharingScreen = localParticipant.screen;
	}
	return [isCameraMuted, isMicMuted, isSharingScreen];
}
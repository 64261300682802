import React, { useContext, useState } from "react";
import { EventContext } from "../../context/event";
import { AttendeeContext } from "../../context/attendee";

export default function AttendeePassword() {
    let [eventProfile] = useContext(EventContext);
    let [attendeeProfile, checkPassword] = useContext(AttendeeContext);

    let [inputPassword, setInputPassword] = useState("");

    function onFormSubmit(event) {
      event.preventDefault();
      checkPassword(eventProfile.id, inputPassword);
    }

    return (
      <section className="flex flex-col justify-center ">
        <form className="mb-0" onSubmit={onFormSubmit}>
          <p className="font-bold text-3xl text-center capitalize">This event is password-protected</p>
          <p className="pt-3 text-center">Enter the password provided by the event organizer to access this event.</p>
          <div className="flex flex-col sm:w-1/2 md:w-1/3 sm:mx-auto my-4 text-left">
            <label className="block text-xs font-light uppercase leading-normal text-gray-700">Password</label>
            <input type="password" value={inputPassword} onChange={(event) => setInputPassword(event.target.value)} className="password-input"/>
          </div>
          {attendeeProfile.error && (<p className="mt-1 text-sm text-red-700 text-center">{attendeeProfile.error}</p>)}
          <button type="submit" className="btn-hub-green inline-block mt-2 mx-auto">Enter Event</button>
        </form>
      </section>
    );
}

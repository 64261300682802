import React, { useEffect, useCallback } from 'react';
import useNotifications from '../../hooks/app/useNotifications';

export default function Notification({id, type, message, timeout, callback, callbackLabel }) {
    let bgClass = type === 'success' ? 'bg-green-50' : type === 'alert' ? 'bg-red-50' : 'bg-blue-50';
    let boldTextClass = type === 'success' ? 'text-green-800' : type === 'alert' ? 'text-red-800' : 'text-blue-800';
    let hoverFocusTextClass = type === 'success' ? 'hover:bg-green-100 focus:bg-green-100' : type === 'alert' ? 'hover:bg-red-100 focus:bg-red-100' : 'hover:bg-blue-100 focus:bg-blue-100';
    let medTextClass = type === 'success' ? 'text-green-500' : type === 'alert' ? 'text-red-700' : 'text-blue-500';

    let { remove} = useNotifications();

    useEffect(() => {
        setTimeout(() => {
            remove(id);
        }, timeout || 5000)
    });

    const handleSubmit = useCallback(() => callback(id), [ callback ])

    function handleRemove() {
        remove(id);
    }

    return (<div className={`rounded-md relative ${ bgClass } p-4 mb-1 shadow`}>
        <div className="flex">
            <div className="flex-shrink-0">
                {type === 'success' && <svg className="h-5 w-5 text-green-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
                </svg>}
                {type === 'alert' && <svg className="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd"/>
                </svg>}
                {type === 'waiting' && <span className="spinner h-5 w-5 inline-block m-0" /> }
                {type !== 'alert' && type !== 'success' && type !== 'waiting' && <svg className="h-5 w-5 text-blue-400" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                </svg>}
            </div>
            <div className="ml-3">
                <p className={`text-sm leading-5 font-normal ${boldTextClass}`}>{ message }</p>

                {callback && typeof callback === 'function' && (<div className="mt-2">
                    <button onClick={ handleSubmit } className="text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150" >
                        { callbackLabel || 'Accept' }
                    </button>
                    <button onClick={ handleRemove } className="ml-6 text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                        Dismiss
                    </button>
                </div>)}

            </div>
            <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                <button onClick={handleRemove} className={`inline-flex rounded-md p-1.5 ${medTextClass} ${hoverFocusTextClass} focus:outline-none transition ease-in-out duration-150`}>
                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"/>
                    </svg>
                </button>
                </div>
            </div>
        </div>
    </div>);
}

import React, { memo, useMemo, useState, createContext, useContext, useEffect } from 'react';
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ArrowDownSVG, ArrowUpSVG, DoubleDownSVG, DoubleUpSVG, StarFullSVG, ThumbtackSVG } from "../../../components/Icons";
import useConfig from "../../../hooks/app/useConfig";
import useToggle from "../../../hooks/helper/useToggle";
import useEventData from "../../../hooks/data/useEventData";
import useHotkeys from "@reecelucas/react-use-hotkeys";
import usePermissions from "../../../hooks/app/usePermissions";
import useCurrentUser from "../../../hooks/app/useCurrentUser";
import useNotifications from "../../../hooks/app/useNotifications";
import useQueue from "use-queue";

let scallingRow = '';

const ListsContext = createContext([{}, () => {}]);

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const move = (source, destination, droppableSource, droppableDestination, updates) => {
	const sourceClone = Array.from(source);
	const destClone = Array.from(destination);

	if (updates) {
		sourceClone[droppableSource.index] = Object.assign({}, sourceClone[droppableSource.index], updates);
	}

	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, removed);

	const result = {};
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;

	return result;
};

function ListItem({ index, id, name, logo, isFeatured, isPinned, isPublished, allowPinning, allowUnpinning, isPendingPublish }) {
	const [ state, _, { setIsPinned, setIsFeatured, pushToTop, pushUp, pushToBottom, pushDown, resetExhibitor } ] = useContext(ListsContext);
	const listId = isFeatured && !isPinned ? 'featured' : !isFeatured && isPinned ? 'pinned' : 'regular';

	const canMoveUp = index !== 0;
	const canMoveDown = index !== (state[listId].length - 1);

	return <Draggable draggableId={ id } index={ index }>
		{({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
			<div ref={ innerRef } { ...draggableProps } { ...dragHandleProps } className={`flex flex-col sm:flex-row justify-between border ${ isDragging ? 'border-2 border-blue-400' : 'border-gray-200'} sm:shadow sm:hover:shadow-md cursor-pointer mb-2 bg-white p-1 rounded ${scallingRow}`}>
				{ logo ? <section className="hidden sm:flex bg-white border-4 border-gray-100 h-12 w-12 max-w-none justify-center items-center mr-6">
					<img src={logo} alt={`${name} Vendor Image`} className="h-full w-auto"/>
				</section> : <span className="hidden sm:block h-12 w-12 rounded bg-gray-100 mr-6" />
				}
				<div className="flex-grow flex flex-col">
					<h3 className="text-md font-base">{ name }</h3>
					<div>
						<span className="px-2 py-0 inline text-xs leading-5 font-medium uppercase rounded-full bg-black text-white mr-3">#{  id.substring( id.length - 6) }</span>
						{ isPublished && <span className="px-2 py-0 inline text-xs leading-5 font-medium uppercase rounded-full bg-green-600 text-white">Published</span> }
						{ !isPublished && isPendingPublish && <span className="px-2 py-0 inline text-xs leading-5 font-medium uppercase rounded-full bg-yellow-300 text-black">Pending Publication</span> }
						{ !isPublished && !isPendingPublish && <span className="px-2 py-0 inline text-xs leading-5 font-medium uppercase rounded-full bg-red-500 text-white">Not Published</span> }
					</div>
				</div>
				<div className="mt-3 sm:mt-0 flex items-center justify-center">
					<span role="tooltip" aria-label="Toggle Feature" onClick={ () => isFeatured ? resetExhibitor(listId, index) : setIsFeatured(listId, index) }>
						<StarFullSVG classes={`h-8 w-8 mx-2 cursor-pointer transform duration-400 rotate-0 hover:-rotate-180 transition ease-in-out duration-100 fill-current ${ isFeatured ? 'text-yellow-300 hover:text-gray-300' : 'text-gray-300 hover:text-yellow-300'}`} />
					</span>
					<span role="tooltip" aria-label="Move to the Top" onClick={ () => canMoveUp && pushToTop(listId, index) }>
						<DoubleUpSVG disabled={!canMoveUp} classes={`h-8 w-8 mx-4 cursor-pointer fill-current ${ !canMoveUp ? 'text-gray-100 cursor-not-allowed' : 'text-gray-300'} hover:text-gray-900`} />
					</span>
					<span role="tooltip" aria-label="Move One Up" onClick={ () => canMoveUp && pushUp(listId, index) }>
						<ArrowUpSVG disabled={!canMoveUp} classes={`h-8 w-8 mx-4 cursor-pointer fill-current ${ !canMoveUp ? 'text-gray-100 cursor-not-allowed' : 'text-gray-300'} hover:text-gray-900`} />
					</span>
					<span role="tooltip" aria-label="Move One Down" onClick={ () => canMoveDown && pushDown(listId, index) }>
					<ArrowDownSVG disabled={!canMoveDown} classes={`h-8 w-8 mx-4 cursor-pointer fill-current ${ !canMoveDown ? 'text-gray-100 cursor-not-allowed' : 'text-gray-300'} hover:text-gray-900`} />
					</span>
					<span role="tooltip" aria-label="Move to the Bottom" onClick={ () => canMoveDown && pushToBottom(listId, index) }>
					<DoubleDownSVG disabled={!canMoveDown} classes={`h-8 w-8 mx-4 cursor-pointer fill-current ${ !canMoveDown ? 'text-gray-100 cursor-not-allowed' : 'text-gray-300'} hover:text-gray-900`} />
					</span>
					{ allowPinning && <span role="tooltip" aria-label="Toggle Pin" onClick={ () => setIsPinned(listId, index) }>
						<ThumbtackSVG classes="h-8 w-8 mx-4 cursor-pointer transform hover:rotate-45 transition ease-in duration-100 fill-current text-gray-300 hover:text-red-700" />
					</span> }
					{ allowUnpinning && <span role="tooltip" aria-label="Toggle Pin" onClick={ () => resetExhibitor(listId, index) }>
						<ThumbtackSVG classes="h-8 w-8 mx-4 cursor-pointer transform rotate-45 hover:rotate-0 transition ease-in duration-100 fill-current text-red-700 hover:text-gray-300" />
					</span> }
				</div>
			</div>
		)}
	</Draggable>;
}

const List = memo(React.forwardRef(function List({ name, heading, panelClasses, nameClasses, headingClasses, allowPinning, allowUnpinning, items, droppableProps, placeholder }, ref) {
	return <section ref={ref} { ...droppableProps } className={`${ panelClasses } relative w-full block min-h-manage-list`}>
		{ name && <h2 className={`${ nameClasses } text-sm font-base uppercase`}>{ name }</h2> }
		{ heading && <h2 className={`${ headingClasses } text-sm font-base uppercase`}>{ heading }</h2> }
		<div className="relative">
			{ (items || []).map((item, idx) => <ListItem allowPinning={allowPinning} allowUnpinning={allowUnpinning} index={idx} key={item.id} { ...item } />) }
			{ placeholder }
		</div>
	</section>;
}));

function ExhibitorManageLists() {
	const [ state, setState ] = useContext(ListsContext);

	const onDragEnd = ({ source, destination }) => {
		// dropped outside the list
		if (!destination) {
			return;
		}

		// not being moved from original list
		if (source.droppableId === destination.droppableId) {
			setState(state => ({
				...state,
				[destination.droppableId]: reorder(
					state[source.droppableId],
					source.index,
					destination.index
				)
			}));
		} else {
			let updates;
			switch (destination.droppableId) {
				case 'featured':
					updates = { isFeatured: true, isPinned: false };
					break;
				case 'pinned':
					updates = { isFeatured: false, isPinned: true };
					break;
				default:
					updates = { isFeatured: false, isPinned: false };
					break;
			}

			setState(state => ({
				...state,
				...move(
					state[source.droppableId],
					state[destination.droppableId],
					source,
					destination,
					updates
				)
			}));
		}
	};

	return <>
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="featured">
				{({ innerRef, droppableProps, placeholder }) => (
					<List ref={ innerRef } droppableProps={ droppableProps } placeholder={ placeholder }
								name="Featured Booths"
								panelClasses="rounded-md bg-green-100 border border-green-300 pt-5 px-3 mb-10"
								nameClasses="px-2 -mt-4 ml-4 absolute top-0 left-0 bg-green-100 rounded-full border border-green-300"
								allowPinning={ true }
								items={ state.featured } />
				)}
			</Droppable>
			<Droppable droppableId="pinned">
				{({ innerRef, droppableProps, placeholder }) => (
					<List ref={ innerRef } droppableProps={ droppableProps } placeholder={ placeholder }
								name="Booths"
								heading="Pinned"
								panelClasses="rounded-t-md bg-yellow-100 border border-yellow-300 pt-5 px-3"
								nameClasses="px-2 -mt-4 ml-4 absolute top-0 left-0 bg-white rounded-full border border-gray-200"
								headingClasses="-mt-4 w-full font-medium text-center text-gray-600"
								allowUnpinning={ true }
								items={ state.pinned } />
				)}
			</Droppable>
			<Droppable droppableId="regular">
				{({ innerRef, droppableProps, placeholder }) => (
					<List ref={ innerRef } droppableProps={ droppableProps } placeholder={ placeholder }
								panelClasses="rounded-b-md border-b border-l border-r border-gray-200 pt-5 px-3 mb-20"
								allowPinning={ true }
								items={ state.regular } />
				)}
			</Droppable>
		</DragDropContext>
	</>;
}

function diffs(arr1, arr2) {
	return arr1.filter(function(i) { return arr2.indexOf(i) < 0; })
}

function processQueue(promise, next) {
	promise().then(() => {
		next();
	});
}

export default function MangeBoothLayout({ exhibitors, onComplete, onCancel }) {
	const { hasEventEditAccess } = usePermissions();
	if (!hasEventEditAccess ) {
		return null;
	}

	const { apiHost } = useConfig();
	const [ currentQueue, addQueue ] = useQueue(processQueue);
	const [ currentUser ] = useCurrentUser();
	const [ isSaving, toggleIsSaving ] = useToggle();
	const [ eventProfile, setEventProfile ] = useEventData();
	const { append: appendNotification } = useNotifications();
	const [ serverError, setServerError ] = useState('');
	const originalArrangementIds = useMemo(() => ({
		featuredExhibitors: exhibitors.filter(i => i.isFeatured && !i.isPinned).map(e => e.id),
		pinnedExhibitors: exhibitors.filter(i => !i.isFeatured && i.isPinned).map(e => e.id),
		exhibitors: exhibitors.filter(i => !i.isFeatured && !i.isPinned).map(e => e.id)
	}), []);
	const [ state, setState ] = useState({
		featured: exhibitors.filter(i => i.isFeatured && !i.isPinned),
		pinned: exhibitors.filter(i => !i.isFeatured && i.isPinned),
		regular: exhibitors.filter(i => !i.isFeatured && !i.isPinned)
	});

	useHotkeys('Escape', onComplete);

	useEffect(() => {
		document.body.classList.add('overflow-y-hidden');
		return () => document.body.classList.remove('overflow-y-hidden');
	}, []);

	function updateVendorProfile(id, values) {
		return () => Promise.resolve().then(() => axios({
			url: `${process.env.API_HOST}/village/events/${eventProfile.urlSlug}/profiles/${ id }`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			},
			data: { ...values }
		}))
	}

	function submitData() {
		toggleIsSaving(true);

		let arrangements = {
			featuredExhibitors: state.featured.map(f => f.id),
			pinnedExhibitors: state.pinned.map(p => p.id),
			exhibitors: state.regular.map(r => r.id)
		};

		diffs(arrangements.featuredExhibitors, originalArrangementIds.featuredExhibitors).map( id => {
			addQueue(updateVendorProfile(id, { isFeatured: true, isPinned: false }))
		});

		diffs(arrangements.pinnedExhibitors, originalArrangementIds.pinnedExhibitors).map( id => {
			addQueue(updateVendorProfile(id, { isFeatured: false, isPinned: true }))
		});

		diffs(arrangements.exhibitors, originalArrangementIds.exhibitors).map( id => {
			addQueue(updateVendorProfile(id, { isFeatured: false, isPinned: false }))
		});

		addQueue(() => Promise.resolve().then(() => axios({
			url: `${ apiHost }/village/events/${eventProfile.urlSlug}`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			},
			data: { arrangements }, // we replace on the server so we need to make sure everything is there now.
		}).then(({ data }) => {
			if (data && data.message || !data) {
				setServerError(data.message);
				window.scrollTo(0, 0);
				appendNotification('alert', "Uh oh. We couldn't save your changes.");
				return;
			}
			setEventProfile( state => ({ ...state, ...data }));

			if (typeof onComplete === 'function') {
				onComplete([ ...state.featured, ...state.pinned, ...state.regular ]);
			}

			appendNotification('success', "Success! Your changes have been saved.");
		}).catch(e => {
			setServerError(e.message);
			toggleIsSaving(false);
			window.scrollTo(0, 0);
			appendNotification('alert', "Uh oh. We couldn't save your changes.");
		})));
	}

	function pushToTop(currentList, currentIndex) {
		setState(state => ({
			...state,
			[currentList]: reorder(
				state[currentList],
				currentIndex,
				0
			)
		}));
	}

	function pushUp(currentList, currentIndex) {
		setState(state => ({
			...state,
			[currentList]: reorder(
				state[currentList],
				currentIndex,
				(currentIndex - 1)
			)
		}));
	}

	function pushDown(currentList, currentIndex) {
		setState(state => ({
			...state,
			[currentList]: reorder(
				state[currentList],
				currentIndex,
				(currentIndex + 1)
			)
		}));
	}

	function pushToBottom(currentList, currentIndex) {
		setState(state => ({
			...state,
			[currentList]: reorder(
				state[currentList],
				currentIndex,
				(state[currentList].length - 1)
			)
		}));
	}

	function setIsFeatured(currentList, currentIndex) {
		setState(state => ({
			...state,
			...move(
				state[currentList],
				state['featured'],
				{ index: currentIndex, droppableId: currentList },
				{ index: state['featured'].length, droppableId: 'featured'},
				{ isPinned: false, isFeatured: true }
			)
		}))
	}

	function setIsPinned(currentList, currentIndex) {
		setState(state => ({
			...state,
			...move(
				state[currentList],
				state['pinned'],
				{ index: currentIndex, droppableId: currentList },
				{ index: state['pinned'].length, droppableId: 'pinned'},
				{ isPinned: true, isFeatured: false }
			)
		}))
	}

	function resetExhibitor(currentList, currentIndex) {
		setState(state => ({
			...state,
			...move(
				state[currentList],
				state['regular'],
				{ index: currentIndex, droppableId: currentList },
				{ index: 0, droppableId: 'regular'},
				{ isPinned: false, isFeatured: false }
			)
		}))
	}

	return (
		<section className="fixed bg-white top-0 bottom-0 left-0 right-0 z-50 flex flex-col">
			<header className="py-4 sm:py-6 px-6 sm:px-8 flex flex-col sm:flex-row justify-between border-b border-gray-200">
				<div className="flex items-center">
					<h1 className="text-lg font-base capitalize">Manage Booth Layout</h1>
				</div>
				<div className="flex flex-col-reverse sm:flex-row items-center">
					{ !isSaving && <span className="text-gray-400 mx-auto sm:mx-0 mt-2 sm:mt-0 cursor-pointer mr-0 sm:mr-4" onClick={ onCancel }>Cancel</span> }
					{ !isSaving && <button className="btn-hub-green sm:w-auto ml-0 sm:ml-2 mt-2 sm:mt-0 text-sm" onClick={ submitData }>Save Changes</button> }
					{ isSaving && (
						<button disabled={true} className="btn-hub-green sm:w-auto sm:ml-0 mt-2 sm:mt-0 sm:mr-0 flex text-sm items-center cursor-not-allowed">
							<span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving { currentQueue.length } changes...</span>
						</button>
					)}
				</div>
			</header>
			<main className="flex-grow flex h-full overflow-x-hidden overflow-y-auto">
				<div className="container mx-auto py-12">

					{ serverError && <div className="rounded py-2 px-3 bg-red-50 text-gray-700 text-sm w-full my-2"><strong className="font-medium">Error:</strong>{ serverError }</div> }

					<div className="mb-6 text-right">
						<a href="https://help.eventhub.net/hc/en-us/articles/360053763153"
							 target="_blank"
							 className="inline uppercase text-xs tracking-normal underline text-right">Need Help?</a>
					</div>

					<ListsContext.Provider value={[ state, setState , { setIsPinned, setIsFeatured, resetExhibitor, pushToTop, pushUp, pushToBottom, pushDown } ]}>
						<ExhibitorManageLists />
					</ListsContext.Provider>
				</div>
			</main>
		</section>
	);
}
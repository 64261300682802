import React, { useContext, useEffect } from 'react';
import axios from "axios";
import { EventsContext } from "../../context/admin/events";
import useCurrentUser from "../app/useCurrentUser";
import usePermissions from "../app/usePermissions";
import useNotifications from "../app/useNotifications";
// import useQueue from "use-queue";

// function processQueue(promise, next) {
// 	promise().then(() => {
// 		next();
// 	});
// }

export default function useAdminData() {
	const [ currentUser ] = useCurrentUser();
	const { isAdmin }  = usePermissions();
	const { append }  = useNotifications();
	const [ events, setEvents ] = useContext(EventsContext);
	// const [ queue, addQueue ] = useQueue(processQueue);

	useEffect(() => {
		if (!isAdmin) {
			return;
		}

		axios({
			url: `${process.env.API_HOST}/village/events`,
			method: 'GET',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			}
		}).then(({ data }) => {
			append('success', 'All Events Loaded.');
			setEvents(data);
		}).catch(err => {
			append('alert', 'Error: ' + err.message);
			console.log('err', err);
		})

	}, [ currentUser ]);

	// function getEventVendors(eventId) {
	// 	console.log(`getting ${eventId} vendors`);
	// 	return axios({
	// 		url: `${process.env.API_HOST}/village/events/${eventId}/profiles`,
	// 		method: 'GET',
	// 		withCredentials: true,
	// 		headers: {
	// 			'X-Requested-With': 'XMLHttpRequest',
	// 			'Authorization': `Bearer ${ currentUser.token }`
	// 		}
	// 	}).then(({ data }) => {
	// 		console.log(`found ${data.length} vendors for ${eventId} event`)
	// 		return data;
	// 	}).catch(err => {
	// 		console.log('err', err);
	// 	});
	// }
	//
	// function updateVendorProfile(eventId, vendorId, values) {
	// 	return axios({
	// 		url: `${process.env.API_HOST}/village/events/${eventId}/profiles/${vendorId}`,
	// 		method: 'POST',
	// 		withCredentials: true,
	// 		headers: {
	// 			'X-Requested-With': 'XMLHttpRequest',
	// 			'Authorization': `Bearer ${ currentUser.token }`
	// 		},
	// 		data: { ...values }
	// 	})
	// }
	//
	// useEffect(()=> {
	// 	if (!events || !events.length) {
	// 		return;
	// 	}
	//
	// 	events.map((event) => {
	// 		addQueue(() => Promise.resolve(event).then((event) => new Promise((resolve, reject) => {
	// 			if (!event.villageProfile.urlSlug) {
	// 				return resolve();
	// 			}
	// 			getEventVendors(event.villageProfile.urlSlug).then(vendors => {
	// 				console.log('updating ' + (vendors || []).length + ' vendors for ', event.villageProfile.name);
	// 				if (!(vendors || []).length) {
	// 					return resolve();
	// 				}
	// 				Promise.all(vendors.map((vendor) => {
	// 					if (!vendor.conferenceUrl || vendor.conferenceUrl === '') {
	// 						console.log('opting in vendor ', vendor.id);
	// 						return updateVendorProfile(event.villageProfile.urlSlug, vendor.id,{ virtualBoothOptIn: true }).then(() => {
	// 							console.log('updated vendor ', vendor.id);
	// 						}).catch(() => Promise.resolve());
	// 					} else {
	// 						console.log('opting out vendor ', vendor.id);
	// 						return updateVendorProfile(event.villageProfile.urlSlug, vendor.id,{ virtualBoothOptIn: false }).then(() => {
	// 							console.log('updated vendor ', vendor.id);
	// 						}).catch(() => Promise.resolve());
	// 					}
	// 				})).then((data) => {
	// 					console.log('updated ' + data.length + ' vendors for ', event.villageProfile.name);
	// 					resolve();
	// 				});
	// 			});
	// 		})))
	// 	});
	// }, [events]);
	//
	// useEffect(() => {
	// 	console.log(`${ queue.length } events in queue.`);
	// }, [queue]);

	return Object.assign(
		[ events ],
		{
			events
		}
	) ;
}
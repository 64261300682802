import React from 'react';

export default function TestColumns () {
	return <section className="container mb-6">
		<section className="hidden sm:flex h-20 bg-blue-500 w-full items-center justify-around">
			<p className="text-white text-2xl">Small</p>
		</section>
		<section className="hidden md:flex h-20 bg-red-500 w-full items-center justify-around">
			<p className="text-white text-2xl">Medium</p>
		</section>
		<section className="hidden lg:flex h-20 bg-green-500 w-full items-center justify-around">
			<p className="text-white text-2xl">Large</p>
		</section>
		<section className="hidden xl:flex h-20 bg-yellow-300 w-full items-center justify-around">
			<p className="text-white text-2xl">XL</p>
		</section>
	</section>;
}
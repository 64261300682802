import React, { useState } from 'react';
import DailyIframe from "@daily-co/daily-js";
import Booth from "./Booth";
import BrowserUnsupported from "./BrowserUnsupported";
import { MessagesProvider } from "../../context/sessionMessages";
import AgeVerificationModal from "../AgeVerificationModal";
import { CallObjectProvider } from "../../context/sessionCallObject";
import useDeviceDetect from '../../hooks/helper/useDeviceDetect';
import MobileBooth from "./MobileBooth";

function VirtualChat({ handleFailedAgeVerification, onLeaveBooth, session, settings, hasHostAccess, endTime }) {
	const [ passedAgeVerification, setAgeVerification ] = useState(false);
	const detectDevice = useDeviceDetect();

	if (!hasHostAccess && settings.videoChat.enableAgeVerification && !passedAgeVerification) {
		return <section className="block w-full h-hero bg-black">
			<AgeVerificationModal acceptableAge={ settings.videoChat.ageVerification }
														handleAcceptedAge={ () => setAgeVerification(true) }
														handleYoungerAge={ handleFailedAgeVerification } />
		</section>
	}

	return (<>
		<CallObjectProvider onLeaveBooth={ onLeaveBooth } settings={{...session, ...settings, hasHostAccess}}>
			<MessagesProvider>
				<section className={`virtual-chat flex flex-col items-end justify-end relative`}>
					<div className="bg-gray-900 sm:h-virtual-booth block w-full">
						{ detectDevice.isMobile() ? <MobileBooth settings={{...session, ...settings}} hasHostAccess={hasHostAccess} endTime={endTime} /> : <Booth settings={{...session, ...settings}} hasHostAccess={hasHostAccess} endTime={endTime} />}
					</div>
				</section>
			</MessagesProvider>
		</CallObjectProvider>
	</>);
}

export default function VirtualChatStage(props) {
	return (DailyIframe.supportedBrowser().supported ? <VirtualChat {...props} /> : <BrowserUnsupported />);
}
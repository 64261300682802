import React, { useState, useEffect } from 'react';
import { useForm, FormContext } from "react-hook-form";
import UploadTarget from "../../../../components/UploadTarget";
import { INITIAL_DISPLAY_AD } from '../../../../context/displayAds';
import useEventData from "../../../../hooks/data/useEventData";
import useCurrentUser from "../../../../hooks/app/useCurrentUser";
import useToggableElm from "../../../../hooks/helper/useToggableElm";
import axios from "axios";
import UISelect from "../../../../components/UISelect";
import useConfig from "../../../../hooks/app/useConfig";
import { DisplayAdSchema } from "../../../../schemas";

const PLACEMENT_SIZES = {
	'event:horizontal': '728×90 or 468x60',
	'event:vertical': '300×250, 300x600, or 160x600'
}
const PLACEMENT_SIZE_VALIDATION = {
	'event:horizontal':  [
		{
			width: 728,
			height: 90
		},
		{
			width: 468,
			height: 60
		}
	],
	'event:vertical': [
		{
			width: 300,
			height: 250
		},
		{
			width: 300,
			height: 600
		},
		{
			width: 160,
			height: 600
		}
	]
}

export default function DisplayAdModal({ onCloseModal, onSave, onDelete, defaultValues, boothOptions }) {
	const [ eventProfile ] = useEventData();
	const [ currentUser ] = useCurrentUser();
	const { apiHost } = useConfig();
	const [ serverError, setServerError ] = useState('');
	const [ DeleteToggleLink ] = useToggableElm({
		type: ['a', 'p'],
		callbacks: [ shouldDeleteItem ],
		text: ['Delete Ad', <><span className="spinner h-5 w-5 inline-block" />&nbsp;Deleting Ad</>],
		classNames: ['text-red-700 mx-auto sm:mx-0 mt-2 sm:mt-0', 'flex items-center text-red-700 mx-auto sm:mx-0 mt-2 sm:mt-0']
	});

	const methods = useForm({
		defaultValues: defaultValues ? { ...defaultValues, booth: (defaultValues.booth && defaultValues.booth._id ? defaultValues.booth._id : null) } : { ...INITIAL_DISPLAY_AD, event: eventProfile.id },
		validationSchema: DisplayAdSchema
	});
	const { register, handleSubmit, setValue, watch, errors, formState: { dirty, isSubmitting } } = methods;
	let watchedItems = watch();

	useEffect(() => {
		register({ name: 'event', type: 'custom' });
		register({ name: 'imageUrl', type: 'custom' });
		register({ name: 'size', type: 'custom' });
	},[]);

	function setUploadFile(name, url) {
		setValue(name, url);
	}

	function setImageSize(size) {
		setValue('size', size);
	}

	function submitData(values) {
		console.log(values);
		if (values.url && values.booth) {
			return alert("Display Ads can only have one redirection. Please choose either website url or booth as a redirection for this ad.")
		}
		if (!values.url && !values.booth) {
			return alert("Display Ads need to have at least one redirection. Please choose either website url or booth as a redirection for this ad.")
		}
		return new Promise((resolve, reject) => {
			axios({
				url: `${apiHost}/village/events/${eventProfile.urlSlug}/ads${ defaultValues && defaultValues._id ? `/${defaultValues._id}` : '' }`,
				method: 'POST',
				withCredentials: true,
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'Authorization': `Bearer ${ currentUser.token }`
				},
				data: {
					...values,
					booth: values.booth !== '' ? values.booth : null
				}, // we replace on the server so we need to make sure everything is there now.
			}).then(({ data }) => {
				if (data.message) {
					setServerError(data.message);
					return reject();
				}
				onSave(data);
				onCloseModal();
				return resolve();
			}).catch(e => {
				setServerError(e.message);
				return reject();
			});
		});
	}

	function shouldModalClose() {
		if (!dirty || dirty && confirm("It seems you've changed some info, do you want to close this?")) {
			onCloseModal();
		}
	}

	function shouldDeleteItem() {
		if (prompt("Type 'DELETE' to confirm.") === 'DELETE') {
			return new Promise((resolve, reject) => {
				axios({
					url: `${apiHost}/village/events/${eventProfile.urlSlug}/ads/${defaultValues._id}`,
					method: 'DELETE',
					withCredentials: true,
					headers: {
						'X-Requested-With': 'XMLHttpRequest',
						'Authorization': `Bearer ${ currentUser.token }`
					}
				}).then(({ data }) => {
					if (data.message) {
						setServerError(data.message);
						return reject();
					}
					onDelete(defaultValues);
					return resolve();
				}).catch(e => {
					setServerError(e.message);
					return reject();
				});
			});
		}
	}

	return (<div className="fixed z-20 bottom-0 overflow-auto inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">

		<div className="fixed inset-0 transition-opacity">
			<div className="absolute inset-0 bg-gray-500 opacity-75" />
		</div>

		<div className="bg-white rounded-md sm:overflow-hidden shadow-xl transform transition-all sm:max-w-3xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<FormContext {...methods}>
				<form onSubmit={handleSubmit(submitData)} className="mb-0">

					<div className="bg-gray-200 px-4 py-3 sm:px-6">
						<h3 className="text-lg uppercase leading-6 font-normal text-gray-900">{defaultValues ? 'Edit' : 'Create'} Display Ad</h3>
					</div>

					<div className="flex flex-col sm:flex-row ">
						<div className="px-6 py-3 flex-grow">
							{ serverError && <p className="mt-1 mb-2 text-sm text-red-700">{ serverError }</p> }
							<strong className="uppercase text-sm font-medium leading-none tracking-snug text-center mb-4">Item Details</strong>

							<div className="flex">
								<div className={ defaultValues ? "mt-2 w-full" : "mt-2 w-3/4"}>
									<div className="flex justify-between">
										<label htmlFor="label" className="block text-xs font-light uppercase leading-normal text-gray-700">Label</label>
										<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal text-red-700">Required</span>
									</div>
									<input name="label" id="label" ref={register} className="mt-1 base-input" placeholder="Something describing the Ad" />
									{ errors.label && <p className="mt-1 text-sm text-red-700" id="email-error">{ errors.label.message }</p> }
								</div>
								{ !defaultValues && (
									<div className="mt-2 ml-4 w-1/4">
										<div className="flex justify-between mb-1">
											<label htmlFor="type" className="block text-xs font-light uppercase leading-normal text-gray-700">Placement</label>
										</div>
										<UISelect options={ [{ value: 'event:horizontal', text: 'Main Hall (Horizontal)'}, { value: 'event:vertical', text: 'Main Stage (Vertical)'}] }
															name="type" register={register}
															disabled={!!watchedItems.imageUrl}
															classes="w-full" />
									</div>
								) }
							</div>

							<strong className="block mt-3 uppercase tracking-normal text-sm">Redirection</strong>

							<p className="py-3 text-sm">A redirection for a display ad can either be an external website url or a booth.<br /><strong>You may only pick one.</strong></p>

							<div className="flex items-center">
								<div className="mt-2 w-1/2">
									<div className="flex justify-between">
										<label htmlFor="url" className="block text-xs font-light uppercase leading-normal text-gray-700">Website Url</label>
									</div>
									<input name="url" id="url" ref={register} className="mt-1 base-input" placeholder="http://your-awesome-domain.com" />
								</div>
								<p className="text-sm mx-4 mt-6">Or</p>
								<div className="mt-2 w-1/2">
									<div className="flex justify-between">
										<label htmlFor="booth" className="block text-xs font-light uppercase leading-normal text-gray-700">Booth</label>
									</div>
									<UISelect options={ boothOptions } id="booth" name="booth" register={register} classes="w-full" />
								</div>
							</div>
							{ errors.url && <p className="mt-1 text-sm text-red-700" id="url-error">{ errors.url.message }</p> }

							<strong className="block mt-3 uppercase tracking-normal text-sm">Media</strong>

							<div className="mt-2">
								<div className="flex justify-between">
									<label htmlFor="imageUrl" className="block text-xs font-light uppercase leading-normal text-gray-700">Ad Image</label>
									<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal">(Approved Sizes: { PLACEMENT_SIZES[watchedItems.type] })</span>
								</div>
								<UploadTarget folder={`events/${eventProfile.id}/village/ads/`}
															name="imageUrl"
															image={ watchedItems.imageUrl }
															enableCropping={ false }
															validSizes={ PLACEMENT_SIZE_VALIDATION[watchedItems.type] }
															aspectRatio={3/2}
															onSize={setImageSize}
															onChange={setUploadFile} />
								{ errors.imageUrl && <p className="mt-1 text-sm text-red-700" id="email-error">Pick a nice photo.</p> }
							</div>

						</div>
					</div>
					<div className="px-4 py-3 flex flex-col-reverse sm:flex-row justify-between items-center border-gray-300 border-t">
						{ defaultValues ? <DeleteToggleLink /> : <span /> }
						<div className="flex flex-col-reverse sm:flex-row items-center">
							<span className="text-gray-400 mx-auto sm:mx-0 mt-2 sm:mt-0 cursor-pointer" onClick={shouldModalClose}>Cancel</span>
							<button type="submit" disabled={isSubmitting} className="float-right btn-hub-green sm:w-auto sm:ml-2 mt-2 sm:mt-0 text-sm flex items-center">
								{ !isSubmitting && 'Save Changes' }
								{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
							</button>
						</div>
					</div>
				</form>
			</FormContext>
		</div>

	</div>);
}
/**
 * Call state is comprised of:
 * - "Call items" (inputs to the call, i.e. participants or shared screens)
 * - UI state that depends on call items (for now, just whether to show "click allow" message)
 *
 * Call items are keyed by id:
 * - "local" for the current participant
 * - A session id for each remote participant
 * - "<id>-screen" for each shared screen
 */
const INITIAL_STATE = {
  local: {
    local: {
      isLoading: true,
      audioTrack: null,
      videoTrack: null,
      isScreenShare: false
    }
  },
  callItems: {},
  numberOfParticipants: 0,
  clickAllowTimeoutFired: false,
  camOrMicError: null,
  fatalError: null
};

// --- Actions ---
const CLICK_ALLOW_TIMEOUT = "CLICK_ALLOW_TIMEOUT";
const PARTICIPANTS_CHANGE = "PARTICIPANTS_CHANGE";
const CAM_OR_MIC_ERROR = "CAM_OR_MIC_ERROR";
const FATAL_ERROR = "FATAL_ERROR";

// --- Reducer and helpers --
function callReducer(callState, action) {
  switch (action.type) {
    case CLICK_ALLOW_TIMEOUT:
      return {
        ...callState,
        clickAllowTimeoutFired: true
      };
    case PARTICIPANTS_CHANGE:
      const callItems = getCallItems(action.participants, callState.callItems);
      return {
        ...callState,
        numberOfParticipants: (Object.values(callItems).length - 1),
        callItems
      };
    case CAM_OR_MIC_ERROR:
      return { ...callState, camOrMicError: action.message };
    case FATAL_ERROR:
      return { ...callState, fatalError: action.message };
    default:
      throw new Error();
  }
}

function getLocalCallItem(callItems) {
  return callItems["local"];
}

function getCallItems(participants, prevCallItems) {
  let callItems = { ...INITIAL_STATE.callItems }; // Ensure we *always* have a local participant
  for (const [id, participant] of Object.entries(participants || {})) {
    // Here we assume that a participant will join with audio/video enabled.
    // This assumption lets us show a "loading" state before we receive audio/video tracks.
    // This may not be true for all apps, but the call object doesn't yet support distinguishing
    // between cases where audio/video are missing because they're still loading or muted.
    const hasLoaded = prevCallItems[id] && !prevCallItems[id].isLoading;
    const missingTracks = !(participant.audioTrack || participant.videoTrack);
    callItems[id] = {
      isLoading: !hasLoaded && missingTracks,
      audioTrack: participant.audioTrack,
      videoTrack: participant.videoTrack,
      name: participant.user_name,
      participant
    };
    if (participant.screenVideoTrack) {
      callItems[id + "-screen"] = {
        isLoading: false,
        videoTrack: participant.screenVideoTrack
      };
    }
  }
  return callItems;
}

// --- Derived data ---

// True if id corresponds to local participant (*not* their screen share)
function isLocal(id) {
  return id.indexOf('local') > -1;
}

function isScreenShare(id) {
  return id.endsWith("-screen");
}

function containsScreenShare(callItems) {
  return Object.keys(callItems).some(id => isScreenShare(id));
}

function getMessage(callState, videoChatSettings) {
  function shouldShowClickAllow() {
    const localCallItem = getLocalCallItem(callState.callItems);
    const hasLoaded = localCallItem && !localCallItem.isLoading;
    const isOwner = localCallItem && localCallItem.participant && localCallItem.participant.owner;
    const isAllowedToSpeakOrHaveCameraOn = isOwner || (videoChatSettings && videoChatSettings.participantsAllowed && (videoChatSettings.participantAllowSound || videoChatSettings.participantAllowVideo));
    return !hasLoaded && callState.clickAllowTimeoutFired && isAllowedToSpeakOrHaveCameraOn;
  }

  let header = null;
  let detail = null;
  let isError = false;
  let canDismiss = false;
  if (callState.fatalError) {
    header = `Fatal error: ${callState.fatalError}`;
    isError = true;
  } else if (callState.camOrMicError) {
    header = `Camera or mic access error: ${callState.camOrMicError}`;
    detail =
      "See https://help.daily.co/en/articles/2528184-unblock-camera-mic-access-on-a-computer to troubleshoot.";
    isError = true;
  } else if (shouldShowClickAllow()) {
    header = 'You may need to click "Allow" to turn on your mic or camera.';
    canDismiss = true;
  } else if (Object.keys(callState.callItems).length === 1) {
    header = "Waiting for other attendees.";
  }
  return header || detail ? { header, detail, isError, canDismiss } : null;
}

export {
  INITIAL_STATE,
  CLICK_ALLOW_TIMEOUT,
  PARTICIPANTS_CHANGE,
  CAM_OR_MIC_ERROR,
  FATAL_ERROR,
  callReducer,
  isLocal,
  isScreenShare,
  containsScreenShare,
  getMessage
};

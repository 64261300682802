import React, { useState, useEffect, useRef } from 'react';

export default function TimePicker({ classes, value, onSelectedTime }) {
	const isFirstRun = useRef(true);
	let [ hour, setHour ] = useState(1);
	let [ minute, setMinute ] = useState(0);
	let [ ampm, setAMPM ] = useState('am');

	useEffect(() => {
		let defaultHour = parseInt(value.split(':')[0]);
		let defaultMinute = parseInt(value.split(':')[1]);
		let defaultAMPM = parseInt(value.split(':')[0]) < 12 ? 'am' : 'pm';

		let closestMinute = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].reduce(function(prev, curr) {
			return (Math.abs(curr - defaultMinute) < Math.abs(prev - defaultMinute) ? curr : prev);
		});

		setHour(defaultHour === 0 ? 12 : defaultHour > 12 ? defaultHour - 12 : defaultHour);
		setMinute(closestMinute);
		setAMPM(defaultAMPM);
	}, []);

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}

		let finalHour = ampm === 'pm' && hour === 12 ? 12 : ampm === 'pm' ? (hour + 12) : ampm === 'am' && hour === 12 ? 0 : hour;
		onSelectedTime({ hour: finalHour, minute });
	}, [ hour, minute, ampm ]);

	return <div className={`time-input mt-1 p-5 ${classes}`}>
		<div className="flex justify-between">
			<select name="hour" value={hour} onChange={(e) => setHour(parseInt(e.target.value))} className="bg-transparent appearance-none outline-none">
				<option value="1">01</option>
				<option value="2">02</option>
				<option value="3">03</option>
				<option value="4">04</option>
				<option value="5">05</option>
				<option value="6">06</option>
				<option value="7">07</option>
				<option value="8">08</option>
				<option value="9">09</option>
				<option value="10">10</option>
				<option value="11">11</option>
				<option value="12">12</option>
			</select>
			<span>:</span>
			<select name="minute" value={minute} onChange={(e) => setMinute(parseInt(e.target.value))} className="bg-transparent appearance-none outline-none">
				<option value="0">00</option>
				<option value="5">05</option>
				<option value="10">10</option>
				<option value="15">15</option>
				<option value="20">20</option>
				<option value="25">25</option>
				<option value="30">30</option>
				<option value="35">35</option>
				<option value="40">40</option>
				<option value="45">45</option>
				<option value="50">50</option>
				<option value="55">55</option>
			</select>
			<select name="ampm" value={ampm} onChange={(e) => setAMPM(e.target.value)} className="bg-transparent appearance-none outline-none">
				<option value="am">AM</option>
				<option value="pm">PM</option>
			</select>
		</div>
	</div>
}
import React, { useEffect, useState, useRef, useMemo } from 'react';
import axios from 'axios';
import ReactPlayer from "react-player/lazy"
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { FacebookProvider, Page } from 'react-facebook';

const playerConfig = {
	vimeo: {
		playerOptions: {
			background: false,
			controls: true,
			transparent: false
		}
	},
	twitch: {
		options: {
			controls: true
		}
	},
};

function Facebook({url, height}) {
	const facebookContainer = useRef(null);
	const [ width, setWidth ] = useState(0);

	useEffect(() =>  setWidth(facebookContainer.current ? facebookContainer.current.clientWidth : 0), [facebookContainer.current]);
	
	return <>
		<section className="h-full w-full flex flex-row justify-center bg-gray-100">
			<div ref={facebookContainer} className='facebookContainer flex flex-row justify-center h-full w-full'>
				<FacebookProvider title="Facebook feed" className="w-full h-full items-center" appId="1703056040009516" wait>
					<Page href={url} width={width} height={height} tabs="timeline" />
				</FacebookProvider>
			</div>
		</section>
	</>
}


function Twitter({url, height}) {
	return <>
		<section className="h-full w-full flex flex-row justify-center">
			<div className={`h-full w-full`}>
				<TwitterTimelineEmbed
				sourceType="url"
				url={url}
				options={
					{height}
				}
				theme="dark"
				noHeader={true}
				noBorders={true}
				noFooter={true}
				noScrollbar={true}/>
			</div>
		</section>
	</>
}

function TikTok({url}) {
	let [ videoData, setVideoData ] = useState({});
	let linkRegex = /<a[\s\S]*?<\/a>/g;
	let songTitleRegex = /(?=title=")[^"]*(?=")/g;
	let songUrlRegex = /(?=href=")[^"]*(?=")/g;
	let songLinkIndex = 1; // the second link in TikTok's embed is always the song link

	useEffect(() => {
		axios({
			url: `https://www.tiktok.com/oembed?url=${url}`,
			method: 'GET',
		}).then(({ data }) => {
			let songFullLink = data.html.match(linkRegex)[songLinkIndex];

			setVideoData({
				url,
				id: url.replace(`${data.author_url}/video/`, ''),
				title: data.author_url.replace(`${data.provider_url}/`, ''),
				authorUrl: data.author_url,
				caption: data.title,
				songTitle: songFullLink.match(songTitleRegex)[0],
				songUrl: songFullLink.match(songUrlRegex)[0],
			});
		});
	}, [])

	return <div className="w-full h-full">
		<div className="w-full h-full">
			<iframe className="w-full h-full" scrolling="no" id="player" src={videoData.url} title={videoData.title} allowFullScreen={true} frameBorder="0" />
		</div>
	</div>
}

function Pinterest({url}) {
	useEffect(() => {
		const script = document.createElement('script')
		script.async = true
		script.type = 'text/javascript'
		script.dataset.pinBuild = 'doBuild'
		script.src = '//assets.pinterest.com/js/pinit.js'
		document.body.appendChild(script)
		if (window.doBuild) {
			window.doBuild()
		}
	}, [])

	return <>
		<a data-pin-do="embedBoard" data-pin-scale-height="240" data-pin-scale-width="80" href={url} />
	</>
}

function CustomWidget({iframe}) {
	return <div className="customWidget" dangerouslySetInnerHTML={{ __html: iframe }}>
	</div>
}

export default function SocialMediaWidget({ _id, url, type, size, height }) {
	const [ widget ] = useState({_id, url, type, size, height});
	const [ isVideo ] = useState(type === 'youtu.be' || type === 'youtube' || type === 'vimeo' || type === 'twitch');
	const heightInPixels = useMemo(() => {
		return widget.height === '1/2' ? '437.5px' : widget.height === '3/4' ? '525px' : widget.height === 'full' ? '700px' : '350px'
	}, [widget.height]);

    return (
		<div className={`socialMediaWidget w-full sm:w-full ${widget.type === 'tiktok' ? 'lg:w-auto' : widget.size === '1/4' ? 'lg:w-1/4' : widget.size === '1/2' ? 'lg:w-1/2' : widget.size === '3/4' ? 'lg:w-3/4' : 'lg:w-full'} px-5 lg:px-10 mb-6 z-0`} style={{height: heightInPixels}}>
			<div className="w-full flex flex-col h-full vendor-card-shadow overflow-hidden rounded">
				<div className={`widgetContainer flex flex-grow z-0 w-full h-full`}>
					{ type === 'facebook' && <Facebook url={url} height={heightInPixels} />}
					{ type === 'instagram' && <Instagram url={url} size={widget.size} />}
					{ type === 'pinterest' && <Pinterest url={url} />}
					{ isVideo && <ReactPlayer className="w-full h-full" url={url} config={playerConfig} width="100%" height="100%"/>}
					{ type === 'tiktok' && <TikTok url={url} />}
					{ type === 'twitter' && <Twitter url={url} height={heightInPixels} />}
					{ type === 'custom' && <CustomWidget iframe={url} />}
				</div>
			</div>
		</div>

    );
}
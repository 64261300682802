import React, {useMemo} from 'react';
import usePermissions from "../../hooks/app/usePermissions";
import useAttendee from "../../hooks/data/useAttendee";
import useConfig from "../../hooks/app/useConfig";

const greetings = ['Howdy', 'Hi there', 'Good day', 'Hey there', 'Hi', 'Hello','Hola','Hey','Cheers',"What's up", "G'day mate", "What's new", 'Hiya', 'Yo', 'Greetings'];
const greeting = greetings[Math.floor(Math.random() * greetings.length)];
const IS_BETA = window.location.host.indexOf('beta') > -1;

export default function TixBar() {
	let version = useMemo(()=> (Object.values(document.getElementsByTagName('script')).filter(script => { return script.src.indexOf('/app.') > 0}).map(script => script.src.split('.'))[0][ IS_BETA ? 3 : 2] || 'local'), [ document.getElementsByTagName('script') ]);
	const { apiHost, appVersion } = useConfig();
	let [ currentUser, _, __, logoutAttendee ] = useAttendee();
	let { isAttendee }  = usePermissions();

	if (!isAttendee) {
		return null;
	}


	function logout(e) {
		e.preventDefault();
		logoutAttendee();
	}

	return (<div className={`admin-bar flex flex-fix flex-col sm:flex-row justify-between w-full py-2 px-4 leading-none items-center text-center bg-gray-900 text-white text-xs z-40`}>
		<div className="flex flex-col sm:flex-row items-center text-center sm:text-auto">
			<label className="rounded px-2 py-1 bg-white text-black uppercase mr-2">{ apiHost.indexOf('beta') > -1 ? 'beta | ' : '' }App Ver: {appVersion} {apiHost.indexOf('beta') > -1 ? `-(${version})` : ''}</label>
			<p className="text-sm">{ greeting }, { currentUser.name }</p>
		</div>
		<div className="mt-2 sm:mt-0">
			<a href="#" className="hover:underline mt-2 sm:mt-0" onClick={ logout }>Logout</a>
		</div>
	</div>);
}

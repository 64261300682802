import React, { useState, useEffect } from 'react';

export default function Toggle({ isFlipped, isLoading, onToggle }) {
	isLoading = isLoading || false;
	const [flipped, setFlipped] = useState(isFlipped || false);

	useEffect(() => {
		setFlipped(isFlipped);
	},[ isFlipped ]);

	function handleToggle(){
		let toggleVal = !flipped;
		setFlipped(toggleVal);
		onToggle(toggleVal);
	}

	return (<span onClick={ handleToggle } role="checkbox" tabIndex="0" className={`${flipped ? 'bg-hub-green' : 'bg-gray-200'} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}>
		<span className={`${flipped ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}>
			{ isLoading && <span className="spinner h-5 w-5 inline-block" /> }
		</span>
	</span>);
}


import { useContext, useState } from 'react';
import { EventContext } from "../../context/event";
import {isAfter, getUnixTime, isBefore} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import axios from 'axios';
import SimpleYoutubeAPI from 'simple-youtube-api';
import useInterval from '../helper/useInterval';
const youtubeParseUrl = SimpleYoutubeAPI.util.parseURL;

export function getVideoType(url) {
	if (url.includes('youtube.com') || url.includes('youtu.be')) {
		return 'youtube';
	} else if (url.includes('twitch.tv')) {
		return 'twitch';
	} else if (url.includes('vimeo.com')) {
		return 'vimeo';
	} else if (url.includes('wistia.com')) {
		return 'wistia';
	} else {
		return '';
	}
}

export async function getVideoObject(options) {
	if (!options) return null;
	let { url } = options;
	let type = getVideoType(options.url);
	let videoObj = { ...options, type };

	switch (type) {
		case 'youtube':
			let { video } = youtubeParseUrl(options.url);
			videoObj = Object.assign({}, videoObj, {
				id: video,
				chatUrl: `https://www.youtube.com/live_chat?v=${video}&embed_domain=${window.location.hostname}`
			});
			break;
		case 'vimeo':
			let { data } = await axios({
				url: `https://vimeo.com/api/oembed.json?url=${url}`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			});

			videoObj = Object.assign({}, videoObj, {
				id: data.video_id,
				live: data.duration === 0,
				chatUrl: data.duration === 0 ? `https://vimeo.com/live-chat/${data.video_id}#extern` : ''
			});
			break;
		case 'twitch':
			let id = url.replace(/^(?:https?:\/\/)?(?:www\.|go\.)?twitch\.tv\/(videos\/)?/, '');
			videoObj = Object.assign({}, videoObj, {
				id,
				live: true,
				chatUrl: `https://www.twitch.tv/embed/${id}/chat?parent=${window.location.hostname}`
			});
			break;
		case 'wistia':
			videoObj = Object.assign({}, videoObj, {
				id: url.replace(/https?:\/\/[^.]+\.(wistia\.com|wi\.st)\/(medias|embed)\//, ''),
				live: false
			});
			break;
	}

	return videoObj;
}

export default function useShowTimes() {
	const [ eventProfile ] = useContext(EventContext);
	let [ isMainStageActive, toggleMainStageActive ] = useState(false);
	let [ mainStageVideo, setMainStageVideo ] = useState({ id: null, url: null, isLive: false });
	let [ nextMainStageTime, setNextMainStageTime ] = useState({ start: null, end: null, timezone: null });

	useInterval(() => {
		const now = Date.now();
		if (!nextMainStageTime.start || !nextMainStageTime.end) return;

		if ((isMainStageActive && now >= nextMainStageTime.end) /* video -> next video || cover picture*/ ||
			(!isMainStageActive && now >= nextMainStageTime.start) /* cover picture -> video */ ) {
			refreshMainStage();
		}
	}, 1000 * 5);

	if (!nextMainStageTime.start || !nextMainStageTime.end) {
		refreshMainStage(); // initialize nextMainStageTime on first render
	}

	function calculateAndSetNextMainStageTime() {
		if (!eventProfile.mainStageTimes || eventProfile.mainStageTimes && !eventProfile.mainStageTimes.length) {
			return;
		}

		let nowDate = new Date();
		let now = getUnixTime(nowDate);
		let closestStageTime = eventProfile.mainStageTimes.reduce(function(a, b) {
			let aStartTime = getUnixTime(zonedTimeToUtc(a.startTime, eventProfile.timezone));
			let aEndTime = getUnixTime(zonedTimeToUtc(a.endTime, eventProfile.timezone));
			let isAUpcoming = now < aStartTime;
			let isMiddleOfA = now > aStartTime && now < aEndTime;
			return isMiddleOfA || isAUpcoming ? a : b;
		});
		let nextStartTime = zonedTimeToUtc(closestStageTime.startTime, eventProfile.timezone);
		let nextEndTime = zonedTimeToUtc(closestStageTime.endTime, eventProfile.timezone);
		setNextMainStageTime({
			start: nextStartTime,
			end: nextEndTime,
			timezone: eventProfile.timezone
		});
		return { closestStageTime, nextStartTime, nextEndTime };
	}

	function refreshMainStage() {
		if (!eventProfile.mainStageTimes || !eventProfile.mainStageTimes.length) {
			return;
		}

		const { closestStageTime, nextStartTime, nextEndTime } = calculateAndSetNextMainStageTime();

		// No current content to show
		if (isAfter(new Date(), nextEndTime) || isBefore(new Date(), nextStartTime)) {
			toggleMainStageActive(false); // forces cover photo; no new sessions
			return;
		}

		// Are we during the next time..
		if (isAfter(new Date(), nextStartTime) && isBefore(new Date(), nextEndTime)) {
			toggleMainStageActive(true);
		}

		if (closestStageTime.url) {
			getVideoObject({ url: closestStageTime.url }).then((videoObject) => {
				let newMainStageVideo = Object.assign({}, videoObject, {
					displayChat: closestStageTime.displayChat,
				});
				setMainStageVideo(newMainStageVideo);
			});
		}
	}

	return { isMainStageActive, mainStageVideo, nextMainStageTime };
}
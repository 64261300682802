import React, { useEffect, useState, useMemo, useRef } from 'react';
import useConfig from "../../hooks/app/useConfig";
import axios from "axios";
import useToggle from "../../hooks/helper/useToggle";
import AgeVerificationModal from "../../features/AgeVerificationModal";
import {VIRTUAL_USER_LOCAL_STORAGE_KEY} from "../../context/sessionCallObject";
import AskForParticipantName from "../../features/VirtualChat/AskForParticipantName";
import useLocalStorage from "../../hooks/helper/useLocalStorage";
import useDeviceDetect from "../../hooks/helper/useDeviceDetect";
import VillageLink from "../VillageLink";

const ZOOM_API_KEY = 'XN1bGU-jQK-UEfBMZfB5Ww';

export default function ZoomContainer({ config, handleFailedAgeVerification, onLeaveBooth, hasHostAccess }) {
    const detectDevice = useDeviceDetect();
    const iframeRef = useRef(null);
    const [ currentiFrameSrc, setIframeSrc ] = useState();
    const { apiHost } = useConfig();
    const isWebinar = config.type.indexOf('webinar') > 0;
    const [ passedAgeVerification, setAgeVerification ] = useToggle(false);
    const [ userName, setUserName, hasLoadedName ] = useLocalStorage(VIRTUAL_USER_LOCAL_STORAGE_KEY, null);
    const [ userHasSavedName, setUserHasSavedName ] = useState(false);
    const [ userEmail, setUserEmail, hasLoadedEmail ] = useLocalStorage(VIRTUAL_USER_LOCAL_STORAGE_KEY+"_EMAIL", null);
    const [ isLoading, setLoading ] = useToggle(true);
    const [ state, setState ] = useState({
        email: '',
        signature: null
    });
    const iframeUrl = useMemo(() => `/zoom/meeting.html?data=${encodeURIComponent(btoa(JSON.stringify({
        signature: state.signature,
        meetingNumber: config.zoom.meetingNumber,
        apiKey: ZOOM_API_KEY,
        userName: userName,
        role: hasHostAccess ? 1 : 0,
        china: false,
        userEmail: isWebinar ? userEmail : '',
        passWord: config.zoom.password
    })))}`, [ state, userName, isWebinar, userEmail, config ])

    console.log('Current Zoom Link change', currentiFrameSrc);

    useEffect(() => {
        // current iframe is not null and is not using the meeting.html iframe
        // because of that we should close the frame.
        if (currentiFrameSrc && currentiFrameSrc.indexOf('meeting.html') < 0) {
            onLeaveBooth();
        }
    }, [currentiFrameSrc])

    useEffect(()=>{
        if (!iframeRef.current) {
            return;
        }
        const iframe = iframeRef.current;

        function dispatchChange() {
            const newHref = iframe.contentWindow ? iframe.contentWindow.location.href : null;
            if (newHref && newHref !== currentiFrameSrc) {
                setIframeSrc(newHref);
            }
        }

        const unloadHandler = function () {
            // Timeout needed because the URL changes immediately after
            // the `unload` event is dispatched.
            setTimeout(dispatchChange, 0);
        };

        function attachUnload() {
            // Remove the unloadHandler in case it was already attached.
            // Otherwise, there will be two handlers, which is unnecessary.
            iframe.contentWindow.removeEventListener("unload", unloadHandler);
            iframe.contentWindow.addEventListener("unload", unloadHandler);
        }

        function eventLoader() {
            attachUnload();
            dispatchChange();
        }

        iframe.addEventListener("load", eventLoader);

        attachUnload();

        return () => iframe.removeEventListener("load", eventLoader)
    }, [iframeRef])

    useEffect(()=> {
        if (!state.signature && isLoading && (isWebinar && !userEmail)) {
            return;
        }

    }, [state, isLoading, isWebinar, userEmail]);

    useEffect(() => {
        setLoading(true);
        axios({
            url: `${ apiHost }/village/zoom-meeting/token?meeting=${config.zoom.meetingNumber}&role=${hasHostAccess ? '1' : '0'}`,
            method: 'GET'
        }).then(({data}) => {
            setLoading(false);
            if (data) {
                setState((s) => ({...s, signature: data.signature}));
            }
        }).catch(() => {
            setLoading(false);
        });
    }, [config]);

    function saveParticipantName(name) {
        setUserName(name);
        setUserHasSavedName(true);
    }

    function saveParticipantEmail(email) {
        setUserEmail(email);
    }

    if (!hasHostAccess && config.zoom.enableAgeVerification && !passedAgeVerification) {
        return <section className="block w-full h-hero bg-black">
            <AgeVerificationModal acceptableAge={ config.zoom.ageVerification }
                                  handleAcceptedAge={ () => setAgeVerification(true) }
                                  handleYoungerAge={ handleFailedAgeVerification } />
        </section>
    }

    if (!userHasSavedName && hasLoadedName) {
        return <AskForParticipantName onNameSave={ saveParticipantName } defaultValue={ userName } />;
    }

    if (isWebinar && !userEmail && hasLoadedEmail) {
        return <AskForParticipantName onNameSave={ saveParticipantEmail } title="What's your email?" placeholderTitle="jane@example.com" error="Must enter a valid email address." defaultValue={""}/>;
    }

    if (detectDevice.isMobile() || detectDevice.isSafari()) {
        return (
            <div className="w-full bg-gray-700 inner-shadow px-6 py-24 flex justify-around">
                <VillageLink to={config.url} target='_blank' eventLabel="launch third-party conference link from session page" className="cursor-pointer rounded font-medium bg-white py-3 px-12 text-base mx-auto">Launch &#8220;{config.name || 'Enter Zoom Meeting'}&#8221;</VillageLink>
            </div>
        )
    }

    return (<section className="flex flex-col items-end justify-end relative">
        <div className="bg-gray-900 sm:h-virtual-booth block w-full">
            {iframeUrl ? <iframe ref={iframeRef} src={iframeUrl} allow="camera; microphone" height="100%" width="100%" frameBorder="0" /> : null}
        </div>
    </section>);
};
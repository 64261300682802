import stableSort from 'stable';

export function getExhibitorTags(exhibitors) {
	let displayItems = exhibitors.displayItems || exhibitors;
	try {
		return stableSort(displayItems
			.map(i => i.tags) // just get the tags,
			.flat() // flatten the array of arrays [[1,2],[3,4]] => [1,2,3,4]
			.filter((value, index, self) => self.indexOf(value) === index), (a,b) => a.localeCompare(b)) // get unique values
			.map(t => ({ text: t, value: t}));
	} catch {
		return [];
	}
}
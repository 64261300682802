import React, {useEffect, useReducer, useState, useMemo} from "react";
import TrayButton, {
  TYPE_MUTE_CAMERA,
  TYPE_MUTE_MIC,
  TYPE_SCREEN,
  TYPE_ENTER_FULL_SCREEN,
  TYPE_EXIT_FULL_SCREEN,
  TYPE_CHAT,
} from "../TrayButton";
import Icon from "../Icon/Icon";
import { logDailyEvent } from "../../logUtils";
import DailyIframe from "@daily-co/daily-js";

import useCallObject from "../../../../hooks/data/useSessionCallObject";
import useDeviceDetect from "../../../../hooks/helper/useDeviceDetect";
import { getStreamStates } from "../../utils";
import {callReducer, isScreenShare, PARTICIPANTS_CHANGE} from "../Call/callState";
import useToggle from "../../../../hooks/helper/useToggle";
import { ArrowUpSVG, ArrowDownSVG } from "../../../../components/Icons";
import { volumeAnalyzer, stopVolumeAnalyzer } from "../../../../utils/volumeAnalyzer";

const INITIAL_STATE = {
  local: {
    local: {
      isLoading: true,
      audioTrack: null,
      videoTrack: null,
      isScreenShare: false
    }
  },
  callItems: {},
  numberOfParticipants: 0,
  clickAllowTimeoutFired: false,
  camOrMicError: null,
  fatalError: null
};

function ParticipantRow({ name, id, isOwner, video, audio }) {
  const [ callObject ] = useCallObject();

  function handleMuteUser() {
    callObject.updateParticipant(id, { setAudio: false });
  }

  function handleStopVideoUser() {
    callObject.updateParticipant(id, { setVideo: false });
  }

  function handleBootUser() {
    if (confirm('This will kick out the participant from the booth. Do you want to continue?')) {
      callObject.updateParticipant(id, { eject: true });
    }
  }

  return (
    <li className="flex justify-between">
      <span className="text-sm text-gray-700 w-48 truncate">{ name }</span>
      <div className="content-center flex">
        { !isOwner && audio && <button onClick={ handleMuteUser } className="px-2 text-xs text-gray-600"><Icon type={TYPE_MUTE_MIC} highlighted={false} customHoverText="turn microphone off"/></button> }
        { !isOwner && video && <button onClick={ handleStopVideoUser } className="px-2 text-xs text-gray-600"><Icon type={TYPE_MUTE_CAMERA} highlighted={false} customHoverText="turn camera off"/></button> }
        { !isOwner && <button onClick={ handleBootUser } className="px-2 text-xs text-gray-600">Boot User</button> }
      </div>
    </li>
  )
}

export default function Tray(props) {
  const { settings, hasHostAccess } = props;
  const [ callObject ] = useCallObject();
  const [ callState, dispatch ] = useReducer(callReducer, INITIAL_STATE);
  const [ showParticipantMenu, toggleParticipantMenu ] = useToggle();
  const [ isCameraMuted, setCameraMuted ] = useState(false);
  const [ isMicMuted, setMicMuted ] = useState(false);
  const [ isSharingScreen, setSharingScreen ] = useState(false);
  const detectDevice = useDeviceDetect();
  const [ volume, setVolume ] = useState(0);

  useEffect(() => {
    if (!callObject) return;

    const events = ["participant-joined", "participant-updated", "participant-left"];

    function handleNewParticipantsState(event) {
      event && logDailyEvent(event);
      dispatch({
        type: PARTICIPANTS_CHANGE,
        participants: callObject.participants()
      });
    }

    // Use initial state
    handleNewParticipantsState();

    // Listen for changes in state
    for (const event of events) {
      callObject.on(event, handleNewParticipantsState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewParticipantsState);
      }
    };
  }, [callObject]);

  const participants = useMemo(() => {
    let participants = {hosts: [], speakers: [], attendees: []};

    Object.entries(callState.callItems).forEach(([id, callItem]) => {
      if (!isScreenShare(id) && !callItem.participant.local) {
        let row = <ParticipantRow 
          key={id} id={id}
          video={callItem.participant.video}
          audio={callItem.participant.audio}
          name={callItem.participant.user_name}
          isOwner={callItem.participant.owner} 
        />;

        if (callItem.participant.user_id.includes('organizer')) {
          participants.hosts.push(row);
        } else if (callItem.participant.user_id.includes('speaker')) {
          participants.speakers.push(row);
        } else {
          participants.attendees.push(row);
        }
      }
    });

    participants.hosts = participants.hosts.sort((a,b) => a.props.name < b.props.name ? -1 : 1);
    participants.speakers = participants.speakers.sort((a,b) => a.props.name < b.props.name ? -1 : 1);
    participants.attendees = participants.attendees.sort((a,b) => a.props.name < b.props.name ? -1 : 1);

    return {...participants, count: participants.hosts.length + participants.speakers.length + participants.attendees.length };
  }, [callState]);

  function toggleCamera() {
    callObject.setLocalVideo(isCameraMuted);
  }

  function toggleMic() {
    callObject.setLocalAudio(isMicMuted);
  }

  function toggleSharingScreen() {
    isSharingScreen ? callObject.stopScreenShare() : callObject.startScreenShare();
  }

  function leaveCall() {
    props.onClickLeaveCall && props.onClickLeaveCall();
  }

  useEffect(() => {
    if (!callObject) return;

    function getAudioLevels(event) {
      if (event.track.kind !== 'audio') return;

      if (event.track.muted) {
        toggleMic();
        return alert(`We can't pick up your sound. Please check that your microphone is set up properly and that your browser is connected to your mic.`)
      }

      volumeAnalyzer(setVolume);
    }

    function stopVisualizer(event) {
      event.track.kind === 'audio' && stopVolumeAnalyzer();
    }

    if (callObject._participants.local.audio) {
      getAudioLevels({track: {kind: 'audio'}});
    }

    callObject.on('track-started', getAudioLevels);
    callObject.on('track-stopped', stopVisualizer);


    function handleNewParticipantsState(event) {
      event && logDailyEvent(event);
      const [isCameraMuted, isMicMuted, isSharingScreen] = getStreamStates(callObject);
      setCameraMuted(isCameraMuted);
      setMicMuted(isMicMuted);
      setSharingScreen(isSharingScreen);
    }

    handleNewParticipantsState();
    callObject.on("participant-updated", handleNewParticipantsState);

    return function cleanup() {
      callObject.off("participant-updated", handleNewParticipantsState);
      callObject.off('track-started', getAudioLevels);
      callObject.off('track-stopped', stopVisualizer);
    };
  }, [callObject]);

  // console.log('settings.videoChat.participantAllowVideo', settings.videoChat.participantAllowVideo)
  // console.log('settings.videoChat.participantAllowSound', settings.videoChat.participantAllowSound)
  // console.log('hasHostAccess && settings.videoChat.ownerScreenShare', hasHostAccess && settings.videoChat.ownerScreenShare)
  // console.log('!hasHostAccess && settings.videoChat.participantScreenShare', !hasHostAccess && settings.videoChat.participantScreenShare)

  return (<div className='h-auto xl:h-1/12 flex items-center justify-between flex-none px-3 bg-gray-100'>
    { hasHostAccess ? <div className="w-full sm:w-auto relative">
<button className="w-full sm:w-auto pb-2 sm:pb-auto text-gray-700 text-sm tracking-wider float-right" onClick={ () => toggleParticipantMenu() }>{ !showParticipantMenu ? 'Manage' : 'Hide'} Participants ({participants && participants.count}) { !showParticipantMenu ? <ArrowUpSVG classes={`h-4 w-4 inline fill-current`}></ArrowUpSVG> : <ArrowDownSVG classes={`h-4 w-4 inline fill-current`}></ArrowDownSVG>}</button>
      <div className={`${showParticipantMenu ? 'inline' : 'hidden' } rounded p-3 bg-white absolute bottom-0 z-10 h-48 ${detectDevice.isMobile() && detectDevice.isPortrait() ? 'w-screen' : 'w-128' } overflow-hidden mb-10 shadow`}>
        <div className="overflow-y-auto w-full h-full">
          {participants.hosts.length > 0 && <div className="pb-2">
            <label>Hosts ({participants.hosts.length})</label>
            <ul>
              { participants.hosts }
            </ul>
          </div>}
          {participants.speakers.length > 0 && <div className="pb-2">
            <label>Speakers ({participants.speakers.length})</label>
            <ul>
              { participants.speakers }
            </ul>
          </div>}
          {participants.attendees.length > 0 && <div className="pb-2">
            <label>Attendees ({participants.attendees.length})</label>
            <ul>
              { participants.attendees }
            </ul>
          </div>}
        </div>
      </div>
    </div> : !DailyIframe.supportedBrowser().mobile ? <div /> : null}
    <div className="w-3/5 sm:w-auto pb-1 flex justify-between">
      { (hasHostAccess || (!hasHostAccess && settings.videoChat.participantAllowVideo && settings.videoChat.participantsAllowed)) && <TrayButton
        type={TYPE_MUTE_CAMERA}
        disabled={props.disabled}
        highlighted={isCameraMuted}
        onClick={toggleCamera}
      /> }
      { (hasHostAccess || (!hasHostAccess && settings.videoChat.participantAllowSound && settings.videoChat.participantsAllowed)) && <div className="relative">
        <div className="relative z-10">
          <TrayButton
            type={TYPE_MUTE_MIC}
            disabled={props.disabled}
            highlighted={isMicMuted}
            onClick={toggleMic}
          />
        </div>
        {!isMicMuted && <div className="volumeVisualizer absolute bottom-0 inset-x-0 bg-hub-green opacity-50 z-0" style={{height: `${volume}%`}} />}
      </div>}
      { DailyIframe.supportedBrowser().supportsScreenShare && !DailyIframe.supportedBrowser().mobile && ((hasHostAccess && settings.videoChat.ownerScreenShare) || (!hasHostAccess && settings.videoChat.participantScreenShare)) && (
        <TrayButton
          type={TYPE_SCREEN}
          disabled={props.disabled}
          highlighted={isSharingScreen}
          onClick={toggleSharingScreen}
        />
      ) }
      { !detectDevice.isMobile() && <TrayButton
          type={props.isFullScreen ? TYPE_EXIT_FULL_SCREEN : TYPE_ENTER_FULL_SCREEN}
          onClick={props.onClickFullScreen}
        /> }
      { DailyIframe.supportedBrowser().mobile && 
        <TrayButton
          type={TYPE_CHAT}
          highlighted={props.isChatOpen}
          onClick={props.toggleChat}
          className="fill-current"
        />
      }
    </div>
    <button className="text-red-700 text-sm font-bold uppercase tracking-wider float-right" onClick={ leaveCall }>LEAVE BOOTH</button>
  </div>);
}

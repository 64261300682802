import React, { useEffect, useState, useMemo } from 'react';
import axios from "axios";
import ReactGA from 'react-ga';
import { Link, useHistory } from 'react-router-dom';
import {subMinutes, differenceInMilliseconds, setMilliseconds, getUnixTime, setSeconds, addMinutes} from 'date-fns';
import Footer from '../Footer';
import NavBar from '../NavBar';
import AdminBar from '../AdminBar';
import Header from '../Header';
import Notification from "../Notification";
import useMode from "../../hooks/app/useMode";
import useStyles from "../../hooks/app/useStyles";
import useConfig from "../../hooks/app/useConfig";
import useAttendee from '../../hooks/data/useAttendee';
import useEventData from '../../hooks/data/useEventData';
import AttendeeTicket from '../../features/AttendeeTicket';
import useBoothTimes from '../../hooks/data/useBoothTimes';
import useCurrentUser from '../../hooks/app/useCurrentUser';
import usePermissions from '../../hooks/app/usePermissions';
import AttendeePassword from '../../features/AttendeePassword';
import useNotifications from '../../hooks/app/useNotifications';
import useWhiteLabelSlugs from '../../hooks/app/useWhitelabelSlugs';
import AgeVerificationModal from "../../features/AgeVerificationModal";
import useAnnouncements from "../../hooks/app/useAnnouncements";
import {zonedTimeToUtc} from "date-fns-tz";
import useToggle from "../../hooks/helper/useToggle";
import useInterval from "../../hooks/helper/useInterval";

function PopupAnnouncement() {
	const history = useHistory();
	const [ eventProfile ] = useEventData();
	const { announcements, removeAnnouncement } = useAnnouncements();
	const [ showPopup, togglePopup ] = useToggle(false);

	const announcement = useMemo(() => {
		if (!announcements || announcements && !announcements.popup || !announcements.popup.length) {
			return;
		}

		return announcements.popup.reduce(function(a, b) {	
			let aStartTime = getUnixTime(zonedTimeToUtc(a.startTime, eventProfile.timezone));	
			let bStartTime = getUnixTime(zonedTimeToUtc(b.startTime, eventProfile.timezone));	

			return aStartTime <= bStartTime ? a : b;	
		});
	}, [announcements.popup]);

	useInterval(() => {
		let nowIsAfterStartTime = getUnixTime(new Date()) > getUnixTime(zonedTimeToUtc(announcement.startTime, eventProfile.timezone));

		if (!showPopup && announcement && nowIsAfterStartTime) {
			togglePopup(true);
		}
	}, announcement ? 1000 : null);

	if (!showPopup || !announcement) {
		return null;
	}

	function handleClose() {
		removeAnnouncement(announcement._id, 'popup');
		togglePopup(false);
	}

	function handleCTAClick() {
		handleClose();
		if (announcement.booth) {
			history.push(`${ !window.white_label_slug ? `/${eventProfile.urlSlug}` : '' }/${ announcement.booth.urlSlug }`);
		} else {
			window.open(announcement.ctaUrl, '_blank').focus();
		}
	}

	const CTA_BUTTON_STYLES = { backgroundColor: eventProfile.advanceStyles.brandColor, color: eventProfile.advanceStyles.brandText };
	const CTA_BUTTON_CLASSES = "rounded px-3 py-2 text-center font-normal";

	return (
		<div className="fixed z-50 bottom-0 overflow-auto inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">

			<div className="fixed inset-0 transition-opacity">
				<div className="absolute inset-0 bg-gray-500 opacity-75" />
			</div>

			<div className="bg-white rounded-md overflow-hidden shadow-xl transform transition-all sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline" style={{maxWidth: '500px'}}>
				{ announcement && announcement.imageUrl && <img src={announcement?.imageUrl} className="w-full" />}
				<div className="p-4 flex flex-col">
					<h3 className="text-lg font-bold mb-2">{announcement.title}</h3>
					<p className="text-sm leading-none mb-6">{announcement.description}</p>
					{ (announcement.ctaUrl || announcement.booth) && <button type="button" className={CTA_BUTTON_CLASSES} style={CTA_BUTTON_STYLES} onClick={handleCTAClick}>{announcement.ctaLabel || 'Learn More'}</button> }
					<span className="text-base font-normal pt-3 text-center cursor-pointer hover:underline" onClick={handleClose}>Got it, thanks</span>
				</div>
			</div>

		</div>
	);
}

function SlideInAnnouncement() {
	const history = useHistory();
	const [ eventProfile ] = useEventData();
	const { announcements, removeAnnouncement } = useAnnouncements();
	const [currentAnnouncements, setCurrentAnnouncements] = useState(getCurrentAnnouncements)
	
	useEffect(() => setCurrentAnnouncements(getCurrentAnnouncements()), [announcements.slidein]);

	function getCurrentAnnouncements() {
		if (!announcements || announcements && !announcements.slidein || !announcements.slidein.length) {
			return;
		}

		let now = getUnixTime(new Date());
		return announcements.slidein.filter((a) => {
			let aStartTime = getUnixTime(zonedTimeToUtc(a.startTime, eventProfile.timezone));
			return aStartTime <= now;
		});
	}

	useInterval(() => {
		setCurrentAnnouncements(getCurrentAnnouncements());
	}, announcements.slidein && announcements.slidein.length ? 1000 : null);

	if (!currentAnnouncements || !currentAnnouncements.length) {
		return null;
	}

	function handleBodyClick(announcement) {
		return (event) => {
			event?.preventDefault();
			removeAnnouncement(announcement._id, 'slidein');
			if (announcement.booth) {
				history.push(`${ !window.white_label_slug ? `/${eventProfile.urlSlug}` : '' }/${ announcement.booth.urlSlug }`);
			} else if (announcement.ctaUrl) {
				window.open(announcement.ctaUrl, '_blank').focus();
			}
		}
	}

	return (
		<div className="fixed w-full sm:w-84 sm:bottom-5 sm:right-5 z-50">
			{currentAnnouncements && currentAnnouncements.length > 0 && currentAnnouncements.map((announcement) => <div key={announcement._id} className="rounded-md relative mb-3 shadow-xl bg-white border-2 border-gray-300">
				<h3 className="text-lg font-bold flex justify-between">
					<span className="w-full cursor-pointer pl-3 pt-3" onClick={handleBodyClick(announcement)}>{announcement.title}</span>
					<span className="text-2xl pr-3 pt-3 leading-none font-normal text-center cursor-pointer text-left" onClick={() => removeAnnouncement(announcement._id, 'slidein')}>&times;</span></h3>
				<p className="text-sm leading-none cursor-pointer p-3" onClick={handleBodyClick(announcement)}>{announcement.description}</p>
			</div>)}
		</div>
	);
}

function Notifications() {
	const { notifications } = useNotifications();
	return (
		<div className="fixed w-full sm:w-time-modal sm:top-5 sm:right-5 z-50">
			{ notifications && notifications.map((notification, index) => <Notification key={index} { ...notification } />)}
		</div>
	)
}

export default function Layout({ children }) {
	const mode = useMode();
	const { apiHost } = useConfig();
	const { eventSlug } = useWhiteLabelSlugs();
	const { loadCustomStyles } = useStyles();
	const [ currentUser ] = useCurrentUser();
	const [ attendeeProfile ] = useAttendee();
	const { setAnnouncements } = useAnnouncements();
	const { append: appendNotification, notifications } = useNotifications();
	const [ eventProfile, setEventProfile ] = useEventData();
	const [ loadingEvent, setLoadingEvent ] = useState(true);
	const displayContents = attendeeProfile.authorized || currentUser.token;
	const protectionType = eventProfile.onlyAllowTicketHolders ? 'ticket' : eventProfile.attendeePassword ? 'password' : null;
	const { isExhibitor, hasEventEditAccess } = usePermissions();
	const { nextBoothTime } = useBoothTimes();
	const [ passedAgeVerification, setAgeVerification ] = useState(false);
	const notificationLength = 10000;

	if (!eventSlug) {
		window.location.href = 'https://landing.eventhub.net/vv';
	}

	function getAnnouncements(eventProfileId) {
		let nowInUTC = new Date(new Date().toUTCString());
		let minTime = setMilliseconds(setSeconds(subMinutes(nowInUTC, 2), 0), 0).toISOString();
		let maxTime = setMilliseconds(setSeconds(addMinutes(nowInUTC, 2), 0), 0).toISOString();
		axios({
			url: `${ apiHost }/village/events/${eventProfile.id || eventProfileId}/announcements/${minTime}/${maxTime}`,
			method: 'get'
		}).then(({ data }) => {
			if (data && data.length > 0) {
				setAnnouncements(data);
			}
		}).catch(err => {});
	}

	useEffect(() => {
		if (!nextBoothTime || !isExhibitor) {
			return;
		}

		let now = getUnixTime(new Date());
		let timers = [];

		let secondsUntilBoothsStart = differenceInMilliseconds(getUnixTime(nextBoothTime.start), now);
		let secondsUntilFiveMinutesBeforeBoothsStart = differenceInMilliseconds(getUnixTime(subMinutes(nextBoothTime.start, 5)), now);
		let secondsUntilBoothsEnd = differenceInMilliseconds(getUnixTime(nextBoothTime.end), now);
		let secondsUntilFiveMinutesBeforeBoothsEnd = differenceInMilliseconds(getUnixTime(subMinutes(nextBoothTime.end, 5)), now);

		if (secondsUntilFiveMinutesBeforeBoothsStart > 0) {
			timers.push(setTimeout(() => {
				appendNotification('info', 'Booth sessions start in 5 minutes.', notificationLength);
			}, secondsUntilFiveMinutesBeforeBoothsStart*1000));
		}

		if (secondsUntilBoothsStart > 0) {
			timers.push(setTimeout(() => {
				appendNotification('info', 'Booth sessions are starting now.', notificationLength);
			}, secondsUntilBoothsStart*1000));
		}

		if (secondsUntilFiveMinutesBeforeBoothsEnd > 0) {
			timers.push(setTimeout(() => {
				appendNotification('info', 'Booth sessions end in 5 minutes.', notificationLength);
			}, secondsUntilFiveMinutesBeforeBoothsEnd*1000));
		}

		if (secondsUntilBoothsEnd > 0) {
			timers.push(setTimeout(() => {
				appendNotification('info', 'Booth sessions are ending now.', notificationLength);
			}, secondsUntilBoothsEnd*1000));
		}

		return () => timers.map(t => clearTimeout(t));

	}, [nextBoothTime, isExhibitor]);

	useEffect(() => {
		loadCustomStyles();
		if (process.env.NODE_ENV === 'production') {
			const ADMIN_GA_TRACKING_ID = 'UA-73704125-2';
			ReactGA.initialize(ADMIN_GA_TRACKING_ID, {
				debug: false,
				gaOptions: {
					name: 'adminTracker'
				}
			});
		}
	}, []);

	useEffect(function() {
		if (!eventSlug) {
			return;
		}
		axios({
			url: `${ apiHost }/village/events/${eventSlug}`,
			method: 'get'
		}).then(({ data: eventProfile}) => {
			setEventProfile(state => ({ ...Object.assign({}, state, {...eventProfile}) }));
			setLoadingEvent(false);
			getAnnouncements(eventProfile.id);
		}).catch(err => {
			setLoadingEvent(false);
		});
	}, [ eventSlug ]);

	useInterval(getAnnouncements, eventProfile && eventProfile.id ? 30 * 1000 : null);

	if (loadingEvent) {
		return (<div className="flex flex-grow justify-center items-center h-full w-full mt-20 md:mt-64">
			<h1 className="text-bold text-3xl flex items-center m-auto text-gray-500"><span className="spinner h-full w-20 inline-block" />&nbsp;Loading Event...</h1>
		</div>);
	} else if (!eventProfile.id && !loadingEvent) {
		return (<div className="flex flex-grow justify-center items-center h-full w-full mt-20 md:mt-64">
			<h1 className="text-bold text-3xl flex items-center m-auto text-gray-500">Failed to load event...</h1>
		</div>);
	}

	if (eventProfile.isTerminated && (!currentUser || currentUser && currentUser.type !== 'admin')) {
		return <main className="mt-32 sm:mt64">
			<div className="container bg-white mx-auto shadow rounded mx-3 sm:mx-auto text-center overflow-hidden">
				<NavBar hideHeaderLinks={true} />
				<section className="px-12 py-24 flex">
					<h1 className="text-4xl font-medium mx-auto">Event has ended.</h1>
				</section>
			</div>
		</main>
	}

	if (!eventProfile.isActive && mode === 'public') {
		return <main className="mt-32 sm:mt64">
			<div className="container bg-white mx-auto shadow rounded mx-3 sm:mx-auto text-center overflow-hidden">
				<NavBar hideHeaderLinks={true} />
				<section className="px-12 py-24 flex">
					<h1 className="text-4xl font-medium mx-auto">Event is coming soon...</h1>
				</section>
			</div>
		</main>
	}

	if (!displayContents) {
		return (
			<>
				<AdminBar />
				<main className="mt-32 sm:mt64">
					<div className="container bg-white mx-auto shadow-md rounded-md mx-3 sm:mx-auto text-center overflow-hidden">
						<NavBar hideHeaderLinks={true} />
						<section className="p-12 flex justify-center">
							{ protectionType === 'password' ? <AttendeePassword/> : <AttendeeTicket/> }
						</section>
					</div>
				</main>
			</>
		);
	}

	function handleFailedAgeVerification(e) {
		e.preventDefault();
		return "We're sorry, but this event requires a minimum age for all attendees. Please contact the event organizer for more information.";
	}

	if (!hasEventEditAccess && eventProfile.enableAgeVerification && !passedAgeVerification) {
		return <section className="block w-full h-hero bg-black">
			<AgeVerificationModal  acceptableAge={ eventProfile.ageVerification }
														 handleAcceptedAge={ () => setAgeVerification(true) }
														 handleYoungerAge={ handleFailedAgeVerification }
														 pageType="Event" />
		</section>
	}

	return (
		<>
			<PopupAnnouncement />
			<SlideInAnnouncement />
			<Notifications />
			<Header />
			{ children }
			<div id="skip-to-event-home">
				<a href="#content" className="skip-content-button">Skip to event home</a>
			</div>
			<Footer />
		</>
	);
}

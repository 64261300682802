import React, { useEffect } from 'react';
import useHotkeys from "@reecelucas/react-use-hotkeys";

export default function CoverModal({ title, onComplete = () => console.log('no handler passed to close modal'), containsChanges, isSaving = false, saveText = 'Save', onSave = () => console.log('no handler passed to save modal'), children }) {
	useHotkeys('Escape', () => {
		if (!containsChanges || confirm("It seems you've made some changes. If you leave you'll lose them.")) {
			onComplete()
		}
	});

	useEffect(() => {
		document.body.classList.add('overflow-y-hidden');
		return () => document.body.classList.remove('overflow-y-hidden');
	}, []);

	function handleCancel(e) {
		e.preventDefault();
		onComplete();
	}

	return (
		<section className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-white flex flex-col">
			<header className="py-4 sm:py-6 px-6 sm:px-8 flex flex-col sm:flex-row justify-between border-b border-gray-200">
				<div className="flex items-center">
					<h1 className="text-lg font-base capitalize">{ title }</h1>
				</div>
				<form onSubmit={onSave}>
					<div className="flex flex-col-reverse sm:flex-row items-center">
						<a href="#" className="text-gray-400 mx-auto sm:mx-0 mt-2 sm:mt-0 cursor-pointer mr-0 sm:mr-4" onClick={ handleCancel }>Cancel</a>
						{ !isSaving && <button className="btn-hub-green sm:w-auto ml-0 sm:ml-2 mt-2 sm:mt-0 text-sm">{ saveText }</button> }
						{ isSaving && (
							<button disabled={true} className="btn-hub-green sm:w-auto sm:ml-0 mt-2 sm:mt-0 sm:mr-0 flex text-sm items-center cursor-not-allowed">
								<span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span>
							</button>
						)}
					</div>
				</form>
			</header>
			<section className="flex-grow flex h-full overflow-x-hidden overflow-y-auto">
				{ children }
			</section>
		</section>
	);
}
import React, { useContext, useState } from "react";
import { EventContext } from "../../context/event";
import { AttendeeContext } from "../../context/attendee";
import useDeviceDetect from "../../hooks/helper/useDeviceDetect";
import useToggle from "../../hooks/helper/useToggle";

export default function AttendeeTicket() {
	const detectDevice = useDeviceDetect();
	const [ showRegModal, toggleModal ] = useToggle();
	const [ eventProfile ] = useContext(EventContext);
	const [ attendeeProfile, _, checkTicket ] = useContext(AttendeeContext);
	const [ inputTix, setInputTix ] = useState("");
	const hasRegFox = eventProfile.integrations.filter(i => i.type === 'regfox')[0];

	function onFormSubmit(event) {
		event.preventDefault();
		checkTicket(eventProfile.id, inputTix.trim());
	}

	function clickRegisterBtn() {
		if (detectDevice.isMobile()) {
			window.open(hasRegFox.metadata.pageUrl, '_blank').focus();
		} else {
			toggleModal(true);
		}
	}

	const RegisterModal = () => (<div className="fixed z-50 bottom-0 overflow-auto inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">
		<div className="fixed inset-0 transition-opacity cursor-pointer" onClick={ () => toggleModal(false)}><div className="absolute inset-0 bg-gray-500 opacity-75" /></div>
		<div className="bg-white fixed flex flex-col top-10 bottom-10 left-20 right-20 rounded-md sm:overflow-hidden shadow-xl transform transition-all" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<div className="text-2xl w-full text-right text-gray-500 hover:text-gray-700 cursor-pointer font-normal px-6 py-4 leading-none" onClick={ () => toggleModal(false)}>&times;</div>
			<iframe src={hasRegFox.metadata.pageUrl} allowFullScreen="" scrolling="yes" width="100%" height="100%" frameBorder="0" />
		</div>
	</div>);

	return (
		<>
			{ showRegModal && <RegisterModal />}
			<section className="flex flex-col justify-center ">
				<form className="mb-0" onSubmit={onFormSubmit}>
					<p className="font-bold text-3xl text-center capitalize">This event is for ticketed access only</p>
					<p className="pt-3 text-center">Enter your ticket number provided by the event organizer to access this event.</p>
					<div className="flex flex-col sm:w-1/2 sm:mx-auto my-4 text-left">
						<div className="flex justify-between">
							{ hasRegFox ? (
								<label className="block text-xs font-light uppercase leading-normal text-gray-700">Registrant ID</label>
							) : (
								<label className="block text-xs font-light uppercase leading-normal text-gray-700">Ticket Number</label>
							) }
							<label className="block text-xs font-light leading-normal text-gray-700">*Case Sensitive</label>
						</div>
						<input name="ticket" type="text" value={inputTix} onChange={(event) => setInputTix(event.target.value)} className="base-input"/>
					</div>
					{attendeeProfile.error && (<p className="mt-1 text-sm text-red-700 text-center">{attendeeProfile.error}</p>)}
					<button type="submit" className="btn-hub-green inline-block mt-2 mx-auto">Enter Event</button>
					{ hasRegFox && hasRegFox.metadata.pageUrl && (
						<>
							<hr className="my-4"/>
							<button className="bg-hub-green px-4 py-2 text-normal text-white rounded-full" onClick={ clickRegisterBtn }>{ hasRegFox.metadata.ctaLabel || 'Register For Access' }</button>
						</>
					)}
				</form>
			</section>
		</>
	);
}

import React, { useState, createContext, useEffect } from 'react';
import useCallObject from "../hooks/data/useSessionCallObject";
import { logDailyEvent } from "../features/VirtualChat/logUtils";
import { VIRTUAL_USER_LOCAL_STORAGE_KEY } from "./sessionCallObject";
import useLocalStorage from "../hooks/helper/useLocalStorage";

const INITIAL_STATE = [];
const MessagesContext = createContext([{}, () => {}]);

function MessagesProvider({ children }) {
	const [ callObject ] = useCallObject();
	const [ userName ] = useLocalStorage(VIRTUAL_USER_LOCAL_STORAGE_KEY, null);
	const [ state, setState ] = useState(INITIAL_STATE);

	useEffect(() => {
		if (!callObject) {
			return;
		}

		const events = ['joined-meeting', 'app-message', 'participant-joined', 'participant-updated', 'participant-left'];

		function handleNewMeetingState(event) {
			let action = event ? event.action : callObject.meetingState();
			event && logDailyEvent(event);
			switch (action) {
				case "joined-meeting":
					setState(state => ([...state, { message: `${ userName } has entered the session room`, system: true} ]));
					break;
				case 'participant-joined':
					setState(state => ([...state, { message: `${ event.participant.user_name } has entered the session room`, system: true} ]));
					break;
				case 'app-message':
					if (event.data && (!event.data.message || !event.data.type)) {
						return;
					}
					if (event.data.type !== 'message') {
						return;
					}
					let participant = callObject.participants()[event.fromId];
					setState(state => ([...state, { message: event.data.message, name: participant.user_name } ]));
					break;
				default:
					break;
			}
		}

		// Use initial state
		handleNewMeetingState();

		// Listen for changes in state
		for (const event of events) {
			callObject.on(event, handleNewMeetingState);
		}

		// Stop listening for changes in state
		return function cleanup() {
			for (const event of events) {
				callObject.off(event, handleNewMeetingState);
			}
		};
	}, [ callObject, userName ]);


	return (
		<MessagesContext.Provider value={[state, setState]}>
			{ children }
		</MessagesContext.Provider>
	);
}

export { MessagesContext, MessagesProvider, INITIAL_STATE };
import React, {useMemo, useState} from 'react';
import { useForm } from "react-hook-form";
import { format as formatDate, setHours, setMinutes, setSeconds } from 'date-fns';
import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';
import { TimezonesList } from "../../constants";
import TimePicker from "../../components/TimePicker";
import DatePicker from "../../components/DatePicker";
import { MainStageTimeSchema as validationSchema } from '../../schemas';
import useEventData from "../../hooks/data/useEventData";

function getISODateFormat(date) {
	return formatDate((date || new Date()), 'yyyy-MM-dd');
}

function getISOTimeFormat(date) {
	return formatDate((date || new Date()), 'HH:mm');
}

export default function ManageTrackSessionModal({ defaultValues, onSave, onCloseModal, onDelete }) {
	const [ eventProfile ] = useEventData();
	const [ dateError, setDateError ] = useState('');
	const defaultStartTime = useMemo(()=> utcToZonedTime(defaultValues.startTime, eventProfile.timezone), [defaultValues]);
	const defaultEndTime = useMemo(()=> utcToZonedTime(defaultValues.endTime, eventProfile.timezone), [defaultValues]);
	const [ startTime, setStartTime ] = useState({ _date: defaultStartTime, dateLabel: getISODateFormat(defaultStartTime), hourLabel: getISOTimeFormat(defaultStartTime) }); // expected format: dateLabel: 2020-05-28, hourLabel: 13:00
	const [ endTime, setEndTime ] = useState({ _date: defaultEndTime, dateLabel: getISODateFormat(defaultEndTime), hourLabel: getISOTimeFormat(defaultEndTime) }); // expected format: dateLabel: 2020-05-28, hourLabel: 13:00
	const { register, handleSubmit, errors, formState: { dirty, isSubmitting } } = useForm({ defaultValues, validationSchema });

	function submitData(values) {
		return new Promise((resolve, reject) => {
			// build up times for object...
			let submittedStartTime = zonedTimeToUtc(startTime._date, eventProfile.timezone);
			let submittedEndTime = zonedTimeToUtc(endTime._date, eventProfile.timezone);

			if (submittedEndTime < submittedStartTime) {
				setDateError('End time must be after the start time.');
				return reject();
			}

			resolve();

			let newData = {
				_id: defaultValues._id,
				id: defaultValues.id,
				name: values.name,
				url: values.url,
				description: defaultValues.description,
				cardDescription: values.cardDescription,
				speakers: defaultValues.speakers,
				track: defaultValues.track,
				displayChat: values.displayChat,
				startTime: submittedStartTime.toISOString(),
				endTime: submittedEndTime.toISOString(),
				isMainHallSession: true,
			};
			onSave(newData);
		}).catch(console.log);
	}

	function shouldModalClose() {
		if (!dirty || dirty && confirm("It seems you've enter some info, do you want to close this?")) {
			onCloseModal();
		}
	}

	function handlePickedStartDate(newDate) {
		let date = setSeconds(setMinutes(setHours(newDate, startTime._date.getHours()), startTime._date.getMinutes()), 0);
		setStartTime(state => ({...state, _date: date, dateLabel: getISODateFormat(date)}));
	}

	function handleStartTimeChange({ hour, minute }) {
		let date = startTime._date;
		let newDate = setSeconds(setMinutes(setHours(date, hour), minute), 0);
		setStartTime(state => ({...state, _date: newDate, dateLabel: getISODateFormat(newDate), hourLabel: `${hour}:${minute}`}));
	}

	function handlePickedEndDate(newDate) {
		let date = setSeconds(setMinutes(setHours(newDate, endTime._date.getHours()), endTime._date.getMinutes()), 0);
		setEndTime(state => ({...state, _date: date, dateLabel: getISODateFormat(date)}));
	}

	function handleEndTimeChange({ hour, minute }) {
		let date = endTime._date;
		let newDate = setSeconds(setMinutes(setHours(date, hour), minute), 0);
		setEndTime(state => ({...state, _date: newDate, dateLabel: getISODateFormat(newDate), hourLabel: `${hour}:${minute}`}));
	}

	function shouldDelete(e) {
		e.preventDefault();
		let answer = prompt("Type 'DELETE' to confirm.");
		if (answer === 'DELETE') {
			onDelete();
		}
	}

	return (<div className="fixed z-50 bottom-0 overflow-auto inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">

		<div className="fixed inset-0 transition-opacity">
			<div className="absolute inset-0 bg-gray-500 opacity-75" />
		</div>

		<div className="bg-white rounded-md shadow-xl transform transition-all sm:w-time-modal" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<form onSubmit={handleSubmit(submitData)} className="mb-0">

				<div className="bg-gray-200 rounded-t-md px-4 py-3 sm:px-6">
					<h3 className="text-lg uppercase leading-6 font-normal text-gray-900">{ defaultValues.id ? 'Edit' : 'Add'} Session Stream</h3>
				</div>

				<div className="flex flex-col sm:flex-row ">
					<div className="px-6 py-3 flex-grow">

						<div className="flex flex-col sm:flex-row">
							<div className="w-full mt-0 sm:mt-2">
								<div className="flex justify-between">
									<label htmlFor="name" className="block text-xs font-light uppercase leading-normal text-gray-700">Name</label>
									<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal flex justify-between text-red-700">Required</span>
								</div>
								<input name="name" ref={register} className="mt-1 base-input" placeholder="My Awesome Stream" />
								{ errors.name && <p className="mt-1 text-sm text-red-700">We'll need a name.</p> }
							</div>
						</div>

						<div className="w-full mt-4 mb-2">
							<div className="flex justify-between">
								<label htmlFor="cardDescription" className="block text-xs font-light uppercase leading-normal text-gray-700">Mini Description</label>
								<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal">Max 160 Chars</span>
							</div>
							<textarea name="cardDescription" id="cardDescription" ref={register} maxLength={160} className="mt-1 base-input h-20 resize-none" />
							{ errors.cardDescription && <p className="mt-1 text-sm text-red-700">{ errors.cardDescription.message }</p> }
						</div>

						<div className="flex flex-col sm:flex-row">
							<div className="w-full mt-0 sm:mt-2">
								<div className="flex justify-between">
									<label htmlFor="url" className="block text-xs font-light uppercase leading-normal text-gray-700">YouTube | Twitch | Vimeo | Wistia Url</label>
									<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal text-red-700">Required</span>
								</div>
								<input name="url" ref={register} className="mt-1 base-input" placeholder="http://youtube.com/your-link-here" />
								{ errors.url && <p className="mt-1 text-sm text-red-700">{ errors.url.message }</p> }
								<span className="mt-1 block text-sm font-light uppercase leading-normal text-gray-700">URL must point to video</span>
							</div>
						</div>

						<div className="w-full mt-4">
							<div className="flex items-center">
								<label htmlFor="displayChat" className="block text-xs font-light uppercase leading-normal text-gray-700">Show Chat</label>
								<input type="checkbox" name="displayChat" ref={register} className="mx-2" />
							</div>
							{ errors.displayChat && <p className="mt-1 text-sm text-red-700">{ errors.displayChat.message }</p> }
							<span className="mt-1 block text-xs font-light uppercase leading-5 tracking-normal text-red-700">Chat must be enabled through video platform</span>
						</div>

						<div className="mt-4">
							<div className="flex justify-between">
								<label htmlFor="description" className="block text-xs font-light uppercase leading-normal text-gray-700">When should the livestream appear?</label>
								<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal text-red-700">Required</span>
							</div>

							<p className="mt-2 text-sm font-light leading-normal text-gray-700 truncate">Event Time Zone:<span className="inline text-xs font-italic bg-gray-200 rounded p-1 ml-2 truncate">{TimezonesList[eventProfile.timezone]}</span></p>
							<div className="flex mt-1">
								<div className="w-3/4">
									<label htmlFor="description" className="block text-xs font-light leading-normal text-gray-700">Date</label>
									<DatePicker defaultDate={startTime._date} onPickDate={ handlePickedStartDate } />
								</div>
								<div className="ml-6">
									<label htmlFor="description" className="block text-xs font-light leading-normal text-gray-700">Time</label>
									<TimePicker value={startTime.hourLabel} onSelectedTime={ handleStartTimeChange } />
								</div>
							</div>
						</div>

						<div className="mt-4">
							<div className="flex justify-between">
								<label htmlFor="description" className="block text-xs font-light uppercase leading-normal text-gray-700">When should the livestream end?</label>
								<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal text-red-700">Required</span>
							</div>
							<div className="flex mt-1">
								<div className="w-3/4">
									<label htmlFor="description" className="block text-xs font-light leading-normal text-gray-700">Date</label>
									<DatePicker defaultDate={endTime._date} onPickDate={ handlePickedEndDate } />
									{ dateError && <p className="mt-1 text-sm text-red-700">{ dateError }</p> }
								</div>
								<div className="ml-6">
									<label htmlFor="description" className="block text-xs font-light leading-normal text-gray-700">Time</label>
									<TimePicker value={endTime.hourLabel} onSelectedTime={ handleEndTimeChange } />
								</div>
							</div>
						</div>

					</div>
				</div>
				<div className="px-4 py-3 flex flex-col-reverse sm:flex-row justify-between items-center border-gray-300 border-t">
					{ defaultValues.id ? <a href="#" className="text-red-700 mx-auto sm:mx-0 mt-2 sm:mt-0" onClick={shouldDelete}>Delete Session</a> : <div />}
					<div className="flex items-center">
						<span className="text-gray-400 mr-2 mt-2 sm:mt-0 cursor-pointer" onClick={shouldModalClose}>Cancel</span>
						<button type="submit" disabled={isSubmitting} className="float-right btn-hub-green sm:w-auto sm:ml-2 mt-2 sm:mt-0 mr-0 sm:mr-0 flex items-center">
							{ !isSubmitting && 'Save Stream' }
							{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
						</button>
					</div>
				</div>
			</form>
		</div>

	</div>);
}
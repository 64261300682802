import React, { useEffect, useState, useRef } from 'react';
import cuid from 'cuid';
import axios from 'axios';
import stableSort from 'stable';
import useQueue from "use-queue";
import { getUnixTime } from "date-fns";
import { TwitterPicker } from 'react-color';
import {format as formatTZ, utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';
import { TimezonesList } from "../../../constants";

// components
import Toggle from "../../../components/Toggle";
import BoothSessionModal from "../../BoothSessionModal";
import UploadTarget from "../../../components/UploadTarget";
import ManageAttendees from "../components/ManageAttendees";
import { ArrowDownSVG, ArrowUpSVG, PenSVG } from "../../../components/Icons";
import MainStageTimesModal from "../../MainStageSessionModal";
import EventAdvanceStyles from "../components/AdvanceStylesModal";
import EmbedModal from "../components/EmbedModal";
import HeaderLinkModal from "../components/HeaderLinkModal";
import MangeDisplayAds from "../components/ManageDisplayAds";
import ManageEventBlasts from "../components/ManageEventBlasts";
import TracksModal from "../../TracksModal";
import UISelect, { SelectDropdown } from "../../../components/UISelect";
import { 	EventProfileSchema as DetailsValidationSchema,
					EventProfileAttendeeSchema as AccessValidationSchema,
					EventVirtualSchema as VirtualValidationSchema
				} from "../../../schemas";
import AgeVerificationConfiguration from "../../AgeVerificationFormFields";

// hooks
import { useForm } from "react-hook-form";
import {useHistory} from 'react-router-dom';
import useConfig from "../../../hooks/app/useConfig";
import useToggle from "../../../hooks/helper/useToggle";
import useEventData from "../../../hooks/data/useEventData";
import useToggableElm from "../../../hooks/helper/useToggableElm";
import useCurrentUser from "../../../hooks/app/useCurrentUser";
import usePermissions from "../../../hooks/app/usePermissions";
import useDeviceDetect from "../../../hooks/helper/useDeviceDetect";
import useNotifications from "../../../hooks/app/useNotifications";
import {useCopyToClipboard} from "react-use";

const TimezoneValues = Object.keys(TimezonesList);
const TimezoneText = Object.values(TimezonesList);

function processQueue(promise, next) {
	promise().then(() => {
		next();
	});
}

const MAIN_STAGE_DISPLAY_FORMAT = 'eee L/dd @ hh:mmaaaaa z';
function MainStageTimeCard({ handleEdit, name, startTime, endTime, timezone }) {
	return (<div className="border border-gray-300 hover:border-hub-green hover:bg-gray-50 rounded-md px-2 py-1 mb-2 cursor-pointer" onClick={handleEdit}>
		<p className="flex justify-between text-sm">
			<strong className="block tracking-normal font-normal">{ name }</strong>
		</p>
		<p className="flex justify-between text-sm">
			<span className="text-black font-light">From:</span>
			<span className="text-gray-500">{ formatTZ(utcToZonedTime(startTime, timezone), MAIN_STAGE_DISPLAY_FORMAT, { timeZone: timezone }) }</span>
		</p>
		<p className="flex justify-between text-sm">
			<span className="text-black font-light">To:</span>
			<span className="text-gray-500">{ formatTZ(utcToZonedTime(endTime, timezone), MAIN_STAGE_DISPLAY_FORMAT, { timeZone: timezone })  }</span>
		</p>
	</div>)
}

function BoothSessionTime({ totalSessions, placement, handleEdit, startTime, endTime, timezone }) {
	let borderClasses = totalSessions === 1 ? 'border rounded-md' : placement === 1 && totalSessions > 1 ? 'border-t border-l border-r rounded-t-md' : placement > 1 && placement < totalSessions ? 'border-l border-r border-t hover:border-b' : placement === totalSessions ? 'border rounded-b-md' : 'border';
	return (<div className={`${borderClasses} border-gray-300 px-2 py-1 hover:border-hub-green hover:bg-gray-50 hover:border cursor-pointer`} onClick={handleEdit}>
		<p className="flex justify-between text-sm items-center">
			<span className="text-black font-light uppercase">{ formatTZ(utcToZonedTime(startTime, timezone), 'EEE MM/dd', { timeZone: timezone }) }</span>
			<span className="text-gray-500">{ formatTZ(utcToZonedTime(startTime, timezone), 'h:mmaaaaa', { timeZone: timezone }) }-{ formatTZ(utcToZonedTime(endTime, timezone), 'h:mmaaaaa z', { timeZone: timezone }) }</span>
		</p>
	</div>);
}

function TrackRow({ total, placement, name, color, handleEdit }) {
	let borderClasses = total === 1 ? 'border rounded-md' : placement === 1 && total > 1 ? 'border-t border-l border-r rounded-t-md' : placement > 1 && placement < total ? 'border-l border-r border-t hover:border-b' : placement === total ? 'border rounded-b-md' : 'border';
	return (<div className={`${borderClasses} border-gray-300 px-2 py-1 hover:border-hub-green hover:bg-gray-50 hover:border cursor-pointer`} onClick={handleEdit}>
		<p className="flex justify-between text-sm items-center">
			<span className="text-black font-light truncate">{ name }</span>
			<span className="text-gray-500 flex items-center"><span className="inline-block w-4 h-4 rounded mr-1" style={{backgroundColor: color || '#cccccc'}} />{ color }</span>
		</p>
	</div>);
}

function sortTimes(a, b){
	let aStartTime = getUnixTime(zonedTimeToUtc(a.startTime, a.timezone));
	let bStartTime = getUnixTime(zonedTimeToUtc(b.startTime, b.timezone));
	return aStartTime < bStartTime ? -1 : 1;
}

function HeaderLink({ totalLinks, placement, handleEdit, onPushDown, onPushUp, label, url }) {
	const canMoveUp = placement !== 1;
	const canMoveDown = placement !== totalLinks;

	let borderClasses =
			totalLinks === 1 ? 'border rounded-md' :
			placement === 1 && totalLinks > 1 ? 'border-t border-l border-r rounded-t-md' :
			placement > 1 && placement < totalLinks ? 'border-l border-r border-t hover:border-b' :
			placement === totalLinks ? 'border rounded-b-md' : 'border';

	return (<div className={`${borderClasses} border-gray-300 px-2 py-1 hover:border-hub-green hover:bg-gray-50 hover:border`}>
		<p className="flex flex-no-wrap justify-between text-sm items-center">
			<span className="text-black font-light uppercase w-1/2 truncate">{ label }</span>
			<span className="text-gray-500 flex flex-row-reverse">
				<span onClick={handleEdit}>
					<PenSVG classes={`h-4 w-4 cursor-pointer fill-current text-gray-300 hover:text-gray-900`} />
				</span>
				<span onClick={ () => canMoveDown && onPushDown(placement-1) }>
					<ArrowDownSVG disabled={!canMoveDown} classes={`h-5 w-5 mx-4 cursor-pointer fill-current ${ !canMoveDown ? 'text-gray-100 cursor-not-allowed' : 'text-gray-300'} hover:text-gray-900`} />
				</span>
				<span onClick={ () => canMoveUp && onPushUp(placement-1) }>
					<ArrowUpSVG disabled={!canMoveUp} classes={`h-5 w-5 cursor-pointer fill-current ${ !canMoveUp ? 'text-gray-100 cursor-not-allowed' : 'text-gray-300'} hover:text-gray-900`} />
				</span>
			</span>
		</p>
	</div>);
}

function DetailsPanel({ onSave, addQueue, isActive }) {
	const history = useHistory();
	const { isAdmin }  = usePermissions();
	const [ currentUser ] = useCurrentUser();
	const { apiHost, allowIframeEmbed, allowHeaderNavigationLinks } = useConfig();
	const [ defaultValues, setEventProfile ] = useEventData();
	const [ serverError, setServerError ] = useState('');
	const { register, handleSubmit, getValues, setValue, reset, watch, errors, formState: { dirty, isSubmitting } } = useForm({ defaultValues, validationSchema: DetailsValidationSchema });
	const [ slug, setSlug ] = useState(defaultValues.urlSlug);
	const [ slugSuggestion, setSlugSuggestion ] = useState(null);
	const { append: appendNotification } = useNotifications();
	const eventProfile = getValues();
	const watchedItems = watch();

	const [ showHeaderLinkModal, toggleHeaderLinkModal ] = useToggle();
	const [ headerLinks, setHeaderLinks ] = useState((defaultValues.headerLinks || []).map(l => ({...l, id: l._id})) || []);
	const [ selectedHeaderLinkItem, setHeaderLinksItem ] = useState(null);

	let [ EmbedPageBtn, showEmbedModal, toggleEmbedModal ] = useToggableElm({
		type: ['button', 'button'],
		text: ['Get Embed Main Hall Code'],
		classNames: ['btn-hub-green-solid w-full sm:ml-0 sm:mt-0', 'btn-hub-green-solid w-full sm:ml-0 sm:mt-0']
	});

	useEffect(() => {
		register({ name: 'urlSlug', type: 'custom' });
		register({ name: 'showNameInHeader', type: 'custom' });
	},[]);

	function updateEvent(values) {
		return axios({
			url: `${ apiHost }/village/events/${ defaultValues.urlSlug }`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			},
			data: { ...values }, // we replace on the server so we need to make sure everything is there now.
		});
	}

	function verifySlug() {
		if (slug && slug !== eventProfile.slug) {
			return axios({
				url: `${process.env.API_HOST}/village/events/${	slug	}/verify`,
				method: 'GET',
			});
		}
	}

	function createHeaderLink(e){
		e.preventDefault();
		setHeaderLinksItem({
			label: '',
			url: ''
		});
		toggleHeaderLinkModal(true);
	}

	function editHeaderLink(index) {
		return (e) => {
			e.preventDefault();
			let headerLinkItem = headerLinks[index];
			setHeaderLinksItem({...headerLinkItem});
			toggleHeaderLinkModal(true);
		}
	}

	function saveHeaderLink(headerLink) {
		let newLinks = [...headerLinks];
		if (!!headerLink.id) {
			let linkIndex = headerLinks.map(l => l.id).indexOf(headerLink.id);
			newLinks[linkIndex] = {...headerLink};
		} else {
			newLinks.push({ ...headerLink, id: cuid() });
		}
		setHeaderLinks(newLinks);
		setEventProfile(e => ({...e, headerLinks: newLinks}));
		toggleHeaderLinkModal(false);
		setHeaderLinksItem(null);
	}

	function handleCancelHeaderLink() {
		toggleHeaderLinkModal(false);
		setHeaderLinksItem(null);
	}

	function handleDeleteHeaderLink() {
		let newLinks = headerLinks.filter(l => l.id !== selectedHeaderLinkItem.id);
		setHeaderLinks(newLinks);
		setEventProfile(e => ({...e, headerLinks: newLinks}));
		toggleHeaderLinkModal(false);
		setHeaderLinksItem(null);
	}

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	function pushUp(currentIndex) {
		setHeaderLinks(reorder(
				headerLinks,
				currentIndex,
				(currentIndex - 1)
			)
		);
	}

	function pushDown(currentIndex) {
		setHeaderLinks(reorder(
				headerLinks,
				currentIndex,
				(currentIndex + 1)
			)
		);
	}

	useEffect(() => {
		setSlugSuggestion(null);
		if (slug === defaultValues.urlSlug) {
			setValue('urlSlug', defaultValues.urlSlug);
			return;
		}

		const timer = setTimeout(() => {
			verifySlug().then(() => {
				setValue('urlSlug', slug);
			}).catch(e => {
				if (e.message.includes('500')) {
					setServerError(e.message);
				} else {
					setSlugSuggestion(`It seems ${ slug } is taken, perhaps try ${ e.response.data.slugSuggestion }.`)
				}
			});
		}, 500);
		return () => clearTimeout(timer);
	}, [ slug ]);

	function submitData(values) {
		if (slugSuggestion) {
			return;
		}
		let payload = { ...values, headerLinks};
		return new Promise((resolve, reject) => {
			addQueue(() => Promise.resolve().then(() => updateEvent(payload).then(({ data }) => {
				if (data && data.message || !data) {
					setServerError(data.message);
					window.scrollTo(0, 0);
					appendNotification('alert', "Uh oh. We couldn't save your changes.");
					return reject();
				}
				setEventProfile(profile => ({...profile, ...values}));

				if (defaultValues.urlSlug !== values.urlSlug) {
					history.push(`/${values.urlSlug}`);
				}

				if (typeof onSave === 'function') {
					onSave();
				}

				appendNotification('success', "Success! Your village has been updated.");
				return resolve();
			}).catch(e => {
				setServerError(e.message);
				window.scrollTo(0, 0);
				appendNotification('alert', "Uh oh. We couldn't save your changes.");
				return reject();
			})));
		});
	}

	return (<>
		{ showEmbedModal && <EmbedModal eventSlug={eventProfile.urlSlug} onCloseModal={ () => toggleEmbedModal(false) } /> }
		{ showHeaderLinkModal && <HeaderLinkModal defaultValues={ selectedHeaderLinkItem } onSave={saveHeaderLink} onCloseModal={handleCancelHeaderLink} onDelete={handleDeleteHeaderLink} />}
		{ allowIframeEmbed && <section className="px-6 py-3 bg-gray-100 shadow-inner flex justify-center items-center">
			<EmbedPageBtn />
		</section> }
		<form onSubmit={handleSubmit(submitData)}>
			<section className="px-6 py-4">

				{ serverError && <div className="rounded py-2 px-3 bg-red-50 text-gray-700 text-sm w-full my-2"><strong className="font-medium">Error:</strong>{ serverError }</div> }

				<div className="flex mt-1 mb-3 justify-between">
					<strong className="uppercase tracking-normal text-sm">event details</strong>
					<div className="flex flex-col flex-col-reverse">
						{/*{ allowIframeEmbed && <a href="#" className="inline uppercase text-xs tracking-normal underline text-right cursor-pointer text-hub-green" onClick={ (e) => { e.preventDefault();toggleEmbedModal(true);}}>Embed Page</a> }*/}
						<a href="https://help.eventhub.net/hc/en-us/articles/360048675553-Creating-a-virtual-expo-vendor-village-listing"
							 target="_blank"
							 className="inline uppercase text-xs tracking-normal underline text-right">Need Help?</a>
					</div>
				</div>

				<div className="mt-2">
					<div className="flex justify-between">
						<label htmlFor="name" className="block text-xs font-light uppercase leading-normal text-gray-700">Event Name</label>
						<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal text-red-700">Required</span>
					</div>
					<input name="name" ref={register} className="mt-1 base-input" placeholder="Your Amazing Event!" />
					{ errors.name && <p className="mt-1 text-sm text-red-700">We need to have a name.</p> }
				</div>

				<div className="mt-2">
					<section className="flex justify-between items-center">
						<label className="block text-xs font-light uppercase leading-normal text-gray-700">Show Name in header</label>
						<Toggle isFlipped={ defaultValues.showNameInHeader } onToggle={(val) => setValue('showNameInHeader', val)} />
					</section>
				</div>

				<div className="mt-2">
					<div className="flex justify-between">
						<label htmlFor="urlSlug" className="block text-xs font-light uppercase leading-normal text-gray-700">Custom URL Slug</label>
						<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal">No spaces</span>
					</div>
					<input name="urlSlug" disabled={ !isAdmin && isActive } value={slug} onChange={(event) => setSlug(event.target.value)} className="mt-1 base-input" placeholder="something-spiffy-here" />
					{ !isActive && <p className="mt-1 text-sm text-gray-700">Note: edits generate a new event link</p> }
					{ slugSuggestion && !errors.urlSlug && <p className="mt-1 text-sm text-red-700">{slugSuggestion}</p> }
					{ errors.urlSlug && <p className="mt-1 text-sm text-red-700">{ errors.urlSlug.message }</p> }
				</div>

				{ allowHeaderNavigationLinks && (
					<>
						<div className="flex pt-4 justify-between">
							<strong className="uppercase tracking-normal text-sm">Header Navigation</strong>
						</div>

						<div className="flex justify-between my-2">
							<label htmlFor="showHomepageLinkInHeader" className="block text-xs font-light uppercase leading-normal text-gray-700">Show Homepage Link in header</label>
							<input type="checkbox" id="showHomepageLinkInHeader" name="showHomepageLinkInHeader" ref={register} />
						</div>

						{ watchedItems.showHomepageLinkInHeader && <div className="flex justify-between items-center">
							<label htmlFor="homepageLinkLabel" className="block text-xs uppercase font-light leading-normal text-gray-700 w-1/3 md:1/4">Link Label</label>
							<input name="homepageLinkLabel" id="homepageLinkLabel" ref={register} className="mt-1 base-input" placeholder="Main Hall" />
						</div> }

						{ errors.homepageLinkLabel && <p className="mt-1 text-sm text-red-600">{ errors.homepageLinkLabel.message }</p> }

						<div className="flex justify-between pt-4">
							<strong className="block uppercase tracking-normal text-sm">Header Links</strong>
							{ headerLinks.length > 0 && headerLinks.length < 3 && <a href="#" onClick={ createHeaderLink } className="text-xs leading-5 text-hub-green uppercase tracking-normal hover:underline">add</a> }
						</div>

						<div className="mt-1 mb-2">
							<div className="group flex flex-col">
								{ headerLinks.map((headerLink, index) => <HeaderLink key={index} placement={index+1} totalLinks={headerLinks.length} {...headerLink} handleEdit={editHeaderLink(index)} onPushDown={pushDown} onPushUp={pushUp} />) }
								{ !headerLinks.length && <button onClick={ createHeaderLink } className="w-full py-1 text-sm text-center text-gray-400 hover:text-hub-green border border-gray-300 hover:border-hub-green rounded-md cursor-pointer">Add a Header Link</button> }
							</div>
						</div>
					</>
				)}

				<div className="flex mt-4 mb-3 justify-between">
					<strong className="uppercase tracking-normal text-sm">Social Links</strong>
				</div>

				<div className="mt-2">
					<div className="flex justify-between">
						<label htmlFor="email" className="block text-xs font-light uppercase leading-normal text-gray-700">website url</label>
					</div>
					<input name="websiteUrl" ref={register} className="mt-1 base-input" placeholder="https://eventhub.net/" />
					{ errors.websiteUrl && <p className="mt-1 text-sm text-red-700">{ errors.websiteUrl.message }</p> }
				</div>

				<div className="mt-2">
					<div className="flex justify-between">
						<label htmlFor="twitterUrl" className="block text-xs font-light uppercase leading-normal text-gray-700">twitter url</label>
					</div>
					<input name="twitterUrl" ref={register} className="mt-1 base-input" placeholder="http://twitter.com/eventhuboff" />
					{ errors.twitterUrl && <p className="mt-1 text-sm text-red-700">{ errors.twitterUrl.message }</p> }
				</div>

				<div className="mt-2">
					<div className="flex justify-between">
						<label htmlFor="facebookUrl" className="block text-xs font-light uppercase leading-normal text-gray-700">facebook url</label>
					</div>
					<input name="facebookUrl" ref={register} className="mt-1 base-input" placeholder="http://facebook.com/eventhub" />
					{ errors.facebookUrl && <p className="mt-1 text-sm text-red-700">{ errors.facebookUrl.message }</p> }
				</div>

				<div className="mt-2">
					<div className="flex justify-between">
						<label htmlFor="instagramUrl" className="block text-xs font-light uppercase leading-normal text-gray-700">instagram url</label>
					</div>
					<input name="instagramUrl" ref={register} className="mt-1 base-input" placeholder="http://instagram.com/eventhub" />
					{ errors.instagramUrl && <p className="mt-1 text-sm text-red-700">{ errors.instagramUrl.message }</p> }
				</div>

				<div className="mt-2">
					<div className="flex justify-between">
						<label htmlFor="gaTrackingID" className="block text-xs font-light uppercase leading-normal text-gray-700">google analytics Tracking ID</label>
					</div>
					<input name="gaTrackingID" ref={register} className="mt-1 base-input" placeholder="UA-12345-1" />
					{ errors.gaTrackingID && <p className="mt-1 text-sm text-red-700">{ errors.gaTrackingID.message }</p> }
				</div>

				<div className="mt-10 text-center">
					<button type="submit" disabled={isSubmitting} className="btn-hub-green mt-2 mx-auto flex items-center">
						{ !isSubmitting && 'Save Details' }
						{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
					</button>
					{ dirty && <p className="text-xs leading-5 text-gray-500 uppercase tracking-normal cursor-pointer mt-4" onClick={() => reset(defaultValues)}>Undo Changes</p>}
				</div>

			</section>
		</form>
	</>);

}

function ProgrammingPanel({ onSave, addQueue }) {
	const history = useHistory();
	const [ currentUser ] = useCurrentUser();
	const [ defaultValues, setEventProfile ] = useEventData();
	const { apiHost, allowVirtualBooth, allowDisplayAds, allowTracksSessionsSpeakers, allowAnnouncements } = useConfig();

	const [ showTrackModal, toggleTrackModal ] = useToggle();
	const [ allTracks, setAllTracks ] = useState([]);
	const [ selectedTrackItem, setSelectedTrackItem ] = useState({});

	const [ showMainStageModal, toggleMainStageTimes ] = useToggle();
	const [ mainStageTimes, setMainStageTimes ] = useState((defaultValues.mainStageTimes || []).map(t => ({...t, id: t._id})) || []);
	const [ selectedMainStageItem, setSelectedMainStageItem ] = useState(null);

	const [ showBoothSessionModal, toggleBoothSessionModal ] = useToggle();
	const [ boothSessionTimes, setBoothSessionTimes ] = useState((defaultValues.boothTimes || []).map(t => ({...t, id: t._id})) || []);
	const [ selectedBoothSessionItem, setBoothSessionTimesItem ] = useState(null);

	const [ serverError, setServerError ] = useState('');
	const { register, handleSubmit, reset, errors, watch, formState: { dirty, isSubmitting } } = useForm({
		defaultValues: {
			...defaultValues,
			timezone: defaultValues.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
		},
		validationSchema: VirtualValidationSchema });
	const { append: appendNotification } = useNotifications();
	const watchedItems = watch();

	let [ ManageDisplayAdsToggleLink, showDisplayAds, toggleDisplayAds ] = useToggableElm({
		type: ['button'],
		text: ['Manage Display Ads'],
		classNames: ['btn-hub-green-solid w-full sm:ml-0 sm:mt-0 mb-3']
	});

	let [ ManageEventBlastsToggleLink, showEventBlasts, toggleEventBlasts ] = useToggableElm({
		type: ['button'],
		text: ['Manage Notifications'],
		classNames: ['btn-hub-green-solid w-full sm:ml-0 sm:mt-0 mb-3']
	});

	useEffect(() => {
		axios({
			url: `${ apiHost }/village/events/${ defaultValues.id }/tracks`,
			method: 'GET',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			}
		}).then(({ data: tracks}) => {
			if (tracks)  {
				setAllTracks(tracks);
			}
		}).catch(e => {
			setServerError(`Error trying to pull all tracks`);
		}) ;
	}, [])

	function updateEvent(values) {
		return axios({
			url: `${ apiHost }/village/events/${defaultValues.urlSlug}`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			},
			data: { ...values }, // we replace on the server so we need to make sure everything is there now.
		});
	}

	function submitData(values) {
		let payload = { ...values, mainStageTimes: stableSort(mainStageTimes.map(t => ({...t, timezone: defaultValues.timezone})), sortTimes), boothTimes: stableSort(boothSessionTimes.map(t => ({...t, timezone: defaultValues.timezone})), sortTimes) };
		return new Promise((resolve, reject) => {
			addQueue(() => Promise.resolve().then(() => updateEvent(payload).then(({ data }) => {
				if (data && data.message || !data) {
					setServerError(data.message);
					window.scrollTo(0, 0);
					appendNotification('alert', "Uh oh. We couldn't save your changes.");
					return reject();
				}
				setEventProfile({ ...defaultValues, ...data });

				if (typeof onSave === 'function') {
					onSave();
				}

				appendNotification('success', "Success! Your village has been updated.");
				return resolve();
			}).catch(e => {
				setServerError(e.message);
				window.scrollTo(0, 0);
				appendNotification('alert', "Uh oh. We couldn't save your changes.");
				return reject();
			})));
		});
	}

	function createBoothSessionTime(e){
		e.preventDefault();
		setBoothSessionTimesItem({
			startTime: new Date(),
			endTime: new Date(),
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
		});
		toggleBoothSessionModal(true);
	}

	function editBoothSessionTime(index) {
		return (e) => {
			e.preventDefault();
			let boothSession = boothSessionTimes[index];
			setBoothSessionTimesItem(boothSession);
			toggleBoothSessionModal(true);
		}
	}

	function saveBoothSessionTime(stageTime) {
		if (!!stageTime.id) {
			let timesIndex = boothSessionTimes.map(t => t.id).indexOf(stageTime.id);
			let newTimes = [...boothSessionTimes];
			newTimes[timesIndex] = {...stageTime};
			newTimes = stableSort(newTimes, sortTimes);
			setBoothSessionTimes(newTimes);
		} else {
			let newTimes = [...boothSessionTimes, { ...stageTime, id: cuid() }];
			newTimes = stableSort(newTimes, sortTimes);
			setBoothSessionTimes(newTimes);
		}
		toggleBoothSessionModal(false);
		setBoothSessionTimesItem(null);
	}

	function handleCancelBoothSessionTime() {
		toggleBoothSessionModal(false);
		setBoothSessionTimesItem(null);
	}

	function handleDeleteBoothSessionTime() {
		let timesIndex = boothSessionTimes.map(t => t.id).indexOf(selectedBoothSessionItem.id);
		let newTimes = [...boothSessionTimes];
		newTimes.splice(timesIndex, 1);
		newTimes = stableSort(newTimes, sortTimes);
		setBoothSessionTimes(newTimes);
		toggleBoothSessionModal(false);
		setBoothSessionTimesItem(null);
	}

	function createMainStageTime(e){
		e.preventDefault();
		setSelectedMainStageItem({
			startTime: new Date(),
			endTime: new Date(),
			displayChat: true,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
		});
		toggleMainStageTimes(true);
	}

	function editMainStageTime(index) {
		return (e) => {
			e.preventDefault();
			let stageTime = mainStageTimes[index];
			setSelectedMainStageItem(stageTime);
			toggleMainStageTimes(true);
		}
	}

	function saveMainStageTime(stageTime) {
		if (!!stageTime.id) {
			let timesIndex = mainStageTimes.map(t => t.id).indexOf(stageTime.id);
			let newTimes = [...mainStageTimes];
			newTimes[timesIndex] = {...stageTime};
			setMainStageTimes(stableSort(newTimes, sortTimes));
		} else {
			let newTimes = stableSort([...mainStageTimes, { ...stageTime, id: cuid() }], sortTimes);
			setMainStageTimes(newTimes);
		}
		toggleMainStageTimes(false);
		setSelectedMainStageItem(null);
	}

	function handleCancelMainStageTime() {
		toggleMainStageTimes(false);
		setSelectedMainStageItem(null);
	}

	function handleDeleteMainStageTime() {
		let timesIndex = mainStageTimes.map(t => t.id).indexOf(selectedMainStageItem.id);
		let newTimes = [...mainStageTimes];
		newTimes.splice(timesIndex, 1);
		setMainStageTimes(newTimes);
		toggleMainStageTimes(false);
		setSelectedMainStageItem(null);
	}

	function createTrack(e){
		e.preventDefault();
		setSelectedTrackItem({});
		toggleTrackModal(true);
	}

	function editTrack(index) {
		return (e) => {
			e.preventDefault();
			let track = allTracks[index];
			setSelectedTrackItem(track);
			toggleTrackModal(true);
		}
	}

	function saveTrack(track) {
		let trackIndex = allTracks.map(t => t._id).indexOf(track._id);
		let newTracks = [...allTracks];
		if (trackIndex > -1) {
			newTracks[trackIndex] = {...track};
			setAllTracks(newTracks.sort((a,b) => a.name > b.name));
		} else {
			setAllTracks(newTracks.concat([ track ]).sort((a,b) => a.name > b.name));
		}
		toggleTrackModal(false);
		setSelectedTrackItem(null);
	}

	function handleCancelTrack() {
		toggleTrackModal(false);
		setSelectedTrackItem(null);
	}

	function handleDeleteTrack() {
		let trackIndex = allTracks.map(t => t._id).indexOf(selectedTrackItem._id);
		let newTracks = [...allTracks];
		newTracks.splice(trackIndex, 1);
		setAllTracks(newTracks);
		toggleTrackModal(false);
		setSelectedTrackItem(null);
	}

	function navigateTo(url) {
		return (e) => {
			e.preventDefault();
			history.push(!window.white_label_slug ? `/${defaultValues.urlSlug}/${url}` : `/${url}`);
		}
	}

	return (
		<>
			{ showMainStageModal && <MainStageTimesModal defaultValues={ selectedMainStageItem } onSave={saveMainStageTime} onCloseModal={handleCancelMainStageTime} onDelete={handleDeleteMainStageTime} />}
			{ showBoothSessionModal && <BoothSessionModal defaultValues={ selectedBoothSessionItem } onSave={saveBoothSessionTime} onCloseModal={handleCancelBoothSessionTime} onDelete={handleDeleteBoothSessionTime} />}
			{ showTrackModal && <TracksModal defaultValues={ selectedTrackItem } onSave={saveTrack} onCloseModal={handleCancelTrack} onDelete={handleDeleteTrack} />}
			{ showDisplayAds && <MangeDisplayAds onComplete={() => toggleDisplayAds(false)} />}
			{ showEventBlasts && <ManageEventBlasts onComplete={() => toggleEventBlasts(false)} />}
			{ (allowDisplayAds || allowTracksSessionsSpeakers) && (
				<section className="px-6 py-3 bg-gray-100 shadow-inner flex flex-col justify-center items-center">
					{ allowDisplayAds && <ManageDisplayAdsToggleLink/> }
					{ allowAnnouncements && <ManageEventBlastsToggleLink /> }
					{ allowTracksSessionsSpeakers && <>
						<button onClick={navigateTo('schedule')} className="btn-hub-green-solid w-full sm:ml-0 sm:mt-0 mb-3">Manage Session Schedule</button>
						<button onClick={navigateTo('speakers')} className="btn-hub-green-solid w-full sm:ml-0 sm:mt-0">Manage Speakers</button>
					</> }
				</section>
			) }
			<form onSubmit={handleSubmit(submitData)}>
				<section className="px-6 py-4">

					{ serverError && <div className="rounded py-2 px-3 bg-red-50 text-gray-700 text-sm w-full my-2 mb-3"><strong className="font-medium">Error:</strong>{ serverError }</div> }

					<div className="flex mt-1 mb-3 justify-between">
						<strong className="uppercase tracking-normal text-sm">video conference</strong>
						<a href="https://help.eventhub.net/hc/en-us/articles/360048675553-Creating-a-virtual-expo-vendor-village-listing"
							 target="_blank"
							 className="inline uppercase text-xs tracking-normal underline text-right">Need Help?</a>
					</div>

					<div className="mt-2">
						<div className="flex justify-between">
							<label htmlFor="generalInfoUrl" className="block text-xs font-light uppercase leading-normal text-gray-700">General Info Link</label>
						</div>
						<input name="generalInfoUrl" ref={register} className="mt-1 base-input" placeholder="http://link.to.your.call.here.com" />
						{ errors.generalInfoUrl && <p className="mt-1 text-sm text-red-700">{ errors.generalInfoUrl.message }</p> }
					</div>

					<div className="mt-2">
						<div className="flex justify-between">
							<label htmlFor="exhibitorInfoUrl" className="block text-xs font-light uppercase leading-normal text-gray-700">Booth Services Link</label>
						</div>
						<input name="exhibitorInfoUrl" ref={register} className="mt-1 base-input" placeholder="http://link.to.your.call.here.com" />
						{ errors.exhibitorInfoUrl && <p className="mt-1 text-sm text-red-700">{ errors.exhibitorInfoUrl.message }</p> }
					</div>

					<div className="flex flex-col mt-4">
						<label htmlFor="exhibitorInfoUrl" className="block text-xs font-light uppercase leading-normal text-gray-700">Event Time Zone</label>
						<UISelect options={ TimezoneText.map((timezone, tIndex) => ({text:timezone, value: TimezoneValues[tIndex]})) }
											name="timezone" register={register}  classes="mt-2" />
					</div>

					<div className="flex justify-between mt-4">
						<strong className="block uppercase tracking-normal text-sm">Booth Sessions</strong>
						{ boothSessionTimes.length > 0 && <a href="#" onClick={ createBoothSessionTime } className="text-xs leading-5 text-hub-green uppercase tracking-normal hover:underline">add</a> }
					</div>

					<div className={`flex justify-between my-2 ${ !allowVirtualBooth && 'hidden '}`}>
						<label htmlFor="allowExhibitorsExternalConference" className="block text-xs font-light uppercase leading-normal text-gray-700">Allow External Conferencing</label>
						<input type="checkbox" name="allowExhibitorsExternalConference" ref={register} />
					</div>

					<div className="mt-1 mb-2">
						<div className="flex flex-col">
							{ boothSessionTimes.map((boothSessionTime, index) => <BoothSessionTime key={index} placement={index+1} totalSessions={boothSessionTimes.length} {...boothSessionTime} timezone={watchedItems.timezone} handleEdit={editBoothSessionTime(index)} />) }
							{ !boothSessionTimes.length && <button onClick={ createBoothSessionTime } className="w-full py-1 text-sm text-center text-gray-400 hover:text-hub-green border border-gray-300 hover:border-hub-green rounded-md cursor-pointer">Add a Booth Session Time</button> }
						</div>
					</div>

					<div className="flex justify-between mt-4">
						<strong className="block uppercase tracking-normal text-sm">Main Hall Track</strong>
						{ mainStageTimes.length > 0 && <a href="#" onClick={createMainStageTime} className="text-xs leading-5 text-hub-green uppercase tracking-normal hover:underline">add session</a> }
					</div>

					<div className="mt-1">
						<div className="flex flex-col">
							{ mainStageTimes.map((mainStageTime, index) => <MainStageTimeCard key={index} {...mainStageTime} timezone={watchedItems.timezone} handleEdit={editMainStageTime(index)} />) }
							{ !mainStageTimes.length && <button onClick={ createMainStageTime } className="w-full py-1 text-sm text-center text-gray-400 hover:text-hub-green border border-gray-300 hover:border-hub-green rounded-md cursor-pointer">Add a Main Stage Time</button> }
						</div>
					</div>

					<div className="flex justify-between mt-4">
						<strong className="block uppercase tracking-normal text-sm">Additional Tracks</strong>
						{ allTracks.length > 0 && <a href="#" onClick={ createTrack } className="text-xs leading-5 text-hub-green uppercase tracking-normal hover:underline">add</a> }
					</div>

					<div className="mt-1 mb-2">
						<div className="flex flex-col">
							{ allTracks.map((track, index) => <TrackRow key={index} placement={index+1} total={allTracks.length} {...track} handleEdit={editTrack(index)} />) }
							{ !allTracks.length && <button onClick={ createTrack } className="w-full py-1 text-sm text-center text-gray-400 hover:text-hub-green border border-gray-300 hover:border-hub-green rounded-md cursor-pointer">Add another track</button> }
						</div>
					</div>

					<div className="mt-10 text-center">
						<button type="submit" disabled={ isSubmitting } className="btn-hub-green mt-2 mx-auto flex items-center">
							{ !isSubmitting && 'Save Booth & Session Changes' }
							{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
						</button>
						{ dirty && <p className="text-xs leading-5 text-gray-500 uppercase tracking-normal cursor-pointer mt-4" onClick={ () => reset(defaultValues) }>Undo Changes</p>}
					</div>

				</section>
			</form>
		</>
	);

}

function AccessPanel({ onSave, addQueue }) {
	const eventAgeRef = useRef();
	const [ currentUser ] = useCurrentUser();
	const { apiHost, allowGlobalAgeVerify, allowRegfox } = useConfig();
	const [ defaultValues, setEventProfile ] = useEventData();
	const [ serverError, setServerError ] = useState('');
	const { register, handleSubmit, reset, watch, setValue, errors, formState: { dirty, isSubmitting } } = useForm({ defaultValues, validationSchema: AccessValidationSchema });
	const { append: appendNotification } = useNotifications();
	const [ regFoxIntegration, setRegFoxIntegration ] = useState({ status: null });
	let [ isPasswordVisible, setIsPasswordVisible ] = useToggle();
	const watchedItems = watch();

	useEffect(() => {
		register({ name: 'ageVerification', type: 'custom' });
		register({ name: 'integrationRegFoxFormId', type: 'custom' });
	},[]);

	let [ ManageAttendeeToggleLink, showAttendeeEditor, toggleAttendeeEditor ] = useToggableElm({
		type: ['button'],
		text: ['Manage Attendee Ticket Access'],
		classNames: ['btn-hub-green-solid w-full sm:ml-0 sm:mt-0']
	});

	useEffect(()=> {
		if (!dirty) {
			let regFoxIntegration = defaultValues.integrations ? defaultValues.integrations.filter(i => i.type === 'regfox') : [];
			if (regFoxIntegration.length > 0) {
				console.log('meta', regFoxIntegration[0].metadata)
				setValue('integrationRegFoxApiKey', regFoxIntegration[0].metadata.apikey)
				setValue('integrationRegFoxFormId', regFoxIntegration[0].metadata.formId)
				setValue('integrationRegFoxPageUrl', regFoxIntegration[0].metadata.pageUrl)
				setValue('integrationRegFoxCTALabel', regFoxIntegration[0].metadata.ctaLabel)
			}
		}
	}, [defaultValues]);

	useEffect(() => {
		if (!watchedItems.integrationRegFoxApiKey) {
			return;
		}
		setRegFoxIntegration({ status: 'LOADING' });
		let timer = setTimeout(() => {
			axios({
				url: `${ apiHost }/village/events/${ defaultValues.urlSlug }/integrations/regfox/forms?apikey=${watchedItems.integrationRegFoxApiKey}`,
				method: 'GET',
				withCredentials: true,
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'Authorization': `Bearer ${ currentUser.token }`
				},
			}).then(({data}) => {
				if (data.code === 401) {
					setRegFoxIntegration({ status: 'FAILED' });
					return;
				} else {
					setRegFoxIntegration({
						status: 'SUCCESS',
						options: data.filter(form => form.status !== 3).map((form) => ({ text: form.name, value: form.id}))
					});
				}
			}).catch((err) => {
				console.warn('regfox', err);
			})
		}, 500);
		return () => clearTimeout(timer);
	}, [watchedItems.integrationRegFoxApiKey])

	function updateEvent(values) {
		// our only integration is RegFox...
		let payload = {...values};
		if (values.integrationRegFoxApiKey && values.integrationRegFoxFormId) {
			payload.integrations = [{
				type: 'regfox',
				metadata: {
					apikey: values.integrationRegFoxApiKey,
					formId: values.integrationRegFoxFormId,
					pageUrl: values.integrationRegFoxPageUrl,
					ctaLabel: values.integrationRegFoxCTALabel
				}
			}];
		} else {
			payload.integrations = [];
		}
		return axios({
			url: `${ apiHost }/village/events/${ defaultValues.urlSlug }`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			},
			data: payload, // we replace on the server so we need to make sure everything is there now.
		});
	}

	function submitData(payload) {
		return new Promise((resolve, reject) => {
			addQueue(() => Promise.resolve().then(() => updateEvent(payload).then(({ data }) => {
				if (data && data.message || !data) {
					setServerError(data.message);
					window.scrollTo(0, 0);
					appendNotification('alert', "Uh oh. We couldn't save your changes.");
					return reject();
				}
				setEventProfile({ ...defaultValues, ...data });

				if (typeof onSave === 'function') {
					onSave();
				}

				appendNotification('success', "Success! Your village has been updated.");
				return resolve();
			}).catch(e => {
				setServerError(e.message);
				window.scrollTo(0, 0);
				appendNotification('alert', "Uh oh. We couldn't save your changes.");
				return reject();
			})));
		});
	}

	function handleAgeVerification() {
		let selectedAge = parseInt(eventAgeRef.current.value);
		setValue('ageVerification', selectedAge);
	}

	return (<>
		{ showAttendeeEditor && <ManageAttendees onComplete={ () => toggleAttendeeEditor(false) } />}
		<section className="px-6 py-3 bg-gray-100 shadow-inner flex justify-center items-center">
			<ManageAttendeeToggleLink />
		</section>
		<form onSubmit={handleSubmit(submitData)}>
			<section className="px-6 py-4">

				{ serverError && <div className="rounded py-2 px-3 bg-red-50 text-gray-700 text-sm w-full my-2"><strong className="font-medium">Error:</strong>{ serverError }</div> }

				<div className="flex mt-1 mb-3 justify-between">
					<strong className="uppercase tracking-normal text-sm">Attendees</strong>
					<a href="https://help.eventhub.net/hc/en-us/articles/360057399473-Ticket-Accessed-Events"
						 target="_blank"
						 className="inline uppercase text-xs tracking-normal underline text-right">Need Help?</a>
				</div>

				<div className="mt-2">
					<div className="flex justify-between">
						<label htmlFor="attendeePassword" className="block text-xs font-light uppercase leading-normal text-gray-700">Global Event Access password</label>
						<a href='#' className="text-xs leading-5 text-hub-green uppercase tracking-normal hover:underline" onClick={(e) => { e.preventDefault(); setIsPasswordVisible(!isPasswordVisible) }}>{isPasswordVisible ? "hide" : "show"}</a>
					</div>
					<input type={isPasswordVisible ? "text" : "password"} name="attendeePassword" ref={register} className="mt-1 inline-block base-input " />
					{ errors.attendeePassword && <p className="mt-1 text-sm text-red-700">{ errors.attendeePassword.message }</p> }
				</div>

				<div className="mt-3">
					<div className="flex justify-between">
						<label htmlFor="onlyAllowTicketHolders" className="block text-xs font-light uppercase leading-normal text-gray-700">Only Allow Ticket Holders</label>
						<input type="checkbox" id="onlyAllowTicketHolders" name="onlyAllowTicketHolders" ref={register} className="mt-1 h-4 w-4" />
					</div>
				</div>

				<div className="mt-2">
					<div className="flex justify-between">
						<label htmlFor="attendeeMaxAccessUsage" className="block text-xs font-light uppercase leading-normal text-gray-700">Max access limit per attendee ticket</label>
					</div>
					<input type="number" name="attendeeMaxAccessUsage" ref={register} className="mt-1 inline-block base-input " />
					{ errors.attendeeMaxAccessUsage && <p className="mt-1 text-sm text-red-700">{ errors.attendeeMaxAccessUsage.message }</p> }
				</div>

				{ allowGlobalAgeVerify && <AgeVerificationConfiguration
					enable={ watchedItems.enableAgeVerification }
					age={ watchedItems.ageVerification }
					onSelectAge={ handleAgeVerification }
					ref={ { register: register, ageSelectRef: eventAgeRef } }
					checkBoxName="enableAgeVerification"
					dropDownName="ageVerification"
				/> }

				{ allowRegfox && (
					<>
						<div className="flex mt-2 justify-between">
							<strong className="uppercase tracking-normal text-sm">RegFox Ticketing</strong>
							<a href="https://help.eventhub.net/hc/en-us/articles/360058760494"
								 target="_blank"
								 className="inline uppercase text-xs tracking-normal underline text-right">Need Help?</a>
						</div>

						<div className="mt-2">
							<div className="flex justify-between">
								<label htmlFor="integrationRegFoxApiKey" className="block text-xs font-light uppercase leading-normal text-gray-700">RegFox Api Key</label>
							</div>
							<input type="text" name="integrationRegFoxApiKey" ref={register} className="mt-1 inline-block base-input" />
						</div>

						{ watchedItems.integrationRegFoxApiKey && regFoxIntegration.status === 'FAILED' && <div className="rounded py-2 px-3 bg-red-50 text-gray-700 text-sm w-full my-2"><strong className="font-medium">Error:</strong>There seems to be a problem with your RegFox API Key</div> }
						{ watchedItems.integrationRegFoxApiKey && regFoxIntegration.status === 'SUCCESS' && regFoxIntegration.options && !regFoxIntegration.options.length && <div className="rounded py-2 px-3 bg-yellow-50 text-gray-700 text-sm w-full my-2"><strong className="font-medium">Warning:</strong>To successfully add the RegFox API integration you need to create at least one page in their interface first.</div> }
						{ watchedItems.integrationRegFoxApiKey && regFoxIntegration.status === 'LOADING' && !regFoxIntegration.options && <div className="rounded py-2 px-3 bg-gray-50 text-gray-700 text-sm w-full my-2 w-full text-center">Loading Data From RegFox</div> }

						{ watchedItems.integrationRegFoxApiKey && regFoxIntegration.status === 'SUCCESS' && regFoxIntegration.options && regFoxIntegration.options.length > 0 && (
							<div className="flex flex-col mt-4">
								<label htmlFor="integrationRegFoxFormId" className="block text-xs font-light uppercase leading-normal text-gray-700">RegFox Page</label>
								<SelectDropdown options={ regFoxIntegration.options } name="integrationRegFoxFormId" value={watchedItems.integrationRegFoxFormId} onSelection={(val) => setValue('integrationRegFoxFormId', val)}  classes="mt-2" />
							</div>
						) }

						{ watchedItems.integrationRegFoxApiKey && watchedItems.integrationRegFoxFormId && <WebhookCopier customUrl={`${ apiHost }/api/village/events/${ defaultValues.id }/integrations/regfox/webhook`} /> }

							<div className="mt-2">
								<div className="flex justify-between">
									<label htmlFor="integrationRegFoxPageUrl" className="block text-xs font-light uppercase leading-normal text-gray-700">RegFox Public Page URL</label>
								</div>
								<input type="text" name="integrationRegFoxPageUrl" id="integrationRegFoxPageUrl" ref={register} className="mt-1 inline-block base-input" />
							</div>

							<div className="mt-2">
								<div className="flex justify-between">
									<label htmlFor="integrationRegFoxCTALabel" className="block text-xs font-light uppercase leading-normal text-gray-700">Registration CTA Label</label>
								</div>
								<input type="text" name="integrationRegFoxCTALabel" id="integrationRegFoxCTALabel" ref={register} className="mt-1 inline-block base-input" placeholder={"Register For Access"} />
							</div>
					</>
				) }

				<div className="mt-10 text-center">
					<button type="submit" disabled={isSubmitting} className="btn-hub-green mt-2 mx-auto flex items-center">
						{ !isSubmitting && 'Save Details' }
						{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
					</button>
					{ dirty && <p className="text-xs leading-5 text-gray-500 uppercase tracking-normal cursor-pointer mt-4" onClick={() => reset(defaultValues)}>Undo Changes</p>}
				</div>

			</section>
		</form>
	</>);

}

function WebhookCopier({customUrl}) {

	const [ isCopied, setIsCopied ] = useState(false);
	const [ _, copyToClipboard ] = useCopyToClipboard();

	function handleCopy(e) {
		e.preventDefault();

		copyToClipboard(customUrl);

		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 2 * 1000);
	}

	return (
		<div className="rounded py-2 px-3 bg-gray-50 text-gray-700 text-sm w-full my-2 w-full">
			<div className="mb-2 flex justify-between">
				<span>Webhook Url:</span>
				<a href="https://help.eventhub.net/hc/en-us/articles/360058760494#h_01ES7KPCH26YGMEDCCHR1T9CC6" target="_blank"
				   className="inline uppercase text-xs tracking-normal underline text-right">Need Help?</a>
			</div>
			<input className="w-full font-mono font-bold text-xs p-2 mb-2 bg-white rounded border border-gray-200" readOnly={true} value={customUrl} />
			{ !isCopied && <a href="#" onClick={ handleCopy } className="block text-hub-green underline text-sm w-full text-center">Click to Copy Webhook Url</a> }
			{ isCopied && <div className="text-hub-green underline text-sm w-full text-center">Copied!</div> }
		</div>
	)
}

function DesignPanel({ onSave, addQueue }) {
	const [ currentUser ] = useCurrentUser();
	const { apiHost } = useConfig();
	const [ defaultValues, setEventProfile ] = useEventData();
	const [ showAdvanceStyles, toggleAdvanceStyleModal ] = useToggle();

	const [ showColorPicker, togglePicker ] = useToggle();
	const [ serverError, setServerError ] = useState('');
	const [ color, setColor ] = useState(defaultValues.brandColor);
	const { append: appendNotification } = useNotifications();

	const { register, handleSubmit, getValues, setValue, watch, reset, formState: { dirty, isSubmitting } } = useForm({ defaultValues });
	let watchedItems = watch(['headerLogo', 'coverImage', 'enabledAdvanceStyles']); // this is only here to ensure images in upload target stay current.

	const hasAdvanceStylesEnabled = defaultValues.enabledAdvanceStyles;

	useEffect(() => {
		register({ name: 'brandColor', type: 'custom' }, { required: true });
		register({ name: 'headerLogo', type: 'custom' });
		register({ name: 'coverImage', type: 'custom' });
	},[]);

	function updateEvent(values) {
		return axios({
			url: `${ apiHost }/village/events/${defaultValues.urlSlug}`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			},
			data: { ...values }, // we replace on the server so we need to make sure everything is there now.
		});
	}

	function submitData(values) {
		let payload = { ...values };
		return new Promise((resolve, reject) => {
			addQueue(() => Promise.resolve().then(() => updateEvent(payload).then(({ data }) => {
				if (data && data.message || !data) {
					setServerError(data.message);
					window.scrollTo(0, 0);
					appendNotification('alert', "Uh oh. We couldn't save your changes.");
					return reject();
				}
				setEventProfile({ ...defaultValues, ...data });

				if (typeof onSave === 'function') {
					onSave();
				}

				appendNotification('success', "Success! Your changes have been saved.");
				return resolve();
			}).catch(e => {
				setServerError(e.message);
				window.scrollTo(0, 0);
				appendNotification('alert', "Uh oh. We couldn't save your changes.");
				return reject();
			})));
		});
	}

	function setUploadFile(name, url) {
		setValue(name, url);
	}

	function deleteUploadedImage(name) {
		return (e) => {
			e.preventDefault();
			setValue(name, "");
		}
	}

	function handleColorChange({ hex }) {
		setColor(hex);
		setValue('brandColor', hex);
	}

	return (
		<>
			{ showAdvanceStyles && <EventAdvanceStyles onComplete={ () => toggleAdvanceStyleModal(false) } /> }

			{ hasAdvanceStylesEnabled && (
				<section className="px-6 py-4">
					<div className="mt-1">
						<div className="flex flex-col">
							<a href="#"
								 onClick={ (e) => { e.preventDefault(); toggleAdvanceStyleModal(true); }}
								 className="btn-hub-green-solid w-full flex justify-center items-center font-normal cursor-pointer">Manage Advanced Styles</a>
							{/*<a href='#' className="text-xs leading-5 text-gray-400 focus:outline-none uppercase tracking-normal hover:underline mt-3 text-center" onClick={ toggleAdvanceStyles }>Revert to Basic Styles</a>*/}
						</div>
					</div>
				</section>
			) }

			{ !hasAdvanceStylesEnabled && (<form onSubmit={handleSubmit(submitData)}>
				<section className="px-6 py-4">

					{ serverError && <div className="rounded py-2 px-3 bg-red-50 text-gray-700 text-sm w-full my-2"><strong className="font-medium">Error:</strong>{ serverError }</div> }

					<div className="flex mt-1 mb-3 justify-between">
						<strong className="uppercase tracking-normal text-sm">Basic Styles</strong>
						<a href="https://help.eventhub.net/hc/en-us/articles/360048675553-Creating-a-virtual-expo-vendor-village-listing"
							 target="_blank"
							 className="inline uppercase text-xs tracking-normal underline text-right">Need Help?</a>
					</div>

					<div className="mt-2">
						<label htmlFor="headerLogo" className="block text-xs font-light uppercase leading-normal text-gray-700">Header Logo</label>
						<div className="flex justify-between">
							<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal">(max height 100px)</span>
							<button className="block text-xs font-light uppercase leading-normal text-red-700 hover:underline" onClick={ deleteUploadedImage('headerLogo') }>Remove</button>
						</div>
						<UploadTarget folder={`events/${defaultValues.id}/village/event-images/`}
													name="headerLogo"
													image={ watchedItems.headerLogo }
													onChange={setUploadFile} />
					</div>

					<div className="mt-2">
						<label htmlFor="coverImage" className="block text-xs font-light uppercase leading-normal text-gray-700">Cover image</label>
						<div className="flex justify-between">
							<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal">(1600px x 500px)</span>
							<button className="block text-xs font-light uppercase leading-normal text-red-700 hover:underline" onClick={ deleteUploadedImage('coverImage') }>Remove</button>
						</div>
						<UploadTarget folder={`events/${defaultValues.id}/village/event-images/`}
													name="coverImage"
													image={ watchedItems.coverImage }
													enableCropping={true}
													aspectRatio={16/5}
													finalWidth={ 1600 }
													finalHeight={ 500 }
													onChange={setUploadFile} />
					</div>

					<div className="mt-3">
						<div className="flex justify-between">
							<label htmlFor="color" className="block text-xs font-light uppercase leading-normal text-gray-700">Brand Color</label>
							<div className="text-xs leading-5 text-gray-500 uppercase tracking-normal">
								{ showColorPicker && <span className='text-xs leading-2 text-gray-500 uppercase tracking-normal inline mr-3 cursor-pointer'
																					 onClick={() => togglePicker(false)}>close</span>}
								<div className="rounded-sm h-4 w-12 border-black border-solid border relative cursor-pointer inline-block"
										 style={{backgroundColor: color}}
										 onClick={() => togglePicker(!showColorPicker)} />
								<div className="absolute right-0 mt-2 px-2">
									{ showColorPicker && <TwitterPicker triangle="hide" color={color} onChange={handleColorChange} />}
								</div>
							</div>
						</div>
					</div>

					<div className="mt-10 text-center">
						<button type="submit" disabled={isSubmitting} className="btn-hub-green mt-2 mx-auto flex items-center">
							{ !isSubmitting && 'Save Design Changes' }
							{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
						</button>
						{ dirty && <p className="text-xs leading-5 text-gray-500 uppercase tracking-normal cursor-pointer mt-4" onClick={() => reset(defaultValues)}>Undo Changes</p>}
					</div>

					{/*<div className="bg-yellow-50 rounded p-3 justify-center text-center mt-6">*/}
					{/*	<p className="text-gray-900 text-sm mx-auto mb-3">To get access to set more colors, choose fonts, and apply custom styles with CSS.</p>*/}
					{/*	<a href='#' onClick={ toggleAdvanceStyles } className="btn-hub-green w-full bg-white">Enable Advance Styles</a>*/}
					{/*</div>*/}

				</section>
			</form>) }

		</>
	);

}

export default function EventEditColumn({ onSave }) {
	const detectDevice = useDeviceDetect();
	const [ currentUser ] = useCurrentUser();
	const [ _, addQueue ] = useQueue(processQueue);
	const { apiHost, allowTicketAccess } = useConfig();
	const [ eventProfile, setEventProfile ] = useEventData();
	const [ showAdvanceStyles, toggleAdvanceStyleModal ] = useToggle();
	const [ activeTab, setActivePanel ] = useState(window.location.hash.replace('#edit-', '') || 'details');

	const [ activatingProfile, setActivatingProfile ] = useToggle();
	const { append: appendNotification } = useNotifications();

	function updateEvent(values) {
		return axios({
			url: `${ apiHost }/village/events/${eventProfile.urlSlug}`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			},
			data: { ...values }, // we replace on the server so we need to make sure everything is there now.
		});
	}

	function handleActivateToggle(isActive) {
		setActivatingProfile(true);
		return new Promise((resolve, reject) => {
			addQueue(() => Promise.resolve().then(() => updateEvent({ isActive }).then(({ data }) => {
				if (data && data.message || !data) {
					appendNotification('alert', `Uh oh. We couldn't ${isActive ? 'activate' : 'deactivate'} your event village.`);
					return reject();
				}

				setEventProfile((state) => ({ ...state, isActive }));
				setActivatingProfile(false);
				if (isActive) {
					appendNotification('success', "Your profile is now active.")
				} else {
					appendNotification('note', "Your profile has been deactivated.")
				}

				return resolve();
			}).catch(e => {
				appendNotification('alert', `Uh oh. We couldn't ${isActive ? 'activate' : 'deactivate'} your event village.`);
				if (e.message) {
					appendNotification('alert', `Server responded with: ${ e.message }`);
				}
				return reject();
			})));
		});
	}

	function handleChangeTab(tab) {
		return (e) => {
			e?.preventDefault();
			if (activeTab !== tab) {
				setActivePanel(tab);
			}
		}
	}

	let activeTabClass = 'text-gray-900 border-b-2 border-blue-400';
	let inactiveTabClass = 'text-gray-500 hover:text-gray-900 border-b-2 border-gray-300 hover:border-hub-green cursor-pointer';

	return (<>
		{ showAdvanceStyles && <EventAdvanceStyles onComplete={ () => toggleAdvanceStyleModal(false) } /> }
		<aside className={`${detectDevice.isMobile() && 'fixed top-0 right-0 bottom-0 left-0 border-8 border-black border-opacity-50 z-50 shadow overflow-auto'} sm:w-4/12 xl:w-3/12 2xl:2/12 min-w-1/3 bg-white shadow-inner pb-20 sm:pb-0`}>
			<section className="px-6 py-4 bg-gray-800 shadow-inner flex justify-between items-center sm:hidden" onClick={(e) => {e.preventDefault();onSave();}}>
				<label className="text-white leading-tight uppercase text-sm mx-auto cursor-pointer">Cancel Edit</label>
			</section>
			<section className="px-6 py-4 bg-gray-500 shadow-inner flex justify-between items-center">
				<label className="text-white leading-tight uppercase text-sm">Publish Event</label>
				<Toggle isFlipped={ eventProfile.isActive } isLoading={activatingProfile} onToggle={handleActivateToggle} />
			</section>

			<nav className="flex justify-between mt-2">
				<a href="#" onClick={ handleChangeTab('details') } className={`uppercase w-full tracking-normal text-sm text-center outline-none ${ activeTab === 'details' ? activeTabClass : inactiveTabClass}`}>Info</a>
				<a href="#" onClick={ handleChangeTab('programming') } className={`uppercase w-full tracking-normal text-sm text-center outline-none ${ activeTab === 'programming' ? activeTabClass : inactiveTabClass}`}>Program</a>
				<a href="#" onClick={ handleChangeTab('design') } className={`uppercase w-full tracking-normal text-sm text-center outline-none ${ activeTab === 'design' ? activeTabClass : inactiveTabClass}`}>design</a>
				{ allowTicketAccess && <a href="#" onClick={ handleChangeTab('access') } className={`uppercase w-full tracking-normal text-sm text-center outline-none ${ activeTab === 'access' ? activeTabClass : inactiveTabClass}`}>Access</a> }
			</nav>

			{ activeTab === 'details' && <DetailsPanel onSave={ onSave } addQueue={ addQueue } isActive={eventProfile.isActive} />}
			{ activeTab === 'design' && <DesignPanel onSave={ onSave } addQueue={ addQueue } />}
			{ activeTab === 'programming' && <ProgrammingPanel onSave={ onSave } addQueue={ addQueue } />}
			{ activeTab === 'access' && allowTicketAccess && <AccessPanel onSave={ onSave } addQueue={ addQueue } />}

		</aside>
	</>);
}

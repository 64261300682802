import React, { useState, useEffect, useCallback, createElement, useRef, useMemo } from 'react';

export default function useToggableElm(options) {
	const [ toggleState, setToggleState ] = useState(options.initial_state || false);
	const previousToggleState = useRef(toggleState);
	const optionIndex = useMemo(() => !toggleState ? 0 : 1, [toggleState]);

	const handleClick = useCallback((e) => {
		e?.preventDefault();
		setToggleState(!toggleState);
		previousToggleState.current = !toggleState;
		options?.callbacks && options?.callbacks[optionIndex] && options?.callbacks[optionIndex]();
	}, [ toggleState, options.callbacks ]);

	useEffect(() => {
		// if the toggle state differs, then call the function change.
		if (previousToggleState.current !== toggleState) {
			options?.callbacks && options?.callbacks[optionIndex] && options?.callbacks[optionIndex]();
			previousToggleState.current = toggleState;
		}
	}, [ toggleState ]);

	const ToggleElm = useMemo(() => options.type[optionIndex] ? createElement(
		options.type[optionIndex] || options.type[0],
		{
			className: options.classNames[optionIndex]  || options.classNames[0],
			onClick: handleClick,
			...(options.type[optionIndex] === 'a' && { 'href': '#' })
		},
		options.text[optionIndex] || options.text[0]
	) : null, [options, toggleState]);

	return Object.assign([
		() => ToggleElm,
		toggleState,
		setToggleState
	], {
		ToggleElm: () => ToggleElm,
		toggleState,
		setToggleState
	});
}
import React, {useState, createContext, useEffect, useContext} from 'react';
import md5 from '../utils/md5';
import useEventData from "../hooks/data/useEventData";
import useConfig from "../hooks/app/useConfig";

const INITIAL_STATE = {
	id: null,
	isPublished: false,
	isActive: false,
	isFeatured: false,
	isPendingPublish: false,
	name: '',
	description: '',
	logo: null,
	coverImage: null,
	conferenceUrl: null,
	boothPersonName: null,
	accessTicketNumber: null,
	boothPersonTitle: null,
	boothPersonImage: null,
	websiteUrl: null,
	coverVideoUrl: null,
	facebookUrl: null,
	instagramUrl: null,
	twitterUrl: null,
	displayItems: [],
	boothPageEnableAgeVerification: false,
	boothPageAgeVerification: 18,
	virtualBoothOptIn: false,
	virtualBoothEnableAgeVerification: false,
	virtualBoothAgeVerification: 18,
	virtualBoothRoom: null,
	virtualBoothEnableRecording: false,
	virtualBoothEnableChat: true,
	virtualBoothOwnerVideoOn: true,
	virtualBoothOwnerSoundOn: true,
	virtualBoothOwnerScreenShare: true,
	virtualBoothParticipantsAllowed: true,
	virtualBoothParticipantLimit: null,
	virtualBoothParticipantScreenShare: false,
	virtualBoothParticipantAllowVideo: true,
	virtualBoothParticipantStartVideo: false,
	virtualBoothParticipantAllowSound: true,
	virtualBoothParticipantStartSound: false
};
const ExhibitorContext = createContext([{}, () => {}]);

function ExhibitorProvider(props) {
	const { allowVirtualBooth } = useConfig();
	const [ eventProfile ] = useEventData();
	const [state, setState ] = useState(INITIAL_STATE);

	function processNewState(newState) {
		if (!newState) {
			setState(INITIAL_STATE);
		} else {
			newState.virtualBoothRoom = md5(`${eventProfile.urlSlug}/${newState.urlSlug}`);
			if (!allowVirtualBooth) {
				newState.virtualBoothOptIn = false;
			}
			setState(state => ({...state, ...newState}));
		}
	}

	return (
		<ExhibitorContext.Provider value={[state, processNewState]}>
			{props.children}
		</ExhibitorContext.Provider>
	);
}

export { ExhibitorContext, ExhibitorProvider, INITIAL_STATE };
import React, { useRef, useState } from 'react';
import CropModal from "../CropModal";
import axios from 'axios';
import cuid from 'cuid';
import useConfig from "../../hooks/app/useConfig";

function ImageSize(file) {
	const _URL = window.URL || window.webkitURL;
	return new Promise((resolve) => {
		const img = document.createElement('img');
		img.addEventListener('load', (e) => {
			const { naturalWidth, naturalHeight } = e.target;
			resolve({ width: naturalWidth, height: naturalHeight});
		});
		img.src = _URL.createObjectURL(file);
	});
}

export default function UploadTarget({ buttonMode, buttonClasses, buttonText, enableCropping, aspectRatio, finalWidth, finalHeight, circularCrop, cropPreviewClasses, validSizes, folder, name, image, classes, imageClasses, onChange, onSize }) {
	const { apiHost } = useConfig();
	const uploadInputRef = useRef(null);
	const [ file, setFile ] = useState(null);
	const [ uploadImage, setUploadImage ] = useState(null);
	const [ isUploading, setUploadStatus ] = useState(false);
	const [ showCropModal, toggleCropModal ] = useState(false);

	function handleCropSave(data) {
		if (!file) {
			return;
		}
		signAndUploadFile(file, data);
	}

	function signAndUploadFile(file, payload) {
		setUploadStatus(true);
		axios.get(`${apiHost}/sign_s3?file_name=${file.name}&file_type=${file.type}`)
			.then(function (result) {
				let signedRequest = result.data.signed_request.replace('s3.amazonaws.com', 's3-accelerate.amazonaws.com');
				return axios.put(signedRequest, payload, {
					headers: {
						'Content-Type': file.type,
						'x-amz-acl': 'public-read'
					}
				});
			})
			.then(function (result) {
				setUploadStatus(false);
				if (enableCropping) {
					toggleCropModal(false)
				}
				if (onChange && file) {
					onChange(name, `https://eventlocker.s3.amazonaws.com/${file.name}`);
				}
			})
			.catch(function (err) {
				console.log('failed upload');
				console.log(err);
			});
	}

	function onSelectFile(e) {
		if (e.target.files && e.target.files.length > 0) {
			let file = e.target.files[0];
			let imageId = cuid();
			let ext = file.name.split('.').pop();
			let newFileName = `${folder}${imageId}.${ext}`;

			if (!enableCropping && validSizes) {
				ImageSize(file).then((size) => {
					let match = validSizes.find((s) => s.width === size.width && s.height === size.height);
					if (!match) {
						return alert('The image you uploaded does not meet the approved sizes.');
					}
					if (onSize) {
						onSize(size);
					}
					signAndUploadFile({ name: newFileName, type: file.type }, file);
				})
			} else if (!enableCropping && !validSizes) {
				signAndUploadFile({ name: newFileName, type: file.type }, file);
			} else {
				const reader = new FileReader();
				reader.addEventListener('load', function() {
					setFile({ name: newFileName, type: file.type });
					setUploadImage(reader.result);
					toggleCropModal(true);
				});
				reader.readAsDataURL(file);
			}
		}
	}

	if (buttonMode) {
		return (
			<>
				{ showCropModal && <CropModal srcImg={ uploadImage }
																			aspectRatio={aspectRatio}
																			finalWidth={finalWidth} finalHeight={finalHeight}
																			circularCrop={circularCrop}
																			cropPreviewClasses={cropPreviewClasses}
																			onSaveCrop={handleCropSave}
																			onCloseModal={() => toggleCropModal(false)} /> }
				<input type="file"
							 name={name}
							 ref={uploadInputRef}
							 className="hidden"
							 accept="image/png, image/jpeg, image/jpg"
							 onChange={ onSelectFile } />
				{!isUploading && <button onClick={() => uploadInputRef.current.click()} className={buttonClasses}>{buttonText}</button> }
				{isUploading && <button className={buttonClasses}><span className="spinner h-5 w-5 inline-block mr-3" />Saving...</button>}
			</>
		)
	}

	return (<div className={`upload-box ${classes}`}>
		{ showCropModal && <CropModal srcImg={ uploadImage }
																	aspectRatio={aspectRatio}
																	finalWidth={finalWidth} finalHeight={finalHeight}
																	circularCrop={circularCrop}
																	cropPreviewClasses={cropPreviewClasses}
																	onSaveCrop={handleCropSave}
																	onCloseModal={() => toggleCropModal(false)} /> }
		<input type="file"
					 name={name}
					 ref={uploadInputRef}
					 className="hidden"
					 accept="image/png, image/jpeg, image/jpg"
					 onChange={ onSelectFile } />
		<div className="upload-cta" onClick={ () => uploadInputRef.current.click() }>Click to Upload</div>
		{ isUploading && <section className="absolute top-0 left-0 right-0">
			<div className="w-full overflow-hidden">
				<div className="w-1/2 inline-block relative upload-waiting" />
			</div>
		</section> }
		{ image && image !== '' && <img src={ image } className={imageClasses || 'h-full w-auto mx-auto'} alt="Upload Image"/>}
	</div>);
};
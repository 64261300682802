import React, { useEffect, useState, useMemo, useRef } from 'react';
import cuid from 'cuid';
import axios from 'axios';
import useQueue from "use-queue";
import {format as formatDate, getHours, getMinutes, setHours, setMinutes, setSeconds, isBefore} from "date-fns";
import { TimezonesList } from "../../../constants";
import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';

const SLOT_TYPES = {
	'embed': 'Video Content',
	'conference-link': 'External Conference URL',
	'video-chat': 'Live Chat',
	'video-chat-presentation': 'Live Presentation',
	'video-chat-discussion': 'Live Discussion',
};

// components
import Toggle from "../../../components/Toggle";
import {ArrowDownSVG, ArrowUpSVG, ArrowRightSVG, ArrowLeftSVG, CloseSVG, PenSVG} from "../../../components/Icons";
import EventAdvanceStyles from "../components/AdvanceStylesModal";
import { SessionSchema } from "../../../schemas";

// hooks
import { useForm } from "react-hook-form";
import useConfig from "../../../hooks/app/useConfig";
import useToggle from "../../../hooks/helper/useToggle";
import useEventData from "../../../hooks/data/useEventData";
import useCurrentUser from "../../../hooks/app/useCurrentUser";
import useDeviceDetect from "../../../hooks/helper/useDeviceDetect";
import useNotifications from "../../../hooks/app/useNotifications";
import DatePicker from "../../../components/DatePicker";
import TimePicker from "../../../components/TimePicker";
import UISelect  from "../../../components/UISelect";
import stableSort from "stable";
import ProgrammingSlotModal from "./programmingSlotModal";
import usePermissions from "../../../hooks/app/usePermissions";
import useAttendee from "../../../hooks/data/useAttendee";
import {DAILYCO_API_KEY} from "../../../context/sessionCallObject";
import Icon from "../../SessionLiveChat/components/Icon/Icon";
import useInterval from "../../../hooks/helper/useInterval";

function getISODateFormat(date) {
	return formatDate((date || new Date()), 'yyyy-MM-dd');
}

function getISOTimeFormat(date) {
	return formatDate((date || new Date()), 'HH:mm');
}

function processQueue(promise, next) {
	promise().then(() => {
		next();
	});
}

function ProgrammingSlot({ hasEditAccess, total, placement, handleEdit, onPushDown, onPushUp, name, type, duration, forceProgramming, _id : programmingId }) {
	const canMoveUp = placement !== 1;
	const canMoveDown = placement !== total;

	let borderClasses =
			total === 1 ? 'border rounded-md' :
			placement === 1 && total > 1 ? 'border-t border-l border-r rounded-t-md' :
			placement > 1 && placement < total ? 'border-l border-r border-t' :
			placement === total ? 'border rounded-b-md' : 'border';

	function handleVideoChatClick(e) {
		e?.preventDefault();
		if (!programmingId) {
			return;
		}
		forceProgramming(programmingId);
	}

	return (<div className={`${borderClasses} border-gray-300 px-2 py-1 ${ hasEditAccess ? 'hover:bg-gray-50' : ''}`}>
		<p className="flex flex-no-wrap justify-between text-sm items-center">
			<span className="text-black font-light uppercase w-3/4 truncate">{ name }<br /><span className="text-xs text-gray-700 font-italic">{ duration } mins&nbsp;&bull;&nbsp;{SLOT_TYPES[type]}</span></span>
			{ hasEditAccess && <span className="text-gray-500 flex flex-row-reverse">
				<span onClick={handleEdit}>
					<PenSVG classes={`h-4 w-4 cursor-pointer fill-current text-gray-300 hover:text-gray-900`} />
				</span>
				<span onClick={ () => canMoveDown && onPushDown(placement-1) }>
					<ArrowDownSVG disabled={!canMoveDown} classes={`h-5 w-5 mx-4 cursor-pointer fill-current ${ !canMoveDown ? 'text-gray-100 cursor-not-allowed' : 'text-gray-300'} hover:text-gray-900`} />
				</span>
				<span onClick={ () => canMoveUp && onPushUp(placement-1) }>
					<ArrowUpSVG disabled={!canMoveUp} classes={`h-5 w-5 cursor-pointer fill-current ${ !canMoveUp ? 'text-gray-100 cursor-not-allowed' : 'text-gray-300'} hover:text-gray-900`} />
				</span>
			</span>}
		</p>
		{ type.includes('video-chat') && <div className="flex py-1">
			<a href="#" onClick={handleVideoChatClick} className={`rounded px-2 py-1 text-sm ${programmingId ? 'bg-hub-green' : 'bg-gray-400'} text-white w-full text-center`}>Enter {type.includes('discussion') ? 'Discussion' : 'Presentation'}</a>
		</div> }
	</div>);
}

function Speaker({ total, placement, onDelete, onPushDown, onPushUp, name }) {
	const canMoveUp = placement !== 1;
	const canMoveDown = placement !== total;

	let borderClasses =
		total === 1 ? 'border rounded-md' :
		placement === 1 && total > 1 ? 'border-t border-l border-r rounded-t-md' :
		placement > 1 && placement < total ? 'border-l border-r border-t' :
		placement === total ? 'border rounded-b-md' : 'border';

	return (<div className={`${borderClasses} border-gray-300 px-2 py-1 hover:bg-gray-50`}>
		<p className="flex flex-no-wrap justify-between text-sm items-center">
			<span className="text-black font-light uppercase w-3/4 truncate">{ name }</span>
			<span className="text-gray-500 flex flex-row-reverse">
				<span onClick={onDelete}>
					<CloseSVG classes={`h-4 w-4 cursor-pointer fill-current text-gray-300 hover:text-gray-900`} />
				</span>
				<span onClick={ () => canMoveDown && onPushDown(placement-1) }>
					<ArrowDownSVG disabled={!canMoveDown} classes={`h-5 w-5 mx-4 cursor-pointer fill-current ${ !canMoveDown ? 'text-gray-100 cursor-not-allowed' : 'text-gray-300'} hover:text-gray-900`} />
				</span>
				<span onClick={ () => canMoveUp && onPushUp(placement-1) }>
					<ArrowUpSVG disabled={!canMoveUp} classes={`h-5 w-5 cursor-pointer fill-current ${ !canMoveUp ? 'text-gray-100 cursor-not-allowed' : 'text-gray-300'} hover:text-gray-900`} />
				</span>
			</span>
		</p>
	</div>);
}

function DetailsPanel({ defaultValues, onSave, addQueue }) {
	const { apiHost } = useConfig();
	const [ eventProfile ] = useEventData();
	const [ currentUser ] = useCurrentUser();
	const [ attendeeProfile ] = useAttendee();
	const { hasEventEditAccess } = usePermissions();
	const [ tracks, setTracks ] = useState([]);
	const defaultStartTime = useMemo(()=> setSeconds(utcToZonedTime(defaultValues.startTime, eventProfile.timezone), 0), [defaultValues])
	const defaultEndTime = useMemo(()=> setSeconds(utcToZonedTime(defaultValues.endTime || defaultValues.startTime, eventProfile.timezone), 0), [defaultValues])
	const [ startTime, setStartTime ] = useState({ _date: defaultStartTime, dateLabel: getISODateFormat(defaultStartTime), hourLabel: getISOTimeFormat(defaultStartTime) });
	const [ endTime, setEndTime ] = useState({ _date: defaultEndTime, dateLabel: getISODateFormat(defaultEndTime), hourLabel: getISOTimeFormat(defaultEndTime) });

	const [ serverError, setServerError ] = useState('');
	const [ dateError, setDateError ] = useState('');
	const { register, handleSubmit, reset, errors, setValue, formState: { dirty, isSubmitting } } = useForm({ defaultValues: { ...defaultValues, track: defaultValues.track ? defaultValues.track._id : null}, validationSchema: SessionSchema });
	const { append: appendNotification } = useNotifications();

	useEffect(() => {
		axios({
			url: `${ apiHost }/village/events/${eventProfile.id}/tracks`,
			method: 'GET'
		}).then(({data}) => {
			if (data) {
				let newTracks = stableSort(data, (a,b) => a.name > b.name).map(t => ({ text: t.name, value: t._id}));
				newTracks.unshift({text: 'None', value: '' })
				setTracks(newTracks);
			}
		}).catch(() => {
			setTracks([]);
		});
	}, []);

	function updateSession(values) {
		return axios({
			url: `${ apiHost }/village/events/${ eventProfile.id }/sessions/${defaultValues._id}`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token || attendeeProfile.token }`
			},
			data: { ...values }, // we replace on the server so we need to make sure everything is there now.
		});
	}

	function submitData(values) {
		let submittedStartTime = setSeconds(zonedTimeToUtc(startTime._date, eventProfile.timezone), 0);
		let submittedEndTime = setSeconds(zonedTimeToUtc(endTime._date, eventProfile.timezone), 0);

		if (isBefore(submittedEndTime, submittedStartTime)) {
			setDateError('End time must be after the start time.');
			return;
		}

		setDateError('');

		let payload = {
			...values,
			track: values.track === '' ? null : values.track,
			startTime: submittedStartTime.toISOString(),
			endTime: submittedEndTime.toISOString()
		};
		return new Promise((resolve, reject) => {
			addQueue(() => Promise.resolve().then(() => updateSession(payload).then(({ data }) => {
				if (data && data.message || !data) {
					setServerError(data.message);
					window.scrollTo(0, 0);
					appendNotification('alert', "Uh oh. We couldn't save your changes.");
					return reject();
				}
				if (typeof onSave === 'function') {
					onSave({ ...data, speakers: defaultValues.speakers });
				}

				appendNotification('success', "Success! This session has been updated.");
				return resolve();
			}).catch(e => {
				setServerError(e.message);
				window.scrollTo(0, 0);
				appendNotification('alert', "Uh oh. We couldn't save your changes.");
				return reject();
			})));
		});
	}

	function handlePickedStartDate(newDate) {
		handleStartTimeChange({ hour: getHours(startTime._date), minute: getMinutes(startTime._date), newDay: newDate});
		handleEndTimeChange({ hour: getHours(endTime._date), minute: getMinutes(endTime._date), newDay: newDate});
	}

	function handleStartTimeChange({ hour, minute, newDay }) {
		let newDate = setMinutes(setHours(newDay || startTime._date, hour), minute);
		setStartTime(state => ({...state, _date: newDate, dateLabel: getISODateFormat(newDate), hourLabel: `${hour}:${minute}`}));
	}

	function handleEndTimeChange({ hour, minute, newDay }) {
		let newDate = setMinutes(setHours(newDay || startTime._date, hour), minute);
		setEndTime(state => ({...state, _date: newDate, dateLabel: getISODateFormat(newDate), hourLabel: `${hour}:${minute}`}));
	}

	if (tracks && !tracks.length) {
		return null;
	}

	return (<>
		<form onSubmit={handleSubmit(submitData)}>
			<section className="px-6 py-4">

				{ serverError && <div className="rounded py-2 px-3 bg-red-50 text-gray-700 text-sm w-full my-2"><strong className="font-medium">Error:</strong>{ serverError }</div> }

				<div className="flex mt-1 mb-3 justify-between">
					<strong className="uppercase tracking-normal text-sm">session details</strong>
					<div className="flex flex-col flex-col-reverse">
						<a href="https://help.eventhub.net/hc/en-us/articles/360048675553-Creating-a-virtual-expo-vendor-village-listing"
							 target="_blank"
							 className="inline uppercase text-xs tracking-normal underline text-right">Need Help?</a>
					</div>
				</div>

				<div className="mt-2">
					<div className="flex justify-between">
						<label htmlFor="name" className="block text-xs font-light uppercase leading-normal text-gray-700">Session Name</label>
						<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal text-red-700">Required</span>
					</div>
					<input name="name" ref={register} className="mt-1 base-input" placeholder="Your Amazing Event!" />
					{ errors.name && <p className="mt-1 text-sm text-red-700">{ errors.name.message }</p> }
				</div>

				<div className="mt-2">
					<div className="flex justify-between">
						<label htmlFor="name" className="block text-xs font-light uppercase leading-normal text-gray-700">Track</label>
					</div>
					<UISelect name="track" disabled={!hasEventEditAccess} classes="mt-1 w-full" options={tracks} register={register({ required: !defaultValues.isTimeSlot })} />
					{ errors.track && <p className="mt-1 text-sm text-red-700">You must choose a track.</p> }
				</div>

				<div className="mt-2">
					<div className="flex justify-between">
						<label htmlFor="cardDescription" className="block text-xs font-light uppercase leading-normal text-gray-700">Mini Description</label>
						<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal">Max 160 Chars</span>
					</div>
					<textarea name="cardDescription" id="cardDescription" ref={register} maxLength={160} className="mt-1 base-input h-32 resize-none" />
				</div>

				{ hasEventEditAccess && <div className="mt-2">
					<div className="flex justify-between">
						<label htmlFor="cardDescription" className="text-xs font-light uppercase leading-normal text-gray-700">Date</label>
					</div>
					<DatePicker defaultDate={startTime._date} onPickDate={ handlePickedStartDate } />

					<p className="mt-2 text-sm font-light leading-normal text-gray-700 truncate">Event Time Zone:<span className="inline text-xs font-italic bg-gray-200 rounded p-1 ml-2 truncate">{TimezonesList[eventProfile.timezone]}</span></p>
				</div> }


				{ hasEventEditAccess && <>
					<div className="mt-2 flex flex-col lg:flex-row justify-between">
						<div className="mt-2 md:mt-0 w-full mr-3">
							<div className="flex justify-between">
								<label htmlFor="cardDescription" className="block text-xs font-light uppercase leading-normal text-gray-700">Start Time</label>
							</div>
							<TimePicker value={startTime.hourLabel} classes="in-panel" onSelectedTime={ handleStartTimeChange } />
						</div>
						<div className="my-2 md:mt-0 w-full mr-3 lg:ml-3">
							<div className="flex justify-between">
								<label htmlFor="cardDescription" className="block text-xs font-light uppercase leading-normal text-gray-700">End Time</label>
							</div>
							<TimePicker value={endTime.hourLabel} classes="in-panel" onSelectedTime={ handleEndTimeChange } />
						</div>
					</div>
					{ dateError && <p className="mt-1 text-sm text-red-700">{ dateError }</p> }
				</>}


				<div className="mt-10 text-center">
					<button type="submit" disabled={isSubmitting} className="btn-hub-green mt-2 mx-auto flex items-center">
						{ !isSubmitting && 'Save Details' }
						{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
					</button>
					{ dirty && <p className="text-xs leading-5 text-gray-500 uppercase tracking-normal cursor-pointer mt-4" onClick={() => reset(defaultValues)}>Undo Changes</p>}
				</div>

			</section>
		</form>
	</>);

}

function ProgrammingPanel({ defaultValues, onSave, addQueue, forceProgramming }) {
	const { apiHost } = useConfig();
	const [ eventProfile ] = useEventData();
	const [ currentUser ] = useCurrentUser();
	const [ attendeeProfile ] = useAttendee();
	const { hasEventEditAccess } = usePermissions();
	const speakerSelect = useRef(null);

	const [ speakers, setSpeakers ] = useState(null);
	const [ speakerOptions, setSpeakerOptions ] = useState([{text: 'Choose a Speaker', value: null}]);

	const [ showProgrammingSlotModal, toggleProgrammingSlotModal ] = useToggle();
	const [ selectedProgrammingSlotItem, setProgrammingSlotItem ] = useState(null);

	const [ serverError, setServerError ] = useState('');
	const { register, handleSubmit, reset, setValue, watch, formState: { dirty, isSubmitting } } = useForm({
		defaultValues: {
			programming: (defaultValues.programming || []).map(s => ({...s, id: s._id})) || [],
			speakers: defaultValues.speakers ? defaultValues.speakers.map(s => s._id) : []
	} });
	const { append: appendNotification } = useNotifications();
	const watchedItems = watch();

	useEffect(() => {
		register({ name: 'speakers', type: 'custom' });
		register({ name: 'programming', type: 'custom' });
	}, []);

	useEffect(() => {
		axios({
			url: `${ apiHost }/village/events/${eventProfile.urlSlug}/profiles?type=speaker`,
			method: 'GET'
		}).then(({data}) => {
			if (data) {
				setSpeakers(data.reduce((obj, speaker) => {obj[speaker.id] = speaker; return obj;}, {}))
				setSpeakerOptions(o => [...o, ...stableSort((data || []).filter(s => watchedItems.speakers.indexOf(s.id) < 0), (a,b) => a.name > b.name).map((s) => ({ text: s.name, value: s.id }))]);
			}
		}).catch(() => {
			setSpeakerOptions([]);
			setSpeakers([])
		});
	}, []);

	function updateSession(values) {
		return axios({
			url: `${ apiHost }/village/events/${eventProfile._id}/sessions/${defaultValues._id}`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token || attendeeProfile.token }`
			},
			data: { ...values }, // we replace on the server so we need to make sure everything is there now.
		});
	}

	function submitData(values) {
		let payload = {...values};
		return new Promise((resolve, reject) => {
			addQueue(() => Promise.resolve().then(() => updateSession(payload).then(({ data }) => {
				if (data && data.message || !data) {
					setServerError(data.message);
					window.scrollTo(0, 0);
					appendNotification('alert', "Uh oh. We couldn't save your changes.");
					return reject();
				}

				setValue('programming', (data.programming || []).map(s => ({...s, id: s._id})) || []);

				if (typeof onSave === 'function') {
					onSave({ ...data, speakers: data.speakers.map(s => speakers[s])});
				}

				appendNotification('success', "Success! Your session's programming has been updated.");
				return resolve();
			}).catch(e => {
				setServerError(e.message);
				window.scrollTo(0, 0);
				appendNotification('alert', "Uh oh. We couldn't save your changes.");
				return reject();
			})));
		});
	}

	function createProgrammingSlot(e){
		e.preventDefault();
		setProgrammingSlotItem({name: '', type: 'embed'});
		toggleProgrammingSlotModal(true);
	}

	function editProgrammingSlot(index) {
		return (e) => {
			e.preventDefault();
			setProgrammingSlotItem({...watchedItems.programming[index]});
			toggleProgrammingSlotModal(true);
		}
	}

	function handleSaveProgrammingSlot(slot) {
		if (!!slot.id) {
			let linkIndex = watchedItems.programming.map(l => l.id).indexOf(slot.id);
			let newProgramming = [...watchedItems.programming];
			newProgramming[linkIndex] = {...slot};
			setValue('programming', newProgramming);
		} else {
			let newProgramming = [...watchedItems.programming, { ...slot, id: cuid() }];
			setValue('programming', newProgramming);
		}
		toggleProgrammingSlotModal(false);
		setProgrammingSlotItem(null);
	}

	function handleCancelProgrammingSlot() {
		toggleProgrammingSlotModal(false);
		setProgrammingSlotItem(null);
	}

	function handleDeleteProgrammingSlot() {
		let newProgramming = [...watchedItems.programming];
		let index = newProgramming.map(l => l.id).indexOf(selectedProgrammingSlotItem.id);
		newProgramming.splice(index, 1);
		setValue('programming', newProgramming);
		toggleProgrammingSlotModal(false);
		setProgrammingSlotItem(null);
	}

	function handleDeleteSpeaker(index) {
		return () => {
			let newSpeakers = [...watchedItems.speakers];
			let speaker = speakers[newSpeakers[index]];
			let options = [...speakerOptions, { text: speaker.name, value: speaker._id }];
			setSpeakerOptions(options);
			newSpeakers.splice(index, 1);
			setValue('speakers', newSpeakers);
		}
	}

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	function pushUp(value) {
		return (currentIndex) => {
			setValue(
				value,
				reorder(
					watchedItems[value],
					currentIndex,
					(currentIndex - 1)
				)
			);
		}
	}

	function pushDown(value) {
		return (currentIndex) => {
			setValue(
				value,
				reorder(
					watchedItems[value],
					currentIndex,
					(currentIndex + 1)
				)
			);
		}
	}

	function speakerSelection(speaker) {
		let options = [...speakerOptions];
		let index = options.map(l => l.value).indexOf(speaker);
		options.splice(index, 1);
		setSpeakerOptions(options);
		speakerSelect.current.value = null;
		setValue('speakers', [...watchedItems.speakers, speaker]);
	}

	if (!speakers) {
		return null;
	}

	return (
		<>
			{showProgrammingSlotModal && selectedProgrammingSlotItem && <ProgrammingSlotModal onSave={handleSaveProgrammingSlot} onDelete={handleDeleteProgrammingSlot} onCloseModal={handleCancelProgrammingSlot} defaultValues={selectedProgrammingSlotItem}  />}
			<form onSubmit={handleSubmit(submitData)}>
				<section className="px-6 py-4">

					{ serverError && <div className="rounded py-2 px-3 bg-red-50 text-gray-700 text-sm w-full my-2 mb-3"><strong className="font-medium">Error:</strong>{ serverError }</div> }

					<div className="flex mt-1 mb-3 justify-between">
						<a href={ hasEventEditAccess ? "https://help.eventhub.net/hc/en-us/articles/360048675553-Creating-a-virtual-expo-vendor-village-listing" : "https://help.eventhub.net/hc/en-us/articles/360057376953-SPEAKERS-How-to-Edit-Your-Bio-Sessions-and-Present"}
							 target="_blank"
							 className="inline uppercase text-xs tracking-normal underline text-right">Need Help?</a>
					</div>

					{ hasEventEditAccess && <>
						<div className="mt-2">
							<div className="flex justify-between">
								<strong className="uppercase tracking-normal text-sm">Speakers</strong>
							</div>
							<div className={`mt-1 w-full inline-block relative`}>
								<select
									name="speakers" id="speakers"
									onChange={(e) => speakerSelection(e.target.value) } ref={speakerSelect}
									className={`block appearance-none w-full text-sm font-thin text-gray-800 bg-white border-none pl-2 pr-6 py-1 rounded-md shadow-inner-input leading-5 tracking-normal focus:outline-none focus:shadow-outline`}>
									{ speakerOptions.map(({ value, text }, idx) => <option key={idx} value={ value }>{ text }</option>)}
								</select>
								<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
									<svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
										<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
									</svg>
								</div>
							</div>
						</div>

						<div className="my-2">
							<div className="group flex flex-col">
								{ watchedItems.speakers.map((speaker, index) => (
									<Speaker key={index} placement={index+1}
													 total={watchedItems.speakers.length} {...speakers[speaker]}
													 onDelete={handleDeleteSpeaker(index)}
													 onPushDown={pushDown('speakers')} onPushUp={pushUp('speakers')} />
								)) }
							</div>
						</div>
					</> }

					<div className="flex justify-between pt-4">
						<strong className="uppercase tracking-normal text-sm">Programming</strong>
						{ hasEventEditAccess && watchedItems.programming.length > 0 && <a href="#" onClick={ createProgrammingSlot } className="text-xs leading-5 text-hub-green uppercase tracking-normal hover:underline">add</a> }
					</div>

					<div className="mt-1 mb-2">
						<div className="group flex flex-col">
							{ watchedItems.programming.map((slot, index) => (
								<ProgrammingSlot key={index} placement={index+1}
																 hasEditAccess={hasEventEditAccess}
																 total={watchedItems.programming.length} {...slot}
																 handleEdit={editProgrammingSlot(index)}
																 forceProgramming={forceProgramming}
																 onPushDown={pushDown('programming')} onPushUp={pushUp('programming')} />
							)) }
							{ hasEventEditAccess && !watchedItems.programming.length && <button onClick={ createProgrammingSlot } className="w-full py-1 text-sm text-center text-gray-400 hover:text-hub-green border border-gray-300 hover:border-hub-green rounded-md cursor-pointer">Add a Programming Slot</button> }
							{!hasEventEditAccess && !watchedItems.programming.length && <p className="text-gray-600 text-sm italic py-1">No programming yet. Be sure to check back closer to the event!</p>}
						</div>
					</div>

					{ hasEventEditAccess && <div className="mt-10 text-center">
						<button type="submit" disabled={ isSubmitting } className="btn-hub-green mt-2 mx-auto flex items-center">
							{ !isSubmitting && 'Save Changes' }
							{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
						</button>
						{ dirty && <p className="text-xs leading-5 text-gray-500 uppercase tracking-normal cursor-pointer mt-4" onClick={ () => reset(defaultValues) }>Undo Changes</p>}
					</div>}

				</section>
			</form>
		</>
	);

}

function displayRecordingDuration(seconds) {
	const format = val => `0${Math.floor(val)}`.slice(-2)
	const hours = seconds / 3600
	const minutes = (seconds % 3600) / 60
	return [hours, minutes, seconds % 60].map(format).join(':')
}

function createDailyRecipe(videoTracks, audioTracks) {
	// start building our recipe
	let recipe = {}
	recipe.composite_mode = "tracks-layout"
	recipe.size = "1280x720"
	recipe.tracks = []

	// determine the grid size based on the number of video tracks.
	// Example: 7 tracks need to go in a 3x3 grid, but 10 need to go 4x4
	let gridSize = Math.ceil(Math.sqrt(videoTracks.length));

	// partition videoTracks into a 2d array to make layout easier
	let videoGrid = [];
	while(videoTracks.length) {
		videoGrid.push(videoTracks.splice(0, gridSize))
	}

	// calculate sizes
	let outputSize = { width: 1280, height: 720 }
	let tileSize = {
		width: outputSize.width / gridSize,
		height: outputSize.height / gridSize
	}

	// insert each video into the recipe
	videoGrid.forEach(function(row, rowNum) {
		row.forEach(function(col, colNum) {
			recipe.tracks.push({
				id: col.id,
				size: `${tileSize.width}x${tileSize.height}`,
				position: `${colNum * tileSize.width}x${rowNum * tileSize.height}`
			})
		})
	})

	// insert all the audio tracks
	audioTracks.forEach(t => recipe.tracks.push({id: t.id}));

	return recipe;
}

function RecordingCard({ defaultValues, placement }) {
	const [ openCard, toggleCard ] = useToggle();
	const [ openTrackChoices, toggleChoices ] = useToggle();
	const [ tracks, setTracks ] = useState();
	const [ compositeVideo, setComposite ] = useState();
	const [ serverError, setServerError ] = useState('');
	const [ recordingGenerating, toggleRecording ] = useToggle(false);

	useEffect(() => {
		if (!openCard) {
			toggleChoices(false);
		}
	});

	useInterval(() => {
		axios({
			url: `https://api.daily.co/v1/recordings/${ defaultValues.id }/composites`,
			method: 'GET',
			headers: {
				'content-type': 'application/json',
				'authorization': `Bearer ${ DAILYCO_API_KEY }`
			}
		}).then(({data}) => {
			if (!data.current_error && data.newest_composite) {
				setComposite(data.newest_composite);
				toggleRecording(false);
			} else {
				setServerError(data.current_error);
			}
		})
	}, recordingGenerating ? 1000 : null)

	useEffect(() => {
		axios({
			url: `https://api.daily.co/v1/recordings/${ defaultValues.id }/composites`,
			method: 'GET',
			headers: {
				'content-type': 'application/json',
				'authorization': `Bearer ${ DAILYCO_API_KEY }`
			}
		}).then(({data}) => {
			console.log('current composite', data.newest_composite)
			if (!data.current_error && data.newest_composite) {
				setComposite(data.newest_composite);
			} else {
				setServerError(data.current_error);
			}
		})
	}, []);

	useEffect(() => {
		if (!defaultValues.tracks) {
			return;
		}
		let _tracks = {};

		defaultValues.tracks.forEach((track) => {
			let type = track.type === 'video' && track.media_tag.includes('screen') ? 'screen' : track.type;
			_tracks[track.session_id] = {
				..._tracks[track.session_id],
				id: track.session_id,
				user_id: track.user_id,
				name: track.user_name,
				[type]: [ ...((_tracks[track.session_id] || {})[type] || []), track.id ]
			}
		});

		_tracks = Object.values(_tracks).map((track) => ({
			...track,
			type: track.user_id.includes('organizer') ? 'Organizer' :  track.user_id.includes('speaker') ? 'Speaker' : null,
			includeAudio: true,
			// includeScreen: (track.screen !== undefined),
			includeVideo: track.user_id.includes('organizer') || track.user_id.includes('speaker'),
		}));

		setTracks(_tracks);
	}, [])

	function toggleTrackAudio(trackIdx) {
		return () => {
			setTracks(state => {
				let tracks = [...state];
				tracks[trackIdx] = {...tracks[trackIdx], includeAudio: !tracks[trackIdx].includeAudio}
				return tracks;
			});
		}
	}

	function toggleTrackVideo(trackIdx) {
		return () => {
			setTracks(state => {
				let tracks = [...state];
				tracks[trackIdx] = {...tracks[trackIdx], includeVideo: !tracks[trackIdx].includeVideo}
				return tracks;
			});
		}
	}

	function createCompositeVideo() {
		toggleRecording(true);
		let camIds = tracks.filter(t => t.includeVideo && t.video !== undefined && t.video.length > 0).map(t => t.video);
		let screenIds = tracks.filter(t => t.includeVideo && t.screen !== undefined && t.screen.length > 0).map(t => t.screen);
		let audioIds = tracks.filter(t => t.includeAudio && t.audio !== undefined).map(t => t.audio);
		let videos = [
			...[].concat(...camIds, ...screenIds).map(id => ({ id })),
		];
		let audios = [].concat(...audioIds).map(id => ({ id }));
		let recipe = createDailyRecipe([...videos], [...audios]);
		axios({
			url: `https://api.daily.co/v1/recordings/${ defaultValues.id }/composites`,
			method: 'POST',
			headers: {
				'content-type': 'application/json',
				'authorization': `Bearer ${ DAILYCO_API_KEY }`
			},
			data: recipe
		}).catch(err => {
			setServerError(err.message);
			toggleRecording(false);
		});
	}

	function downloadCompositeVideo() {
		fetch(`https://api.daily.co${compositeVideo.download_url}`, {
			method: 'GET',
			responseType: 'blob',
			headers: {
				'authorization': `Bearer ${ DAILYCO_API_KEY }`
			},
			validateStatus: function (status) {
				return status >= 200; // anything goes...
			}
		}).then((data) => {
			window.open(data.url, "_blank")
		})
	}

	return (<div className="border border-gray-200 rounded-md mb-3 overflow-hidden">
		<div className="flex justify-between px-4 py-2 text-sm cursor-pointer" onClick={() => toggleCard()}><span className="font-normal">Recording #{ placement }</span> <span>{ displayRecordingDuration(defaultValues.duration) }</span></div>
		<div className={`flex flex-col px-4 py-2 text-sm bg-gray-50 ${ openCard ? 'flex' : 'hidden' }`}>
			<p className={`italic text-gray-500 text-xs font-light mb-2 cursor-pointer hover:underline ${ openTrackChoices ? 'hidden' : 'block' }`} onClick={() => toggleChoices()}>Speakers and Hosts are selected by default. Other participants are included in audio only. Click to view selection.</p>
			{ openTrackChoices && tracks && tracks.length && (
				<div className="overflow-y-auto mb-3" style={{maxHeight: '200px'}}>
					{ tracks.map((track, trackIdx) => (
						<div key={trackIdx} className="pb-1 flex justify-between w-full">
							<span className="text-xs">{track.name}{ track.type && <div className="inline-block ml-1 px-1 py-0 bg-yellow-200 text-xs text-black font-normal rounded">{ track.type }</div> }</span>
							<span className="flex flex-row">
								{ track.video && <button onClick={toggleTrackVideo(trackIdx)} className="w-6 h-6 border-none bg-transparent cursor-pointer">
								  <Icon
									  type='camera'
									  className="h-4 w-4"
									  highlighted={!track.includeVideo}
								  />
								</button> }
								{ track.audio ? <button onClick={toggleTrackAudio(trackIdx)} className="w-6 h-6 border-none bg-transparent ml-3 cursor-pointer">
									<Icon
										type='mute-mic'
										className="h-4 w-4"
										highlighted={!track.includeAudio}
									/>
								</button> : <div className="w-6 h-6 ml-3"/> }
							</span>
						</div>
					)) }
				</div>
			) }

			{ serverError && <div className="rounded py-1 px-2 bg-red-50 text-gray-700 text-xs w-full mb-2"><strong className="font-medium">Error:</strong>{ serverError }</div> }

			<button className="text-center py-1 rounded-md bg-white text-hub-green border border-hub-green mb-2 flex items-center justify-around" onClick={createCompositeVideo}>{ !recordingGenerating ? (compositeVideo ? 'Re-Generate Recording' : 'Generate Recording') : <span className="mx-auto"><span className="spinner h-3 w-3 mr-2 inline-block" />&nbsp;Generating Recording</span> }</button>
			{ !recordingGenerating && compositeVideo && compositeVideo.download_url && <button className="text-center py-1 rounded-md text-white bg-hub-green" onClick={downloadCompositeVideo}>Download Recording</button> }
		</div>
	</div>)
}

function RecordingPanel({ defaultValues }) {
	const [ serverError, setServerError ] = useState('');
	const [ programming, setProgramming ] = useState((defaultValues.programming || []).filter(p => p.type.includes('video-chat')).map(s => ({...s, id: s._id})) || [])

	useEffect(() => {
		let roomNames = programming.filter(p => p.type.includes('video-chat')).map(p => `session-${p._id}`);
		let promises = roomNames.map(roomName => axios({
			url: `https://api.daily.co/v1/recordings?room_name=${ roomName }`,
			method: 'GET',
			headers: {
				'content-type': 'application/json',
				'authorization': `Bearer ${ DAILYCO_API_KEY }`
			}
		}))

		Promise.all(promises).then((responses) => {
			let recordings = [].concat(...(responses || []).map(res => res.data.data)).filter(d => d.status === 'finished' && d.tracks.length > 0);
			if (recordings && recordings.length) {
				setProgramming(state => state.map(p => {
					let programRecordings = recordings.filter(r => r.room_name === `session-${p._id}`);
					if (programRecordings) {
						return { ...p, recordings: programRecordings };
					} else {
						return p;
					}
				}));
			}
		}).catch((err) => {
			setServerError(err.message);
		});
	}, []);

	if (!programming) {
		return null;
	}

	return (
		<>
			<section className="px-6 py-4">
				{ serverError && <div className="rounded py-2 px-3 bg-red-50 text-gray-700 text-sm w-full my-2 mb-3"><strong className="font-medium">Error:</strong>{ serverError }</div> }

				<div className="flex mt-1 mb-3 justify-between">
					<a href="https://help.eventhub.net/hc/en-us/articles/360048675553-Creating-a-virtual-expo-vendor-village-listing"
					   target="_blank"
					   className="inline uppercase text-xs tracking-normal underline text-right">Need Help?</a>
				</div>

				<div className="flex justify-between pt-4">
					<strong className="uppercase tracking-normal text-sm">Programming Recordings</strong>
				</div>

				<div className="mt-1 mb-2">
					<div className="group flex flex-col">
						{ programming.map((slot, index) => (
							<div key={index}>
								<h3 className="text-black font-light uppercase mb-2">{slot.name}</h3>
								{ slot.recordings && slot.recordings.map(( recording, index ) => (
									<RecordingCard key={index} placement={index+1} defaultValues={recording} />
								)) }
								{ !slot.recordings && <p className="italic text-sm font-light">No Recordings found.</p> }
							</div>
						)) }
					</div>
				</div>

			</section>
		</>
	);
}

export default function SessionEditColumn({ session, onSave, forceProgramming }) {
	const detectDevice = useDeviceDetect();
	const [ currentUser ] = useCurrentUser();
	const [ _, addQueue ] = useQueue(processQueue);
	const { hasEventEditAccess } = usePermissions();
	const { apiHost } = useConfig();
	const [ eventProfile ] = useEventData();
	const [ showAdvanceStyles, toggleAdvanceStyleModal ] = useToggle();
	const urlHash = window.location.hash.replace('#edit-', '');
	const [ activeTab, setActivePanel ] = useState(hasEventEditAccess ? urlHash || 'details' : 'programming');
	const [ activatingProfile, setActivatingProfile ] = useToggle();
	const { append: appendNotification } = useNotifications();
	const [ columnIsOpen, setColumnIsOpen ] = useState(true);

	function updateSession(values) {
		return axios({
			url: `${ apiHost }/village/events/${eventProfile._id}/sessions/${session._id}`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token }`
			},
			data: { ...values }, // we replace on the server so we need to make sure everything is there now.
		});
	}

	function handleActivateToggle(isPublished) {
		setActivatingProfile(true);
		return new Promise((resolve, reject) => {
			addQueue(() => Promise.resolve().then(() => updateSession({ isPublished }).then(({ data }) => {
				if (data && data.message || !data) {
					appendNotification('alert', `Uh oh. We couldn't ${isPublished ? 'activate' : 'deactivate'} your session.`);
					return reject();
				}

				onSave({isPublished});
				setActivatingProfile(false);
				if (isPublished) {
					appendNotification('success', "Your session is now active.")
				} else {
					appendNotification('note', "Your session has been deactivated.")
				}

				return resolve();
			}).catch(e => {
				appendNotification('alert', `Uh oh. We couldn't ${isPublished ? 'activate' : 'deactivate'} your session.`);
				if (e.message) {
					appendNotification('alert', `Server responded with: ${ e.message }`);
				}
				return reject();
			})));
		});
	}

	function handleChangeTab(tab) {
		return (e) => {
			e?.preventDefault();
			if (activeTab !== tab) {
				setActivePanel(tab);
			}
		}
	}

	let activeTabClass = 'text-gray-900 border-b-2 border-blue-400';
	let inactiveTabClass = 'text-gray-500 hover:text-gray-900 border-b-2 border-gray-300 hover:border-hub-green cursor-pointer';

	return (<>
		{ showAdvanceStyles && <EventAdvanceStyles onComplete={ () => toggleAdvanceStyleModal(false) } /> }

		{!columnIsOpen && <aside className='bg-gray-500 h-full'>
			<div className="text-white py-4 cursor-pointer hidden md:block" onClick={() => setColumnIsOpen(c => !c)}>
				<ArrowLeftSVG title="Show Edit Details" classes="w-5 h-5 fill-current" />
			</div>
		</aside>}
		{columnIsOpen && <aside className={`${detectDevice.isMobile() && 'fixed top-0 right-0 bottom-0 left-0 border-8 border-black border-opacity-50 z-50 shadow overflow-auto'} sm:w-4/12 xl:w-3/12 2xl:2/12 min-w-1/3 bg-white shadow-inner pb-20 sm:pb-0`}>
			<section className="px-6 py-4 bg-gray-800 shadow-inner flex justify-between items-center md:hidden" onClick={(e) => {e.preventDefault();onSave();}}>
				<label className="text-white leading-tight uppercase text-sm mx-auto cursor-pointer">{hasEventEditAccess ? 'Cancel Edit' : 'Close'}</label>
			</section>
			<section className="px-6 py-4 bg-gray-500 shadow-inner flex justify-between items-center">
				<div className="text-white hidden md:block cursor-pointer" onClick={() => setColumnIsOpen(c => !c)}>
					<ArrowRightSVG title="Hide Edit Details" classes="w-5 h-5 fill-current" />
				</div>
				{ hasEventEditAccess && <div className="flex justify-between items-center w-full md:w-3/4">
					<label className="text-white leading-tight uppercase text-sm">Publish</label>
					<Toggle isFlipped={ session.isPublished } isLoading={activatingProfile} onToggle={handleActivateToggle} />
				</div>}
			</section>

			<nav className="flex justify-between mt-2">
				{ hasEventEditAccess && <a href="#" onClick={ handleChangeTab('details') } className={`uppercase w-full tracking-normal text-sm text-center outline-none ${ activeTab === 'details' ? activeTabClass : inactiveTabClass}`}>Info</a> }
				<a href="#" onClick={ handleChangeTab('programming') } className={`uppercase w-full tracking-normal text-sm text-center outline-none ${ activeTab === 'programming' ? activeTabClass : inactiveTabClass} ${ hasEventEditAccess ? 'cursor-auto' : 'cursor-default'}`}>Program</a>
			</nav>

			{ activeTab === 'details' && <DetailsPanel defaultValues={session} onSave={ onSave } addQueue={ addQueue } />}
			{ activeTab === 'programming' && <ProgrammingPanel defaultValues={session} onSave={ onSave } addQueue={ addQueue } forceProgramming={forceProgramming} />}

		</aside>}
	</>);
}

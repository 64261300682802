import React, {useMemo, useState} from 'react';
import { useForm } from "react-hook-form";
import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';
import { format as formatDate, setHours, setMinutes, setSeconds, setDate, setMonth, setYear, isBefore } from 'date-fns';

import TimePicker from "../../components/TimePicker";
import DatePicker from "../../components/DatePicker";
import { TimezonesList } from "../../constants";
import useEventData from "../../hooks/data/useEventData";

function getISODateFormat(date) {
	return formatDate((date || new Date()), 'yyyy-MM-dd');
}

function getISOTimeFormat(date) {
	return formatDate((date || new Date()), 'HH:mm');
}

export default function BoothSessionModal({ defaultValues, onSave, onDelete, onCloseModal }) {
	const [ eventProfile ] = useEventData();
	const [ dateError, setDateError ] = useState('');
	const defaultStartTime = useMemo(()=> utcToZonedTime(defaultValues.startTime, eventProfile.timezone), [defaultValues]);
	const defaultEndTime = useMemo(()=> utcToZonedTime(defaultValues.endTime, eventProfile.timezone), [defaultValues]);
	const [ startTime, setStartTime ] = useState({ _date: defaultStartTime, dateLabel: getISODateFormat(defaultStartTime), hourLabel: getISOTimeFormat(defaultStartTime) }); // expected format: dateLabel: 2020-05-28, hourLabel: 13:00
	const [ endTime, setEndTime ] = useState({ _date: defaultEndTime, dateLabel: getISODateFormat(defaultEndTime), hourLabel: getISOTimeFormat(defaultEndTime) }); // expected format: dateLabel: 2020-05-28, hourLabel: 13:00
	const { register, handleSubmit, errors, formState: { dirty, isSubmitting } } = useForm({ defaultValues });

	function submitData(values) {
		return new Promise((resolve, reject) => {
			// build up times for object...
			let submittedStartTime = setSeconds(zonedTimeToUtc(startTime._date, eventProfile.timezone), 0);
			let submittedEndTime = setSeconds(zonedTimeToUtc(endTime._date, eventProfile.timezone), 0);

			if (!isBefore(submittedStartTime, submittedEndTime)) {
				setDateError('End time must be after the start time.');
				return reject();
			}

			resolve();
			onSave({
				id: defaultValues.id,
				startTime: submittedStartTime.toISOString(),
				endTime: submittedEndTime.toISOString(),
			});
		}).catch(console.log);
	}

	function shouldModalClose() {
		if (!dirty || dirty && confirm("It seems you've enter some info, do you want to close this?")) {
			onCloseModal();
		}
	}

	function handlePickedDate(pickedDate) {
		// setDay / Month / Year
		let day = pickedDate.getDate();
		let month = pickedDate.getMonth();
		let year = pickedDate.getFullYear();

		let newStartDate = setYear(setMonth(setDate(startTime._date, day) , month), year);
		let newEndDate = setYear(setMonth(setDate(endTime._date, day) , month), year);
		setStartTime(state => ({...state, _date: newStartDate, dateLabel: getISODateFormat(newStartDate)}));
		setEndTime(state => ({...state, _date: newEndDate, dateLabel: getISODateFormat(newEndDate)}));
	}

	function handleStartTimeChange({ hour, minute }) {
		console.log('handleStartTimeChange', hour, minute);
		let date = startTime._date;
		let newDate = setMinutes(setHours(date, hour), minute);
		console.log('handleStartTimeChange newDate', newDate);
		setStartTime(state => ({...state, _date: newDate, dateLabel: getISODateFormat(newDate), hourLabel: `${hour}:${minute}`}));
	}

	function handleEndTimeChange({ hour, minute }) {
		console.log('handleEndTimeChange', hour, minute);
		let date = startTime._date;
		let newDate = setMinutes(setHours(date, hour), minute);
		setEndTime(state => ({...state, _date: newDate, dateLabel: getISODateFormat(newDate), hourLabel: `${hour}:${minute}`}));
	}

	function shouldDelete(e) {
		e.preventDefault();
		let answer = prompt("Type 'DELETE' to confirm.");
		if (answer === 'DELETE') {
			onDelete();
		}
	}

	return (<div className="fixed z-50 bottom-0 overflow-auto inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">

		<div className="fixed inset-0 transition-opacity">
			<div className="absolute inset-0 bg-gray-500 opacity-75" />
		</div>

		<div className="bg-white rounded-md shadow-xl transform transition-all sm:time-modal" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<form onSubmit={handleSubmit(submitData)} className="mb-0">

				<div className="bg-gray-200 rounded-t-md px-4 py-3 sm:px-6">
					<h3 className="text-lg uppercase leading-6 font-normal text-gray-900">{ defaultValues.id ? 'Edit' : 'Add'} a Virtual Booth Session Time</h3>
				</div>

				<div className="flex flex-col sm:flex-row ">
					<div className="px-6 py-3 flex-grow">

						<p className="mt-2 text-sm font-light leading-normal text-gray-700 truncate">Event Time Zone:<span className="inline text-xs font-italic bg-gray-200 rounded p-1 ml-2 truncate">{TimezonesList[eventProfile.timezone]}</span></p>

						<div className="flex flex-col mt-4">
							<label htmlFor="description" className="block text-xs font-light uppercase leading-normal text-gray-700">When should exhibitors go live?</label>
						</div>

						<div className="flex mt-1">
							<div className="w-1/3">
								<label htmlFor="description" className="block text-xs font-light leading-normal text-gray-700">Date</label>
								<DatePicker defaultDate={startTime._date} onPickDate={ handlePickedDate } />
							</div>
							<div className="w-1/3 ml-4">
								<label htmlFor="description" className="block text-xs font-light leading-normal text-gray-700">Start Time</label>
								<TimePicker value={startTime.hourLabel} onSelectedTime={ handleStartTimeChange } />
							</div>
							<div className="w-1/3">
								<label htmlFor="description" className="block text-xs font-light leading-normal text-gray-700">End Time</label>
								<TimePicker value={endTime.hourLabel} onSelectedTime={ handleEndTimeChange } />
								{ dateError && <p className="mt-1 text-sm text-red-700">{ dateError }</p> }
							</div>
						</div>

					</div>
				</div>

				<div className="px-4 py-3 flex flex-col-reverse sm:flex-row justify-between items-center border-gray-300 border-t">
					{ defaultValues.id ? <a href="#" className="text-red-700 mx-auto sm:mx-0 mt-2 sm:mt-0" onClick={shouldDelete}>Delete Time</a> : <div />}
					<div className="flex items-center">
						<span className="text-gray-400 mr-2 mt-2 sm:mt-0 cursor-pointer" onClick={shouldModalClose}>Cancel</span>
						<button type="submit" disabled={isSubmitting} className="float-right btn-hub-green sm:w-auto sm:ml-2 mt-2 sm:mt-0 mr-0 sm:mr-0 flex items-center">
							{ !isSubmitting && 'Save Times' }
							{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
						</button>
					</div>
				</div>
			</form>
		</div>

	</div>);
}
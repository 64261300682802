import React, {useState, useRef, useEffect} from "react";
import useCallObject from "../../../../hooks/data/useSessionCallObject";
import useMessages from "../../../../hooks/data/useSessionChatMessages";
import useDeviceDetect from "../../../../hooks/helper/useDeviceDetect";
import ChatMessage from ".././../../../components/ChatMessage";
import Countdown from "../Countdown";

export default function BoothChat({ isMinified, isChatOpen, endTime }) {
	const [ callObject ] = useCallObject();
	const [ messages, addMessage ] = useMessages();
	const [ message, setMessage ] = useState('');
	const [ numUnreadMessages, setNumUnreadMessages ] = useState(0);
	const [ shouldHugBottom, setShouldHugBottom ] = useState(true);
	const chatWindow = useRef(null);
	const detectDevice = useDeviceDetect();

	function handleNewMessage(e) {
		e.preventDefault();
		if (!message) {
			return;
		}

		callObject.sendAppMessage({ type: 'message', message: message }, '*');
		setShouldHugBottom(true);
		addMessage(state => ([...state, { message: message } ]));
		setMessage('');
	}

	function scrollToBottom() {
		chatWindow.current.scrollTop = chatWindow.current.scrollHeight;
		setNumUnreadMessages(0);
	}

	function onChatScroll(e) {
		if (!e || !e.target) return;

		const tolerance = 20; // pixels
		if (e.target.scrollHeight - e.target.scrollTop - tolerance <= e.target.clientHeight) {
			// the user scrolled to the bottom, so we should automatically scroll down with new messages from now on
			setShouldHugBottom(true);
			setNumUnreadMessages(0); // they've now seen all messages
		} else {
			// the user manually scrolled the window, either up or down but not all the way to the bottom
			// we should not automatically scroll down when new messages arrive because they might be trying to read an old message
			setShouldHugBottom(false);
		}
	}

	useEffect(() => {
		if (shouldHugBottom) {
			scrollToBottom();
		} else {
			// let the user know there's new messages to be read
			setNumUnreadMessages(numUnreadMessages + 1);
		}
	}, [ messages ]);

	return (<div className={`flex flex-col ${ isMinified ? 'h-64' : 'h-full'}`} >
		<Countdown endTime={endTime} />
		<div className={`bg-white overflow-y-auto flex flex-col flex-grow px-2 lg:px-3 pt-2 ${isChatOpen ? " pb-24" : " pb-10"}`} onScroll={onChatScroll} ref={(e) => { chatWindow.current = e }}>
			{ messages && messages.length > 0 && messages.map(({ message, system, name }, index) => system ? <div key={index} className="flex-shrink-0 text-center px-2 text-xs">{ message }</div> : <div key={index} className={`flex flex-col flex-shrink-0 ${ name ? 'justify-left mr-3 lg:mr-12 mt-2' : 'justify-right ml-3 lg:ml-12 mt-2' }`}>
				<p className={`text-xs text-gray-500 text-normal ${ !name && 'text-right'}`}>{ name || 'You' }</p>
				<div className={`flex ${ !name && 'justify-end'}`}>
					<div className={`${ name ? 'bg-gray-200' : 'bg-blue-400 font-normal text-white'} p-2 rounded text-xs leading-tight w-auto inline whitespace-normal max-w-full break-words`}><ChatMessage text={message}></ChatMessage></div>
				</div>
			</div>) }
		</div>
		<div className={`${ isMinified ? 'relative' : 'fixed'} ${isChatOpen && detectDevice.isMobile() && 'sm:bottom-8'} bottom-0 left-0 right-0 sm:relative px-4 py-3 bg-gray-100`}>
			<form className="mb-0 flex flex-row sm:flex-col lg:flex-row sm:items-left lg:items-center" onSubmit={ handleNewMessage }>
				<input type="text" placeholder="Say something..." value={message} onChange={(e) => setMessage(e.target.value)} className="rounded-md py-1 px-2 shadow-inner-input block w-auto flex-grow text-sm leading-5 text-gray-800" />
				<button type="submit" className="ml-2 sm:ml-0 lg:ml-2 sm:mt-1 lg:mt-auto px-4 py-1 bg-hub-green hover:bg-green-400 text-sm text-white rounded">Send</button>
			</form>
		</div>
		{ !shouldHugBottom && numUnreadMessages > 0 && <div className="flex justify-end inline align-baseline absolute right-6 bottom-24 lg:bottom-16">
			<div onClick={scrollToBottom} className="cursor-pointer bg-red-400 font-normal text-white p-2 rounded text-xs leading-tight w-auto inline whitespace-normal break-all max-w-full">{numUnreadMessages} new message{numUnreadMessages === 1 ? "" : "s"}</div>
		</div> }
	</div>);
}
import React from "react"

const urlRegex = /(?:www|http:|https:)+[^\s]+[\w]/g;
const emailRegex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/gi;
const combinedRegex = /((?:www|http:|https:)+[^\s]+[\w])|([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})/gi;

export default function ChatMessage({ text }) {
  let parts = text.split(combinedRegex);
  let links = text.match(urlRegex);
  let emails = text.match(emailRegex);

  return (
    <div>{parts.map((p, i) => 
      links && links.includes(p) ? <a key={`link${i}`} target="_blank" href={`${links[0].substring(0,3) === 'www' ? 'https://' : ''}${links[0]}`} className="underline">{links.shift()}</a> : 
      emails && emails.includes(p) ? <a key={`email${i}`} target="_blank" href={`mailto:${emails[0]}`} className="underline">{emails.shift()}</a> : 
      p ? p : '')}</div>
  );
}
import ReactGA from 'react-ga';

const TRACKERS = ['adminTracker', 'producerTracker'];

export function useTrackers() {
	return TRACKERS;
}

export function gaTrackEvent(category, eventName, eventLabel) {
	if (process.env.NODE_ENV === 'production') {
		ReactGA.event(
			{
				category: category,
				action: eventName,
				label: eventLabel
			},
			TRACKERS
		);
	}
}

export function gaTrackPageview(path) {
	if (process.env.NODE_ENV === 'production') {
		ReactGA.pageview(path, TRACKERS);
	}
}
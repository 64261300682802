const { useEffect } = require('react');

const getDetections = userAgent => {
	const isAndroid = () => Boolean(userAgent.match(/Android/i));
	const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
	const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
	const isChrome = () => Boolean(userAgent.match(/chrome|cri/i));
	const isSafari = () => Boolean(userAgent.match(/^((?!chrome|android).)*safari/i));
	const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
	const isSSR = () => Boolean(userAgent.match(/SSR/i));
	const isPortrait = () => Boolean(window.matchMedia("(orientation: portrait)").matches);

	const isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows());
	const isDesktop = () => Boolean(!isMobile() && !isSSR());
	return {
		isOpera,
		isChrome,
		isSafari,
		isMobile,
		isDesktop,
		isAndroid,
		isIos,
		isSSR,
		isPortrait
	};
};

export default function useDeviceDetect() {
	useEffect(() => {}, []);
	const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
	return getDetections(userAgent);
};
import React from 'react';

export function SelectDropdown({ options, value, name, id, classes, selectClasses, onSelection, ...props }) {
	return (<div className={`${ classes } inline-block relative`}>
		<select
			name={ name } value={value} onChange={(e) => onSelection(e.target.value) } id={id || name}
			{...props}
			className={`block appearance-none w-full text-sm font-thin text-gray-800 bg-white border-none pl-2 pr-6 py-1 rounded-md shadow-inner-input leading-5 tracking-normal focus:outline-none focus:shadow-outline ${ selectClasses }`}>
			{ options.map(({ value, text }, idx) => <option key={idx} value={ value }>{ text }</option>)}
		</select>
		<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
			<svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
				<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
			</svg>
		</div>
	</div>)
}

export default function UISelect({ options, name, id, register, classes, disabled, selectClasses }) {
	return (<div className={`${ classes } inline-block relative`}>
		<select
			name={ name } ref={register} disabled={disabled} id={id || name}
			className={`${disabled && 'bg-gray-200 cursor-not-allowed'} block appearance-none w-full text-sm font-thin text-gray-800 bg-white border-none px-2 py-1 rounded-md shadow-inner-input leading-5 tracking-normal focus:outline-none focus:shadow-outline ${ selectClasses }`}>
			{ options.map(({ value, text }, idx) => <option key={idx} value={ value }>{ text }</option>)}
		</select>
		<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
			<svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
				<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
			</svg>
		</div>
	</div>)
}
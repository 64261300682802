import React, { useState, useEffect, useRef, useMemo, forwardRef } from 'react';
import { getVideoObject } from '../../../hooks/data/useShowTimes';
import ReactPlayer from 'react-player/lazy';
import useAsync from '../../../hooks/helper/useAsync';
import useEventEmitter from "../../../hooks/helper/useEventEmitter";
import usePermissions from "../../../hooks/app/usePermissions";
import { FilmSVG } from "../../../components/Icons";

const config = {
	youtube: {
		playerVars: {
			autoplay: false,
			controls: true
		}
	},
	vimeo: {
		playerOptions: {
			background: false,
			controls: true,
			autoplay: false
		}
	},
	twitch: {
		options: {
			autoplay: false
		}
	},
	wistia: {
		options: {
			autoPlay: false,
			playbar: true,
		}
	}
};

export const SessionStage = forwardRef(({ insertedVideo, displayChat }, ref) => {
	const playerRef = ref || useRef(null);
	const { on, emit } = useEventEmitter();
	const { mode } = usePermissions();
	const showPlaceholderVideo = mode === 'edit';
	const previousPlaySetting = useRef(null);
	const [ video, setVideo ] = useState(null);
	// const [ state, setState ] = useState(INITIAL_STATE);
	const [ playVideo, setPlayVideo ] = useState(true);
	const { execute, pending, value : mainStageInsertedVideo } = useAsync(() => getVideoObject({url: insertedVideo, displayChat }), null);
	const liveWithChat = useMemo(() => video && video.displayChat, [video]);

	useEffect(() => {
		if (!insertedVideo) return;
		execute();
	}, [ insertedVideo ]);

	useEffect(function() {
		if (pending || !mainStageInsertedVideo) {
			return;
		}
		setVideo(mainStageInsertedVideo);
		changePlayStatus(true);
	}, [ pending, mainStageInsertedVideo ]);


	if (!video) {
		return null;
	}

	function changePlayStatus(shouldPlay) {
		previousPlaySetting.current = shouldPlay;
		setPlayVideo(shouldPlay);
	}

	function dismissVideo(e) {
		e && e.preventDefault();
		changePlayStatus(false);
	}

	function handleOnPlay() {
		changePlayStatus(true);
	}

	function handleOnPause() {
		changePlayStatus(false);
	}

	return (<>
		<div className={`main-stage-container flex flex-fix bg-black mb-16 sm:mb-6 rounded`}>
			<div className={`flex flex-grow flex-col md:flex-row sm:h-hero p-2`}>
				{ !showPlaceholderVideo ? (
					<div className="h-64 sm:h-full w-full">
						<ReactPlayer className={`youtubeContainer ${ liveWithChat ? ' h-hero w-full md:w-3/4 ' : ' h-full ' } ${ video.type === 'wistia' && 'wistiaPlayer' }`}
												 url={ video.url }
												 ref={ playerRef }
												 playing={ playVideo }
												 onPlay={ handleOnPlay }
												 onPause={ handleOnPause }
												 config={ config }
												 width="100%" height="100%"
						/>
					</div>
				) : (
					<div className="flex flex-col justify-center items-center h-full w-full">
						<FilmSVG classes="text-gray-700 fill-current w-32 h-32" />
						<div className="mt-6 px-3 py-2 rounded bg-gray-200 font-medium font-mono text-lg capitalize text-center">
							{ video.type } Video<br /><span className="text-xs block italic">(Not shown in edit mode)</span>
						</div>
					</div>
				)
				}
				{ !showPlaceholderVideo && liveWithChat ? (
					<div className={`youtubeChatContainer h-64 md:h-auto w-full md:w-1/4`}>
						<iframe title="main stage video chat" allowFullScreen="" scrolling="no" width="200px" height="200px" frameBorder="0" src={ video.chatUrl } />
					</div>
				) : null }
			</div>
		</div>
	</>);
})
import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactCrop from 'react-image-crop';

function getResizedCanvas(canvas, newWidth, newHeight) {
	const tmpCanvas = document.createElement('canvas');
	tmpCanvas.width = newWidth;
	tmpCanvas.height = newHeight;

	const ctx = tmpCanvas.getContext('2d');
	ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight);

	return tmpCanvas;
}

function downloadCanvas(blob) {
	const previewUrl = window.URL.createObjectURL(blob);

	const anchor = document.createElement('a');
	anchor.download = 'cropPreview.png';
	anchor.href = URL.createObjectURL(blob);
	anchor.click();

	window.URL.revokeObjectURL(previewUrl);
}

function generateImage(previewCanvas, crop, finalWidth, finalHeight, callback) {
	if (!crop || !previewCanvas) {
		return;
	}

	const newWidth = finalWidth || crop.width;
	const newHeight = finalHeight || crop.height;

	const dpr = window.devicePixelRatio || 1;
	const canvas = dpr !== 1 ? getResizedCanvas(previewCanvas, newWidth, newHeight) : previewCanvas;

	canvas.toBlob(
		(callback || downloadCanvas),
		'image/png',
		1
	);
}

export default function CropModal({ aspectRatio, circularCrop, cropPreviewClasses, finalWidth, finalHeight, srcImg, onCloseModal, onSaveCrop }) {
	circularCrop = circularCrop || false;
	const [ isSubmitting, setSubmitting ] = useState(false);
	const [ previewMode, setPreviewMode ] = useState(false);
	const imgRef = useRef(null);
	const previewCanvasRef = useRef(null);
	const [ crop, setCrop ] = useState({ x:0, y: 0, unit: '%', aspect: aspectRatio });
	const [ completedCrop, setCompletedCrop ] = useState(null);
	const [ originalCrop, setOriginalCrop ] = useState(null);

	useEffect(() => {
		let img = new Image();
		img.onload = function() {
			setCrop((c) => ({ ...c, width: this.width }));
		}
		img.src = srcImg;
	}, [ srcImg ]);

	function checkAndSetCrop(newCrop) {
		if ((newCrop.width === 0 && newCrop.height === 0) || 
			(newCrop.width <= 10 || newCrop.height <= 10)) {
			return;
		}
		setCrop(newCrop);
	}

	const onLoad = useCallback(img => {
		imgRef.current = img;
		if (img.naturalWidth / img.naturalHeight > aspectRatio) {
			setOriginalCrop({ width: img.height * aspectRatio, height: img.height });
		} else {
			setOriginalCrop({ width: img.width, height: img.width / aspectRatio });
		}
	}, []);

	const previewCrop = useCallback(function () {
		if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
			return;
		}

		const image = imgRef.current;
		const canvas = previewCanvasRef.current;
		const crop = completedCrop;
		const dpr = window.devicePixelRatio || 1;

		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext('2d');

		canvas.width = crop.width * dpr;
		canvas.height = crop.height * dpr;

		try {
			ctx.drawImage(
				image,
				crop.x * scaleX || 1,
				crop.y * scaleY || 1,
				crop.width * scaleX || 1,
				crop.height * scaleY || 1,
				0,
				0,
				crop.width * dpr || 1,
				crop.height * dpr || 1
			);
			setPreviewMode(true);
		} catch (e) {
			console.log('canvas error:', e);
		}
	}, [completedCrop]);

	function handleSubmit() {
		setSubmitting(true);

		// make canvas from image
		const image = imgRef.current;
		const canvas = previewCanvasRef.current;
		const crop = completedCrop;
		// const dpr = window.devicePixelRatio || 1;
		const dpr = 1;

		const ctx = canvas.getContext('2d');
		if (image.naturalWidth / image.naturalHeight > aspectRatio) {
			canvas.width = image.naturalHeight * aspectRatio;
			canvas.height = image.naturalHeight;
		} else {
			canvas.width = image.naturalWidth;
			canvas.height = image.naturalWidth / aspectRatio;
		}

		const scaledOffsetX = crop.x / originalCrop.width * canvas.width;
		const scaledOffsetY = crop.y / originalCrop.height * canvas.height;
		const scaledWidth = crop.width / originalCrop.width * canvas.width;
		const scaledHeight = crop.height / originalCrop.height * canvas.height;

		try {
			ctx.drawImage(
				image,
				scaledOffsetX,
				scaledOffsetY,
				scaledWidth,
				scaledHeight,
				0,
				0,
				canvas.width * dpr,
				canvas.height * dpr
			);
		} catch (e) {
			console.log('canvas error:', e);
		}

		generateImage(previewCanvasRef.current, completedCrop, finalWidth, finalHeight, onSaveCrop);
	}

	return (<div className="fixed z-50 bottom-0 overflow-auto inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">

		<div className="fixed inset-0 transition-opacity"><div className="absolute inset-0 bg-gray-500 opacity-75" /></div>

		<div className="bg-white rounded-md sm:overflow-hidden shadow-xl transform transition-all sm:max-w-3xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

					<div className="bg-gray-200 px-4 py-3 sm:px-6">
						<h3 className="text-lg uppercase leading-6 font-normal text-gray-900">Crop Image</h3>
					</div>

					<div className="flex flex-col bg-gray-600 inner-shadow p-6 items-center h-hero">

						{ !previewMode && <ReactCrop
							src={srcImg}
							onImageLoaded={onLoad}
							crop={crop}
							ruleOfThirds={true}
							circularCrop={circularCrop}
							onChange={c => checkAndSetCrop(c)}
							onComplete={c => setCompletedCrop(c)}
						/> }

						<div className={`h-full w-full flex justify-center items-center py-2 px-3 sm:py-6 sm:px-12 ${previewMode ? 'block my-auto' : 'hidden'}`}>
							<canvas
								ref={previewCanvasRef}
								className={`${cropPreviewClasses}`}
								style={{
									width: completedCrop?.width ?? 0,
									height: completedCrop?.height ?? 0
								}}
							/>
						</div>

					</div>

					<div className="px-4 py-3 flex flex-col-reverse sm:flex-row justify-end items-center border-gray-300 border-t">
						<span className="text-gray-400 mx-auto sm:mx-0 mt-2 sm:mt-0 cursor-pointer" onClick={onCloseModal}>Cancel</span>
						{ !previewMode && <button type="button"
										onClick={ previewCrop }
										className="float-right btn-hub-green sm:w-auto sm:ml-2 mt-2 sm:mt-0 mr-0 sm:mr-0 flex text-sm items-center">Preview Crop</button> }
						{ previewMode && <>
							<span className="text-gray-400 ml-4 mt-2 sm:mt-0 cursor-pointer" onClick={() => setPreviewMode(false)}>Back To Crop</span>
							<button type="button"
											disabled={isSubmitting || !completedCrop?.width || !completedCrop?.height}
											onClick={ handleSubmit }
											className="float-right btn-hub-green sm:w-auto sm:ml-2 mt-2 sm:mt-0 mr-0 sm:mr-0 flex text-sm items-center">
								{ !isSubmitting && 'Upload Image' }
								{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
							</button>
						</> }
					</div>
		</div>

	</div>)
}
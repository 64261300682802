import {  useMemo } from 'react';
import { useParams } from "react-router-dom";

export default function useWhiteLabelSlugs() {
	const { eventSlug, boothSlug, sessionId, speakerId, speakerSlug } = useParams();

	return {
		sessionId, speakerId, speakerSlug,
		eventSlug: useMemo(() => window.white_label_slug || eventSlug, [eventSlug, window.white_label_slug]),
		boothSlug: useMemo(() => window.white_label_slug ? boothSlug || eventSlug : boothSlug, [eventSlug, boothSlug, window.white_label_slug])
	}
}
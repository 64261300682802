import React, {useState, useEffect, useMemo} from 'react';
import axios from "axios";
import { useForm } from "react-hook-form";
import {
	format as formatDate,
	setHours,
	setMinutes,
	addMinutes,
	setSeconds,
	setMilliseconds,
	getHours,
	getMinutes
} from "date-fns";
import {utcToZonedTime, zonedTimeToUtc} from "date-fns-tz";

import UploadTarget from "../../../../components/UploadTarget";
import { INITIAL_ANNOUNCEMENTS } from '../../../../context/announcements';
import DatePicker from "../../../../components/DatePicker";
import TimePicker from "../../../../components/TimePicker";
import UISelect, { SelectDropdown } from "../../../../components/UISelect";
import useConfig from "../../../../hooks/app/useConfig";
import useEventData from "../../../../hooks/data/useEventData";
import useCurrentUser from "../../../../hooks/app/useCurrentUser";
import useToggableElm from "../../../../hooks/helper/useToggableElm";
import { AnnouncementSchema } from "../../../../schemas";
import {TimezonesList} from "../../../../constants";
import EventBlastViewer from "./viewEventBlastModal";

function getISODateFormat(date) {
	return formatDate((date || new Date()), 'yyyy-MM-dd');
}

function getISOTimeFormat(date) {
	return formatDate((date || new Date()), 'HH:mm');
}

export default function EventBlastModal({ onCloseModal, onSave, onDelete, defaultValues, boothOptions }) {
	const [ eventProfile ] = useEventData();
	const [ currentUser ] = useCurrentUser();
	const { apiHost } = useConfig();
	const [ serverError, setServerError ] = useState('');
	const [ dateError, setDateError ] = useState('');
	const isPastNotification = useMemo(() => defaultValues && defaultValues.startTime && new Date(defaultValues.startTime) < new Date());
	const defaultStartTime = useMemo(()=> utcToZonedTime(defaultValues?.startTime || new Date(), eventProfile.timezone), [defaultValues]);
	const [ startTime, setStartTime ] = useState({ _date: defaultStartTime, dateLabel: getISODateFormat(defaultStartTime), hourLabel: getISOTimeFormat(defaultStartTime) });
	const [ DeleteToggleLink, _, toggleDelete ] = useToggableElm({
		type: ['a', 'p'],
		callbacks: [ shouldDeleteItem ],
		text: ['Delete Notification', <><span className="spinner h-5 w-5 inline-block" />&nbsp;Deleting Ad</>],
		classNames: ['text-red-700 mx-auto sm:mx-0 mt-2 sm:mt-0', 'flex items-center text-red-700 mx-auto sm:mx-0 mt-2 sm:mt-0']
	});

	if (isPastNotification) {
		return <EventBlastViewer onCloseModal={shouldModalClose} startTime={defaultStartTime} eventBlast={defaultValues} timezone={TimezonesList[eventProfile.timezone]} deleteButton={DeleteToggleLink} />;
	}

	const { register, handleSubmit, setValue, watch, errors, formState: { dirty, isSubmitting } }  = useForm({
		defaultValues: defaultValues ? { ...defaultValues, booth: (defaultValues.booth && defaultValues.booth._id ? defaultValues.booth._id : null) } : { ...INITIAL_ANNOUNCEMENTS, event: eventProfile.id },
		validationSchema: AnnouncementSchema
	});
	let watchedItems = watch();

	useEffect(() => {
		register({ name: 'booth', value: (defaultValues && defaultValues.booth && defaultValues.booth._id ? defaultValues.booth._id : null) });
		register({ name: 'event', type: 'custom' });
		register({ name: 'imageUrl', type: 'custom' });
	},[]);

	function setUploadFile(name, url) {
		setValue(name, url);
	}

	function submitData(values) {
		if (values.ctaUrl && values.booth) {
			return alert("Notifications can only have one redirection. Please choose either website url or booth as a redirection for this ad.")
		}
		// if (!values.ctaUrl && !values.booth) {
		// 	return alert("Notifications need to have at least one redirection. Please choose either website url or booth as a redirection for this ad.")
		// }
		let submittedStartTime = setMilliseconds(setSeconds(zonedTimeToUtc(startTime._date, eventProfile.timezone), 0), 0);

		let now = new Date();
		
		if (values.asap) {
			submittedStartTime = setMilliseconds(setSeconds(addMinutes(zonedTimeToUtc(utcToZonedTime(now, eventProfile.timezone), eventProfile.timezone), 2), 0), 0);
		}

		if (submittedStartTime < now) {
			setDateError('Date and Scheduled Time must be in the future.');
			return;
		}
		
		let payload = {
			...values,
			booth: values.booth !== '' ? values.booth : null,
			startTime: submittedStartTime.toISOString(),
		}
		return new Promise((resolve, reject) => {
			axios({
				url: `${apiHost}/village/events/${eventProfile.urlSlug}/announcements${ defaultValues && defaultValues._id ? `/${defaultValues._id}` : '' }`,
				method: 'POST',
				withCredentials: true,
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'Authorization': `Bearer ${ currentUser.token }`
				},
				data: payload
			}).then(({ data }) => {
				if (data.message) {
					setServerError(data.message);
					return reject();
				}
				onSave(data);
				onCloseModal();
				return resolve();
			}).catch(e => {
				setServerError(e.message);
				return reject();
			});
		});
	}

	function shouldModalClose() {
		let hasDateChanged = defaultValues && (defaultStartTime > startTime._date || defaultStartTime < startTime._date);
		let isDirty = dirty || hasDateChanged;
		if (!isDirty || isDirty && confirm("It seems you've changed some info, do you want to close this?")) {
			onCloseModal();
		}
	}

	function shouldDeleteItem() {
		if (prompt("Type 'DELETE' to confirm.") === 'DELETE') {
			return new Promise((resolve, reject) => {
				axios({
					url: `${apiHost}/village/events/${eventProfile.urlSlug}/announcements/${defaultValues._id}`,
					method: 'DELETE',
					withCredentials: true,
					headers: {
						'X-Requested-With': 'XMLHttpRequest',
						'Authorization': `Bearer ${ currentUser.token }`
					}
				}).then(({ data }) => {
					if (data.message) {
						setServerError(data.message);
						return reject();
					}
					onDelete(defaultValues);
					return resolve();
				}).catch(e => {
					setServerError(e.message);
					return reject();
				});
			});
		} else {
			toggleDelete(false)
		}
	}

	function handlePickedStartDate(newDate) {
		let hours = getHours(startTime._date);
		let minutes = getMinutes(startTime._date);
		let _date = setMinutes(setHours(newDate || startTime._date, hours), minutes);
		setStartTime(state => ({...state, _date, dateLabel: getISODateFormat(_date)}));
		setDateError('');
	}

	function handleStartTimeChange({ hour, minute }) {
		let newDate = setMinutes(setHours(startTime._date, hour), minute);
		setStartTime(state => ({...state, _date: newDate, dateLabel: getISODateFormat(newDate), hourLabel: `${hour}:${minute}`}));
		setDateError('');
	}

	function handleBoothSelect(value) {
		setValue('booth', value);
	}

	return (<div className="fixed z-20 bottom-0 overflow-auto inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">

		<div className="fixed inset-0 transition-opacity">
			<div className="absolute inset-0 bg-gray-500 opacity-75" />
		</div>

		<div className="bg-white rounded-md shadow-xl transform transition-all sm:max-w-3xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<form onSubmit={handleSubmit(submitData)} className="mb-0">

				<div className="bg-gray-200 px-4 py-3 sm:px-6">
					<h3 className="text-lg uppercase leading-6 font-normal text-gray-900">{defaultValues ? 'Edit' : 'Create'} Notification</h3>
				</div>

				<div className="flex flex-col sm:flex-row ">
					<div className="px-6 py-3 flex-grow">
						{ serverError && <p className="mt-1 mb-2 text-sm text-red-700">{ serverError }</p> }
						<strong className="uppercase text-sm font-medium leading-none tracking-snug text-center mb-4">Details</strong>

						<div className="flex">
							<div className={ defaultValues ? "mt-2 w-full" : "mt-2 w-3/4"}>
								<div className="flex justify-between">
									<label htmlFor="title" className="block text-xs font-light uppercase leading-normal text-gray-700">Headline</label>
									<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal text-red-700">Required</span>
								</div>
								<input name="title" id="title" ref={register} maxLength={40} className="mt-1 base-input" placeholder="Something describing the notification" />
								{ errors.title && <p className="mt-1 text-sm text-red-700" id="email-error">{ errors.title.message }</p> }
							</div>
							{ !defaultValues && (
								<div className="mt-2 ml-4 w-1/4">
									<div className="flex justify-between mb-1">
										<label htmlFor="type" className="block text-xs font-light uppercase leading-normal text-gray-700">Placement</label>
									</div>
									<UISelect options={ [{ value: 'popup', text: 'Pop-up'}, { value: 'slidein', text: 'Slide In'}] }
														name="type" register={register} classes="w-full" />
								</div>
							) }
						</div>

						<div className="flex flex-col sm:flex-row">
							<div className="w-full mt-0 sm:mt-2">
								<div className="flex justify-between">
									<label htmlFor="description" className="block text-xs font-light uppercase leading-normal text-gray-700">Description</label>
									<p className="block text-xs font-light uppercase leading-normal text-gray-700">Max 160 chars</p>
								</div>
								{ errors.description && <p className="mt-1 text-sm text-red-700">{ errors.description.message }</p> }
								<textarea name="description" id="description" ref={register} maxLength={160} className="mt-1 base-input h-16 resize-none" />
							</div>
						</div>

						<div className="mt-4">
							<div className="flex justify-between">
								<label htmlFor="description" className="block text-xs font-light uppercase leading-normal text-gray-700">When should this be scheduled?</label>
								<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal text-red-700">Required</span>
							</div>
							{ !defaultValues && (
								<div className="w-full flex items-center">
									<label htmlFor="asap" className="block text-xs font-light uppercase leading-normal text-gray-700">As soon as possible <span className="capitalize">(typically within the next two minutes)</span></label>
									<input type="checkbox" name="asap" id="asap" ref={register} className="mt-1 h-8 w-8" />
								</div>
							) }
							{ !watchedItems.asap && (
								<>
									<p className="text-sm font-light leading-normal text-gray-700">Based on event time zone: <span className="text-xs font-italic bg-gray-200 rounded px-2 py-1">{TimezonesList[eventProfile.timezone]}</span></p>
									<div className="flex flex-col sm:flex-row mt-2">
										<div className="w-full">
											<label htmlFor="date" className="block text-xs font-light leading-normal text-gray-700">Date</label>
											<DatePicker defaultDate={startTime._date} onPickDate={ handlePickedStartDate } />
										</div>
										<div className="flex flex-col ml-auto pt-1 sm:pt-auto sm:ml-6 w-full sm:w-1/3">
											<label htmlFor="startTime" className="block text-xs font-light leading-normal text-gray-700">Scheduled Time</label>
											<TimePicker value={startTime.hourLabel} onSelectedTime={ handleStartTimeChange } />
										</div>
									</div>
									{ dateError && <p className="mt-1 text-sm text-red-700">{ dateError }</p> }
								</>
							) }
						</div>

						<div className="flex mt-3 justify-between">
							<strong className="uppercase tracking-normal text-sm">Call to Action</strong>
							<p className="text-xs leading-5 text-gray-500 uppercase tracking-normal">The redirection for a notification can either be an external website url or a booth.</p>
						</div>

						<div className="flex items-center">
							<div className="mt-2 w-1/2">
								<div className="flex justify-between">
									<label htmlFor="ctaUrl" className="block text-xs font-light uppercase leading-normal text-gray-700">Website Url</label>
								</div>
								<input name="ctaUrl" id="ctaUrl" ref={register} disabled={watchedItems.booth !== '' && watchedItems.booth !== null && watchedItems.booth !== 'None'} className="mt-1 base-input" placeholder="http://your-awesome-domain.com" />
								{ errors.ctaUrl && <p className="mt-1 text-sm text-red-700">{ errors.ctaUrl.message }</p> }
							</div>
							<p className="text-sm mx-4 mt-6">Or</p>
							<div className="mt-2 w-1/2">
								<div className="flex justify-between">
									<label htmlFor="booth" className="block text-xs font-light uppercase leading-normal text-gray-700">Booth</label>
								</div>
								<SelectDropdown
									id="booth" name="booth" selectClasses={watchedItems.ctaUrl !== '' ? 'bg-gray-200' : ''}
									options={ boothOptions } value={watchedItems.booth || ''} disabled={watchedItems.ctaUrl !== ''}
									onSelection={handleBoothSelect}
									classes="w-full"
								/>
							</div>
						</div>

						{ (watchedItems.type || defaultValues.type) === 'popup' && (
							<div className="mt-2">
								<div className="flex justify-between">
									<label htmlFor="ctaLabel" className="block text-xs font-light uppercase leading-normal text-gray-700">CTA Button Label</label>
								</div>
								<input name="ctaLabel" id="ctaLabel" ref={register} className="mt-1 base-input" maxLength={30} placeholder="Learn More" />
							</div>
						) }

						{ (watchedItems.type || defaultValues.type) === 'popup' && (
							<>
								<div className="mt-2">
									<div className="flex justify-between">
										<strong className="block uppercase tracking-normal text-sm">Image</strong>
										<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal">(Approved Sizes: 500px 250px or a 2:1 ratio)</span>
									</div>
									<UploadTarget folder={`events/${eventProfile.id}/village/ads/`}
																name="imageUrl"
																image={ watchedItems.imageUrl }
																aspectRatio={ 2 }
																enableCropping={ true }
																validSizes={ [{width: 500, height: 250}] }
																onChange={setUploadFile} />
									{ errors.imageUrl && <p className="mt-1 text-sm text-red-700" id="email-error">Pick a valid photo.</p> }
								</div>
							</>
						)}

					</div>
				</div>
				<div className="px-4 py-3 flex flex-col-reverse sm:flex-row justify-between items-center border-gray-300 border-t">
					{ defaultValues ? <DeleteToggleLink /> : <span /> }
					<div className="flex flex-col-reverse sm:flex-row items-center">
						<span className="text-gray-400 mx-auto sm:mx-0 mt-2 sm:mt-0 cursor-pointer" onClick={shouldModalClose}>Cancel</span>
						<button type="submit" disabled={isSubmitting} className="float-right btn-hub-green sm:w-auto sm:ml-2 mt-2 sm:mt-0 text-sm flex items-center">
							{ !isSubmitting && 'Save Changes' }
							{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
						</button>
					</div>
				</div>
			</form>
		</div>

	</div>);
}
import React, {useMemo} from 'react';
import AddToCalendar from 'react-add-to-calendar';
import {CalendarSVG} from "../../../components/Icons";
import {differenceInMinutes, addMinutes} from "date-fns";

const calendarTypes = [
  { google: 'Google Calendar' },
  { apple: 'Apple Calendar' },
  { outlookcom: 'Outlook Calendar' }
];

const stopPropagation = (e) => {
  console.log('stopPropagation', e);
  e.stopPropagation();
  e.preventDefault();
  return false;
};

export default function AddToCalendarButton({ _id, programming, name, cardDescription, startTime, endTime, buttonClasses, dropdownClasses, sessionUrl }) {
  const duration = useMemo(() => {
    let timedDuration = startTime && endTime ? differenceInMinutes(new Date(endTime), new Date(startTime)) : 0;
    return programming && programming.length > 0 ? programming.map(p => p.duration).reduce((num, duration) => num + duration, 0) : timedDuration;
  }, [ programming, startTime, endTime ]);
  const defaultStartTime = useMemo(()=> new Date(startTime), [startTime]);
  const defaultEndTime = useMemo(()=> addMinutes(new Date(startTime), duration), [endTime, duration]);
  const sessionEvent = {
    title: name,
    description: cardDescription,
    location: sessionUrl,
    startTime: defaultStartTime,
    endTime: defaultEndTime
  }

  return <div aria-label='Add to Calendar' className={`flex p-2 h-6 w-6 relative ${buttonClasses}`} onClick={stopPropagation}>
    <AddToCalendar rootClass="absolute top-0 right-0 h-6 w-6 text-center z-50 cursor-pointer focus:border-solid focus:border-2 focus:p-2 focus:border-gray-300"
                   buttonClassOpen="hidden" buttonLabel='AC' buttonClassClosed="text-transparent"
                   dropdownClass={`calendar-add-dropdown absolute top-6 right-0 mt-2 w-56 rounded-md shadow-lg z-50 bg-white shadow-md ${dropdownClasses}`}
                   listItems={calendarTypes} event={sessionEvent} />
    <CalendarSVG classes="h-6 w-6 absolute top-0 right-0 z-20" onClick={stopPropagation} />
  </div>
}

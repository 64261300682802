import React, { useState, useEffect, useLayoutEffect, useRef, useMemo, forwardRef } from 'react';
import useShowTimes, { getVideoObject } from '../../../hooks/data/useShowTimes';
import ReactPlayer from 'react-player/lazy';
import useAsync from '../../../hooks/helper/useAsync';
import useWindowScroll from "../../../hooks/helper/useWindowScroll";
import useEventEmitter from "../../../hooks/helper/useEventEmitter";
import useToggle from "../../../hooks/helper/useToggle";
import usePermissions from "../../../hooks/app/usePermissions";
import { FilmSVG } from "../../../components/Icons";
import useWhiteLabelSlugs from "../../../hooks/app/useWhitelabelSlugs";
import { VerticalAdPlacement as AdPlacement } from "../../../components/AdPlacement";
import useConfig from "../../../hooks/app/useConfig";

const config = {
	youtube: {
		playerVars: {
			autoplay: false,
			controls: true
		}
	},
	vimeo: {
		playerOptions: {
			background: false,
			controls: true,
			autoplay: false
		}
	},
	twitch: {
		options: {
			autoplay: false
		}
	},
	wistia: {
		options: {
			autoPlay: false,
			playbar: true,
		}
	}
};

const INITIAL_STATE = {
	
}

const STAGE_STATES = {
	inMainHall: {
		forceClose: false,
		forceFloat: false,
		shouldFloat: false,
		closedByBoothOrSession: false,
	},
	notInMainHall: {
		forceClose: false,
		forceFloat: true,
		shouldFloat: true,
		closedByBoothOrSession: false,
	},
	closedByUser: {
		forceClose: true,
		forceFloat: false,
		shouldFloat: false,
		closedByBoothOrSession: false,
	},
	closedByBoothOrSession: {
		forceClose: true,
		forceFloat: false,
		shouldFloat: false,
		closedByBoothOrSession: true,
	},
	
}

export const MainStage = forwardRef(({ insertedVideo, displayChat, adIsVisible }, ref) => {
	const playerRef = ref || useRef(null);
	const { boothSlug : vendorSlug, sessionId } = useWhiteLabelSlugs();
	const { on } = useEventEmitter();
	const { y: windowY } = useWindowScroll();
	const { isLoggedIn, mode } = usePermissions();
	const showPlaceholderVideo = mode === 'edit';
	const stickyPointRef = useRef(null);
	const previousPlaySetting = useRef(null);
	const [ video, setVideo ] = useState(null);
	const { mainStageVideo } = useShowTimes();
	const [ state, setState ] = useState(STAGE_STATES.inMainHall);
	const [ playRequest, setRequestToTogglePlay ] = useState();
	const [ playVideo, setPlayVideo ] = useState(true);
	const { execute, pending, value : mainStageInsertedVideo } = useAsync(() => getVideoObject({url: insertedVideo, displayChat }), null);
	const liveWithChat = useMemo(() => video && video.displayChat && !adIsVisible, [video, mainStageVideo, adIsVisible]);

	useEffect(() => {
		if (state.forceClose || previousPlaySetting.current === playRequest) {
			return;
		}
		setPlayVideo(playRequest);
	}, [ playRequest ]);

	useEffect(() => {
		return on('close:main_stage', () => {setState(STAGE_STATES.closedByBoothOrSession); changePlayStatus(false)});
	}, []);

	useEffect(() => {
		return on('pause:main_stage', () => setRequestToTogglePlay(false));
	}, []);

	useEffect(() => {
		return on('play:main_stage', () => setRequestToTogglePlay(true));
	}, []);

	useEffect(function() {
		let isInMainHall = !vendorSlug && !sessionId;
		let closedByUser = state.forceClose && !state.closedByBoothOrSession;

		if (isInMainHall) {
			setState(STAGE_STATES.inMainHall);
		} else if (!closedByUser) {
			setState(STAGE_STATES.notInMainHall);
		}
	}, [ vendorSlug, sessionId ]);

	useLayoutEffect(() => {
		if (vendorSlug || sessionId || state.forceClose && !playVideo) {
			return;
		}
		const timer = setTimeout(() => {
			setState(s => ({...s, shouldFloat: (stickyPointRef && stickyPointRef.current && windowY >= stickyPointRef.current.offsetTop)}));
		}, 100);
		return () => clearTimeout(timer);
	}, [ windowY, vendorSlug, sessionId ]);

	useEffect(() => {
		if (!insertedVideo) return;
		execute();
	}, [ insertedVideo ]);

	useEffect(function() {
		if (pending || !mainStageInsertedVideo) {
			return;
		}
		setVideo(mainStageInsertedVideo);
		changePlayStatus(true);
	}, [ pending, mainStageInsertedVideo ]);

	useEffect(function() {
		setVideo(mainStageVideo);
		changePlayStatus(!mainStageInsertedVideo);
	}, [ mainStageVideo, pending, mainStageInsertedVideo ]);

	if (!video) {
		return null;
	}

	function changePlayStatus(shouldPlay) {
		previousPlaySetting.current = shouldPlay;
		setPlayVideo(shouldPlay);
	}

	function dismissVideo(e) {
		e && e.preventDefault();
		changePlayStatus(false);
		setState(STAGE_STATES.closedByUser);
	}

	function handleOnPlay() {
		changePlayStatus(true);
	}

	function handleOnPause() {
		changePlayStatus(false);
	}

	return (<>
		{ state.shouldFloat && !state.forceFloat && <div className="bg-black h-64 sm:h-hero block w-full" /> }
		<div className={`main-stage-container ${ (vendorSlug || sessionId) && state.forceClose && 'hidden' } flex flex-fix bg-black ${ state.shouldFloat && `fixed bottom-0 right-0 z-40 mr-6 ${ isLoggedIn && mode === 'edit' && 'sm:mr-4/12  xl:mr-2/12' } mb-16 sm:mb-6 rounded ` }`}>
			{ state.shouldFloat && <button onClick={ dismissVideo } className="absolute top-0 right-0 -mt-8 mr-4 py-1 px-3 rounded-lg bg-gray-200 text-xs uppercase text-gray-600 hover:bg-gray-300 shadow ">Close Video</button> }
			<div className={`flex flex-grow ${ state.shouldFloat ? ' flex-col ' : ' flex-col md:flex-row ' } ${ state.shouldFloat ? ' p-2 sm:py-2 sm:px-4 ' : ' sm:h-hero p-2 ' }`}>
				{ !showPlaceholderVideo ? (
					<div className={ state.shouldFloat ? ' w-64 md:w-84 h-40 md:h-57 ' : ' h-64 sm:h-full w-full '}>
						<ReactPlayer className={`youtubeContainer ${ liveWithChat ? ' h-hero w-full md:w-3/4 ' : ' h-full ' } ${ video.type === 'wistia' && 'wistiaPlayer' }`}
												 url={ video.url }
												 ref={ playerRef }
												 playing={ playVideo }
												 onPlay={ handleOnPlay }
												 onPause={ handleOnPause }
												 config={ config }
												 width="100%" height="100%"
						/>
					</div>
				) : (
					<div className="flex flex-col justify-center items-center h-full w-full">
						<FilmSVG classes="text-gray-700 fill-current w-32 h-32" />
						<div className="mt-6 px-3 py-2 rounded bg-gray-200 font-medium font-mono text-lg capitalize text-center">
							{ video.type } Video<br /><span className="text-xs block italic">(Not shown in edit mode)</span>
						</div>
					</div>
				)
				}
				{ !showPlaceholderVideo && liveWithChat ? (
					<div className={`youtubeChatContainer ${ state.shouldFloat ? 'h-64 w-full' : 'h-64 md:h-auto w-full md:w-1/4 '}`}>
						<iframe title="main stage video chat" allowFullScreen="" scrolling="no" width="200px" height="200px" frameBorder="0" src={ video.chatUrl } />
					</div>
				) : null }
			</div>
		</div>
		<div ref={stickyPointRef} className="w-full h-0" />
	</>);
})

export default function MainStageContainer(props) {
	const { allowDisplayAds } = useConfig();
	const { boothSlug } = useWhiteLabelSlugs();
	const [ adIsVisible, toggleAdVisibility ] = useToggle(false);
	const [ forceHideAd, setForceHideAd ] = useState(false);

	function handleAdVisibilityChange(isAdVisible) {
		toggleAdVisibility(isAdVisible);
	}

	useEffect(function() {
		setForceHideAd(boothSlug);
	}, [ boothSlug ]);

	return (
		<div className="bg-black w-full flex flex-col sm:flex-row">
			<div className={adIsVisible ? 'w-full sm:w-3/4' : 'w-full'}>
				<MainStage {...props} adIsVisible={ adIsVisible } />
			</div>
			{ allowDisplayAds && !forceHideAd && <AdPlacement onAdVisibilityChange={handleAdVisibilityChange} /> }
		</div>
	)
}
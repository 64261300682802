
export default function useConfig() {
	return {
		env: window.location.host.split('.')[0] === 'beta' ? 'beta' : process.env.NODE_ENV,
		apiHost: process.env.API_HOST,
		appVersion: process.env.APP_VERSION,
		allowTracksSessionsSpeakers: process.env.ALLOW_TRACKS_SESSIONS_SPEAKERS === 'true',
		allowVirtualBooth: process.env.ALLOW_VIRTUAL_BOOTH === 'true',
		allowTicketAccess: process.env.ALLOW_TICKET_ACCESS === 'true',
		allowSocialWidgets: process.env.ALLOW_SOCIAL_WIDGETS === 'true',
		allowAnnouncements: process.env.ALLOW_ANNOUNCEMENTS === 'true',
		allowRegfox: process.env.ALLOW_REGFOX === 'true',
		allowSortingExhibitors: process.env.ALLOW_SORT_EXHIBITORS === 'true',
		allowIframeEmbed: process.env.ALLOW_IFRAME_EMBED === 'true',
		allowDisplayAds: process.env.ALLOW_DISPLAY_ADS === 'true',
		allowGlobalAgeVerify: process.env.ALLOW_GLOBAL_AGE_VERIFY === 'true',
		allowHeaderNavigationLinks: process.env.ALLOW_HEADER_NAVIGATION_LINKS === 'true',
		allowAdvanceStyles: process.env.ALLOW_ADVANCE_STYLES === 'true',
		allowAdvanceStylesFor: process.env.ALLOW_ADVANCE_STYLES_FOR.split('|')
	}
}
import React, { useState, createContext } from 'react';

const INITIAL_STATE = [];

const NotificationsContext = createContext([{}, () => {}]);

function NotificationsProvider(props) {
	const [state, setState] = useState(INITIAL_STATE);

	return (
		<NotificationsContext.Provider value={[state, setState]}>
			{props.children}
		</NotificationsContext.Provider>
	);
}

export { NotificationsContext, NotificationsProvider, INITIAL_STATE };
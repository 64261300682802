import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import { decode } from 'jsonwebtoken';

// hooks
import useQueryParams from "../hooks/helper/useQueryParams";
import useLocalStorage from "../hooks/helper/useLocalStorage";
import useNotifications from "../hooks/app/useNotifications";
import useInterval from "../hooks/helper/useInterval";
import useConfig from "../hooks/app/useConfig";

const WINDOW_NAME_PUBLIC = 'forced_public_view';
const WINDOW_NAME_PREVIEW = 'forced_preview_view';
const WINDOW_NAME_EDIT = 'forced_edit_view';
const LOCAL_STORAGE_KEY = 'village_otp';
const INITIAL_STATE = {
	loading: true,
	view: 'public',
	booths: null
};
const UserContext = createContext([{}, () => {}]);
const SESSION_NOTIFICATION_THRESHOLDS = [ 10 * 60, 60, -1 ]; // 10 mins, 1 min, end marker

function UserProvider(props) {
	const query = useQueryParams();
	const { apiHost } = useConfig();
	const { append, remove } = useNotifications();
	const [ state, setState ] = useState(INITIAL_STATE);
	const oneTimePassword = query.get('otp');
	const publicQueryParam = query.get('public');
	const previewQueryParam = query.get('preview');
	const editQueryParam = query.get('edit');
	const [ storedOneTimePassword, setStoredOneTimePassword, hasCheckedStorage ] = useLocalStorage(LOCAL_STORAGE_KEY);
	const [ numSessionTimeoutWarnings, setNumSessionTimeoutWarnings ] = useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			let decoded = decode(storedOneTimePassword);
			if (decoded && (Math.round((new Date()).getTime() / 1000) - 10 * 60 * 1000) > decoded.exp) {
				// we're ten minutes away from expiration...
				// append({
				//  type: 'confirm',
				//  message: 'You're going to expire'
				//  callback: () => { ajax to get new token, set token via setStoredOneTimePassword },
				//  callbackLabel: 'Extend Session'
				// })
			}
		}, 30 * 1000)
		return () => clearInterval(timer);
	})

	// deps storedOneTimePassword
	// if window.name === '' then...
	// Preview mode is having an JWT AND (?preview param or window.name == WINDOW_NAME_PREVIEW)
	// Edit mode is having an JWT AND ?edit param or an otp param
	// Public mode is having no JWT or having a ?preview param
	// else do nothing
	useEffect(() => {
		let wasParamPassed = publicQueryParam === '' || previewQueryParam === '' || editQueryParam === '' || oneTimePassword === '';
		if (storedOneTimePassword && (wasParamPassed || !window.name)) {
			if (publicQueryParam === '') {
				window.name = WINDOW_NAME_PUBLIC;
			} else if (previewQueryParam === '') {
				window.name = WINDOW_NAME_PREVIEW;
			} else {
				window.name = WINDOW_NAME_EDIT;
			}
		} else if (hasCheckedStorage && !storedOneTimePassword && !window.name) {
			window.name = WINDOW_NAME_PUBLIC;
		} else if (oneTimePassword) {
			window.name = WINDOW_NAME_EDIT;
		}
	}, [ storedOneTimePassword, oneTimePassword, publicQueryParam, previewQueryParam, editQueryParam ])

	useEffect(() => {
		if (storedOneTimePassword === ''){
			return;
		}
		let decoded = decode(storedOneTimePassword);
		if (decoded && Math.round((new Date()).getTime() / 1000) > decoded.exp) {
			window.name = WINDOW_NAME_PUBLIC;
			setStoredOneTimePassword(null);
			setState(s => ({...s, loading: false}));
		} else if (decoded) {
			document.body.classList.add('loggedin-user');
			setState(s => ({ ...s, ...decoded, loading: false, token: storedOneTimePassword }));
		}
	}, [storedOneTimePassword]);

	useEffect(function() {
		if (!oneTimePassword) {
			setState(s => ({...s, loading: false}));
			return;
		}
		axios.get(`${ apiHost }/village/verify`, {
			headers: {
				'Authorization': `Bearer ${oneTimePassword}`
			}
		}).then(({data: user}) => {
			console.log('otp is valid');
			setStoredOneTimePassword(oneTimePassword);
			setState(s => ({...s, ...user}));
		}).catch(() => {
			console.error('otp is bad');
			setState(s => ({...s, loading: false}));
			setStoredOneTimePassword(null);
		});
	}, [oneTimePassword]);

	useInterval(() => {
		let decoded = decode(storedOneTimePassword);
		if (!decoded) return;

		let timeRemaining = decoded.exp - Math.round((new Date()).getTime() / 1000);
		let index = Math.min(numSessionTimeoutWarnings, SESSION_NOTIFICATION_THRESHOLDS.length);
		let currentThreshold = SESSION_NOTIFICATION_THRESHOLDS[index];

		const callback = (notificationId) => {
			axios.get(`${ apiHost }/village/events/refresh-token/${ storedOneTimePassword }`)
				.then((response) => {
					setStoredOneTimePassword(response.data.token)
					setNumSessionTimeoutWarnings(0);
					remove(notificationId);
				})
				.catch(() => {
					// unable to refresh; token must be expired
					window.location.assign('https://eventhub.net/login');
				});
		};

		if (timeRemaining < 0) {
			// expired
			window.location.assign('https://eventhub.net/login');
		} else if (timeRemaining < currentThreshold) {
			setNumSessionTimeoutWarnings(numSessionTimeoutWarnings + 1); // keep track of the number of warnings given
			let userFriendlyTimeRemaining = Math.max(1, Math.round(timeRemaining / 60));
			let minutesText = userFriendlyTimeRemaining === 1 ? 'minute' : 'minutes';
			const notificationMessage = `You will be signed out in ${userFriendlyTimeRemaining} ${minutesText}. Do you need more time?`;
			const acceptMessage = 'Yes, keep me signed in';
			append('', notificationMessage, currentThreshold * 1000, callback, acceptMessage);
		}
	},  1000); // check every 30 seconds

	function logoutCurrentUser() {
		setState(INITIAL_STATE);
		setStoredOneTimePassword(null);
	}

	return (
		<UserContext.Provider value={[state, logoutCurrentUser]}>
			{props.children}
		</UserContext.Provider>
	);
}

export { UserContext, UserProvider, INITIAL_STATE, WINDOW_NAME_PUBLIC, WINDOW_NAME_PREVIEW, WINDOW_NAME_EDIT };

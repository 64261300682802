import React, { useEffect } from 'react';

export default function useBlur(ref, handleBlurEffect = () => console.warn('No function was passed to handle blur effect.')) {
	function blurEffect(event) {
		if (ref.current && !ref.current.contains(event.target)) {
			handleBlurEffect();
		}
	}

	useEffect(function() {
		window.addEventListener('click', blurEffect);
		return () => {
			window.removeEventListener('click', blurEffect);
		}
	}, [ref]);
}
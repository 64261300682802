import React, { createContext, useState } from 'react';

const EventsContext = createContext({});

function EventsProvider(props) {
	const [ events, setEvents ] = useState({});

	function on(event, cb) {
		let listeners = (events[event] || []);
		listeners.push(cb);
		setEvents(e => ({ ...e, [event]: [...listeners] }));
		return () => setEvents(e => ({ ...e, [event]: [...e[event].filter(i => i !== cb)] }));
	}

	function emit(event, ...args) {
		for (let i of events[event] || []) {
			i(...args)
		}
	}

	return (
		<EventsContext.Provider value={{ on, emit }}>
			{props.children}
		</EventsContext.Provider>
	);
}

export { EventsContext, EventsProvider };
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { ProgrammingSlotSchema as validationSchema } from '../../../schemas';
import AgeVerificationConfiguration from "../../AgeVerificationFormFields";
import usePermissions from "../../../hooks/app/usePermissions";

const SLOT_TYPES = [
	{ text: 'Video Content', value: 'embed'},
	{ text: 'External Conference URL', value: 'conference-link'},
	{ text: 'Video Chat', value: 'video-chat'},
	{ text: 'Live Presentation', value: 'video-chat-presentation'},
	{ text: 'Live Discussion', value: 'video-chat-discussion'},
	{ text: 'Zoom Meeting', value: 'zoom-meeting'},
	{ text: 'Zoom Webinar', value: 'zoom-webinar'}
];

const INITIAL_VIDEO_CHAT_SETTINGS = {
	'video-chat': {
		enableChat: false,
		enableRecording: false,
		ownerSoundOn: false,
		ownerVideoOn: false,
		ownerScreenShare: false,
		participantsAllowed: false,
		participantAllowVideo: false,
		participantAllowSound: false,
		participantStartVideo: false,
		participantStartSound: false,
		participantScreenShare: false,
	},
	'video-chat-presentation': {
		enableChat: true,
		enableRecording: false,
		ownerSoundOn: true,
		ownerVideoOn: true,
		ownerScreenShare: true,
		participantsAllowed: false,
		participantAllowVideo: false,
		participantAllowSound: false,
		participantStartVideo: false,
		participantStartSound: false,
		participantScreenShare: false,
	},
	'video-chat-discussion': {
		enableChat: true,
		enableRecording: false,
		ownerSoundOn: true,
		ownerVideoOn: true,
		ownerScreenShare: true,
		participantsAllowed: true,
		participantAllowVideo: true,
		participantAllowSound: true,
		participantStartVideo: true,
		participantStartSound: true,
		participantScreenShare: true,
	}
};

const urlRegex = /(?:https?:\/\/)[^\s]+[\w]/g;

export default function ProgrammingSlotModal({ defaultValues, onSave, onCloseModal, onDelete }) {
	const { isAdmin } = usePermissions();
	const virtualChatAgeRef = useRef(null);
	const zoomAgeRef = useRef(null);
	const [ type, setType ] = useState(defaultValues.type);
	const [ customUrl, setUrl ] = useState(defaultValues.url);
	const [ urlError, setUrlError ] = useState('');
	const [ videoChat, setVideoChat ] = useState({...defaultValues.videoChat});
	const { register, handleSubmit, errors, watch, setValue, formState: { dirty, isSubmitting } } = useForm({ defaultValues: {
		...defaultValues,
		enableAgeVerification: defaultValues.type.indexOf('zoom') > -1 ? defaultValues.zoom?.enableAgeVerification : defaultValues.videoChat?.enableAgeVerification,
		ageVerification: defaultValues.type.indexOf('zoom') > -1 ? defaultValues.zoom?.ageVerification : defaultValues.videoChat?.ageVerification,
		zoomMeetingNumber: defaultValues.zoom?.meetingNumber,
		zoomPassword: defaultValues.zoom?.password,
		}, validationSchema });
	const watchedItems = watch();

	useEffect(() => {
		if (videoChat.participantStartVideo && !videoChat.participantAllowVideo) {
			setVideoChat(v => ({...v, participantStartVideo: false}));
		}
		if (videoChat.participantStartSound && !videoChat.participantAllowSound) {
			setVideoChat(v => ({...v, participantStartSound: false}));
		}
	}, [ videoChat.participantAllowSound, videoChat.participantAllowVideo ]);

	useEffect(() => {
		if (videoChat.participantStartVideo && !videoChat.participantAllowVideo) {
			setVideoChat(v => ({...v, participantAllowVideo: true}));
		}
		if (videoChat.participantStartSound && !videoChat.participantAllowSound) {
			setVideoChat(v => ({...v, participantAllowSound: true}));
		}
	}, [ videoChat.participantStartVideo, videoChat.participantStartSound ]);

	useEffect(() => {
		if (!videoChat.participantsAllowed) {
			setVideoChat(v => ({
				...v,
				participantAllowSound: false,
				participantAllowVideo: false,
				participantScreenShare: false
			}));
		}
	}, [ videoChat.participantsAllowed ]);

	useEffect(() => {
		register({ name: 'ageVerification', type: 'custom' });
		register({ name: 'type', type: 'custom' });
		register({ name: 'url', type: 'custom' });
	},[]);

	function onSubmit(values) {
		if (type.indexOf('zoom') > -1 && !values.zoomMeetingNumber.length) {
			alert("Please enter a meeting number for a zoom program type");
			return false;
		}

		if (!type.includes('video-chat') && !customUrl) {
			setUrlError(`${SLOT_TYPES.find(s => s.value === type).text} requires a url.`);
			return false;
		} else if (!type.includes('video-chat') && !customUrl.match(urlRegex)) {
			setUrlError('Must be a valid url starting with http://');
			return false;
		}

		let { name, duration, url, showChat } = values;
		let videoChatValues = null, zoom = null;
		if (type.indexOf('zoom') > -1) {
			zoom = {
				meetingNumber: values.zoomMeetingNumber.replace(/\s/g, ''),
				password: values.zoomPassword,
				enableAgeVerification: values.enableAgeVerification,
				ageVerification: values.ageVerification
			}
		} else if (type.indexOf('video-chat') > -1) {
			videoChatValues = {
				...videoChat,
				enableAgeVerification: values.enableAgeVerification,
				ageVerification: values.ageVerification
			}
		}
		onSave({
			...defaultValues,
			zoom,
			name,
			duration,
			url: customUrl,
			type,
			showChat,
			videoChat: videoChatValues
		})
	}

	function shouldModalClose() {
		if (!dirty || dirty && confirm("It seems you've enter some info, do you want to close this?")) {
			onCloseModal();
		}
	}

	function shouldDelete(e) {
		e.preventDefault();
		let answer = prompt("Type 'DELETE' to confirm.");
		if (answer === 'DELETE') {
			onDelete();
		}
	}

	function handleAgeVerification(fieldName, ref) {
		let selectedAge = parseInt(ref.current.value);
		setValue(fieldName, selectedAge);
	}

	function handleVideoChatSettingChange(e) {
		e.persist();
		setVideoChat(v => ({...v, [e.target.name]: e.target.checked}));
	}

	function handleTypeChange(e) {
		let newType = e.target.value;
		if (newType.indexOf('video-chat') > -1) {
			setVideoChat(v => ({...v, ...INITIAL_VIDEO_CHAT_SETTINGS[newType]}));
		}
		setType(newType);
	}

	function handleUrlChange(e) {
		e.preventDefault();
		setUrl(e.target.value);
	}

	return (<div className="fixed z-50 bottom-0 overflow-auto inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">

		<div className="fixed inset-0 transition-opacity">
			<div className="absolute inset-0 bg-gray-500 opacity-75" />
		</div>

		<div className="bg-white rounded-md shadow-xl transform transition-all sm:w-time-modal" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<form onSubmit={handleSubmit(onSubmit)} className="mb-0">

				<div className="bg-gray-200 rounded-t-md px-4 py-3 sm:px-6">
					<h3 className="text-lg uppercase leading-6 font-normal text-gray-900">{ defaultValues.id ? 'Edit' : 'Add'} Programming <strong>{ isAdmin && defaultValues.id ? `(${defaultValues.id})` : ''}</strong></h3>
				</div>

				<div className="flex flex-col sm:flex-row ">
					<div className="px-6 py-3 flex-grow">

						<div className="flex flex-col sm:flex-row">
							<div className="w-full mt-0 sm:mt-2">
								<div className="flex">
									<label htmlFor="name" className="block text-xs font-light uppercase leading-normal text-gray-700">Name</label>
									<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal flex justify-between italic text-red-700">*</span>
								</div>
								<input name="name" ref={register} className="mt-1 base-input" placeholder="My Awesome Stream" />
								{ errors.name && <p className="mt-1 text-sm text-red-700">We'll need a name.</p> }
							</div>
						</div>

						<div className="flex flex-col sm:flex-row">
							<div className="w-full sm:w-1/2 mt-2 ml-0 sm:mr-3">
								<div className="flex">
									<label htmlFor="type" className="block text-xs font-light uppercase leading-normal text-gray-700">Type</label>
									<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal text-red-700">*</span>
								</div>
								<div className="inline-block relative w-full mt-1">
									<select
										name="type" id="type" value={type} onChange={handleTypeChange}
										disabled={!!defaultValues._id}
										className={`${ !!defaultValues._id ? 'bg-gray-100' : '' } block appearance-none w-full text-sm font-thin text-gray-800 bg-white border-none px-2 py-1 rounded-md shadow-inner-input leading-5 tracking-normal focus:outline-none focus:shadow-outline`}>
										{ SLOT_TYPES.map(({ text, value}, tIndex) => <option key={tIndex} value={value}>{text}</option>) }
									</select>
									<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
										<svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
											<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
										</svg>
									</div>
								</div>
							</div>
							<div className="w-full sm:w-1/2 mt-0 sm:mt-2">
								<div className="flex justify-between">
									<label htmlFor="duration" className="text-xs font-light uppercase leading-normal text-gray-700">Duration<span className="ml-1 text-xs leading-5 uppercase tracking-normal italic text-red-700">*</span></label>
									<span className="text-xs leading-5 uppercase tracking-normal italic text-gray-500">in whole minutes</span>
								</div>
								<input name="duration" id="duration" type="number" ref={register} className="mt-1 base-input" placeholder="30" />
								{ errors.duration && <p className="mt-1 text-sm text-red-700">Duration must be a numeric value in minutes</p> }
							</div>
						</div>

						{ ['embed', 'conference-link'].indexOf(type) > -1 && <div className="flex flex-col sm:flex-row">
							<div className={`mt-0 sm:mt-2 w-full ${ type === 'embed' ? 'sm:w-3/4' : '' }`}>
								<div className="flex">
									{ type === 'embed' && (
										<label htmlFor="url" className="block text-xs font-light uppercase leading-normal text-gray-700">YouTube | Twitch | Vimeo | Wistia Url</label>
									) }
									{ type === 'conference-link' && (
										<label htmlFor="url" className="block text-xs font-light uppercase leading-normal text-gray-700">Zoom | GotoMeeting | Google Meet/Hangouts | Other Url</label>
									) }
									<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal text-red-700">*</span>
								</div>
								<input name="url" value={customUrl} onChange={handleUrlChange} className="mt-1 base-input" placeholder="http://example/your-link-here" />
								{ urlError && <p className="mt-1 text-sm text-red-700">{ urlError }</p> }
								{ type === 'embed' && <span className="mt-1 block text-sm font-light uppercase leading-normal text-gray-700">URL must point to video</span> }
							</div>
							{ type === 'embed' && (
								<div className="w-full sm:w-1/4 mt-2 ml-0 sm:ml-3">
									<div className="flex justify-between">
										<label htmlFor="showChat" className="block text-xs font-light uppercase leading-normal text-gray-700">Show Chat</label>
									</div>
									<input type="checkbox" name="showChat" ref={register} className="mt-1 h-8 w-8 mx-auto" />
									{ errors.showChat && <p className="mt-1 text-sm text-red-700">{ errors.showChat.message }</p> }
								</div>
							) }
						</div> }

						{ ['zoom-meeting', 'zoom-webinar'].indexOf(type) > -1 && <>
							<div className="flex flex-col sm:flex-row">
								<div className={`mt-0 sm:mt-2 w-full`}>
									<div className="flex">
										<label htmlFor="url" className="block text-xs font-light uppercase leading-normal text-gray-700">Zoom Url</label>
										<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal text-red-700">*</span>
									</div>
									<input name="url" value={customUrl} onChange={handleUrlChange} className="mt-1 base-input" placeholder="http://example.zoom.us/link-here" />
									{ urlError && <p className="mt-1 text-sm text-red-700">{ urlError }</p> }
								</div>
							</div>
							<div className="flex flex-col sm:flex-row">
								<div className={`mt-0 sm:mt-2 w-full`}>
									<div className="flex">
										<label htmlFor="url" className="block text-xs font-light uppercase leading-normal text-gray-700">Meeting Number</label>
										<span className="text-xs leading-5 text-gray-500 uppercase tracking-normal text-red-700">*</span>
									</div>
									<input name="zoomMeetingNumber" ref={register} className="mt-1 base-input" placeholder="123456789" />
								</div>
								<div className={`mt-0 sm:mt-2 w-full mt-2 ml-0 sm:ml-6`}>
									<div className="flex">
										<label htmlFor="url" className="block text-xs font-light uppercase leading-normal text-gray-700">Meeting Password</label>
									</div>
									<input name="zoomPassword" ref={register} className="mt-1 base-input" placeholder="whatever-password" />
								</div>
							</div>
							<div className="w-full sm:w-1/2 mt-2 flex flex-col">
								<AgeVerificationConfiguration
									enable={ watchedItems.enableAgeVerification }
									age={ watchedItems.ageVerification }
									onSelectAge={ () => handleAgeVerification("ageVerification", zoomAgeRef) }
									ref={ { register: register, ageSelectRef: zoomAgeRef } }
									checkBoxText={null} selectClasses="base-input"
									checkBoxName="enableAgeVerification"
									dropDownName="ageVerification"
								/>
							</div>
						</> }

						{ type.indexOf('video-chat') > -1 && (
							<>
								<div className="flex mt-2">
									<div className="flex flex-col w-full mr-3">
										<AgeVerificationConfiguration
											enable={ watchedItems.enableAgeVerification }
											age={ watchedItems.ageVerification }
											onSelectAge={ () => handleAgeVerification("ageVerification", virtualChatAgeRef) }
											ref={ { register: register, ageSelectRef: virtualChatAgeRef } }
											checkBoxText={null} selectClasses="base-input"
											checkBoxName="enableAgeVerification"
											dropDownName="ageVerification"
										/>

										<strong className="block mt-3 uppercase tracking-normal text-xs">Room Settings</strong>

										<div className="flex justify-between mt-2">
											<label htmlFor="enableChat" className="block text-xs font-light uppercase leading-normal text-gray-700">Enable Chat</label>
											<input type="checkbox" name="enableChat" id="enableChat" checked={videoChat.enableChat} onChange={handleVideoChatSettingChange} />
										</div>

										<strong className="block mt-3 uppercase tracking-normal text-xs">Host Settings</strong>

										<div className="flex justify-between mt-2">
											<label htmlFor="ownerVideoOn" className="block text-xs font-light uppercase leading-normal text-gray-700">Start with my video on</label>
											<input type="checkbox" name="ownerVideoOn" id="ownerVideoOn" checked={videoChat.ownerVideoOn} onChange={handleVideoChatSettingChange} />
										</div>

										<div className="flex justify-between mt-2">
											<label htmlFor="ownerSoundOn" className="block text-xs font-light uppercase leading-normal text-gray-700">Start with my sound on</label>
											<input type="checkbox" name="ownerSoundOn" id="ownerSoundOn" checked={videoChat.ownerSoundOn} onChange={handleVideoChatSettingChange} />
										</div>

										<div className="flex justify-between mt-2">
											<label htmlFor="ownerScreenShare" className="block text-xs font-light uppercase leading-normal text-gray-700">Allow Screen Share</label>
											<input type="checkbox" name="ownerScreenShare" id="ownerScreenShare" checked={videoChat.ownerScreenShare} onChange={handleVideoChatSettingChange} />
										</div>

									</div>
									<div className="flex flex-col w-full">

										<label htmlFor="participantLimit" className="mt-2 flex justify-between text-xs font-light uppercase leading-normal text-gray-700"><span>Participant Limit</span><span>(max 200)</span></label>
										<input type="number" name="participantLimit" className="mt-3 base-input" max={200} maxLength={3} ref={register} />
										{ errors.participantLimit && <p className="mt-1 text-sm text-red-600">{ errors.participantLimit.message }</p> }

										<strong className="block mt-3 uppercase tracking-normal text-xs">Participant Settings</strong>

										<div className="flex justify-between mt-2">
											<label htmlFor="participantsAllowed" className="block text-xs font-light uppercase leading-normal text-gray-700">Allow Participant Controls</label>
											<input type="checkbox" name="participantsAllowed" id="participantsAllowed" checked={videoChat.participantsAllowed} onChange={handleVideoChatSettingChange} />
										</div>

										{ videoChat && videoChat.participantsAllowed && <>
											<div className="flex justify-between mt-2">
												<label htmlFor="participantAllowVideo" className="block text-xs font-light uppercase leading-normal text-gray-700">Allow Video</label>
												<input type="checkbox" name="participantAllowVideo" id="participantAllowVideo" checked={videoChat.participantAllowVideo} onChange={handleVideoChatSettingChange} />
											</div>

											<div className="flex justify-between mt-2">
												<label htmlFor="participantStartVideo" className="block text-xs font-light uppercase leading-normal text-gray-700">Start with video on</label>
												<input type="checkbox" name="participantStartVideo" id="participantStartVideo" checked={videoChat.participantStartVideo} onChange={handleVideoChatSettingChange} />
											</div>

											<div className="flex justify-between mt-2">
												<label htmlFor="participantAllowSound" className="block text-xs font-light uppercase leading-normal text-gray-700">Allow Sound</label>
												<input type="checkbox" name="participantAllowSound" id="participantAllowSound" checked={videoChat.participantAllowSound} onChange={handleVideoChatSettingChange} />
											</div>

											<div className="flex justify-between mt-2">
												<label htmlFor="participantStartSound" className="block text-xs font-light uppercase leading-normal text-gray-700">Start with sound on</label>
												<input type="checkbox" name="participantStartSound" id="participantStartSound" checked={videoChat.participantStartSound} onChange={handleVideoChatSettingChange} />
											</div>

											<div className="flex justify-between mt-2">
												<label htmlFor="participantScreenShare" className="block text-xs font-light uppercase leading-normal text-gray-700">Allow Desktop Screen Share</label>
												<input type="checkbox" name="participantScreenShare" id="participantScreenShare" checked={videoChat.participantScreenShare} onChange={handleVideoChatSettingChange} />
											</div>
										</>}
									</div>
								</div>

							</>
						)}


					</div>
				</div>
				<div className="px-4 py-3 flex flex-col-reverse sm:flex-row justify-between items-center border-gray-300 border-t">
					{ defaultValues.id ? <a href="#" className="text-red-700 mx-auto sm:mx-0 mt-2 sm:mt-0" onClick={shouldDelete}>Delete</a> : <div />}
					<div className="flex items-center">
						<span className="text-gray-400 mr-2 mt-2 sm:mt-0 cursor-pointer" onClick={shouldModalClose}>Cancel</span>
						<button type="submit" disabled={isSubmitting} className="float-right btn-hub-green sm:w-auto sm:ml-2 mt-2 sm:mt-0 mr-0 sm:mr-0 flex items-center">
							{ !isSubmitting && 'Save' }
							{ isSubmitting && <><span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span></> }
						</button>
					</div>
				</div>
			</form>
		</div>

	</div>);
}
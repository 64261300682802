import React, { useState, createContext } from 'react';

const INITIAL_DISPLAY_AD = {
	label: '',
	type:  'event:horizontal',
	imageUrl: '',
	url: '',
	booth: null,
	event: '',
	isActive: false,
	order: 999999,
	startTime: '',
	endTime:   ''
}

export function randomIntFromInterval(min, max) { // min and max included
	return Math.floor(Math.random() * (max - min + 1) + min);
}

const INITIAL_STATE = { horizontal: [], vertical: [] };

const DisplayAdsContext = createContext([{}, (ads) => {}]);

function DisplayAdsProvider(props) {
	const [ state, setState ] = useState(INITIAL_STATE);

	function setAds(ads) {
		setState({
			vertical: (ads || []).filter(a => a.type.indexOf('vertical') > 0 && a.isActive),
			horizontal: (ads || []).filter(a => a.type.indexOf('horizontal') > 0 && a.isActive)
		});
	}

	return (
		<DisplayAdsContext.Provider value={[state, setAds]}>
			{props.children}
		</DisplayAdsContext.Provider>
	);
}

export { DisplayAdsContext, DisplayAdsProvider, INITIAL_STATE, INITIAL_DISPLAY_AD };

import React, { useMemo } from 'react';
import useMode from "./useMode";
import useEventData from "../data/useEventData";
import useCurrentUser from "./useCurrentUser";
import useAttendee from "../data/useAttendee";
import useExhibitorData from "../data/useExhibitorData";

export default function usePermissions() {
	const mode = useMode();
	const [ eventProfile ] = useEventData();
	const [ currentUser ] = useCurrentUser();
	const [ attendeeProfile ] = useAttendee();
	const [ exhibitorProfile  ] = useExhibitorData();
	const isEditMode = mode === 'edit';
	const isPreviewMode = mode === 'preview';

	let deps = [ currentUser, eventProfile, exhibitorProfile ];

	const isAdmin = useMemo(() => currentUser && currentUser.id && currentUser.type === 'admin', [currentUser]);

	const isOrganizer = useMemo(() => currentUser && currentUser.id && currentUser.type === 'organizer' && currentUser.scopes && currentUser.scopes.filter(s => s.type === 'event').map(s => s.id).indexOf(eventProfile.id) > -1, [currentUser, eventProfile]);

	const isExhibitor = useMemo(() => currentUser && currentUser.id && ['admin', 'organizer'].indexOf(currentUser.type) < 0, [currentUser]);

	const isAttendee = useMemo(() => attendeeProfile && attendeeProfile.token && attendeeProfile.authorized, [attendeeProfile]);

	const isCurrentExhibitor = useMemo(() => exhibitorProfile && currentUser && currentUser.id === exhibitorProfile.user, deps);

	const hasEventEditAccess = useMemo(() => isEditMode && (isAdmin || isOrganizer), deps);

	const hasExhibitorEditAccess = useMemo(() => isEditMode && (isAdmin || isCurrentExhibitor), deps);

	const hasPreviewAccess = useMemo(() => isPreviewMode && (isAdmin || isOrganizer || isExhibitor), deps);

	const accessibleOrders = useMemo(() => currentUser && currentUser.id && currentUser.booths ? currentUser.booths.map(b => b.id) : [], [currentUser])

	const isLoggedIn = useMemo(() => currentUser && currentUser.id && currentUser.token && top === window, [currentUser]);

	return Object.assign([ mode, isAdmin, isOrganizer, isExhibitor, isLoggedIn, hasPreviewAccess, hasEventEditAccess, hasExhibitorEditAccess, accessibleOrders, isAttendee, isCurrentExhibitor ], { mode, isAdmin, isOrganizer, isExhibitor, isAttendee, isLoggedIn, hasPreviewAccess, hasEventEditAccess, hasExhibitorEditAccess, accessibleOrders, isCurrentExhibitor });
}

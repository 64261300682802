import { useState, useMemo, useEffect } from "react";
import { isAfter, isBefore, addMinutes, differenceInMinutes } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import useEventData from "../../hooks/data/useEventData";
import useSessionData from "../../hooks/data/useSessionData";
import useInterval from "../../hooks/helper/useInterval";

export default function useSessionTimes() {
  const [ eventProfile ] = useEventData();
  const [ session ] = useSessionData();
  const duration = useMemo(() => {
    return session ? differenceInMinutes(zonedTimeToUtc(session.endTime, eventProfile.timezone), zonedTimeToUtc(session.startTime, eventProfile.timezone)) : 0;
  }, [ session ]);
  
  const [ sessionIsActive, setSessionIsActive ] = useState(session && isAfter(new Date(), zonedTimeToUtc(session.startTime, eventProfile.timezone)) && isBefore(new Date(), addMinutes(zonedTimeToUtc(session.startTime, eventProfile.timezone), duration)));

  useInterval(() => {
    setSessionIsActive(isAfter(new Date(), zonedTimeToUtc(session.startTime, eventProfile.timezone)) && isBefore(new Date(), addMinutes(zonedTimeToUtc(session.startTime, eventProfile.timezone), duration)));
  }, session ? 1000 : null);

  useEffect(() => {
    if (!session || session.programming && !session.programming.length) {
      return;
    }
    setSessionIsActive(isAfter(new Date(), zonedTimeToUtc(session.startTime, eventProfile.timezone)) && isBefore(new Date(), addMinutes(zonedTimeToUtc(session.startTime, eventProfile.timezone), duration)));
  }, [ session ]);

  return { sessionIsActive, duration }
}
import React, { useMemo, createElement } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import {PencilSVG} from "../../../components/Icons";
import AddToCalendarButton from "../AddToCalendarButton";
import usePermissions from "../../../hooks/app/usePermissions";
import useEventData from "../../../hooks/data/useEventData";
import { differenceInMinutes } from "date-fns";
import {zonedTimeToUtc} from "date-fns-tz";

export default function SessionCard({ session, handleEdit }) {
  const { hasEventEditAccess } = usePermissions();
  const { eventProfile } = useEventData();
  const duration = useMemo(() => {
    return session ? differenceInMinutes(zonedTimeToUtc(session.endTime, eventProfile.timezone), zonedTimeToUtc(session.startTime, eventProfile.timezone)) : 0;
  }, [ session ]);

  const hasEditAccess = hasEventEditAccess && handleEdit;

  let sessionUrl = `${!window.white_label_slug ? `/${eventProfile.urlSlug}` : ''}${session.isBoothSession ? '#booths' : '/'}${session.isMainHallSession ? 'mainhall/' : !session.isBoothSession ? 'session/' : ''}${!session.isBoothSession ? session._id : ''}`;
  let SessionContainer = session.isBoothSession ? HashLink : session.isMainHallSession || session.isPublished || hasEventEditAccess ? Link : ({ children, ...props }) => createElement('div', props, children);

  return (
      <div className={`flex w-full h-auto lg:h-session-card border-t border-b border-hub-border-grey ${ hasEventEditAccess && !session.isMainHallSession && !session.isPublished && !session.isTimeSlot ? 'bg-yellow-50' : '' }`} style={{borderLeft: `4px ${session.track ? session.track.color : 'transparent'} solid`, marginTop: '-1px'}}>
        <div className="h-full w-full sm:pl-auto flex flex-col md:flex-row flex-no-wrap md:flex-wrap lg:flex-no-wrap justify-between items-center">

          {session.isTimeSlot ? (
            <div className="flex flex-col md:flex-row w-full md:w-8/12 pl-6 p-2">
              <div className="mr-0 sm:mr-4 w-full md:w-7/12">
                <div className="font-medium text-lg leading-none">{session.name}</div>
                { duration > 0 ? <div className="text-black text-sm leading-none">{duration} minutes</div> : null }
              </div>
              <div className="text-sm leading-none flex mr-0 sm:mr-4 py-4 sm:py-0 md:w-5/12">{session.cardDescription}</div>
            </div>
          ) : (
            <SessionContainer to={sessionUrl} className="flex flex-col md:flex-row w-full md:w-8/12 pl-6 p-2">
              <div className="flex flex-col justify-between mr-0 sm:mr-4 h-full w-full sm:w-7/12">
                <div className="font-medium text-lg leading-none">{session.name}</div>
                {session.speakers && session.speakers.length > 0 && <div className="text-gray-500 text-sm">{session.speakers.slice(0, 6).map(s => s.name).join(', ')}</div>}
                { duration > 0 ? <div className="mt-1 text-black text-sm leading-none">{duration} minutes</div> : null }
              </div>

              { session.cardDescription && session.cardDescription.length > 0 && <div className="text-sm self-center leading-none flex mr-0 sm:mr-4 py-4 sm:py-0 md:w-5/12">
                {session.cardDescription}
              </div> }
            </SessionContainer>
          )}

          { (session.track || hasEditAccess) && <div className="flex relative md:justify-end md:items-center w-full pl-6 md:w-4/12">
              { session.isTimeSlot && session.track ? (
                  <div className="h-full w-full p-2 flex justify-end">
                      <label className="leading-none py-2 px-4 text-xs text-center rounded-full self-end featured-pill w-32 truncate" style={{backgroundColor: `${session.track.color}5C`}}>{session.track.name}</label>
                  </div>
              ) : !session.isTimeSlot && session.track ? (
                  <SessionContainer to={sessionUrl} className="h-full w-full p-2 flex justify-end">
                      <label className="leading-none cursor-pointer py-2 px-4 text-xs text-center rounded-full self-end featured-pill w-32 truncate" style={{backgroundColor: `${session.track.color}5C`}}>{session.track.name}</label>
                  </SessionContainer>
              ) : <div className="h-full w-full p-2 flex justify-end" /> }
            { !hasEditAccess && !session.isTimeSlot && (session.isPublished || session.isMainHallSession || session.isBoothSession) ?
              <div className="p-2">
                <AddToCalendarButton {...session} sessionUrl={`${window.location.origin}${session.isMainHallSession ? '/' + eventProfile.urlSlug : sessionUrl}`} buttonClasses="ml-4" />
              </div> :
              <div className="p-2 ml-auto sm:ml-10" />
            }
            { hasEditAccess && (
              <div className="p-2 rounded bg-gray-100 ml-3 flex items-center">
                <button className="flex" onClick={handleEdit}>
                  <PencilSVG classes="h-3 w-3 mr-2" /><span className="text-sm text-gray-700 leading-none">Edit</span>
                </button>
              </div>
            ) }
          </div>}

        </div>
      </div>
  )
}
import React from "react";
import useDeviceDetect from "../../hooks/helper/useDeviceDetect";

export default function BrowserUnsupported() {
  const detectDevice = useDeviceDetect();

  return (detectDevice.isIos() && detectDevice.isChrome() ? 
  <p className="bg-white mt-32 px-6 py-4 rounded container m-auto text-center">
    You'll need to switch browsers to use our virtual booth.
    <br />
    Try joining on Safari.
  </p> : 
  <p className="bg-white mt-32 px-6 py-4 rounded container m-auto text-center">
    You'll need to upgrade your browser to use our virtual booth.
    <br />
    See&nbsp;
    <a href="https://docs.daily.co/docs/browsers" target="_blank" className="text-hub-green underline">this page</a>
    &nbsp;for help getting on a supported browser version.
  </p>);
}

import { useRef } from 'react';

export default function useConstant(fn = () => console.warn('No function was passed to useConstant')) {
	const ref = useRef()

	if (!ref.current) {
		ref.current = { v: typeof fn === 'function' ? fn() : fn }
	}

	return ref.current.v
}
import React, { useEffect, useRef } from "react";
import usePermissions from "../../../../hooks/app/usePermissions";
import useCallObject from "../../../../hooks/data/useSessionCallObject";

export function LargeTile({ isLoading, isLocalPerson, videoTrack, audioTrack, participant }) {
  const videoEl = useRef(null);
  const audioEl = useRef(null);

  useEffect(() => {
    videoEl.current && (videoEl.current.srcObject = new MediaStream([videoTrack]));
  }, [ videoTrack ]);

  useEffect(() => {
    audioEl.current && (audioEl.current.srcObject = new MediaStream([audioTrack]));
  }, [ audioTrack ]);

  return (<div className={`flex relative justify-center bg-black rounded overflow-hidden tile large h-36 sm:h-full max-w-md local`}>
    { isLoading && <p className="loading">Loading...</p> }
    { isLocalPerson && <p className="absolute top-0 left-0 z-10 m-2 bg-hub-green px-2 py-1 rounded text-xs font-medium text-white">You</p> }
    { !isLocalPerson && participant && participant.user_name && <p className="absolute top-0 left-0 z-10 m-2 bg-white px-2 py-1 rounded text-xs font-medium text-black">{ participant.user_name }</p> }
    { videoTrack && <video className="w-full h-full relative max-w-none" autoPlay muted playsInline ref={ videoEl } /> }
    { !isLocalPerson && audioTrack && <audio autoPlay ref={ audioEl } /> }
  </div>);
}

export function SmallTile({ isLoading, isLocalPerson, videoTrack, audioTrack, participant }) {
  const videoEl = useRef(null);
  const audioEl = useRef(null);

  useEffect(() => {
    videoEl.current && (videoEl.current.srcObject = new MediaStream([videoTrack]));
  }, [ videoTrack ]);

  useEffect(() => {
    audioEl.current && (audioEl.current.srcObject = new MediaStream([audioTrack]));
  }, [ audioTrack ]);

  return (<div className={`flex relative justify-center bg-black rounded overflow-hidden w-56 mx-3 tile small ${ isLocalPerson && 'local'}`}>
    { isLoading && <p className="loading">Loading...</p> }
    { !audioTrack && <label className="absolute top-0 right-0 z-10 m-2 bg-red-500 px-2 py-1 rounded text-xs font-medium text-white">Muted</label> }
    { isLocalPerson && <p className="absolute top-0 left-0 z-10 m-2 bg-hub-green px-2 py-1 rounded text-xs font-medium text-white">You</p> }
    { !isLocalPerson && participant && participant.user_name && <p className="absolute top-0 left-0 z-10 m-2 bg-hub-green px-2 py-1 rounded text-xs font-medium text-black">{ participant.user_name }</p> }
    { videoTrack && <video className="h-full w-auto relative max-w-none" autoPlay muted playsInline ref={ videoEl } /> }
    { !isLocalPerson && audioTrack && <audio autoPlay ref={ audioEl } /> }
  </div>);
}

export function AudioTile({ isLocalPerson, audioTrack }) {
  const audioEl = useRef(null);

  useEffect(() => {
    audioEl.current && (audioEl.current.srcObject = new MediaStream([audioTrack]));
  }, [ audioTrack ]);

  return !isLocalPerson && audioTrack ? <audio autoPlay ref={ audioEl } /> : null;
}

export function ParticipantTile({ hasHostAccess : isLoggedIn, isLocalPerson, isScreenShare, soundOnly, videoTrack, audioTrack, participant, isActiveSpeaker }) {
  const [ callObject ] = useCallObject();
  const videoEl = useRef(null);
  const audioEl = useRef(null);

  useEffect(() => {
    videoEl.current && (videoEl.current.srcObject = new MediaStream([videoTrack]));
  }, [ videoTrack ]);

  useEffect(() => {
    audioEl.current && (audioEl.current.srcObject = new MediaStream([audioTrack]));
  }, [ audioTrack ]);

  function handleMuteUser() {
    callObject.updateParticipant(participant.session_id, { setAudio: false });
  }

  function handleStopVideoUser() {
    callObject.updateParticipant(participant.session_id, { setVideo: false });
  }

  function handleBootUser() {
    if (confirm('This will kick out the participant from the booth. Do you want to continue?')) {
      callObject.updateParticipant(participant.session_id, { eject: true });
    }
  }

  if (soundOnly) {
    return !isLocalPerson && audioTrack ? <audio autoPlay ref={ audioEl } /> : null;
  }

  return (<div className={`participantTile relative flex justify-center bg-black overflow-hidden tile ${ isActiveSpeaker && 'border-4 border-yellow-200' } ${ isLocalPerson && 'local'}`}>
    {/*{ isLoading && <p className="loading">Loading...</p> }*/}
    { isLoggedIn && !isLocalPerson && !isScreenShare && <section className="controls absolute top-0 bottom-0 left-0 right-0 z-10 transition-all duration-250 ease-in-out opacity-0 hover:opacity-100 bg-black bg-opacity-75 py-12 px-6 flex-col justify-center items-center">
      { participant && participant.audio && <button onClick={ handleMuteUser } className="shadow py-2 px-3 block bg-white text-sm text-gray-600 text-center mb-3 rounded w-32">Mute Audio</button> }
      { participant && participant.video && <button onClick={ handleStopVideoUser } className="py-2 px-3 block bg-white font-medium text-sm text-red-700 text-center mb-3 rounded w-32">Stop Video</button> }
      <button onClick={ handleBootUser } className="bg-red-500 py-2 px-3 block text-sm font-medium text-white text-center rounded w-32">Boot User</button>
    </section> }
    <div className="absolute top-0 right-0 z-10 flex flex-col m-2">
      { participant && participant.owner && !participant.user_id.includes('speaker') && <label className="mb-1 bg-yellow-300 px-2 py-1 rounded text-xs font-medium text-black text-center">Host</label> }
      { participant && participant.owner && participant.user_id.includes('speaker') && <label className="mb-1 bg-yellow-300 px-2 py-1 rounded text-xs font-medium text-black text-center">Speaker</label> }
      { participant && !participant.audio && <label className="mb-1 bg-red-500 px-2 py-1 rounded text-xs font-medium text-white text-center">Muted</label> }
      { participant && !participant.video && <label className="mb-1 bg-red-500 px-2 py-1 rounded text-xs font-medium text-white text-center">Video Off</label> }
    </div>
    { isLocalPerson && <label className="absolute top-0 left-0 z-10 m-2 bg-hub-green px-2 py-1 rounded text-xs font-medium text-white">You</label> }
    { !isLocalPerson && participant && participant.user_name && <label className="absolute top-0 left-0 z-10 m-2 bg-white px-2 py-1 rounded text-xs font-medium text-black">{ participant.user_name }</label> }
    { videoTrack && <video className={`${participant && participant.screen ? 'h-32' : 'h-full'} w-auto relative ${ isScreenShare ? "" : "max-w-none"}`} autoPlay muted playsInline ref={ videoEl } /> }
    { !isLocalPerson && audioTrack && <audio autoPlay ref={ audioEl } /> }
  </div>);
}

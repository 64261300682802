import { useContext, useEffect } from 'react';
import axios from 'axios';
import { EventContext, INITIAL_STATE as INITIAL_EVENT_STATE } from "../../context/event";
import useWhiteLabelSlugs from "../app/useWhitelabelSlugs";

export default function useEventData() {
	const { eventSlug } = useWhiteLabelSlugs();
	const [ eventProfile, setEventProfile ] = useContext(EventContext);

	function resetEventProfile() {
		setEventProfile(INITIAL_EVENT_STATE);
	}

	function loadEventData() {
		console.log('loadEventData called')
		if (state && state.urlSlug === eventSlug) {
			return state;
		}
		axios({
			url: `${process.env.API_HOST}/village/events/${eventSlug}`,
			method: 'get'
		}).then(response => {
			console.log('res', response);
			setEventProfile(state => ({ ...state, ...response.data }));
		});
	}

	function loadEventExhibitors() {
		console.log('load event exhibitors called')
		if (state.urlSlug === eventSlug) {
			return state.exhibitors;
		}
		axios({
			url: `${process.env.API_HOST}/village/events/${eventSlug}/orders`,
			method: 'get'
		}).then(response => {
			console.log(response);
			setEventProfile(state => ({ ...state, exhibitors: [...response.data] }));
		});
	}

	return Object.assign(
		[ eventProfile, setEventProfile, resetEventProfile ],
		{ eventProfile, setEventProfile, resetEventProfile }
	);
}
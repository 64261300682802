import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import { decode } from 'jsonwebtoken';

// hooks
import useLocalStorage from "../hooks/helper/useLocalStorage";
import useEventData from "../hooks/data/useEventData";
import useQueryParams from "../hooks/helper/useQueryParams";
import useCurrentUser, { WINDOW_NAME_PREVIEW } from "../hooks/app/useCurrentUser";
import useWhyDidYouUpdate from "../hooks/helper/useWhyDidYouUpdate";

const LOCAL_STORAGE_KEY = 'attendee_jwt';
const LOCAL_STORAGE_TYPE = 'attendee_type';

const INITIAL_STATE = {
	authorized: false,
	ticketNumber: null,
	token: null,
	error: ""
};

const AttendeeContext = createContext([{}, () => {}]);

function AttendeeProvider(props) {
	const query = useQueryParams();
	const secureTixParam = query.get('tix');
	const secureRegistrantId = query.get('reg');
	const [ state, setState ] = useState(INITIAL_STATE);
	const [ eventProfile ] = useEventData();
	const [ storedAttendeeKey, setStoredAttendeeKey, hasCheckedAttendeeKeyStorage ] = useLocalStorage(LOCAL_STORAGE_KEY);
	const [ storedAttendeeType, setStoredAttendeeType ] = useLocalStorage(LOCAL_STORAGE_TYPE);
	const [ user, logoutCurrentUser ] = useCurrentUser();

	useEffect(() => {
		console.log('user', user.token, 'state.token', state.token)
		let isLoggedInAsTwoUsers = user.token && state.token;
		if ( isLoggedInAsTwoUsers && secureTixParam) {
			logoutCurrentUser();
		} else if ( isLoggedInAsTwoUsers ) {
			logoutAttendee();
		}
	}, [user.token, state.token]);

	useEffect(() => {
		if (!storedAttendeeKey || !secureTixParam) return;

		if (storedAttendeeKey !== secureTixParam) {
			setStoredAttendeeKey(secureTixParam);
		}

	}, [storedAttendeeKey, secureTixParam]);

	useEffect(() => {
		if (secureTixParam) {
			window.name = WINDOW_NAME_PREVIEW;
		}
	}, [secureTixParam]);
	
	useEffect(() => {
		if (!eventProfile.attendeePassword && !eventProfile.onlyAllowTicketHolders && !storedAttendeeKey) {
			setState(s => ({...s, authorized: true}));
			return;
		} else if (!storedAttendeeKey){
			setState(s => ({...s, authorized: false}));
			return;
		}

		let decoded = decode(storedAttendeeKey);

		if (decoded && Math.round((new Date()).getTime() / 1000) > decoded.exp && storedAttendeeType !== 'ticket') {
			setStoredAttendeeKey(null);
			setStoredAttendeeType(null);
			setState(s => ({...s, authorized: false, ticketNumber: null, token: null, error: ""}));
		} else if (decoded) {
			setState(s => ({...s, authorized: true, error: "", ...decoded}));
			if (storedAttendeeType === 'ticket') {
				checkTicketValidity();
			}
		}
	}, [storedAttendeeKey, eventProfile]);

	useEffect(function() {
		if (!secureTixParam || !hasCheckedAttendeeKeyStorage || hasCheckedAttendeeKeyStorage && storedAttendeeKey) {
			return;
		}
		checkTicket(eventProfile.id, null, secureTixParam);
	}, [secureTixParam , eventProfile.id]);

	useEffect(function() {
		if (!secureRegistrantId || !hasCheckedAttendeeKeyStorage || hasCheckedAttendeeKeyStorage && storedAttendeeKey) {
			return;
		}
		checkTicket(eventProfile.id,  secureRegistrantId);
	}, [secureRegistrantId , eventProfile.id]);

	function checkPassword(eventId, password) {
		axios({
			url: `${ process.env.API_HOST }/village/events/${ eventId }/attendee/password-verify`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest'
			},
			data: {
				password: password
			}
		}).then((response) => {
			setState(s => ({...s, authorized: true, error: ""}));
			setStoredAttendeeKey(response.data.jwt);
			setStoredAttendeeType('password');
		}).catch((e) => {
			let message;
			switch (e.response.status) {
				case 400: {
					message = "Unable to find this event. Please verify the URL or contact the event organizer for more information.";
					break;
				}
				case 401: {
					let attemptsRemaining = e.response.data.attemptsRemaining;
					let attemptsText = attemptsRemaining + (attemptsRemaining === 1 ? " attempt" : " attempts");
					message = `Invalid password. You have ${attemptsText} remaining.`;
					break;
				}
				case 403: {
					let ms = e.response.data.timeRemaining;
					let seconds = Math.floor(ms / 1000);
					let minutes = Math.floor(seconds / 60);
					let timeText;
					if (minutes === 0) {
						timeText = seconds + (seconds === 1 ? " second" : " seconds")
					} else {
						timeText = minutes + (minutes === 1 ? " minute" : " minutes");
					}
					message = `Invalid password. Please wait ${timeText} before trying again.`;
					break;
				}
				default: message = "An error occurred. Please try again later or contact the event organizer for more information.";
			}
			setState(s => ({...s, authorized: false, error: message}));
			setStoredAttendeeKey(null);
		});
	}

	function checkTicket(eventId, ticket, token) {
		if (!eventId || !ticket && !token) {
			return;
		}
		axios({
			url: `${ process.env.API_HOST }/village/events/${ eventId }/attendee/ticket-verify`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest'
			},
			data: {
				ticketNumber: ticket,
				accessToken: token
			}
		})
			.then((response) => {
			setState(s => ({...s, authorized: true, ticketNumber: ticket, token: response.data.jwt, ...decode(response.data.jwt), error: ""}));
			setStoredAttendeeKey(response.data.jwt);
			setStoredAttendeeType('ticket');
		})
			.catch((e) => {
			let message;
			switch (e.response.status) {
				case 404: {
					message = "Unable to find this event. Please verify the URL or contact the event organizer for more information.";
					break;
				}
				case 429: {
					message = `Your ticket has already been used ${ eventProfile.attendeeMaxAccessUsage } times. Please contact the event organizer for more information on limiting and usage.`;
					break;
				}
				case 401: {
					message = `Your ticket has been revoked. Please contact the event organizer for more information.`;
					break;
				}
				case 402: {
					let attemptsRemaining = e.response.data.attemptsRemaining;
					let attemptsText = attemptsRemaining + (attemptsRemaining === 1 ? " attempt" : " attempts");
					message = `You've enter the wrong ticket, you have ${ attemptsText } remaining.`;
					break;
				}
				case 403: {
					let ms = e.response.data.timeRemaining;
					let seconds = Math.floor(ms / 1000);
					let minutes = Math.floor(seconds / 60);
					let timeText;
					if (minutes === 0) {
						timeText = seconds + (seconds === 1 ? " second" : " seconds")
					} else {
						timeText = minutes + (minutes === 1 ? " minute" : " minutes");
					}
					message = `You've enter the wrong ticket too many times, please wait ${timeText} before trying again.`;
					break;
				}
				default: message = "An error occurred. Please try again later or contact the event organizer for more information.";
			}
			setState(s => ({...s, authorized: false, ticketNumber: null, token: null, error: message}));
			setStoredAttendeeKey(null);
		});
	}

	function checkTicketValidity() {
		axios({
			url: `${ process.env.API_HOST }/village/check-ticket-validity`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest'
			},
			data: {
				accessToken: storedAttendeeKey
			}
		}).then((res) => {
			console.log(decode(storedAttendeeKey))
			setState(s => ({...s, ...decode(storedAttendeeKey), token: storedAttendeeKey }));
		}).catch((e) => {
			setState(s => ({...s, authorized: false, ticketNumber: null, token: null, error: 'There appears to be an issue with your ticketed access, please contact the event organizer.'}));
			setStoredAttendeeKey(null);
			setStoredAttendeeType(null);
		});
	}

	function logoutAttendee() {
		setStoredAttendeeKey(null);
		setStoredAttendeeType(null);
		setState(INITIAL_STATE);
	}

	return (
		<AttendeeContext.Provider value={[state, checkPassword, checkTicket, logoutAttendee]}>
			{ props.children }
		</AttendeeContext.Provider>
	);
}

export { AttendeeContext, AttendeeProvider, INITIAL_STATE };
import React, { useState } from "react";
import axios from "axios";
import TextEditor from "../../../components/TextEditor";
import useConfig from "../../../hooks/app/useConfig";
import useEventData from "../../../hooks/data/useEventData";
import useCurrentUser from "../../../hooks/app/useCurrentUser";
import useAttendee from "../../../hooks/data/useAttendee";

export default function EditDescriptionArea({ session, onComplete }) {
	const { apiHost } = useConfig();
	const [ attendee ] = useAttendee();
	const [ currentUser ] = useCurrentUser();
	const [ eventProfile ] = useEventData();
	const [ descriptionForm, setDescriptionForm ] = useState({ show: true, value: session.description, submitting: false });

	function submitNewDescription(e) {
		e.preventDefault();
		setDescriptionForm({ ...descriptionForm, submitting: true});
		axios({
			url: `${apiHost}/village/events/${eventProfile.id}/sessions/${session._id}`,
			method: 'POST',
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': `Bearer ${ currentUser.token ? currentUser.token : attendee.token }`
			},
			data: { description: descriptionForm.value }, // we replace on the server so we need to make sure everything is there now.
		}).then((res) => {
			if (onComplete) {
				onComplete({ description: descriptionForm.value });
			}
		}).catch(e => {
			setDescriptionForm(state => ({ ...state, submitting: false, error: e?.message}));
		});
	}

	function cancelNewDescription(e) {
		e.preventDefault();
		if (onComplete) {
			onComplete();
		}
	}

	return (<section className="flex flex-col mb-20">
		<div className="mb-3 flex flex-col sm:flex-row justify-between">
			<h2 className='inline uppercase text-sm flex items-center'>Description</h2>
			{ !descriptionForm.submitting && (
				<div className="flex flex-col-reverse sm:flex-row items-center">
					<span className="text-gray-400 mx-auto sm:mx-0 mt-2 sm:mt-0 cursor-pointer" onClick={cancelNewDescription}>Cancel</span>
					<button className="btn-hub-green sm:w-auto ml-0 sm:ml-2 mt-2 sm:mt-0 text-sm"
									onClick={submitNewDescription}>Save Description Changes</button>
				</div>
			) }
			{ descriptionForm.submitting && (
				<button className="btn-hub-green sm:w-auto sm:ml-0 mt-2 sm:mt-0 sm:mr-0 flex text-sm items-center">
					<span className="spinner h-5 w-5 inline-block" />&nbsp;<span>Saving...</span>
				</button>
			) }
		</div>
		{ descriptionForm.show && (
			<TextEditor value={descriptionForm.value} handleChange={(value) => setDescriptionForm(state => ({ ...state, value }))} />
		) }
	</section>)
}
import React, { useState, useEffect } from 'react';
import { differenceInSeconds } from 'date-fns';

export default function Countdown({ endTime }) {
  const totalSeconds = 60;
  const firstThirdOver = totalSeconds * (2/3);
  const secondThirdOver = totalSeconds * (1/3);

  
  const [ secondsUntilChatEnds, setSecondsUntilChatEnds ] = useState(-1);
  const [ status, setStatus ] = useState('beginning');

  if (!endTime) return null;

  useEffect(() => {
    if (secondsUntilChatEnds >= firstThirdOver) {
      setStatus('beginning');
    } else if (secondsUntilChatEnds >= secondThirdOver) {
      setStatus('middle');
    } else if (secondsUntilChatEnds < secondThirdOver) {
      setStatus('ending');
    }
  }, [secondsUntilChatEnds]);

  useEffect(() => {
    let now = new Date();
    setSecondsUntilChatEnds(differenceInSeconds(endTime, now));

    let interval = setInterval(() => {
      setSecondsUntilChatEnds(s => s-1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  let backgroundColor = status === 'beginning' ? 'bg-green-100' : status === 'ending' ? 'bg-red-50' : 'bg-yellow-50';
  let textColor = status === 'beginning' ? 'text-green-800' : status === 'ending' ? 'text-red-800' : 'text-yellow-800';

  return (secondsUntilChatEnds <= 60 ? 
    <div className={`w-full text-center text-xs py-1 ${backgroundColor} ${textColor}`}>
      <p>Time Left: { secondsUntilChatEnds >= 0 ? secondsUntilChatEnds : 0 } seconds</p>
    </div> : 
  null);
}
import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loading from '../../../components/Loading';
import useAdminData from '../../../hooks/data/useAdminData';
import useEventData from "../../../hooks/data/useEventData";
import { truncate } from "../../../utils/text";

export default function Events() {
	let history = useHistory();
	const { events } = useAdminData();
	let { resetEventProfile } = useEventData();

	useEffect(() => {
		resetEventProfile();
	}, []);

	if (!events) {
		return <Loading>Waiting for Event Data...</Loading>
	}

	function navigateTo(url) {
		return (e) => {
			e.preventDefault();
			history.push(url);
		}
	}

	return (<>
		<Helmet>
			<title>View All Virtual Village Events | Admin</title>
		</Helmet>
		<section className="w-full h-full">
			<main className="container mx-auto mt-6 bg-white rounded p-6 pb-12">
				<h1 className="text-xl mb-3">All Events with the Virtual Village Upgrade</h1>
				<div className="flex flex-col">
					<div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
						<div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
							<table className="min-w-full max-w-full header-w-strip">
								<thead>
									<tr>
										<th className="w-10/12">Name</th>
										<th className="w-2/12">Status</th>
									</tr>
								</thead>
								<tbody className="bg-white">
								{ events.filter(e => e.villageProfile.name && e.villageProfile.urlSlug).map(({ villageProfile }, index) =>
									<tr key={index} className={`${ index % 2 && 'bg-gray-50'} hover:bg-green-100 cursor-pointer`}>
											<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
												<Link to={`/${villageProfile.urlSlug}`}>
													<div className="flex items-center">
														<div className="flex-shrink-0 h-20 w-20">
															{ villageProfile.headerLogo ? <div className="h-full w-full rounded bg-gray-200 flex items-center justify-center">
																<img className="max-h-full w-auto" src={ villageProfile.headerLogo } alt="" />
															</div>: <div className="flex h-full items-center justify-center">
																<span className="block h-1/2 w-full" />
															</div> }
														</div>
														<div className="ml-4">
															<div className="text-sm leading-5 font-medium text-gray-900">{ truncate(villageProfile.name, 80) }</div>
															<div className="text-sm leading-5 text-gray-500 font-mono">/{ truncate(villageProfile.urlSlug, 80) }</div>
														</div>
													</div>
												</Link>
											</td>
											<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 w-auto">
												{ villageProfile.isActive && <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Published</span> }
											</td>
									</tr>) }
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</main>
		</section>
	</>);
}
import React from 'react';
import { quillFormats, quillModules } from "../../constants";
import ReactQuill, { Quill } from "react-quill";
import useConstant from "../../hooks/helper/useConstant";

function extractVideoUrl(url) {
	let match =
		url.match(
			/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/,
		) ||
		url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/);
	if (match) {
		return `${match[1] || 'https'}://www.youtube.com/embed/${
			match[2]
		}?showinfo=0`;
	}
	// eslint-disable-next-line no-cond-assign
	if ((match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))) {
		return `${match[1] || 'https'}://player.vimeo.com/video/${match[2]}/`;
	}
	return url;
}

// https://vimeo.com/1084537
const BlockEmbed = Quill.import("blots/block/embed");
const Link = Quill.import("formats/link");

Link.sanitize = (url) => {
	return url.match(/^(https?:\/\/)/g) ? url : 'http://' + url;
}

class EmbedResponsive extends BlockEmbed {
	static blotName = "embed-responsive";
	static tagName = "DIV";
	static className = "fitVids-wrapper";

	static create(value) {
		const node = super.create(value);

		const child =  document.createElement("iframe");
		child.setAttribute('frameborder', '0');
		child.setAttribute('allowfullscreen', true);
		child.setAttribute('src', this.sanitize(value));
		child.classList.add("embed-responsive-item");

		node.appendChild(child);

		return node;
	}

	static sanitize(url) {
		return extractVideoUrl(Link.sanitize(url));
	}

	static value(domNode) {
		const iframe = domNode.querySelector('iframe');
		return iframe.getAttribute('src');
	}
}

Quill.register(EmbedResponsive);

const CustomToolbar = () => (
	<div id="text-editor-toolbar">
		<span className="ql-formats">
      <select className="ql-font" />
			<select className="ql-header" onChange={e => e.persist()} />
    </span>
		<span className="ql-formats">
			<button className="ql-bold" />
			<button className="ql-italic" />
			<button className="ql-underline" />
			<button className="ql-strike" />
			<button className="ql-blockquote" />
    </span>
		<span className="ql-formats">
      <button className="ql-direction" value="rtl"/>
      <select className="ql-align" />
    </span>
		<span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
		<span className="ql-formats">
			<select className="ql-color" />
			<select className="ql-background" />
    </span>
		<span className="ql-formats">
      <button className="ql-script" value="sub" />
      <button className="ql-script" value="super" />
    </span>
		<span className="ql-formats">
      <button className="ql-link"/>
      <button className="ql-image"/>
      <button className="ql-embed-responsive">
				<svg viewBox="0 0 18 18">
					<rect className="ql-stroke" height="12" width="12" x="3" y="3" />
					<rect className="ql-fill" height="12" width="1" x="5" y="3" />
					<rect className="ql-fill" height="12" width="1" x="12" y="3" />
					<rect className="ql-fill" height="2" width="8" x="5" y="8" />
					<rect className="ql-fill" height="1" width="3" x="3" y="5" />
					<rect className="ql-fill" height="1" width="3" x="3" y="7" />
					<rect className="ql-fill" height="1" width="3" x="3" y="10" />
					<rect className="ql-fill" height="1" width="3" x="3" y="12" />
					<rect className="ql-fill" height="1" width="3" x="12" y="5" />
					<rect className="ql-fill" height="1" width="3" x="12" y="7" />
					<rect className="ql-fill" height="1" width="3" x="12" y="10" />
					<rect className="ql-fill" height="1" width="3" x="12" y="12" />
				</svg>
			</button>
    </span>
		<span className="ql-formats">
      <button className="ql-clean"/>
    </span>
	</div>
);

export default function TextEditor({ value, handleChange }) {
	const modules = useConstant(quillModules);
	const formats = useConstant(quillFormats);

	return (<>
		<CustomToolbar />
		<ReactQuill className="w-full h-128 pb-12"
								placeholder={"Write something here..."}
								theme={'snow'}
								modules={ modules }
								formats={ formats }
								defaultValue={ value }
								onChange={ handleChange } />
	</>);
}